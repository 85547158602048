import { useState, useEffect, useCallback, useRef } from 'react';

const DEFAULT_OPTION: IntersectionObserverInit = {
  root: null,
  threshold: 0.5,
  rootMargin: '0px',
};

export default function useIntersect(option: Partial<IntersectionObserverInit> = DEFAULT_OPTION) {
  const ref: React.MutableRefObject<any> | null = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const intersectionOptions: IntersectionObserverInit = Object.assign(DEFAULT_OPTION, option);

  const getObserver = useCallback(() => {
    if (!ref.current) {
      ref.current = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      }, intersectionOptions);
    }

    return ref.current as IntersectionObserver;
  }, [intersectionOptions]);

  const observer = getObserver();
  const subscribe = useCallback(
    (node: HTMLElement | null) => {
      if (node) {
        observer.observe(node);
      }
    },
    [observer],
  );
  const unsubscribe = useCallback(() => observer.disconnect(), [observer]);

  useEffect(() => () => observer.disconnect(), [observer]);

  return { isIntersecting, subscribe, unsubscribe };
}
