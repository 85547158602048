import React from 'react';
import PropTypes from 'prop-types';

import {
  desktopIcons,
  mobileIcons,
} from '../../../../assets/images/cross-sell/jeju';
import CrossSellJejuSection from './CrossSellJejuSection';
import styles from './CrossSellJejuSectionRentCar.module.scss';
import sectionTypes from '../../utils/section';
import { handleRentalCarClick } from '../../utils/jejuSendEvent';

const rentcars = ['morning', 'carnival', 'minicoup', 'mustang', 'tesla'];
const sectionData = sectionTypes.rentcar;

function CrossSellJejuRentCar(props) {
  const { isMobile, isWebView } = props;
  const icons = isMobile ? mobileIcons : desktopIcons;

  return (
    <CrossSellJejuSection {...props} {...sectionData}>
      <div className={styles.container}>
        {rentcars.map((rentcar) => (
          <a
            key={rentcar}
            href={isWebView ? sectionData.deeplink : sectionData.linkPath}
            className={styles.rentcar}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleRentalCarClick(isWebView)}
          >
            <img className={styles.image} src={icons[rentcar]} alt={rentcar} />
          </a>
        ))}
      </div>
      <p className={styles.desc}>
        *특정일 기준이며, 실제 사용일에 따라 가격이 변경될 수 있습니다.
      </p>
    </CrossSellJejuSection>
  );
}

CrossSellJejuRentCar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isWebView: PropTypes.bool.isRequired,
};

export default CrossSellJejuRentCar;
