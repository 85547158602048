import React from 'react';

import PropTypes from 'prop-types';

import { reviewTypeEnum } from '../../../enum';
import useResponsive from '../../../hooks/useResponsive';
import Rating from '../../starRating/StarRating';

import styles from './CardRating.module.scss';

function renderReviewCount(type, count) {
  if (type === reviewTypeEnum.mrt) {
    return count.toLocaleString();
  }

  return `후기 ${count >= 99 ? '99+' : count}개`;
}

function CardRating(props) {
  const { score, type, reviewCount } = props;
  const { isMobile } = useResponsive();

  if (!reviewCount) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Rating rating={score} size={isMobile ? 's' : 'm'} />
      <span className={styles.review}>{renderReviewCount(type, reviewCount)}</span>
    </div>
  );
}

CardRating.propTypes = {
  score: PropTypes.number,
  type: PropTypes.string,
  reviewCount: PropTypes.number,
};

CardRating.defaultProps = {
  score: 0,
  type: reviewTypeEnum.mrt,
  reviewCount: 0,
};

export default CardRating;
