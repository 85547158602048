import React from 'react';
import HotelEventBanner from '../../components/flight/HotelEventBanner';
import { initComponent, renderReactComponent } from './share';

function renderSkyScannerBanner() {
  const { pathname } = window.location;
  const fromPC = pathname.includes('AIRINTSCH0100100210.k1');
  const fromMobile = pathname.includes('AIRMBLSCH0100100210.k1');

  if (!fromPC && !fromMobile) {
    return;
  }

  let targetParent = null;
  let targetRef = null;

  if (fromPC) {
    targetParent = document.getElementById('confirmDetail');
    targetRef = document.getElementsByClassName('mrt_modal_header')[0];
  }

  if (fromMobile) {
    targetParent = document.getElementById('popDetail');
    targetRef = document.getElementById('detailInfoDiv');

    targetRef.setAttribute('style', 'position: relative');
  }

  const targetDiv = targetParent.insertBefore(initComponent('HotelEventBanner'), targetRef);

  renderReactComponent(<HotelEventBanner />, targetDiv);
}

export default renderSkyScannerBanner;
