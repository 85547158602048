import {
  shape,
  arrayOf,
  oneOf,
  bool,
  string,
  number,
  oneOfType,
} from 'prop-types';
import {
  stayFacilityEnum,
  stayCategoryEnum,
  staySortEnum,
  roomAmenityEnum,
  stayServiceEnum,
  stayPlaceTypeEnum,
  stayCouponTypeEnum,
} from '../../enum';

export const stayPopularCityType = shape({
  name: string,
  latitude: number,
  longitude: number,
  additionalInformation: string,
  type: string,
});
// 화면에서 사용하는 autocomplete 변환 타입 (autocomplete -> placeSuggest)
export const stayPlaceSuggestionType = shape({
  id: string,
  label: string,
  name: string,
  latitude: number,
  longitude: number,
  type: oneOf(Object.values(stayPlaceTypeEnum)),
  gid: number,
});

export const stayCategoryType = oneOf(Object.keys(stayCategoryEnum));
export const stayFacilityType = oneOf(Object.keys(stayFacilityEnum));
export const stayServiceType = oneOf(Object.keys(stayServiceEnum));
export const roomAmenityType = oneOf(Object.values(roomAmenityEnum));

export const stayFilterType = shape({
  categories: stayCategoryType,
  facilities: arrayOf(stayFacilityType),
  services: arrayOf(stayServiceType),
  maxPrice: number,
  minPrice: number,
});

export const stayFilterOptionItemType = shape({
  code: string,
  label: string,
});

export const stayFilterOptionType = shape({
  categories: string,
  facilities: arrayOf(stayFilterOptionItemType),
  services: arrayOf(stayFilterOptionItemType),
  applyPublicPromo: bool,
});

export const stayServerRenderedSearchTermType = shape({
  /** 체크인 날짜 (YYYY-MM-DD) */
  checkin: string,
  /** 체크아웃 날짜 (YYYY-MM-DD) */
  checkout: string,
  /** 성인 수 */
  numberOfAdults: number,
  /** 어린이 수 */
  numberOfChildren: number,
  /** 어린이 나이 */
  ageOfChildren: arrayOf(number),
  /** filter */
  filter: stayFilterOptionType,
  /** 국가, 도시, 장소 등의 정보 */
  place: shape({
    latitude: number,
    longitude: number,
    placeId: string,
    placeName: string,
    gid: number,
    type: oneOf(Object.values(stayPlaceTypeEnum)),
  }),
});

export const staySearchTermType = shape({
  /** 체크인 날짜 (YYYY-MM-DD) */
  checkin: string,
  /** 체크아웃 날짜 (YYYY-MM-DD) */
  checkout: string,
  /** 성인 수 */
  numberOfAdults: number,
  /** 어린이 수 */
  numberOfChildren: number,
  /** 어린이 나이 */
  ageOfChildren: arrayOf(number),
  /** filter */
  filter: stayFilterType,
  /** 국가, 도시, 장소 등의 정보 */
  place: shape({
    latitude: number,
    longitude: number,
    placeId: string,
    placeName: string,
    gid: number,
    type: oneOf(Object.values(stayPlaceTypeEnum)),
  }),
});

export const staySortType = oneOf(Object.values(staySortEnum));

export const stayPriceType = shape({
  salePrice: number,
  basePrice: number,
  discountrate: number,
  priceForOne: number,
});

export const stayRatePlanType = shape({
  rateplanId: oneOfType([string, number]),
  package: bool,
  packageDesc: string,
  supplier: string,
  freeCancelDate: string,
  name: string,
  rateplanName: string,
  couponLabel: string,
  price: stayPriceType,
  refundPolicies: arrayOf(
    shape({
      until: string,
      amount: number,
      percentage: number,
    }),
  ),
});

export const stayRoomItemType = shape({
  id: number,
  name: string,
  capacity: string, // 투숙 가능 인원 정보
  freeCancelText: string, // 무료 취소 문구
  desc: string,
  compistion: shape({
    size: string,
    layout: string,
    beds: string,
  }), // 시설 정보
  tags: shape({
    amenities: arrayOf(string),
    policies: arrayOf(string),
    types: arrayOf(string),
    views: arrayOf(string),
  }),
  props: shape({
    amenities: arrayOf(string),
    policies: arrayOf(string),
    types: arrayOf(string),
    views: arrayOf(string),
  }),
  images: arrayOf(string),
  items: arrayOf(stayRatePlanType),
});

export const stayRoomType = shape({
  totalCount: number,
  gdsPropertyId: oneOfType([string, number]),
  nights: number,
  price: stayPriceType,
  refundPolicy: string,
  rooms: arrayOf(stayRoomItemType),
  // ONDA 조회 시 식별자 맵핑이 불가하여 이름으로 Like 검색을 함
  // 이 때문에 검색 조건에 부합하진 않지만 유사도를 지닌 목록을 mismatched로 내려줌
  mismatched: arrayOf(stayRoomItemType),
});

// 태그 표시를 위한 텍스트 정보
export const stayTagType = shape({
  categories: arrayOf(string),
  facilities: arrayOf(string),
  services: arrayOf(string),
});

// 상세화면 아이콘 표시를 위한 코드 정보
export const stayPropsType = shape({
  categories: arrayOf(string),
  facilities: arrayOf(string),
  services: arrayOf(string),
});

export const stayItemType = shape({
  gid: number,
  offerId: number,
  name: string,
  address: string,
  checkin: string,
  checkout: string,
  price: stayPriceType,
  available: bool, // 예약 가능 여부
  cancelable: bool,
  applyPublicPromo: bool, // 임시쿠폰 - 대국민숙소 쿠폰
  couponLabel: string, // 마리트 쿠폰 사용 가능 금액
  capacity: string, // 투숙 가능 인원 정보
  summary: string, // 한줄 요약
  city: shape({
    key: string,
    name: string,
  }),
  latitude: number,
  logitude: number,
  star: number, // 평점
  desc: string, // 숙소 안내
  reservDesc: string, // 예약 안내
  images: arrayOf(string),
  tags: stayTagType,
  props: stayPropsType,
  distance: number,
});

export const stayComparisonType = shape({
  supplierId: number,
  supplierCode: string,
  supplierLabel: string,
  price: number,
  lowest: bool,
});

export const stayCouponType = oneOf(Object.values(stayCouponTypeEnum));

export const stayProductCouponType = shape({
  templateId: number,
  flatAmount: number,
  couponType: string,
  bannerText: string,
  disabledBannerText: string,
});

export const stayBannerType = shape({
  app_link: shape({
    android: string,
    ios: string,
  }),
  id: number,
  image: shape({
    desktop: string,
    mobile: string,
  }),
  link: string,
  title: string,
});

export const stayHotelBanefitBannerType = shape({
  imageUrl: string,
  landingUrl: string,
});
