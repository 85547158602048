import React from 'react';
import PropTypes from 'prop-types';

import styles from './FlightSeatInfo.module.scss';

function FlightSeatInfo(props) {
  const { data } = props;

  return <span className={styles.seatInfo}>{data.SEAT_GRAD}</span>;
}

FlightSeatInfo.propTypes = {
  data: PropTypes.object,
};

export default FlightSeatInfo;
