function getVersionInfo(userAgent: string, keyList: string[], separator = '/traveler;') {
  const versionInfo = userAgent.split(' ').find((userAgentString) => {
    const matched = keyList.find((key) => userAgentString.includes(key));

    return Boolean(matched);
  });

  if (!versionInfo) {
    return null;
  }

  // 해당하는 플랫폼키가 없는 경우 undefined를 return
  // 해당하는 플랫폼키가 있을 경우 ['ios', '6.12.0']의 형태
  const [platform, versionString] = versionInfo.split(separator);

  // 버전 문자열 뒤에 예상치 못한 문자열이 추가되는 케이스에도 버전 정보를 추출할 수 있도록 한다.
  // ex) ios/traveler;7.6.0;resolution=3x
  const versionRegx = new RegExp('^[^;]+');
  const matchedVersion = versionString.match(versionRegx)?.[0] ?? '';
  const version = versionString.includes(';') ? matchedVersion : versionString;

  return [platform, version];
}

export function getGuideAppVersion(userAgent: string) {
  if (!userAgent) {
    return;
  }

  const androidPlatformKey = 'android/guide;';
  const iosPlatformKey = 'ios/guide;';

  const versionInfo = getVersionInfo(userAgent, [androidPlatformKey, iosPlatformKey], '/guide;');

  if (!versionInfo) {
    return;
  }

  return versionInfo;
}

export function getMobileAppVersion(userAgent: string) {
  if (!userAgent) {
    return;
  }

  const androidPlatformKey = 'android/traveler;';
  const iosPlatformKey = 'ios/traveler;';

  const versionInfo = getVersionInfo(userAgent, [androidPlatformKey, iosPlatformKey]);

  if (!versionInfo) {
    return;
  }

  return versionInfo;
}
