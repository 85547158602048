const language = {
  title: 'The best way\nto reach Korean travelers!',
  subTitle: `Become MyRealTrip's partner today`,
  registPartner: 'Apply now',
  cover: 'Main image',
  withMyreltrip: 'Benefits for our partners',
  whyTogether: [
    {
      key: 'why1',
      title: 'No Additional Fees',
      description:
        'We do not charge listing or marketing fees to our partners. Also, both VAT and credit card transaction fees are covered by us.',
    },
    {
      key: 'why2',
      title: 'Best way to reach Korean travelers',
      description: `MyRealTrip is Korea's No.1 travel platform with the largest number of users, transactions, and inventory.`,
    },
    {
      key: 'why3',
      title: 'Marketing Support',
      description:
        'We have a strong presence in all major marketing channels, including top search engines and social media, which we use to advertise our partners’ products.',
    },
    {
      key: 'why4',
      title: 'Easy & Convenient Operation',
      description:
        'You can use our partner page to easily manage reservations, calendar, and payments whenever and wherever you are.',
    },
  ],
  becomePartner: 'Anyone (individual/corporate) can apply to be our partner!',
  tabs: [
    {
      key: 'tour',
      label: 'Tour',
    },
    {
      key: 'ticket',
      label: 'Ticket',
    },
    {
      key: 'hotdeal',
      label: 'Hot Deal',
    },
    {
      key: 'lodging',
      label: 'Lodging',
    },
  ],
  tabItems: [
    {
      key: 1,
      process: [
        {
          key: 'tab1-1',
          title: 'Create an Account',
          description:
            'After registering as a member of MyRealTrip, apply to become a partner on our partner page with your registered account.',
        },
        {
          key: 'tab1-2',
          title: 'Register Your Tour',
          description: 'Register a tour you wish to sell and upload basic documents.',
          caption:
            '*Required Documents: Individual Partner (Certificate of Residence), Corporate Partner (Business License)',
        },
        {
          key: 'tab1-3',
          title: 'Interview & Document Verification',
          description: 'Complete a video interview and submit additional documents if required.',
        },
        {
          key: 'tab1-4',
          title: 'Meet the first customer!',
          description: 'Time to introduce your own unique tours to Korean travelers!',
        },
      ],
    },
    {
      key: 2,
      process: [
        {
          key: 'tab2-1',
          title: 'Create an Account',
          description:
            'After registering as a member of MyRealTrip, apply to become a partner on our partner page with your registered account.',
        },
        {
          key: 'tab2-2',
          title: 'Register Your Ticket',
          description: 'Register a ticket you wish to sell and upload basic documents.',
        },
        {
          key: 'tab2-3',
          title: 'Interview & Document Verification',
          description: 'Complete a video interview and submit additional documents if required.',
        },
        {
          key: 'tab2-4',
          title: 'Meet the first customer!',
          description: 'Time to introduce your own unique travel products to Korean travelers!',
        },
      ],
    },
    {
      key: 3,
      process: [
        {
          key: 'tab3-1',
          title: 'Create an Account',
          description:
            'After registering as a member of MyRealTrip, apply to become a partner on our partner page with your registered account.',
        },
        {
          key: 'tab3-2',
          title: 'Submit Documents',
          description: 'Upload all required documents.',
        },
        {
          key: 'tab3-3',
          title: 'Manager Assignment',
          description: 'Based on the information you provide, an agent from MyRealTrip will contact you seperately.',
        },
        {
          key: 'tab3-4',
          title: 'Product Curation and Sale',
          description: 'Time to introduce your own unique travel products to Korean travelers!',
        },
      ],
    },
    {
      key: 4,
      process: [
        {
          key: 'tab4-1',
          title: 'Create an Account',
          description:
            'After registering as a member of MyRealTrip, apply to become a partner on our partner page with your registered account.',
        },
        {
          key: 'tab4-2',
          title: 'Submit Documents',
          description: 'Register your guesthouse and upload required documents.',
          caption: '*Required Document: Certificate of Residence',
        },
        {
          key: 'tab4-3',
          title: 'Confirmation on Product Details',
          description: 'Finalize the product description through consultation with MyRealTrip before listing.',
        },
        {
          key: 'tab4-4',
          title: 'Meet the First Guest',
          description: 'Host your first guest from MyRealTrip!',
        },
      ],
    },
  ],
  faq: 'FAQ',
  questions: [
    {
      key: 'faq1',
      question: 'Q.What is the difference between an individual partner and a corporate partner?',
      answer:
        "Individual partner usually refers to a guide who works individually or as a small team to offer a local tour with a special theme, using personal knowledge and experience. Business partners are partners with a valid 'business license,' who usually sell a variety of travel products ranging from guided tours, tickets to airtel packages.",
    },
    {
      key: 'faq2',
      question: 'Q.What are the qualifications required to become a MyRealTrip partner?',
      answer:
        '[Guided Tour] You must be eligible to stay in your current city for at least a year with a valid residence status. Some tours may require further qualifications. [Lodging] Only those who have a proper visa to operate a private guesthouse can become a lodging partner. [Ticket/Hot Deals] You must have a valid business license to list ticket or hot deal products. Individual partner without a business license cannot sell these products.',
    },
    {
      key: 'faq3',
      question: 'Q.Is the interview required? If so, how is it conducted?',
      answer: `If you wish to sell a tour or ticket product, the interview process is mandatory. The interview will be conducted through a video call (via Skype or Google Hangout) and will take approximately 15-20 minutes. Below are some of the topics that will be covered during the interview: 

      1) Whether you meet the basic standards to become our partner
      2) Inquiries and feedback regarding the travel product you wish to sell 
      3) Detailed instructions on operation
  
      `,
    },
    {
      key: 'faq4',
      question: 'Q. Do I need to take an interview for every product?',
      answer: `No. The interview is a one-time process which should be completed for partner registration. Once you are successfully registered as our partner, you do not have to take additional interviews to list new travel products. For every new travel product registered, our product monitoring team will review it carefully and let you know whether it is ready to be on sale within 2-3 working days.`,
    },
    {
      key: 'faq5',
      question: 'Q. What is the payout process?',
      answer: `You can apply for a payout only on our PC/Mobile Web. The payment settlement is done only after the tour is completed (or the ticket is used) and you can apply for a payout at any time starting from the day after the completion of the tour. Please go to [Partner Page] > [Payment/Billing] > [Banking Details] and register your bank account. Once completed, you can go to [My Payments] to request a payout.`,
    },
    {
      key: 'faq6',
      question: 'Q. How is the commission used?',
      answer: `MyRealTrip's commission is used to cover various marketing campaigns, credit card transaction fees, and platform maintenance and development.`,
    },
  ],
  faqMore: 'Do you have other inquiries?',
  customerCenter: 'Customer Center',
  footer: 'Meet your first Korean traveler now\n at MyRealTrip.',
};

export default language;
