import { useEffect } from 'react';

import styled from '@emotion/styled';
import { CsWidget } from 'mrt-components';
import media from 'mrt-utils/mediaQuery';

import FooterBottomBox from '@layout/app/components/FooterBottomBox';
import FooterTopBox from '@layout/app/components/FooterTopBox';
import { FooterPostMessage, FooterThemeMessage } from '@layout/constants/footer';
import { useLayoutFooterContext } from '@layout/modules/LayoutFooterContext';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

export default function Footer() {
  const { user, isDisabled, setDisabled } = useLayoutFooterContext();

  useEffect(() => {
    const handleThemeChange = (event: MessageEvent<FooterPostMessage>) => {
      const {
        data: { type, value },
      } = event;

      if (type === FooterThemeMessage.MRT_FOOTER_CHANGE) {
        setDisabled(value?.disabled ?? false);
      }
    };

    window.addEventListener('message', handleThemeChange);

    return () => window.removeEventListener('message', handleThemeChange);
  }, [setDisabled]);

  if (isDisabled) {
    return <CsWidget {...(user && { user })} />;
  }

  return (
    <>
      <CsWidget {...(user && { user })} />
      <Container id="mrt-footer">
        <Inner>
          <FooterTopBox />
          <FooterBottomBox />
        </Inner>
      </Container>
    </>
  );
}

const Container = styled.footer`
  position: relative;
  padding-bottom: calc(constant(safe-area-inset-bottom));
  padding-bottom: calc(env(safe-area-inset-bottom));
  background-color: ${globalVariables.white};
  border-top: 1px solid ${globalVariables.gray_100};
`;

const Inner = styled.div`
  max-width: 1060px;
  margin: 0 auto;
  padding: 40px 0;

  ${media.mobile} {
    padding: 32px 16px;
  }
`;
