import { useEffect, useRef } from 'react';

// Only Mounted 함수실행을 위한 hooks
// callback에 조건문이 포함되면 미발송되므로, 대신 condition 매개변수에 조건을 넘겨준다.
export default function useDidMount(callback: () => void, condition = true) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current && condition) {
      callback();
      didMountRef.current = true;
    }
  }, [callback, condition]);
}
