import styled from '@emotion/styled';
import { helper } from '@myrealtrip/frontend-weblog';
import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import { getSigninWebLink } from 'mrt-utils';

import i18n from '@layout/app/utils/i18n';
import Button from '@myrealtrip/design-system/dist/components/Buttons/Button';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import SideBarLink from './SideBarLink';

const { sendClickLog } = helper;

const baseLogData = {
  screenName: WeblogScreenName.COMMON,
  pageCategory: WeblogPageCategory.COMMON,
};

export default function LoggedOutItems() {
  const handleOpenCsHelper = () => {
    sendClickLog({
      ...baseLogData,
      eventName: 'topmenu',
      itemKind: 'menu',
      itemName: '1:1 채팅 상담',
      targetUrl: 'https://myrealtrip.channel.io/',
    });

    window.csHelper?.open();
  };

  const handleClickLogo = () => {
    sendClickLog({
      ...baseLogData,
      eventName: 'mainLogo',
    });
  };

  const handleClickSignInUp = () => {
    const targetUrl = getSigninWebLink();

    sendClickLog({
      ...baseLogData,
      eventName: 'topmenu',
      itemKind: 'menu',
      itemName: '로그인 및 회원가입',
      targetUrl,
    });

    window.location.href = targetUrl;
  };

  return (
    <Container>
      <MrtLogoSection>
        <MrtLogoAnchor href={process.env.HOME_URL} onClick={handleClickLogo}>
          <MrtLogoImage name="logo_mrt_v2_web" />
        </MrtLogoAnchor>
      </MrtLogoSection>
      <ButtonSection>
        <SignInUpButton type="primary" size="large" onClick={handleClickSignInUp}>
          {i18n.t('header:signInUp')}
        </SignInUpButton>
      </ButtonSection>
      <Section>
        <SideBarLink
          label={i18n.t('header:helpChat')}
          iconName="ic_chatservice_md_gray_600"
          onClick={handleOpenCsHelper}
        />
      </Section>
      <Section noBorder>
        <SideBarLink
          label={i18n.t('header:signInPartner')}
          link={`${process.env.PARTNER_URL}/signIn`}
          alignCenter
        />
      </Section>
    </Container>
  );
}

const Container = styled.div``;

const Section = styled.div<{ noBorder?: boolean }>`
  padding: 20px 16px;
  border-bottom: ${({ noBorder }) => (noBorder ? '0' : `1px solid ${globalVariables.gray_100}`)};
`;

const MrtLogoSection = styled.div`
  padding: 24px 0;
`;
const MrtLogoAnchor = styled.a`
  display: block;
`;
const MrtLogoImage = styled(Icon)`
  width: 100%;
  height: 30px;
`;

const ButtonSection = styled.div`
  padding: 0 16px 20px;
  border-bottom: 1px solid ${globalVariables.gray_100};
`;
const SignInUpButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;

  & > button {
    background: var(--blue-500);
    width: 100%;
    padding: 0;
    border-radius: 12px;
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;
