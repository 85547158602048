import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classnames from 'classnames';

import styles from './Modal.module.scss';
import { childrenType } from '../../types';
import { modalPositionEnum } from '../../enum';

function Modal(props) {
  const {
    isOpen,
    onClose,
    lock,
    position,
    isFullMode,
    children,
    overlayStyle,
    contentStyle,
    overlayClass,
    style,
    ...overridingProps
  } = props;
  const modalClass = classnames(styles.modal, styles[position], {
    [styles.fullMode]: isFullMode,
  });

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={!lock}
      shouldCloseOnEsc={!lock}
      closeTimeoutMS={300}
      overlayClassName={classnames(styles.overlay, overlayClass)}
      className={modalClass}
      ariaHideApp={false}
      style={{ overlay: overlayStyle, content: style || contentStyle }}
      {...overridingProps}
    >
      {children}
    </ReactModal>
  );
}

Modal.propTypes = {
  /** Modal show/hide */
  isOpen: PropTypes.bool.isRequired,
  /** true이면 백그라운드 클릭시 modal이 hide 되지 않음 */
  lock: PropTypes.bool,
  /** Close callback */
  onClose: PropTypes.func,
  /** Modal content의 위치(modalPositionEnum) */
  position: PropTypes.oneOf([
    modalPositionEnum.top,
    modalPositionEnum.center,
    modalPositionEnum.bottom,
  ]),
  /** background를 가린 전체 모드 */
  isFullMode: PropTypes.bool,
  /** Custom overlay style */
  overlayStyle: PropTypes.shape({}),
  /** Custom content style */
  contentStyle: PropTypes.shape({}),
  style: PropTypes.shape({}),
  /** Modal content  */
  children: childrenType.isRequired,
};

Modal.defaultProps = {
  lock: false,
  onClose: () => null,
  position: modalPositionEnum.center,
  isFullMode: false,
  overlayStyle: {},
  contentStyle: {},
  // 이전 스펙 호환을 위한 null 처리
  style: null,
};

export default Modal;
