import { css } from '@emotion/react';
import { forwardRef, useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

import { Configs } from 'mrt-constants';
import { GlobalSearch } from 'mrt-types';

import styled from '@emotion/styled';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import { searchBanner } from '../recoil';
import useSearchManager from '../useSearchManager';
import sendSearchCombineLog from '../utils/sendSearchCombineLog';

interface Props {
  keyword: GlobalSearch.Banner;
  isSelected: boolean;
}

const RecentKeywordItem = forwardRef<HTMLAnchorElement, Props>(({ keyword, isSelected }, ref) => {
  const { getSearchWord, setRecentKeywords } = useSearchManager();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keywordTitle = useMemo(() => getSearchWord(keyword), []);
  const targetUrl = `${Configs.SERVICE_DOMAIN}/search-bridge?q=${encodeURIComponent(keywordTitle)}`;
  const setSearchBanner = useSetRecoilState(searchBanner);

  const sendLog = () => {
    try {
      sendSearchCombineLog({
        additionalLog: {
          searchWord: keywordTitle,
          targetUrl,
        },
      });
    } catch (e) {
      // ignore
    }
  };

  const handleClickLink = () => {
    sendLog();

    setRecentKeywords(keyword);
  };

  useEffect(() => {
    if (isSelected) {
      setSearchBanner(keyword);
    }
  }, [isSelected, keyword, setSearchBanner]);

  return (
    <Anchor href={targetUrl} ref={ref} isSelected={isSelected} onClick={handleClickLink}>
      <ClockIcon name="ic_clock_sm_filled_gray_500" alt="" />
      <Label>{keywordTitle}</Label>
    </Anchor>
  );
});

RecentKeywordItem.displayName = 'RecentKeywordItem';

export default RecentKeywordItem;

const Anchor = styled.a<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${globalVariables.gray_50};
    `};

  :hover {
    background-color: ${globalVariables.gray_50}; /* gray-50 */
  }
`;

const ClockIcon = styled(Icon)`
  margin-right: 8px;
`;

const Label = styled.span`
  font-size: 15px;
  color: ${globalVariables.gray_700}; // gray-700
`;
