const checklist = {
  insurance: {
    name: 'Travel Insurance',
    desc: {
      desktop:
        "Get traveler's insurance in three minutes, at a lower price than the airport, and enjoy a comfortable trip.",
      mobile: 'Sign up in three minutes at a lower cost than the airport.',
    },
  },
  shilladfs: {
    name: 'Shilla Internet Duty Free Shop',
    desc: {
      desktop:
        'You will receive 100,000 won in reserve from the Shilla Internet Duty Free Shop for members who purchase MyRealTrip.',
      mobile: 'We will give you 100,000 won in reserve at the Shilla Internet Duty Free Shop.',
    },
  },
  ssgdfs: {
    name: 'Shinsegae Duty Free Shop',
    desc: {
      desktop: 'Presenting Credit Points & Smart Gift Cards to customers who purchase MyRealTrip!',
      mobile: 'Presenting Credit Points & Smart Gift Cards to customers!',
    },
  },
  wifi: {
    name: 'Portable Wifi Egg',
    desc: {
      desktop: 'Avoid high data usage payment with our wifi all over the world!',
      mobile: 'Anywhere in the world without worrying about data usage payment!',
    },
  },
  rent: {
    name: 'Rental Car',
    desc: {
      desktop:
        'Compare price and reserve famous overseas Rental Car all at once! Try quickly and easily make a reservation.',
      mobile: 'Compare price and reserve famous overseas Rental Car all at once!',
    },
  },
  myvisa: {
    name: 'My Visa',
    desc: {
      desktop: "Check your country's required visa documents and apply quickly and easily.",
      mobile: 'Visa for overseas travel, now easily apply.',
    },
  },
  usim: {
    name: 'USIM',
    desc: {
      desktop: 'A necessity when traveling! No need to worry about data usage and cost!',
      mobile: 'A necessity when traveling. Get USIM at reasonable price!',
    },
  },
};

export default checklist;
