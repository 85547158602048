const category = {
  header: 'What kind of offer are you looking for in {{location}}?',
  total: 'Total',
  experience: {
    guide_tour: 'Guided Tours',
    ticket: 'Ticket/Transit Pass',
    activity: 'Activity',
    snap: 'Snap',
    restaurant: 'Restaurant',
    entertainment: 'Entertainment',
    utility: 'Utility',
  },
  airtel: {
    package: 'Package',
    airtel: 'Air/Hotel',
    transport: 'Airline Ticket/Passage Ticket',
    rentacar: 'Rental Car',
    accommodation: 'Special Accommodation price',
  },
};

export default category;
