import { css } from '@emotion/react';

export const container = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  text-align: center;
  overflow: hidden;
`;
