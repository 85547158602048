import {
  createOfferPreOrderForm,
  afterCreateOfferPreOrderForm,
  createRentcarPreOrderForm,
  afterCreateRentcarPreOrderForm,
  createLodgingPreOrderForm,
  afterCreateLodgingPreOrderForm,
  afterCreateOrderFormByEstimateProposalId,
  createOrderFormByEstimateProposalId,
} from 'mrt-apis';
import { MrtVertical } from 'mrt-types';

// api refetch 함수 MAPPER
export const API_MAPPER = {
  // 2.0 TNA
  [MrtVertical.TNA]: createOfferPreOrderForm,
  // 렌터카
  [MrtVertical.CAR]: createRentcarPreOrderForm,
  // 한인민박
  [MrtVertical.BNB]: createLodgingPreOrderForm,
  // 견적서
  [MrtVertical.ESTIMATE]: createOrderFormByEstimateProposalId,
} as const;

// api refetch 성공 후 후처리 함수 MAPPER
export const AFTER_REQUEST_MAPPER = {
  // 2.0 TNA
  [MrtVertical.TNA]: afterCreateOfferPreOrderForm,
  // 렌터카
  [MrtVertical.CAR]: afterCreateRentcarPreOrderForm,
  // 한인민박
  [MrtVertical.BNB]: afterCreateLodgingPreOrderForm,
  // 견적서
  [MrtVertical.ESTIMATE]: afterCreateOrderFormByEstimateProposalId,
} as const;
