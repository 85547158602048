import mrtConstant from './mrt';
import flightConstant from './flight';
import { MYPAGE_URL, WISH_URL } from 'mrt-constants';

const isFlightMode = process.env.FLIGHT_MODE === 'YES';

const getLink = (path) => {
  return isFlightMode ? process.env.HOME_URL + path : path;
};

export default {
  ...(isFlightMode ? flightConstant : mrtConstant),
  link: {
    howToUse: getLink('/about/howitworks'),
    registGuide: `${process.env.PARTNER_URL}/welcome`,
    message: getLink('/messenger/channels'),
    wishlist: WISH_URL,
    myTrip: getLink('/reservations'),
    invitationFriends: getLink('/invite_friends'),
    signIn: `${process.env.AUTH_URL}/users/sign_in`,
    signUp: `${process.env.AUTH_URL}/users/sign_up`,
    signOut: getLink('/users/sign_out'),
    profile: MYPAGE_URL,
    search: getLink('/search'),
    home: getLink('/'),
    experience: getLink('/experiences'),
    domestic: getLink('/campaigns/domestic_trip'),
    flight: process.env.FLIGHTS_URL, // 추후 변경,
    accommodations: getLink('/accommodations'),
    hotel: getLink('/about/hotel'),
    hotdeal: getLink('/airtel'),
    checklist: getLink('/about/checklist'),
    package: getLink('/campaigns/realpackage'),
    coupon: getLink('/campaigns/mrtcoupon'),
    rentcar: 'https://www.myrealtrip.com/campaigns/jejurent',
    golf: 'https://www.myrealtrip.com/themes/878/offers',
    insurance:
      process.env.TARGET_ENV === 'production'
        ? 'https://market.bomappbiz.co.kr/overseas-axa?partner=MYREALTRIP'
        : 'https://market.bomappbiz.co.kr:3200/overseas-axa?partner=MYREALTRIP',
    popularCity: getLink('/cities'),
    service: getLink('/about/introduce'),
    company: getLink('/about/company'),
    press: getLink('/about/press'),
    b2b: getLink('/b2b'),
    realGuide: getLink('/about/realguide'),
    blog: 'https://blog.myrealtrip.com',
    customerCenter: getLink('/help/customer-center'),
    faq: getLink('/help/faq'),
    policy: getLink('/terms/policy'),
    privacy: getLink('/terms/privacy'),
    refund: getLink('/terms/cancel'),
    price: getLink('/best_price'),
    allRegion: getLink('/regions'),
    partner: getLink('/partner'),
    affiliation: getLink('/affiliation/agency/reservations'),
    about: 'https://about.myrealtrip.com',
    career: 'https://careers.myrealtrip.com',
    announcement: getLink('/announcement'),
  },
  defaultLnbMenus: [
    {
      id: 1,
      code: 'flights',
      title: '항공권',
      position: 1,
      lnb_required_badge: false,
      link: {
        mobile: process.env.FLIGHTS_URL,
        desktop: process.env.FLIGHTS_URL,
      },
    },
    {
      id: 2,
      code: 'accommodations',
      title: '호텔',
      position: 2,
      lnb_required_badge: false,
      link: {
        mobile: '/accommodations',
        desktop: '/accommodations',
      },
    },
    {
      id: 3,
      code: 'pension',
      title: '국내 숙소',
      position: 3,
      lnb_required_badge: false,
      link: {
        mobile: '/accommodations/stays',
        desktop: '/accommodations/stays',
      },
    },
    {
      id: 4,
      code: 'tour_ticket',
      title: '투어&티켓',
      position: 4,
      lnb_required_badge: false,
      link: {
        mobile: '/experiences',
        desktop: '/experiences',
      },
    },
    {
      id: 5,
      code: 'golf',
      title: '국내골프',
      position: 5,
      lnb_required_badge: false,
      link: {
        mobile: '/themes/878/offers',
        desktop: '/themes/878/offers',
      },
    },
    {
      id: 6,
      code: 'lantour',
      title: '랜선투어',
      position: 6,
      lnb_required_badge: false,
      link: {
        mobile: '/themes/882/offers',
        desktop: '/themes/882/offers',
      },
    },
    {
      id: 7,
      code: 'package',
      title: '패키지',
      position: 7,
      lnb_required_badge: false,
      link: {
        mobile: '/airtel',
        desktop: '/airtel',
      },
    },
    {
      id: 8,
      code: 'discount',
      title: '할인혜택',
      position: 8,
      lnb_required_badge: false,
      link: {
        mobile: '/campaigns/mrtcoupon',
        desktop: '/campaigns/mrtcoupon',
      },
    },
  ],
};
