import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
  objectOf,
  object,
  element,
  instanceOf,
} from 'prop-types';
import {
  hotelFacilityEnum,
  hotelCategoryEnum,
  hotelAgencyEnum,
  roomAmenityEnum,
  hotelSortEnum,
  apiStatusEnum,
  refundStatusEnum,
  refundReasonEnum,
  airtelCategoryEnum,
  airtelSortEnum,
  lodgingSortEnum,
} from '../enum';
import { quickLinkItemType } from './QuickLink';

export const routerType = shape({
  history: shape({
    push: func,
    replace: func,
    goBack: func,
  }),
  location: shape({
    pathname: string,
    state: shape({}),
  }),
  match: shape({
    params: shape({}),
  }),
});

export const profileImageType = shape({
  urls: shape({
    large: string,
    medium: string,
    original: string,
    small: string,
    thumb: string,
  }),
});

export const userType = shape({
  id: number,
  email: string,
  username: string,
  profile_image: profileImageType,
  point: string,
  numberOfCoupons: string,
  guideId: number,
  isAffiliation: bool,
  role: string,
  isLodgingGuide: bool,
});

export const railsEnvType = oneOf(['development', 'staging', 'production']);

export const childrenType = oneOfType([arrayOf(node), node, func]);

export const cityType = shape({
  location_name: string,
  city_key_name: string,
  country_key_name: string,
});

export const notificationType = shape({
  id: string,
  created_at: number,
  fresh: bool,
  unread: bool,
  message: string,
  web_link: string,
});

export const priceType = shape({
  amount: number,
  currency_code: string,
  currency_symbol: string,
  present: string,
  krw_amount: number,
});

export const landsapeType = shape({
  urls: shape({
    original: string,
    xlarge_square: string,
    large: string,
    large_square: string,
    medium: string,
    medium_square: string,
    mobile_web: string,
    android_nav: string,
  }),
});

export const hotelUrlType = shape({
  desktop: string,
  mobile: string,
  ios: string,
  android: string,
});

export const searchOffersType = shape({
  id: number,
  guide_id: number,
  title: string,
  subtitle: string,
  total_traveler_reviews: number,
  ignore_wait_confirm: bool,
  score: number,
  main_photo: profileImageType,
  original_price: priceType,
  main_price: priceType,
  guide: shape({
    username: string,
    profile_image: profileImageType,
  }),
  city_infos: arrayOf(
    shape({
      id: number,
      key_name: string,
      name: string,
      country_info: shape({
        id: number,
        key_name: string,
        name: string,
      }),
    }),
  ),
  search_cache_key: string,
  search_score: number,
  search_title: string,
  search_description: string,
});

export const searchLocationType = shape({
  id: number,
  key_name: string,
  name: string,
  landscape: landsapeType,
  type: oneOf(['city', 'country']),
  featured: bool,
  country_info: shape({
    id: number,
    key_name: string,
    name: string,
  }),
  hotel_urls: hotelUrlType,
});

export const searchResultType = shape({
  query: string,
  suggested_queries: arrayOf(string),
  offers: arrayOf(searchOffersType),
  locations: arrayOf(searchLocationType),
  pagination: shape({
    current_page: number,
    total_pages: number,
    total_count: number,
  }),
});

export const citySimpleType = shape({
  key: string,
  name: string,
  image: string,
});

export const mainCityType = shape({
  key: string,
  name: string,
  image: string,
});

const offerTypeObject = shape({
  id: number | string,
  gpid: string,
  title: string,
  image: string,
  review: shape({
    count: number,
    type: string,
    star: number,
  }),
  type: string,
  category: string,
  tags: arrayOf(string),
  duration: shape({
    size: number,
    unit: string,
  }),
  isFreeRefund: bool,
  isGuarantee: bool,
  isIgnoreWaitConfirm: bool,
  nowUse: bool,
  price: shape({
    main: number,
    origin: number,
  }),
  guide: shape({
    id: number,
    name: string,
    isRealGuide: bool,
  }),
  city: citySimpleType,
  country: citySimpleType,
  period: shape({
    start: string,
    end: string,
  }),
  isMRT2: bool,
  cityCount: number,
  offerCategory: shape({
    code: string,
  }),
  offerSubCategories: arrayOf(
    shape({
      code: string,
    }),
  ),
  productUrls: shape({
    APP: string,
    WEB: string,
  }),
});

export const offerType = shape(offerTypeObject);

export const logsType = shape({
  reco_algs: arrayOf(number),
  reco_ids: arrayOf(number),
  reco_key: string,
});

export const hotelType = shape({
  id: number,
  thumbnail: string.isRequired,
  title: string.isRequired,
  reviewScore: number.isRequired,
  reviewStatus: string,
  reviewCount: number.isRequired,
  price: number.isRequired,
  link: string.isRequired,
  star: number,
  distance: number,
});

export const hotelAgencyType = oneOf(Object.values(hotelAgencyEnum));
export const hotelFacilityType = oneOf(Object.values(hotelFacilityEnum));
export const hotelCategoryType = oneOf(Object.values(hotelCategoryEnum));
export const roomAmenityType = oneOf(Object.values(roomAmenityEnum));

export const hotelLandmarkType = shape({
  id: number,
  name: string,
  engName: string,
  latitude: number,
  longitude: number,
  distance: number,
});

export const hotelFilterType = shape({
  selectedReviewScore: number,
  selectedMinPrice: number,
  selectedMaxPrice: number,
  selectedRating: number,
  selectedFreeServices: arrayOf(hotelFacilityType),
  selectedFacilities: arrayOf(hotelFacilityType),
  selectedLandmarks: arrayOf(hotelLandmarkType),
  selectedCategories: arrayOf(hotelCategoryType),
});

export const hotelSearchTermType = shape({
  /** 국가, 도시, 장소 등의 정보 */
  place: shape({
    latitude: number,
    longitude: number,
    countryName: string,
    cityName: string,
    placeName: string,
  }),
  /** 체크인 날짜 (YYYY-MM-DD) */
  checkin: string,
  /** 체크아웃 날짜 (YYYY-MM-DD) */
  checkout: string,
  /** 객실 수 */
  numberOfRooms: number,
  /** 성인 수 */
  numberOfAdults: number,
  /** 어린이 수 */
  numberOfChildren: number,
  /** 어린이 나이 */
  ageOfChildren: arrayOf(number),
  /** filter */
  filter: shape({
    star: number,
  }),
});

export const hotelSortType = oneOf(Object.values(hotelSortEnum));

export const hotelRoomType = shape({
  id: string,
  hotelAgencyKind: hotelAgencyType,
  name: string,
  /** 총 요금 */
  totalPrice: number,
  /** 1박 평균 요금 */
  pricePerNight: number,
  /** 특가 */
  specialPrice: number,
  /** 무료 서비스 */
  amenities: arrayOf(roomAmenityType),
  /** 요금 정책 */
  policies: arrayOf(roomAmenityType),
  roomUrl: string,
});

export const hotelReviewSummaryType = shape({
  score: number,
  scoreDescription: string,
});

export const hotelReviewCategoryType = shape({
  id: string,
  name: string,
  score: number,
});

export const hotelReviewItemType = shape({
  id: oneOfType([number, string]),
  author: string,
  authorType: string,
  country: string,
  date: string,
  score: number,
  headline: string,
  pros: string,
  cons: string,
  index: number,
});

export const hotelReviewType = shape({
  totalCount: number,
  summary: hotelReviewSummaryType,
  categories: arrayOf(hotelReviewCategoryType),
  items: arrayOf(hotelReviewItemType),
});

export const hotelItemType = shape({
  id: number,
  name: string,
  engName: string,
  introduction: string,
  address: string,
  checkin: string,
  checkout: string,
  city: string,
  countryCode: string,
  /** 도심으로부터의 거리 (단위: m) */
  distance: number,
  latitude: number,
  logitude: number,
  /** 평점 (0 - 10) */
  rating: number,
  /** 등급 (0 - 5) */
  numberOfStars: number,
  /** 이용후기 정보 */
  review: hotelReviewType,
  /** 호텔 대표 이미지 목록 */
  imageUrls: arrayOf(string),
  category: hotelCategoryType,
  /** 서비스 목록 */
  facilities: arrayOf(hotelFacilityType),
  /** 객실 목록 */
  rooms: arrayOf(hotelRoomType),
  /** 최저가 객실 정보 */
  minimumPriceRoom: hotelRoomType,
  /** 객실 정보 업데이트 중인지 여부 */
  isRefreshing: bool,
});

export const placeSuggestionType = shape({
  id: string,
  name: string,
  countryCode: string,
  label: string,
  type: string,
  suggestionType: string,
  country: string | null,
  countryName: string | null,
  placeName: string | null,
  cityName: string | null,
  region: string | null,
  hotelId: number | null,
  latitude: number,
  longitude: number,
});

export const popularCityType = shape({
  name: string,
  countryCode: string,
  label: string,
  type: string,
  suggestionType: string,
  country: string | null,
  countryName: string | null,
  placeName: string | null,
  cityName: string | null,
  region: string | null,
  hotelId: number | null,
  latitude: number,
  longitude: number,
});

export const wishlistType = shape({
  key: string,
  name: string,
  offers: offerType,
});

export const slideType = shape({
  id: oneOfType([string, number]),
});

export const virtualizedSwiperOptionType = shape({
  wrapperClass: string,
  on: shape({
    transitionStart: func,
  }),
});

export const imageSwiperOptionType = shape({
  loop: bool,
  preloadImages: bool,
  lazy: bool,
});

export const apiStatusType = oneOf([
  apiStatusEnum.idle,
  apiStatusEnum.loading,
  apiStatusEnum.error,
]);

export const mapCenterType = shape({
  lat: number,
  lng: number,
});

export const bannerType = shape({
  id: number,
  image: shape({
    mobile: string,
    desktop: string,
  }),
  link: string,
});

export const swiperType = objectOf(oneOfType([string, number, element, func, bool, object]));

export const categoryType = shape({
  code: string,
  name: string,
});

export const offerCategoryType = shape({
  id: number,
  name: string,
  icon: string,
  code: string,
});

export const bankAccountType = shape({
  accountNumber: string,
  accountOwner: string,
  bankCode: string,
  bankName: string,
});

export const bannersType = shape({
  title: string,
  sub_title: string,
  code: string,
  link_for_web: string,
  link_for_mobile_web: string,
  link_for_mobile: string,
  image: string,
});

export const verticalTabType = shape({
  code: string,
  title: string,
  link: string,
  offer_categories: arrayOf(offerCategoryType),
  quicklinks: arrayOf(quickLinkItemType),
  banners: arrayOf(bannersType),
});

export const bankType = shape({
  code: string,
  name: string,
});

export const refundStatusType = oneOf([
  refundStatusEnum.requested,
  refundStatusEnum.waiting,
  refundStatusEnum.completed,
]);

export const refundReasonType = oneOf(Object.values(refundReasonEnum));

export const messageUserType = shape({
  image: string,
  name: string,
});

export const airtelAreaType = shape({
  id: number,
  name: string,
  regions: arrayOf(
    shape({
      id: number,
      name: string,
    }),
  ),
});

const airtelCategoryCode = oneOf(Object.values(airtelCategoryEnum));

export const airtelCategoryType = shape({
  code: airtelCategoryCode,
  count: number,
  name: string,
});

export const airtelFeatureType = shape({
  category: airtelCategoryCode,
  title: string,
  moreButton: shape({
    label: string,
    link: string,
  }),
  offers: arrayOf(offerType),
});

export const airtelSortType = oneOf(Object.values(airtelSortEnum));

export const lodgingSortType = oneOf(Object.values(lodgingSortEnum));

export const reservationExtraInfoType = shape({
  begin_at: string,
  offer_title: string,
  recent_reservation_day: string,
  buyer_info: arrayOf(
    shape({
      date_of_birth: string,
      first_name: string,
      gender: string,
      korean_name: string,
      last_name: string,
    }),
  ),
  departure_info: shape({
    airport: string,
    day: string,
    hour: string,
    minute: string,
    month: string,
    year: string,
  }),
  email: shape({
    address: string,
  }),
  english_name: shape({
    first_name: string,
    last_name: string,
  }),
  flight_arrival_info: shape({
    airport: string,
    day: string,
    flight_no: string,
    hour: string,
    minute: string,
    month: string,
    year: string,
  }),
  flight_departure_info: shape({
    airport: string,
    day: string,
    flight_no: string,
    hour: string,
    minute: string,
    month: string,
    year: string,
  }),
  hotel_info: shape({
    name: string,
    address: string,
  }),
  phone_detail: shape({
    name: string,
    company: string,
    number: string,
  }),
});

export const inviteFriendsRankType = shape({
  email: string,
  invite_count: number,
  invite_point: number,
});

export const contentsType = shape({
  id: number,
  title: string,
  subtitle: string,
  date: string,
  authorName: string,
  authorDesc: string,
  authorImage: string,
  mainImage: string,
  thumbnail: string,
  contents: string,
});

export const gaEventType = shape({
  action: string,
  category: string,
  label: string,
});

export * from './search';
export const hotelTheme = shape({
  cityEngName: string,
  cityId: number,
  cityKorName: string,
  id: number,
  listOrder: number,
  themeTitle: string,
});

export const keyVlaueType = shape({
  key: oneOfType([string, number]),
  value: oneOfType([string, number]),
});

export const locationBannerType = shape({
  id: number,
  cityName: string,
  title: string,
  link: shape({
    mobile: string,
    desktop: string,
  }),
  image: shape({
    mobile: string,
    desktop: string,
  }),
  position: number,
});

export const experienceFeaturedType = shape({
  title: string,
  offers: arrayOf(offerType),
});

export * from './QuickLink';
export * from './Stays';
export * from './Header';
export * from './weblog';

export const refType = oneOfType([func, shape({ current: instanceOf(Element) })]);

export const channelTalkUserType = shape({
  id: number,
  username: string,
  email: string,
  phoneNumber: string,
});

export const homeOffersType = shape({
  title: string,
  offers: arrayOf(offerType),
});

export const recentOfferType = shape({
  ...offerTypeObject,
  recommend_offers: arrayOf(offerType),
});

export const homeRecentOffersType = shape({
  title: string,
  offers: arrayOf(recentOfferType) | undefined,
});

export const crossSellSectionType = shape({
  key_name: string | null,
  logs:
    {
      reco_algs: arrayOf(number),
      reco_ids: arrayOf(number),
      reco_key: string,
    } | null,
  name: string | null,
  recos: arrayOf(offerType),
  section: shape({
    more_link: string,
    name: string, // ex. XSELL_HOTELS
    page_name: string,
    pos: number,
    title: string,
  }),
});

export const homeRecentRecommendLinkType = shape({
  title: string,
  image: string,
  href: string,
  isLast: bool,
  city: string,
  itemKind: string,
});
