import { useEffect, useState, memo } from 'react';

import useDetermineMobileContext from 'mrt-components/hooks/useDetermineMobileContext';
import { WeblogScreenName, WeblogPageCategory } from 'mrt-constants';
import CookieManager, { CookieKey } from 'mrt-utils/cookieManager';

import styled from '@emotion/styled';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';
import { helper } from '@myrealtrip/frontend-weblog';

const { sendClickLog, sendImpressionLog } = helper;

// 배너를 노출할 라우트
const allowRoutes: { screenName: WeblogScreenName; regex: RegExp }[] = [];

const currentRoute = allowRoutes.find(({ regex }) => regex.test(window.location.pathname));
const [logEnumKey = ''] =
  Object.entries(WeblogScreenName).find(([, value]) => value === currentRoute?.screenName) ?? [];
const baseLogData = {
  screenName: WeblogScreenName[logEnumKey],
  pageCategory: WeblogPageCategory[logEnumKey],
  eventName: 'upper_banner',
  abtest: 'a',
};

function AppDownloadBanner() {
  const [isDisplay, setIsDisplay] = useState(false);
  const isMobile = useDetermineMobileContext();

  const handleClick = () => {
    sendClickLog({
      ...baseLogData,
      itemKind: 'button',
      itemName: 'install_click',
    });

    window.location.href = 'https://myrealtrip.onelink.me/5NKn/83ea9e4d';
  };

  // 쿠키 만료 기한은 무기한으로 설정
  // 한번 닫은 후 수동으로 사용자가 쿠키 클렌징 전까진 미노출
  const handleClose = () => {
    sendClickLog({
      ...baseLogData,
      itemKind: 'button',
      itemName: 'close',
    });

    CookieManager.set(CookieKey.APP_DOWNLOAD_BANNER, 'true');
    setIsDisplay(false);
  };

  useEffect(() => {
    const hasCookie = Boolean(CookieManager.get(CookieKey.APP_DOWNLOAD_BANNER));
    const canDisplay = Boolean(currentRoute) && !hasCookie;

    if (canDisplay) {
      const itemName = 'app_install';

      sendImpressionLog(
        {
          ...baseLogData,
          itemKind: 'banner',
          itemName,
        },
        `banner_${itemName}`,
      );
    }

    setIsDisplay(canDisplay);
  }, []);

  if (!isDisplay || !isMobile) {
    return null;
  }

  return (
    <Container>
      <ContentContainer>
        <Anchor onClick={handleClick}>
          <AppIcon name="ic_bannerappinstall_36x36" alt="" />
          <Text>
            APP 전용 <b>12만 원 쿠폰팩</b> 받기
          </Text>
          <ArrowIcon name="ic_arrow_right_16x16_blue_500_default" alt="" />
        </Anchor>
        <CloseButton type="button" onClick={handleClose}>
          <CloseIcon name="ic_close_16x16_gray_500_1.5" alt="" />
        </CloseButton>
      </ContentContainer>
    </Container>
  );
}

export default memo(AppDownloadBanner);

const Container = styled.div`
  position: fixed;
  top: 54px;
  width: 100%;
  padding: 6px 10px;
  z-index: 100;
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 52px;
  align-items: center;
  padding: 0 8px 0 14px;
  background: ${globalVariables.blue_100};
  border: 1px solid ${globalVariables.gray_200};
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(33, 37, 41, 0.1);
`;

const Anchor = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0;
  background: none;
  border: none;
  appearance: none;
`;

const AppIcon = styled(Icon)`
  width: 36px;
  height: 36px;
  filter: drop-shadow(0px 8px 16px rgba(33, 37, 41, 0.15));
`;

const Text = styled.span`
  margin-left: 12px;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: -0.39px;

  b {
    font-weight: 800;
    color: ${globalVariables.blue_600};
  }
`;

const ArrowIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;

const CloseButton = styled.button`
  padding: 10px;
  margin-left: auto;
  background: none;
  border: none;
  appearance: none;
`;

const CloseIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;
