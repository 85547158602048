import { useEffect } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CsWidget, useDetermineMobileContext } from 'mrt-components';

import GNB from '@layout/app/components/GNB';
import LNB from '@layout/app/components/LNB';
import useFlight from '@layout/app/hooks/useFlight';
import { getHeaderThemeByPathName } from '@layout/app/utils/getHeaderThemeByPathName';
import {
  HeaderThemeType,
  HeaderSizeType,
  HeaderPostMessage,
  HeaderThemeMessage,
} from '@layout/constants/header';
import { HeaderTheme, HeaderSize } from '@layout/constants/header';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

export default function Header() {
  const {
    theme,
    size,
    isFlightMode,
    user,
    isDisabled,
    setSize,
    setTheme,
    setDisabled,
    setExcludeLnb,
  } = useLayoutHeaderContext();
  const isMobile = useDetermineMobileContext();
  const { initialMobileSearchContainerStyle } = useFlight(isFlightMode);

  useEffect(() => {
    initialMobileSearchContainerStyle();
  }, [initialMobileSearchContainerStyle]);

  // 최초 마운트 시 Header 컴포넌트 테마 설정
  useEffect(() => {
    const initialTheme = getHeaderThemeByPathName({
      isMobile,
      currentPathName: window.location.pathname,
    });

    setTheme(initialTheme);
  }, [isMobile, setTheme]);

  // postMessage를 통해 Header theme, size를 변경할 수 있다.
  useEffect(() => {
    const handleThemeChange = (event: MessageEvent<HeaderPostMessage>) => {
      const {
        data: { type, value },
      } = event;

      if (type === HeaderThemeMessage.MRT_HEADER_CHANGES) {
        setDisabled(value?.disabled ?? false);
        setTheme(value?.theme ?? 'white');
        setSize(value?.size ?? 'default');
        setExcludeLnb(value?.excludeLnb ?? false);
      }
    };

    window.addEventListener('message', handleThemeChange);

    return () => window.removeEventListener('message', handleThemeChange);
  }, [setDisabled, setExcludeLnb, setSize, setTheme]);

  if (isDisabled) {
    return <CsWidget {...(user && { user })} />;
  }

  return (
    <>
      <CsWidget {...(user && { user })} />
      <Container id="mrt-main-header" colorTheme={theme} size={size} isFlightMode={isFlightMode}>
        <GNB />
        <LNB />
      </Container>
    </>
  );
}

const HEADER_SIZE_STYLE = {
  // size.full: 민박 상품 리스트(lodging/products)에서 사용하는 Size Style
  [HeaderSize.full]: css`
    position: fixed;
    width: 100%;
    z-index: 9999 !important;

    nav {
      max-width: none;
      padding: 0 32px;
    }
  `,
};
const HEADER_THEME_STYLE = {
  [HeaderTheme.blue]: css`
    background-color: ${globalVariables.blue_500};
    color: ${globalVariables.white};
  `,
  [HeaderTheme.white]: css`
    background-color: ${globalVariables.white};
    color: ${globalVariables.gray_600};
  `,
  [HeaderTheme.transparent]: css`
    background-color: transparent;
    color: ${globalVariables.white};
    z-index: 101;
  `,
};

const Container = styled.header<{
  colorTheme: HeaderThemeType;
  size: HeaderSizeType;
  isFlightMode: boolean;
}>`
  position: relative;
  width: 100%;
  height: auto;
  z-index: ${({ isFlightMode }) => (isFlightMode ? '100;' : 'auto;')};

  ${({ colorTheme }) => HEADER_THEME_STYLE[colorTheme]}
  ${({ size }) => HEADER_SIZE_STYLE[size]}
`;
