function ntracker(code, price, id) {
  console.log(`Code: ${code}, Price: ${price}, ID: ${id}`);
  const nsmX = new Image();
  nsmX.src = `${
    window.location.protocol
  }//ntracker.nsm-corp.com/c/rcv.php?id=20015&code=${code}&value=${price}&trans=${id}`;
}

// eslint-disable-next-line import/prefer-default-export
export { ntracker };
