import moment from 'moment';
import { Children, useEffect, useMemo, useState } from 'react';

import styled from '@emotion/styled';

import FieldWrapper, { FieldChildProps } from './FieldWrapper';
import { SelectUnit, SelectUnitOptions } from './Units';

const currentYear = moment().year();
const currentMonth = moment().month();

const years = (start = currentYear) => {
  const Years: SelectUnitOptions = [{ value: '', content: '년도', isPlaceholder: true }];

  for (let index = start; index >= 1920; index -= 1) {
    Years.push({ value: `${index}`.padStart(4, '0'), content: `${index}`.padStart(4, '0') });
  }

  return Years;
};
const months = (() => {
  const Months: SelectUnitOptions = [{ value: '', content: '월', isPlaceholder: true }];

  for (let index = 1; index <= 12; index += 1) {
    Months.push({ value: `${index}`.padStart(2, '0'), content: `${index}`.padStart(2, '0') });
  }

  return Months;
})();
const days = (daysInNumber: number) => {
  const Days: SelectUnitOptions = [{ value: '', content: '일', isPlaceholder: true }];
  for (let index = 1; index <= daysInNumber; index += 1) {
    Days.push({ value: `${index}`.padStart(2, '0'), content: `${index}`.padStart(2, '0') });
  }

  return Days;
};

export type DateSelectFieldProps = FieldChildProps;

function DateSelectField({
  // - FieldWrapperProps
  name,
  invalid,
  invalidCaption,
  label,
  caption,
  // - FieldChildProps
  onChange,
  onBlur,
  defaultValue, // props : 'YYYY-MM-DD'
  disabled,
}: DateSelectFieldProps): JSX.Element {
  // state : ['YYYY', 'MM', 'DD']
  const [date, setDate] = useState<string[]>(['', '', '']);

  useEffect(() => {
    if (defaultValue) setDate(defaultValue?.split('-'));
  }, [defaultValue]);

  const updateDate = (value: string, index: number, handler?: (value: string) => void) => {
    const nextDate = date.map((e, i) => (i === index ? value ?? '' : e));
    setDate(nextDate);
    handler?.(nextDate.every((is) => !is) ? '' : nextDate.join('-'));
  };

  const isComplete = date.every((n) => n);

  const dateOptions = useMemo(() => {
    return [
      years(name.toLowerCase().includes('passport') ? currentYear + 10 : currentYear),
      months,
      days(
        moment(
          date[1] ? `${date[0]}-${date[1]}` : `${currentYear}-${currentMonth}`,
          'YYYY/MM',
        ).daysInMonth(),
      ),
    ];
  }, [date, name]);

  return (
    <FieldWrapper invalid={invalid} {...{ name, invalidCaption, label, caption }}>
      <SelectBoxWrapper>
        {Children.toArray(
          [0, 1, 2].map((n) => (
            <SelectUnit
              options={dateOptions[n]}
              value={date[n]}
              invalid={(invalid && !date[n]) || (isComplete && invalid)}
              name={n === 0 ? name : undefined}
              onChange={(value) => updateDate(value, n, onChange)}
              onBlur={(value) => updateDate(value, n, onBlur)}
              {...{ disabled }}
            />
          )),
        )}
      </SelectBoxWrapper>
    </FieldWrapper>
  );
}

export default DateSelectField;

const SelectBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    flex: 1;

    &:not(:first-of-type) {
      margin-left: 7px;
    }
  }
`;
