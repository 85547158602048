import { string } from 'prop-types';

import styles from './CardCouponSticker.module.scss';

// FIXME: 추후 type 추가하여 색상 등을 관리
function CardCouponSticker(props) {
  const { label } = props;

  return <span className={styles.container}>{label}</span>;
}

CardCouponSticker.propTypes = {
  label: string.isRequired,
};

export default CardCouponSticker;
