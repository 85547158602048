import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from './FlightFreeBaggageInfo.module.scss';

function FlightFreeBaggageInfo(props) {
  const { data, isMobile } = props;

  return (
    <Fragment>
      {isMobile ? (
        <span className={styles.freeBaggageWeightM}>
          (무료 수하물 {data.BAG})
        </span>
      ) : (
        <div className={styles.freeBaggageInfo}>
          <span className={styles.freeBaggageWeightPC}>
            무료 수하물 {data.BAG}
          </span>
        </div>
      )}
    </Fragment>
  );
}

FlightFreeBaggageInfo.propTypes = {
  data: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default FlightFreeBaggageInfo;
