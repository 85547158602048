import getEncodedQueryString from './getEncodedQueryString';

export default function getReturnUrl() {
  const queryString = getEncodedQueryString({
    stringifyOptions: { addQueryPrefix: true },
  });
  const fullPath = `${process.env.HOME_URL}${window.location.pathname}`;

  // query는 한 번만 encoding 된 상태로 보내야 한다.
  return `${encodeURIComponent(fullPath)}${queryString}`;
}

export function getReturnUrlV2() {
  return encodeURIComponent(window.location.href);
}
