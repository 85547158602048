import { TAG_COLOR } from '@myrealtrip/design-system';
import { ReservationHistoryStatus } from 'mrt-types/reservationHistory';

import { Platform } from './platform';

/** 예약 상태 목록 */
export enum ReservationStatus {
  /** (항공) 예약 대기 */
  WAIT_RSV = 'WAIT_RSV',
  /** (항공) 예약 완료 */
  FINISH_RSV = 'FINISH_RSV',
  /** (항공) 발권 대기 */
  WAIT_ISSUE = 'WAIT_ISSUE',
  /** (항공) 발권중 */
  IN_PROGRESS_ISSUE = 'IN_PROGRESS_ISSUE',
  /** (항공) 발권 완료 */
  FINISH_ISSUE = 'FINISH_ISSUE',
  /** (항공, 2.0, 3.0) 여행 완료 */
  FINISH = 'FINISH',
  /** (항공, 2.0, 3.0) 예약 취소 */
  CANCEL = 'CANCEL',
  /** (2.0, 3.0) 확정 대기 */
  WAIT_CONFIRM = 'WAIT_CONFIRM',
  /** (2.0) 견적서 작성 중 */
  WAIT_INVOICE = 'WAIT_INVOICE',
  /** (2.0) 결제 대기 */
  WAIT_PAYMENT = 'WAIT_PAYMENT',
  /** (3.0) 입금 대기 */
  WAIT_DEPOSIT = 'WAIT_DEPOSIT',
  /** (2.0, 3.0) 예약 확정 */
  CONFIRM = 'CONFIRM',
  /** (2.0, 3.0) 예약 취소 요청 */
  REQUEST_CANCEL = 'REQUEST_CANCEL',
  /** (3.0) 결제 대기 */
  PENDING_PAYMENT = 'PENDING_PAYMENT',
}

export enum ReservationStatusMap {
  TEMP = '임시예약',
  WAIT_DEPOSIT = '입금대기',
  WAIT_CONFIRM = '확정대기',
  CONFIRM = '예약확정',
  REQUEST_CANCEL = '취소요청',
  FINISH = '여행완료',
  CANCEL = '예약취소',
  FAIL = '예약실패',
  PENDING_PAYMENT = '결제대기',
}

export const ReservationStatusColorMap: Record<
  Exclude<
    ReservationStatus,
    | 'WAIT_RSV'
    | 'FINISH_RSV'
    | 'WAIT_ISSUE'
    | 'IN_PROGRESS_ISSUE'
    | 'FINISH_ISSUE'
    | 'WAIT_INVOICE'
    | 'WAIT_PAYMENT'
  >,
  TAG_COLOR
> = {
  WAIT_DEPOSIT: 'gray',
  WAIT_CONFIRM: 'yellow',
  CONFIRM: 'green',
  REQUEST_CANCEL: 'red',
  FINISH: 'gray',
  CANCEL: 'red',
  PENDING_PAYMENT: 'gray',
};

export enum ReservationCancelReason {
  /** 여행자 개인 사정 */
  TRAVELER_PERSONAL_REASON = 'TRAVELER_PERSONAL_REASON',
  /** 건강상의 이유 */
  HEALTH_PROBLEM = 'HEALTH_PROBLEM',
  /** 천재지변 */
  DISASTER = 'DISASTER',
  /** 파트너 요청으로 취소 */
  PARTNER_REQUEST = 'PARTNER_REQUEST',
  /** 재예약 진행을 위해 */
  RETRY_RESERVATION = 'RETRY_RESERVATION',
  /** 파트너 무응답 */
  PARTNER_NO_RESPONSE = 'PARTNER_NO_RESPONSE',
  /** 기타 */
  ETC = 'ETC',
  /** 연동사 취소 */
  ERP_CANCEL = 'ERP_CANCEL',
  /** payment 취소 배치를 통한 취소 */
  SYSTEM = 'SYSTEM',
  /** 매니저 강제 취소 */
  FORCE_SYSTEM = 'FORCE_SYSTEM',
}

export enum ReservationCancelReasonMap {
  TRAVELER_PERSONAL_REASON = '여행자 개인 사정',
  HEALTH_PROBLEM = '건강상의 이유',
  DISASTER = '천재지변',
  PARTNER_REQUEST = '파트너 요청으로 취소',
  RETRY_RESERVATION = '재예약 진행을 위해',
  PARTNER_NO_RESPONSE = '파트너 무응답',
  ETC = '기타',
  ERP_CANCEL = '-',
  SYSTEM = '-',
  FORCE_SYSTEM = '-',
}

export const ReservationCancelReasonList = [
  ReservationCancelReason.TRAVELER_PERSONAL_REASON,
  ReservationCancelReason.HEALTH_PROBLEM,
  ReservationCancelReason.DISASTER,
  ReservationCancelReason.PARTNER_REQUEST,
  ReservationCancelReason.RETRY_RESERVATION,
  ReservationCancelReason.PARTNER_NO_RESPONSE,
  ReservationCancelReason.ETC,
];

export enum ReservationProductContentItemEnum {
  TEXT = 'TEXT',
  LINK = 'LINK',
  IMAGE = 'IMAGE',
  MAP = 'MAP',
  ICON = 'ICON',
  TEL = 'TEL',
  LIST_ITEM = 'LIST_ITEM',
}

export enum ReviewStatus {
  NONE = 'NONE',
  READY = 'READY',
  REWARDED = 'REWARDED',
}

export const ReservationHistoryCategory = [
  {
    title: '예정된 여행',
    status: ReservationHistoryStatus.ongoing,
  },
  {
    title: '지난 여행',
    status: ReservationHistoryStatus.finish,
  },
  {
    title: '취소된 여행',
    status: ReservationHistoryStatus.cancel,
  },
  {
    title: '맞춤여행',
    status: ReservationHistoryStatus.estimate,
  },
  {
    title: '해외렌트카',
    status: ReservationHistoryStatus.foreignRentaCar,
  },
];

export const SERVICE_BEGINNING_DATE = '2012-01-01';

export enum BannerType {
  REVIEW = 'review',
}

export const CHECKIN_COMMON_ERROR_MESSAGE =
  '예측하지 못한 문제가 생겼습니다. 일시적일 수 있으니 다시 시도해주세요. 문제가 반복되면 고객지원실(1670-8208)에 문의해주세요.';

export enum ContentInfoType {
  INFO = 'info',
  DISCOUNT = 'discount',
  TOTAL = 'total',
}

export enum TicketType {
  /** 지정일 티켓 */
  USING = 'USING',
  /** 비지정 티켓 */
  EXPIRING = 'EXPIRING',
}

export const TnaCheckoutSupportAppVersion = {
  [Platform.IOS]: '6.24.0',
  [Platform.ANDROID]: '6.20.0',
};

export const ProductRequireInfoKor = {
  PERSONAL_ALL_NAME: '예약자 한글 영문 이름',
  PERSONAL_LICENSE_INFO: '대표 운전자 정보',
  PERSONAL_KOREAN_NAME: '예약자 한글 이름',
  PERSONAL_ENGLISH_NAME: '예약자 영문 이름',
  PERSONAL_REPRESENTATIVE: '대표 투숙자 정보',
};

export enum PhoneAgency {
  SKT = 'SKT',
  KTF = 'KT',
  LGT = 'LG U+',
  SKM = 'SKT 알뜰폰',
  KTM = 'KT 알뜰폰',
  LGM = 'LG U+ 알뜰폰',
}
