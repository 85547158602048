import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { offerTypeEnum } from '../../../enum';
import styles from './CardPriceForVertical.module.scss';

function CardPriceForVertical(props) {
  const { originPrice, mainPrice, originForceVisible, type = '' } = props;
  const hasOrigin = Boolean(originPrice);
  const standard =
    type.toLowerCase() === offerTypeEnum.lodging.toLowerCase()
      ? ' / 1박'
      : ' / 1인';

  return (
    <div className={styles.container}>
      {hasOrigin && (
        <span
          className={classNames(
            originForceVisible ? styles.visible : null,
            styles.origin,
          )}
        >
          {parseInt(originPrice, 10).toLocaleString()}
          <span className={styles.unit}>원</span>
        </span>
      )}
      <span className={styles.main}>
        {parseInt(mainPrice || 0, 10).toLocaleString()}
        <span className={styles.unit}>원</span>
      </span>
      <span className={styles.standard}>{standard}</span>
    </div>
  );
}

CardPriceForVertical.propTypes = {
  originPrice: PropTypes.number,
  mainPrice: PropTypes.number,
  type: PropTypes.oneOf([
    offerTypeEnum.lodging,
    offerTypeEnum.tour,
    offerTypeEnum.hotdeal,
    ...offerTypeEnum.ticket,
  ]),
  originForceVisible: PropTypes.bool,
};

CardPriceForVertical.defaultProps = {
  originPrice: 0,
  mainPrice: 0,
  type: offerTypeEnum.tour,
  originForceVisible: false,
};

export default CardPriceForVertical;
