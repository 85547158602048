// 긴급 공지가 필요한 경우 사용되는 팝업

import React from 'react';
import moment from 'moment';

import NotifyPopup from '../../components/popup/NotifyPopup';
import { initComponent, renderReactComponent } from './share';

export default function createNotifyPopup(mainUrl) {
  const startDate = moment('2022-12-20 20:00', 'YYYY-MM-DD hh:mm');
  const endDate = moment('2022-12-21 05:00', 'YYYY-MM-DD hh:mm');
  const canShowNotice = moment().isBetween(startDate, endDate);

  if (mainUrl.includes(window.location.pathname) && canShowNotice) {
    const [body] = document.getElementsByTagName('body');
    const div = initComponent('flight-notify');
    const popup = body.appendChild(div);
    renderReactComponent(<NotifyPopup />, popup);
  }
}
