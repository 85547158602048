import styled from '@emotion/styled';
import { helper } from '@myrealtrip/frontend-weblog';
import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import media from 'mrt-utils/mediaQuery';

import { getAuthLink } from '@layout/app/utils/getAuthLink';
import i18n from '@layout/app/utils/i18n';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

const { sendClickLog } = helper;

const POLICY_ITEMS = [
  {
    title: i18n.t('footer:help-policy'),
    link: getAuthLink('/terms/policyAgreement'),
    bold: false,
  },
  {
    title: i18n.t('footer:help-privacy'),
    link: getAuthLink('/terms/common/privacy'),
    bold: true,
  },
  {
    title: i18n.t('footer:help-refund'),
    link: getAuthLink('/terms/common/cancel'),
    bold: false,
  },
];

export default function PolicyList() {
  const handleClick =
    ({ title, targetUrl }) =>
    () => {
      sendClickLog({
        eventName: 'footer',
        screenName: WeblogScreenName.COMMON,
        pageCategory: WeblogPageCategory.COMMON,
        menuTitle: title,
        targetUrl: targetUrl,
      });
    };

  return (
    <Container>
      {POLICY_ITEMS.map(({ title, link, bold }) => (
        <PolicyItem key={title}>
          <Link href={link} bold={bold} onClick={handleClick({ title, targetUrl: link })}>
            {title}
          </Link>
        </PolicyItem>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 50%;

  ${media.mobile} {
    width: 100%;
  }
`;

const PolicyItem = styled.div`
  display: flex;
  align-items: center;
  height: 44px;

  &:not(:first-of-type) {
    margin-left: 20px;
  }
`;

const Link = styled.a<{ bold?: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 6px;
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  color: ${globalVariables.gray_600};
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    color: ${globalVariables.blue_500};
    background-color: ${globalVariables.blue_50};
  }
`;
