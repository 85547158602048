/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';

import useRemote from '../../../../packages/mrt-components/hooks/useRemote';

type User = {
  id: number;
  hashMemberId?: string;
  username: string;
  email: string;
  phoneNumber: string;
};

interface Props {
  user?: User;
}

function CsWidget({ user }: Props) {
  const { isLoad } = useRemote(import('remote/csHelper'));
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (isLoad && !isInit) {
      (async function init() {
        await window.csHelper?.init();
        setIsInit(true);
      })();
      return () => window.csHelper?.clear(user);
    }
    return () => {
      window.csHelper?.clear();
    };
  }, [isInit, isLoad, user]);

  useEffect(() => {
    if (isInit) {
      window.csHelper?.login(user);
    }
  }, [isInit, user]);

  return null;
}

export default CsWidget;
