import { apiClient } from '@layout/app/api/apiClient';
import { NotificationItem, NotificationQuery } from '@layout/constants/notification';

export type NotificationListResponse = {
  last_page: boolean;
  notifications: NotificationItem[];
};

export const fetchNotifications = (data: NotificationQuery | null) => {
  const defaultQuery = { except_community_notification: true };

  return apiClient.get<NotificationListResponse>(`${process.env.HOME_URL}/notifications`, {
    params: data ? { ...data, ...defaultQuery } : defaultQuery,
  });
};

export const markAsReadNotification = (id: string) => {
  return apiClient.put(`${process.env.HOME_URL}/notifications/${id}/read`);
};

export type HasFreshNotificationResponse = {
  new_exists: boolean;
};

export const fetchHasFreshNotification = () => {
  return apiClient.get<HasFreshNotificationResponse>(
    `${process.env.HOME_URL}/notifications/new_exists`,
    {
      params: { except_community_notification: true },
    },
  );
};
