import axios from 'axios';
import { useState, useEffect } from 'react';

import CookieManager, { CookieKey } from 'mrt-utils/cookieManager';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { buttonThemeEnum, sizeEnum } from '../../../../enum';
import Grid from '../../../shared/Grid';
import Link from '../../../shared/Link';
import { getReservationUrl } from '../../utils/domesticReservationUtils';
import { handleReservationClick } from '../../utils/jejuSendEvent';
import ReservationInfo from './ReservationInfo';

import styles from './DomesticReservation.module.scss';

function DomesticReservation(props) {
  const { isWebView, isMobile } = props;
  const [result, setResult] = useState([]);
  const link = getReservationUrl(isWebView, isMobile);
  const handleClick = () => handleReservationClick(isWebView);

  async function fetchFlightReservationList() {
    // PNR = Passenger Name Record (항공 예약 일련번호)
    const querystring = new URLSearchParams(window.location.search);

    const PNROneWay = querystring.get('dompnr_1');
    const PNRRoundWay = querystring.get('dompnr_2');
    const KSESID = querystring.get('KSESID');

    const cookieKey = CookieKey.MRT_SESSION_ID;
    const session_id = CookieManager.get(cookieKey);

    const url = `${
      process.env.FLIGHTS_URL
    }/air/agent/b2c/AIR/AAA/FlightsReservationPnr.k1jsn?dompnr_1=${PNROneWay}${
      PNRRoundWay !== null ? `&dompnr_2=${PNRRoundWay}` : ''
    }&KSESID=${KSESID}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Cookie: `${cookieKey}=${session_id}`,
        },
      });

      if (response.status === 200) {
        setResult(response.data.reservationList.data);
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetchFlightReservationList();
  }, [isMobile]);

  return (
    <Grid isFullWidth={isMobile}>
      <section className={classNames(styles.container, styles.fontAppleGothic)}>
        <div className={styles.message}>
          <div className={styles.contents}>
            <div className={styles.iconbox}>
              <img
                src="https://dffoxz5he03rp.cloudfront.net/icons/check_circle_animated.png"
                alt="check img"
              />
            </div>
            <div className={styles.pBox}>
              <p className={styles.main}>항공권 발권이 완료되었습니다</p>
              <p className={styles.sub}>마이리얼트립과 함께 즐거운 여행되세요!</p>
            </div>
            {result.length !== 0
              ? result.map((data, index) => (
                  <ReservationInfo
                    key={data.PNR_SEQNO + index}
                    data={data}
                    index={index}
                    isMobile={isMobile}
                  />
                ))
              : null}
            <div className={styles.reservationBtnBox}>
              <Link
                href={link}
                theme={buttonThemeEnum.gray}
                size={sizeEnum.large}
                onClick={handleClick}
                className={styles.reservationBtn}
              >
                <span className={styles.reservationListBtnTxt}>예약내역 보기</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Grid>
  );
}

DomesticReservation.propTypes = {
  isWebView: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default DomesticReservation;
