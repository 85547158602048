import { ShareChannel, ShareItemType } from 'mrt-types';

import { SystemProvider } from './product';

export const ShareList: ShareItemType[] = [
  {
    code: ShareChannel.KAKAOTALK,
    icon: 'ic_sharekakaotalk_xs_gray_500',
    text: '카카오톡',
  },
  {
    code: ShareChannel.FACEBOOK,
    icon: 'ic_sns_facebook_xs_gray_500',
    text: '페이스북',
  },
  {
    code: ShareChannel.TWITTER,
    icon: 'ic_sns_twitter_xs_gray_500',
    text: '트위터',
  },
  {
    code: ShareChannel.COPYLINK,
    icon: 'ic_insertlink_xs_gray_700',
    text: '링크 복사',
  },
];

export enum ProductRequireInfoFieldType {
  NAME = 'name',
  KOR_NAME = 'korName',
  ENG_NAME = 'engName',
  ENG_FIRST_NAME = 'engFirstName',
  ENG_LAST_NAME = 'engLastName',
  PHONE = 'phone',
  EMAIL = 'email',
  BIRTH_DAY = 'birthDay',
  GENDER = 'gender',
  PASSPORT = 'passport',
  LICENSE_TYPE = 'licenseType',
  RECEIVER = 'receiver',
  RECEIVER_PHONE_NUMBER = 'receiverPhoneNumber',
  ZIPCODE = 'zipcode',
  ADDRESS = 'address',
  ADDRESS_DETAIL = 'addressDetail',
}

export enum ProductRequireInfoFieldTitle {
  name = '운전자 이름',
  korName = '한글 이름',
  engName = '영문 이름',
  engFirstName = '영문 이름',
  engLastName = '영문 성',
  phone = '휴대전화 번호',
  email = '이메일 주소',
  birthDay = '생년월일',
  licenseType = '운전면허',
  receiver = '수령인',
  receiverPhoneNumber = '수령인 휴대전화 번호',
  zipcode = '우편 번호',
  addressDetail = '상세 주소',
  address = '주소',
  gender = '성별',
  birth = '생년월일',
  passport = '여권정보',
}

export enum CovidIsolationCode {
  NO_ENTRANCE = 'NO_ENTRANCE', // 입국 불가
  NO = 'NO', // 격리 없음
  NO_NEGATIVE = 'NO_NEGATIVE', // 음성확인자 격리 없음
  NEED = 'NEED', // 격리 있음
  UNKNOWN = 'UNKNOWN', // 격리 정보 없음
}

export const QuantityDegree = {
  [SystemProvider.AIR]: '',
  [SystemProvider.BNB]: '인', // quantity 값이 도미토리면 인원수, 아니면 개수(이 땐 무조건 1)
  [SystemProvider.STY]: '박',
  [SystemProvider.ACM]: '박',
  [SystemProvider.TNA]: '개',
  [SystemProvider.EXP]: '개',
  [SystemProvider.CAR]: '',
};

// 2022.10 카테고리별 수량 단위 표기 정책 https://myrealtrip.atlassian.net/wiki/spaces/payment/pages/3216113938/-
export const OptionSingularCount = {
  [SystemProvider.AIR]: '',
  [SystemProvider.BNB]: '1박',
  [SystemProvider.STY]: '1박',
  [SystemProvider.ACM]: '1박',
  [SystemProvider.TNA]: '1개',
  [SystemProvider.EXP]: '1개',
  [SystemProvider.CAR]: '24시간',
  [SystemProvider.PKG]: '옵션 1개',
};

export const Field = {
  korName: 'korName',
  engLastName: 'engLastName',
  engFirstName: 'engFirstName',
  phoneIcc: 'phoneIcc',
  phoneNumber: 'phoneNumber',
  passportNumber: 'passportNumber',
  passportExpireDate: 'passportExpireDate',
  passportNationality: 'passportNationality',
  birthDate: 'birthDate',
  gender: 'gender',
  drivingLicenseType: 'drivingLicenseType',
} as const;
