export enum WeblogPageCategory {
  ALL = '전체',
  MAIN = '홈',
  ACCOMMODATION_MAIN = '숙소홈',
  ACCOMMODATION_DETAIL = '국내숙소 상세',
  LODGING_MAIN = '감성숙소 메인',
  LODGING_LIST = '감성숙소 리스트',
  LODGING_DETAIL = '감성숙소 상세',
  LODGING_OPTION = '감성숙소 객실상세 진입',
  RENTACAR_MAIN = '렌터카 홈',
  RENTACAR_LIST = '렌터카 리스트',
  RENTACAR_DETAIL = '렌터카 상세',
  RENTACAR_MAP = '렌터카 지도',
  PURCHASE_COMPLETE = '결제완료',
  PURCHASE_NEW = '3.0 예약화면',
  PURCHASE_COMPLETE_NEW = '3.0 예약완료 화면',
  RESERVATION_HISTORY_ONGOING = '예약내역 (예정된 여행)',
  RESERVATION_HISTORY_VOUCHER = '예약내역 (티켓/패스)',
  RESERVATION_HISTORY_FLIGHT = '예약내역 (항공권)',
  RESERVATION_HISTORY_FINISH = '예약내역 (지난 여행)',
  RESERVATION_HISTORY_CANCEL = '예약내역 (취소된 여행)',
  RESERVATION_HISTORY_CUSTOM_ORDER = '예약내역 (맞춤여행 상담)',

  CHECKOUT = '체크아웃 주문하기',
  CHECKOUT_COMPLETE = '체크아웃 주문 완료',
  CHECKOUT_PAYMENT_FAILED = '체크아웃 결제 실패',
  CHECKOUT_RESERVATION_FAILED = '체크아웃 예약 실패',
  CHECKOUT_RESERVATION_DETAIL = '체크아웃 예약 상세',
  CHECKOUT_RESERVATION_DETAIL_CANCEL = '체크아웃 > 예약 상세 > 예약 취소 모달',
  CHECKOUT_RESERVATION_DETAIL_CANCEL_POPUP = '체크아웃 > 예약 상세 > 예약 취소 모달 > 예약 취소 클릭 > 확인 팝업',
  CHECKOUT_RESERVATION_DETAIL_REQUEST_CANCEL = '체크아웃 > 예약 상세 > 취소 요청 모달',
  CHECKOUT_RESERVATION_DETAIL_REQUEST_CANCEL_POPUP = '체크아웃 > 예약 상세 > 취소 요청 모달 > 취소 요청 클릭 > 확인 팝업',
  CHECKOUT_PAY_LATER = '체크아웃 나중결제',
  REVIEW_REGISTER = '후기 플랫폼 후기 작성',
  REVIEW_MODIFY = '후기 플랫폼 후기 수정',

  PROMOTION_LIST = '프로모션 리스트',
  PROMOTION_DETAIL = '프로모션 상세',

  OFFER_DETAIL = '상품 상세',
  EVENT_DGM = '대국민 숙박대전 이벤트',
  CITY = '도시 페이지',
  COMMON = '공통',
  SIGNIN = '로그인',
  SIGNUP = '회원가입',
  EMAIL_SIGNIN = '이메일 로그인',
  EMAIL_SIGNUP = '이메일 회원가입',
  SEARCH_RESULT = '검색 결과',
  OFFER_LIST = '상품 리스트',
  GRADE_BENEFIT = '회원 등급',
  WISHLIST = '저장됨',
  ESTIMATE_DETAIL = '맞춤여행 상세',
  TRAVELER_LIST = '여행자 정보 관리',
  ADD_NEW_TRAVELER = '여행자 정보 추가',
  RESERVATION_DETAIL_INQUIRE = '예약 문의하기',
  CHAT_ROOM = '대화방',

  CUSTOM_PACKAGE_SCHEDULE_TRAVELER = '여행자 일정표',
  CUSTOM_PACKAGE_REQUEST = '예약요청',
  CUSTOM_PACKAGE_DONE = '예약요청 완료',

  PACKAGE_SEARCH_HOME = '패키지 검색홈',
  PACKAGE_SEARCH_RESULT = '패키지 검색 목록',
  PACKAGE_PERSONAL_CONTACT = '퍼스널케어 유형 선택',

  ORDER_FORM_COUNTDOWN = '주문서 내, 카운트다운 노출',
  ORDER_FORM_REFRESH = '주문서 내, 새로고침 클릭',

  // 항공 결제
  DETAIL_PRICE = '항공주문서 금액상세 클릭',
  CHECK_AGREE_ALLTERMS = '항공주문서 전체 약관동의 클릭',
  CHECK_PAYMENT_POLICY = '항공주문서 결제규정 클릭',
  CHECK_CHANGE_REFUND_POLICY = '항공주문서 변경 및 환불규정 클릭',
  CANCEL_PAYMENT_POLICY = '항공주문서 결제규정 취소',
  CANCEL_CHANGE_REFUND_POLICY = '항공주문서 변경 및 환불규정 취소',
  PAY_NOW = '항공주문서 결제하기 버튼 클릭',
  REBOOK_FLIGHT = '항공권 다시 검색하기 클릭',
  CHECKOUT_PAYMENT_FAILED_FLIGHTS = '항공권 결제 실패',
}

export enum WeblogScreenName {
  MAIN = 'main',
  ACCOMMODATION_MAIN = 'accommodation_main',
  ACCOMMODATION_DETAIL = 'accommodation_detail',
  LODGING_MAIN = 'lodging_main',
  LODGING_LIST = 'lodging_list',
  LODGING_DETAIL = 'lodging_detail',
  LODGING_OPTION = 'lodging_room_detail',
  RENTACAR_MAIN = 'rentacar_main',
  RENTACAR_LIST = 'rentacar_list',
  RENTACAR_DETAIL = 'rentacar_detail',
  RENTACAR_MAP = 'rentacar_map',
  PURCHASE_COMPLETE = 'purchase_complete',
  PURCHASE_NEW = 'purchase_new',
  PURCHASE_COMPLETE_NEW = 'purchase_complete_new',
  RESERVATION_HISTORY_ONGOING = 'reservation_history_ongoing',
  RESERVATION_HISTORY_VOUCHER = 'reservation_history_voucher',
  RESERVATION_HISTORY_FLIGHT = 'reservation_history_flight',
  RESERVATION_HISTORY_FINISH = 'reservation_history_finish',
  RESERVATION_HISTORY_CANCEL = 'reservation_history_cancel',
  RESERVATION_HISTORY_CUSTOM_ORDER = 'reservation_history_custom_order',

  CHECKOUT = 'checkout',
  CHECKOUT_COMPLETE = 'checkout_complete',
  CHECKOUT_PAYMENT_FAILED = 'checkout_payment_failed',
  CHECKOUT_RESERVATION_FAILED = 'checkout_reservation_failed',
  CHECKOUT_RESERVATION_DETAIL = 'checkout_reservation_detail',
  CHECKOUT_RESERVATION_DETAIL_CANCEL = 'checkout_reservation_detail_cancel',
  CHECKOUT_RESERVATION_DETAIL_CANCEL_POPUP = 'checkout_reservation_detail_cancel_popup',
  CHECKOUT_RESERVATION_DETAIL_REQUEST_CANCEL = 'checkout_reservation_detail_request_cancel',
  CHECKOUT_RESERVATION_DETAIL_REQUEST_CANCEL_POPUP = 'checkout_reservation_detail_request_cancel_popup',
  CHECKOUT_PAY_LATER = 'checkout_pay_later',
  REVIEW_REGISTER = 'review_register',
  REVIEW_MODIFY = 'review_modify',

  PROMOTION_LIST = 'promotion_list',
  PROMOTION_DETAIL = 'promotion_detail',
  SECRET_PROMOTION_DETAIL = 'secret_promotion_detail',

  OFFER_DETAIL = 'offer_detail',
  EVENT_DGM = 'campaign_dgm',
  CITY = 'city',
  COMMON = 'common',
  SIGNIN = 'login',
  SIGNUP = 'signup',
  EMAIL_SIGNIN = 'email_signin',
  EMAIL_SIGNUP = 'email_signup',
  SEARCH_RESULT = 'search_result',
  OFFER_LIST = 'offer_list',
  GRADE_BENEFIT = 'grade_benefit',
  WISHLIST = 'wishlist',
  ESTIMATE_DETAIL = 'custom_order_detail',
  TRAVELER_LIST = 'traveler_list',
  ADD_NEW_TRAVELER = 'add_new_traveler',
  COMPANION_BRIDGE = 'companion_bridge',
  RESERVATION_DETAIL_INQUIRE = 'reservation_detail_inquire',
  CHAT_DETAIL = 'chat_detail',
  CHAT_LIST = 'chat_list',

  CUSTOM_PACKAGE_SCHEDULE_TRAVELER = 'custom_package_schedule_traveler',
  CUSTOM_PACKAGE_REQUEST = 'custom_package_request',
  CUSTOM_PACKAGE_DONE = 'custom_package_done',

  PACKAGE_SEARCH_HOME = 'package_search_home',
  PACKAGE_SEARCH_RESULT = 'package_search_result',
  PACKAGE_PERSONAL_CONTACT = 'personal_contact_type',

  // 항공 결제
  INTL_FLIGHTS_PAYLATER = 'intl_flights_payLater',
  CHECKOUT_PAYMENT_FAILED_FLIGHTS = 'checkout_payment_failed_flights',
}

export enum WeblogEventName {
  CANCEL_BUTTON = 'cancel_button',
  REQUEST_CANCEL_BUTTON = 'request_cancel_button',
  CANCEL_POPUP = 'cancel_popup',
  REQUEST_CANCEL_POPUP = 'request_cancel_popup',
  REVIEW_MODIFY = 'review_modify',
  REVIEW_DELETE = 'review_delete',
  REVIEW_DELETE_OK = 'review_delete_ok',
  RESERVATION_DETAIL = 'reservation_detail',
  CANCEL_CHECK_POPUP_BUTTON = 'cancel_check_popup_button',
  REQUEST_CANCEL_CHECK_POPUP_BUTTON = 'request_cancel_check_popup_button',
  WISHLIST = 'wishlist',
  ESTIMATE_DETAIL = 'custom_order_detail',
  TRAVELER_LIST = 'traveler_list',
  ADD_NEW_TRAVELER = 'add_new_traveler',
  EDIT_TRAVELER = 'edit_traveler',
  ENTER_CHAT = 'enter_chat',
  CHECKOUT = 'checkout',
  CHECKOUT_PURCHASE = 'checkout_purchase',
  CHECKOUT_PAY_LATER = 'checkout_pay_later',
  CHECKOUT_PAYMENT_FAILED = 'checkout_payment_failed',
  CHECKOUT_RESERVATION_FAILED = 'checkout_reservation_failed',
  CHECKOUT_COMPLETE = 'checkout_complete',
  CHECKLIST = 'checklist',
  RESERVATION_DETAIL_INQUIRE = 'reservation_detail_inquire',
  RESERVATION_INQUIRE_MORE = 'reservation_inquire_more',
  RESERVATION_HISTORY_INQUIRE_BUTTON = 'reservation_history_inquire_button',
  RESERVATION_NUMBER_COPY = 'reservation_number_copy',
  RESERVATION_DETAIL_INQUIRE_BUTTON = 'reservation_detail_inquire_button',
  RESERVATION_HISTORY_MORE = 'reservation_history_more',
  CHECK_CUSTOM_ORDER = 'check_custom_order',
  MODIFY_CUSTOM_ORDER = 'modify_custom_order',
  PAYMENT = 'payment',
  CHAT_LIST = 'chat_list',
  CHAT_DETAIL = 'chat_detail',
  FINISH_CUSTOM_ORDER = 'finish_custom_order',
  CUSTOM_ORDER_RESERVATION_LIST = 'custom_order_reservation_list',
  CUSTOM_PACKAGE_SCHEDULE_TRAVELER = 'custom_package_schedule_traveler',
  COPY_LINK = 'copy_link',
  IMAGE_DOWNLOAD = 'image_download',
  CUSTOM_PACKAGE_REQUEST = 'custom_package_request',
  CUSTOM_PACKAGE_DONE = 'custom_package_done',
  PACKAGE_SEARCH_HOME = 'package_search_home',
  SEARCH_BAR = 'search_bar',
  SEARCH_FREETEXT = 'search_freetext',
  SEARCH_SUGGEST = 'search_suggest',
  RECENT_CITY = 'recent_city',
  CALENDAR = 'calendar',
  CALENDAR_APPLY = 'calendar_apply',
  COMPANY = 'company',
  SEARCH_APPLY = 'search_apply',
  PACKAGE_SEARCH_RESULT = 'package_search_result',
  OFFER = 'offer',
  INFO_BANNER = 'info_banner',
  BANNER = 'banner',
  RECOMMEND_CITY = 'recommend_city',
  RECOMMEND_CITY_MORE = 'recommend_city_more',
  REFRESH = 'refresh',

  // 항공 결제
  INTL_FLIGHTS_PAYLATER = 'intl_flights_payLater',
  DETAIL_PRICE = 'detailPrice',
  CHECK_AGREE_ALLTERMS = 'checkAgreeAllTerms',
  CHECK_PAYMENT_POLICY = 'checkPaymentPolicy',
  CHECK_CHANGE_REFUND_POLICY = 'checkChangeRefundPolicy',
  CANCEL_PAYMENT_POLICY = 'cancelPaymentPolicy',
  CANCEL_CHANGE_REFUND_POLICY = 'cancelChangeRefundPolicy',
  PAY_NOW = 'payNow',
  INTL_FLIGHTS_PAY_ERROR = 'intl_flights_pay_error',
  REBOOK_FLIGHT = 'rebookFlight',
  CHECKOUT_PAYMENT_FAILED_FLIGHTS = 'checkout_payment_failed_flights',
}
