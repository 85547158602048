import { createContext, ReactNode, useEffect } from 'react';

import { getWebViewMetaInfoByMrtAppVersion } from 'mrt-utils';
import CookieManager, { CookieKey } from 'mrt-utils/cookieManager';

// csHelper 리모트 모듈을 공통 사용하기 위하여 해당 코드 추가
import('remote/csHelper').then(() => null);

interface Props {
  children: ReactNode;
}

type WebViewContenxt = {
  isWebView: boolean;
  platform: string;
  version: string;
  type: string;
  pid: string | null;
};

const defaultValue = {
  isWebView: false,
  platform: '',
  version: '',
  type: '',
  pid: null,
};

export const WebViewContext = createContext<WebViewContenxt>(defaultValue);

function WebViewProvider({ children }: Props) {
  const pid = CookieManager.get(CookieKey.PID) ?? null;
  // 웹뷰 경로가 아닌 경우는 AppVersion이 존재하지 않으므로 null 타입 허용
  const appVersion = CookieManager.get(CookieKey.APP_VERSION) || null;
  const { platform = '', version = '', type = '' } = getWebViewMetaInfoByMrtAppVersion(appVersion);
  // AppVersion 정보가 모두 존재해야 웹뷰인 걸로 판정
  const isWebView = Boolean(platform) && Boolean(version) && Boolean(type);
  const webViewMetaInfo = {
    isWebView,
    platform,
    version,
    type,
    pid,
  };

  useEffect(() => {
    (async function init() {
      await import('remote/csHelper');
      window.csHelper?.setIsWebView?.(isWebView);
      await window.csHelper?.init();
    })();
  }, [isWebView]);

  return <WebViewContext.Provider value={webViewMetaInfo}>{children}</WebViewContext.Provider>;
}

export default WebViewProvider;
