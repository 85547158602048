import qs from 'qs';
import validateUrl from '../../../utils/validateUrl';

export function getReservationUrl(isWebView, isMobile) {
  const { search } = window.location;
  const isGuest = search.toLowerCase().includes('usertype=guest');

  if (isGuest) {
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    const path = isMobile
      ? '/air/b2c/AIR/MBL/AIRMBLLST0100000010.k1'
      : '/air/b2c/AIR/AAA/AIRAAALST0100000010.k1';

    return validateUrl(`${process.env.FLIGHTS_URL}${path}?KSESID=${query.KSESID}`);
  }

  return isWebView ? 'mrt://reservations/flight' : 'https://www.myrealtrip.com/reservations';
}

export function getDateString(data) {
  const month = data.slice(4, 6);
  const monthResult = month[0] === '0' ? month[1] : month;

  const date = data.slice(6, 8);
  const dateResult = date[0] === '0' ? date[1] : date;

  const newdate = new Date(`${data.slice(0, 4)}-${data.slice(4, 6)}-${data.slice(6, 8)}`);

  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const day = week[newdate.getDay()];

  return `${monthResult}월 ${dateResult}일 (${day})`;
}

export function getCountString(adt, chd, inf) {
  const adtStr = adt !== 0 ? `성인 ${adt}명` : '';
  const chdStr = chd !== 0 ? `소아 ${chd}명` : '';
  const infStr = inf !== 0 ? `유아 ${inf}명` : '';

  const comma1 = adt !== 0 && chd !== 0 && inf !== 0 ? ', ' : '';
  const comma2 = inf !== 0 && (adt !== 0 || chd !== 0) ? ', ' : '';

  return adtStr + comma1 + chdStr + comma2 + infStr;
}

export function getFlightTime(str) {
  // str : string -  ex) ("0110") => 1시간 10분
  const hour = str.slice(0, 1) === `0` ? str.slice(1, 2) : str.slice(0, 2);
  const min = str.slice(2, 3) === `0` ? str.slice(3, 4) : str.slice(2, 4);

  return (hour !== `0` ? `${hour}시간 ` : ``) + (min !== `0` ? `${min}분` : ``);
}
