const copyrightDefault = `MyRealTrip | CEO, Donggun Lee | Personal Information Maintenance Manager, Hyungseok Kim | Business Registration Number, 209-81-55339 <a target='_blank' href='https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2098155339'>Check Info</a> | Mail-order Distributor Registration Number,
2019-서울서초-0260<br class='hide-on-tablet'><span class='show-on-tablet'> | </span>18F, 311, Gangnam-daero, Seocho-gu, Seoul, Republic of Korea | E-mail help@myrealtrip.com | marketing@myrealtrip.com | partnership@myrealtrip.com<br><br>
MyRealTrip purchased deduction business guarantee insurance from the Seoul Tourism Association.`;

const footer = {
  about: 'About MyRealTrip',
  'about-service': 'About Service',
  'about-company': 'About Company',
  'about-career': 'Careers',
  'about-announcement': 'Announcement',
  'about-howToUse': 'How to Use',
  'about-press': 'Press Releases',
  partner: 'Partners',
  'partner-registGuide': 'Partner Home',
  'partner-b2b': 'Marketing/Partnership Inquiry',
  'partner-realGuide': 'Real Partner',
  'partner-blog': 'Partner Blog',
  help: 'Help',
  'help-customerCenter': 'Customer Center',
  'help-faq': 'Notice/FAQ',
  'help-policy': 'Terms and Conditions',
  'help-privacy': 'Privacy Policy',
  'help-refund': 'Cancellation and Refund Policy',
  'help-price': 'Best Price Guarantee',
  'help-question-workingHour': `Support at 09:00-18:00\nand both are available 365days (Lunch 12:00-13:00)`,
  'help-question-weekend': 'Weekends/Holidays included',
  'help-question-air-refund':
    '※ Refund of flight tickets available every day from 09:00 to 17:00 (KST)',
  'help-question-air-change':
    '※ Change of flight tickets available on weekdays from 09:00 to 17:00 (KST)',
  'help-holiday': 'Service is available 24/7',
  'help-lunch': 'Lunch',
  'help-chat': '1:1 Chat',
  'help-call': 'Call 1670-8208',
  'help-call2': '1670-8208',
  copyright: `${copyrightDefault}
              MyRealTrip is a mail order brokerage, not a mail order distributor. Therefore, MyRealTrip will not be held responsible for any liabilities arising in connection with any transactions between buyers and sellers on this website.`,
};

export default footer;
