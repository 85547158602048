import { useDetermineMobileContext } from 'mrt-components';
import media from 'mrt-utils/mediaQuery';

import styled from '@emotion/styled';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import AppStoreList from './AppStoreList';
import SNSList from './SNSList';

export default function OutLinkContainer() {
  const isMobile = useDetermineMobileContext();

  return (
    <Container>
      <SNSList />
      {!isMobile && <Divider />}
      <AppStoreList />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-end;

  ${media.mobile} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    order: 2;
  }
`;

const Divider = styled.span`
  width: 1px;
  height: 20px;
  background-color: ${globalVariables.gray_300};
  margin: 0 10px;
`;
