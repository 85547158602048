import { useState, useEffect } from 'react';

import { Platform, BreakPoint } from 'mrt-constants';

const width = window.innerWidth;

const usePlatform = () => {
  const [platform, setPlatform] = useState<Platform>(Platform.DESKTOP);

  const mounted = () => {
    let device = platform;

    if (BreakPoint.MOBILE <= width) device = Platform.PHONE;
    if ('ontouchstart' in window) device = Platform.PHONE;
    if (platform !== device) setPlatform(device);
  };

  useEffect(mounted, [width]);

  return platform;
};
export default usePlatform;
