import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import FlightTicketHeader from './FlightTicketHeader';
import FlightTicketBody from './FlightTicketBody';

import styles from './ReservationInfo.module.scss';

function ReservationInfo(props) {
  const { data, isMobile, index } = props;

  return (
    <Fragment>
      <div className={styles.reservation}>
        <FlightTicketHeader data={data} index={index} />
        <FlightTicketBody data={data} isMobile={isMobile} />
      </div>
    </Fragment>
  );
}

ReservationInfo.propTypes = {
  data: PropTypes.object,
  isMobile: PropTypes.bool,
  index: PropTypes.number,
};

export default ReservationInfo;
