import { Platform } from './platform';

export enum ReviewSortId {
  DESC_LIKE_COUNT = '-likeCount',
  ASC_LIKE_COUNT = 'likeCount',
  DESC_SCORE = '-score',
  ASC_SCORE = 'score',
  DESC_CREATED_AT = '-createdAt',
  ASC_CREATED_AT = 'createdAt',
  DESC_RECOMMEND = '-recommendScore',
}

export enum ReviewFilterId {
  IMAGE = 'imaged',
  COMPANION = 'travelPurposes',
  SCORE = 'score',
}

export enum ReviewTravelConceptShortKey {
  ALONE = 'ALONE',
  LOVER = 'LOVER',
  PARTNER = 'PARTNER',
  FRIENDS = 'FRIENDS',
  CHILDREN = 'CHILDREN',
  PARENTS = 'PARENTS',
}

export const ReviewPlatformSupportAppVersion = {
  [Platform.IOS]: '6.18.0',
  [Platform.ANDROID]: '6.14.0',
};

export enum ReviewRedirectPageType {
  LIST = 'list',
  DETAIL = 'detail',
}

export const ReviewPlatformSource = {
  MRT3: 'MRT3',
} as const;
