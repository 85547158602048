const language = {
  title: 'Welcome!',
  kakaoOAuth: 'Sign up with Kakao',
  footerDescription: 'Already have an account\nwith Myrealtrip?',
  footerLinktext: 'Sign in',
  policy: {
    agreement: 'Terms of Service Agreement (required)',
    privacyUse: 'Personal Information Collection and Usage (required)',
    location: 'Terms and Conditions of :ocation Information (optional)',
  },
  kakao: 'Sign Up with Kakao',
  facebook: 'Sign Up with Facebook',
  naver: 'Sign Up with Naver',
  or: 'or',
  name: {
    label: 'Name*',
    placeholder: 'Enter your name.',
    validate: {
      empty: 'This field is required.',
      short: 'Your name must be at least 2 characters.',
    },
  },
  email: {
    label: 'E-mail*',
    placeholder: 'ID@example.com',
    validate: {
      empty: 'This field is required.',
      notEmail: 'Is this e-mail address correct?',
    },
  },
  password: {
    label: 'Password*',
    placeholder:
      'Password must be between 6~15 characters and consist of at least 2 combination of letters, numbers, and symbols.',
    validate: {
      empty: 'Enter password.',
      short: 'Your password is too short (minimum 6 characters).',
      long: 'Your password is too long (maximum 15 characters).',
      combination:
        'Your password should contain at least 2 among letters, numbers, and symbols.',
      continuous:
        'You cannot use 3 or more consecutive or identical letters and numbers.',
      same:
        'You cannot use 3 or more consecutive or identical letters and numbers.',
      other:
        'Characters other than English letters, numbers and symbols cannot be used.',
      current: 'Please enter a different password from your current password.',
      done: 'Your password is secure.',
    },
  },
  password2: {
    label: 'Confirm password*',
    placeholder: 'Please enter your password again.',
    validate: {
      empty: 'Re-enter password.',
      notEqual: 'Password and confirmation do not match.',
      done: 'Password and confirmation match.',
    },
  },
  agreement: {
    all: 'Agree to full terms and conditions',
    marketing:
      'Agree to receive marketing and promotion notification (optional)',
    subscription: 'Can be changed at Profile> Subscription',
  },
  notices: [
    '*You can still use our service without agreeing to optional terms.',
    '*By signing up, you confirm that you are at least 14 years old.',
  ],
  signUp: 'Sign Up',
  subscription:
    'Note: If you do not agree to receive marketing and promotion notification, you will not be able to receive information on discounts and special offers from MyRealTrip.\nWould you like to receive these benefits?',
  feedback: {
    welcome: {
      title: 'Congratulations on becoming\na Myrealtrip member!',
      description:
        'Please check the registration information below to manage your account.',
    },
    exist: {
      title: 'Already have an account\nwith Myrealtrip.',
      description:
        'Sign in with your previous account with the information below.',
    },
    detail: {
      email: 'Email',
      phone: 'Phone',
      sns: 'SNS',
    },
    login: {
      kakao: 'Sign in with kakao',
      naver: 'Sign in with naver',
      facebook: 'Sign in with facebook',
      mrtEmail: 'Sign in',
    },
    signIn: 'Sign in',
    newJoin: 'New sign up',
  },
  loading: {
    title: 'You are registering.',
    description: 'Please wait.',
  },
  oauth: {
    kakao: 'Kakao',
    naver: 'Naver',
    facebook: 'Facebook',
  },
};

export default language;
