import { css } from '@emotion/react';

import { useDetermineMobileContext } from 'mrt-components';

import styled from '@emotion/styled';
import { HeaderTheme } from '@layout/constants/header';
import { HeaderThemeType } from '@layout/constants/header';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import DefaultLocalNavItems from './DefaultLocalNavItems';
import MainPageLocalNavItems from './MainPageLocalNavItems';

function isHideLnbInMobile(pathName: string) {
  const hideLnbPages = ['/cities', '/countries'];
  const isOffer = /\/offers\/[0-9]+\/?$/g.test(pathName);
  const isPayment = /\/offers\/[0-9]+\/reservations\/new$/g.test(pathName);
  // 3.0 payment
  const isOrder = /\/order\/[^/]+$/g.test(pathName);
  const isOrderPending = /\/order\/[0-9]+\/pending+/g.test(pathName);
  const isStayDetail = /\/accommodations\/stays\/[0-9]+\/?$/g.test(pathName);
  const isPackagesReservation = /\/packages\/reservation\/[0-9]+\/?/g.test(pathName);

  return (
    hideLnbPages.includes(pathName) ||
    isOffer ||
    isPayment ||
    isOrder ||
    isOrderPending ||
    isStayDetail ||
    isPackagesReservation
  );
}

export default function LNB() {
  const { theme, isFlightMode, excludeLnb } = useLayoutHeaderContext();
  const isMobile = useDetermineMobileContext();
  const isMainPagePath = window.location.pathname === '/';
  const canShowMainPageLocalNavBar = !isMobile && isMainPagePath;
  const canShowDefaultLocalNavBar = isFlightMode || !isMainPagePath;
  const isHideMainPageBorder = isMobile && isMainPagePath;

  const needHideLnb = isMobile && isHideLnbInMobile(window.location.pathname);

  if (needHideLnb || excludeLnb) {
    return null;
  }

  return (
    <Container colorTheme={theme} noBorder={isHideMainPageBorder}>
      {canShowMainPageLocalNavBar && (
        <Inner>
          <MainPageLocalNavItems />
        </Inner>
      )}
      {canShowDefaultLocalNavBar && (
        <Inner>
          <DefaultLocalNavItems />
        </Inner>
      )}
    </Container>
  );
}

const LNB_THEME_STYLE = {
  [HeaderTheme.blue]: css`
    border-bottom: 1px solid ${globalVariables.blue_600};
  `,
  [HeaderTheme.white]: css`
    border-bottom: 1px solid ${globalVariables.gray_200};
  `,
  [HeaderTheme.transparent]: css`
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  `,
};

const Container = styled.div<{
  colorTheme: HeaderThemeType;
  noBorder?: boolean;
}>`
  width: 100%;

  ${({ colorTheme }) => LNB_THEME_STYLE[colorTheme]};
  ${({ noBorder }) => noBorder && 'border-bottom: 0;'};
`;

const Inner = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  max-width: 1060px;
  margin: 0 auto;
`;
