import React, { Component } from 'react';

import { Utils } from 'app/common';
import Modal from '../shared/Modal';
import Notify from './notify/Notify';

class NotifyPopup extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.cookieKey = 'notice/notify0225';

    const hasCookie = Utils.getCookie(this.cookieKey);

    this.state = {
      modal: !hasCookie,
    };
  }

  handleModalClick = () => {
    const [body] = document.getElementsByTagName('body');

    this.setState({ modal: false });
    body.style.overflow = 'unset';
  };

  render() {
    const { modal } = this.state;

    return (
      <Modal isOpen={modal} onClose={this.handleModalClick}>
        <Notify
          lock
          onButtonClick={this.handleModalClick}
          cookieKey={this.cookieKey}
          cookieFireDate={20}
        />
      </Modal>
    );
  }
}

export default NotifyPopup;
