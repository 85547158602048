import React from 'react';
import PropTypes from 'prop-types';

import arrow from '../../../../assets/images/common/ic-arrow-right-xs-blue-500.svg';
import { childrenType } from '../../../../types';
import Grid from '../../../shared/Grid';
import Link from '../../../shared/Link';
import Loader from '../../../shared/OfferVerticalCardLoader';
import styles from './CrossSellJejuSection.module.scss';
import CrossSellJejuOutlink from './CrossSellJejuOutlink';
import { handleAllButtonClick } from '../../utils/jejuSendEvent';

function CrossSellJejuSection(props) {
  const {
    isMobile,
    isWebView,
    isLoading,
    title,
    desc,
    linkText,
    linkPath,
    linkAction,
    deeplink,
    deeplinkAction,
    eventLabel,
    children,
  } = props;
  const hasLink = Boolean(linkText) && Boolean(linkPath);
  const link = isWebView ? deeplink : linkPath;
  const handleClick = () => {
    const action = isWebView ? deeplinkAction : linkAction;

    handleAllButtonClick(isWebView, action, eventLabel);
  };

  return (
    <Grid isFullWidth={isMobile}>
      <section className={styles.container}>
        <header className={styles.header}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.desc}>{desc}</p>
          {hasLink && !isMobile && (
            <div className={styles.link}>
              <Link href={link} target="_blank" onClick={handleClick}>
                <span>{linkText}</span>
                <img src={arrow} alt="" />
              </Link>
            </div>
          )}
        </header>
        {isLoading ? <Loader count={4} /> : children}
        {hasLink && isMobile && (
          <div className={styles.footer}>
            <CrossSellJejuOutlink
              link={link}
              label={linkText}
              onClick={handleClick}
              isBlank
            />
          </div>
        )}
      </section>
    </Grid>
  );
}

CrossSellJejuSection.propTypes = {
  isMobile: PropTypes.bool,
  isWebView: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: childrenType,
  title: PropTypes.string,
  desc: PropTypes.string,
  linkText: PropTypes.string,
  linkPath: PropTypes.string,
  linkAction: PropTypes.string,
  deeplink: PropTypes.string,
  deeplinkAction: PropTypes.string,
  eventLabel: PropTypes.string,
};

CrossSellJejuSection.defaultProps = {
  isMobile: false,
  isWebView: false,
  isLoading: false,
  title: '',
  desc: '',
  linkText: '',
  linkPath: '',
  linkAction: '',
  children: null,
  deeplink: '',
  deeplinkAction: '',
  eventLabel: null,
};

export default CrossSellJejuSection;
