import { useQuery } from '@tanstack/react-query';

import { fetchAccount } from '@layout/app/api/account';

export default function useFetchAccount({ isFlightMode = false }) {
  const queryResult = useQuery(['fetchAccount'], () => fetchAccount(), {
    enabled: !isFlightMode,
  });

  return {
    ...queryResult,
    data: queryResult.data?.data.data,
  };
}
