import { shape, string, number } from 'prop-types';

export const searchCityType = shape({
  key_name: string,
  name: string,
  count: number,
});

export const searchLandmarkType = shape({
  key_name: string,
  name: string,
  count: number,
  city_name: string,
});

export const searchPriceType = shape({
  avg: number,
  count: number,
  max: number,
  min: number,
  sum: number,
});
