import activity from './experience/ic-category-activity-md-colored.svg';
import entertainment from './experience/ic-category-entertainment-md-colored.svg';
import guide_tour from './experience/ic-category-guide-tour-md-colored.svg';
import restaurant from './experience/ic-category-restaurant-md-colored.svg';
import snap from './experience/ic-category-snap-md-colored.svg';
import ticket from './experience/ic-category-ticket-md-colored.svg';
import utility from './experience/ic-category-utility-md-colored.svg';
import all from './experience/ic-category-all-md-colored.svg';

export default { activity, entertainment, guide_tour, restaurant, snap, ticket, utility, all };
