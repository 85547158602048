import { checkMobileUserAgent } from 'mrt-utils';
import i18n from 'mrt-i18n';
import { createToast, ToastPropsType } from '@myrealtrip/design-system';

const DEFAULT_TOAST_OPTIONS: ToastPropsType = {
  color: 'warning',
  position: checkMobileUserAgent() ? 'bottom' : 'top',
};
const COMMON_SERVER_ERROR_CODE = 'common.error.serverError';

type CodeParamType = {
  code: string;
  toastOptions?: ToastPropsType;
};

// API 응답의 i18n code를 이용한 에러 토스트 노출
export function openErrorToastByCode({ code, toastOptions }: CodeParamType) {
  // code 가 빈문자열일 경우 공통 에러 메시지 처리
  const toastMessage = i18n([code, COMMON_SERVER_ERROR_CODE]);

  const options: ToastPropsType = { ...DEFAULT_TOAST_OPTIONS, ...toastOptions };

  createToast(toastMessage, options);
}

type MessageParamType = {
  message: string;
  toastOptions?: ToastPropsType;
};

// API 응답의 message를 toast message로 사용하여 에러 토스트 노출
export function openErrorToastByMessage({
  message,
  toastOptions,
}: MessageParamType) {
  // message 가 빈문자열일 경우 공통 에러 메시지 처리
  const toastMessage = message || i18n([COMMON_SERVER_ERROR_CODE]);

  const options: ToastPropsType = { ...DEFAULT_TOAST_OPTIONS, ...toastOptions };

  createToast(toastMessage, options);
}
