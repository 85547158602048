import i18next from 'i18next';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import CookieManager, { CookieKey } from 'mrt-utils/cookieManager';

import language from '../language';

const lng = CookieManager.get(CookieKey.MRT_LNG) || 'ko';

const i18nInstance = i18next
  .use(i18nextBrowserLanguageDetector)
  .createInstance()
  .init({
    namespaces: Object.keys(language[lng]),
    resources: {
      ...language,
    },
    lng,
    fallbackLng: 'ko',
  });

export default i18nInstance;
