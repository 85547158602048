import classNames from 'classnames';
import PropTypes from 'prop-types';

import { cardTypeEnum } from '../../../enum';
import iconBlack from '../../../images/icons/ic-instantbooking-xs-black-400.svg';
import iconYellow from '../../../images/icons/ic-instantbooking-xs-yellow-400.svg';
import styles from './CardInstantBookingSticker.module.scss';

function CardInstantBookingSticker(props) {
  const { type, showText } = props;
  const icon = type === cardTypeEnum.bestSeller ? iconBlack : iconYellow;

  return (
    <div className={classNames(styles.container, styles[type])}>
      <img className={styles.icon} src={icon} alt="즉시확정" />
      {showText && <span className={styles.text}>즉시확정</span>}
    </div>
  );
}

CardInstantBookingSticker.propTypes = {
  type: PropTypes.oneOf(Object.values(cardTypeEnum)),
  showText: PropTypes.bool,
};

CardInstantBookingSticker.defaultProps = {
  type: cardTypeEnum.vertical,
  showText: false,
};

export default CardInstantBookingSticker;
