import { useState, useEffect, useCallback, useContext, ReactNode } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getLocalStorageItem, setLocalStorageItem } from '@myrealtrip/browser';
import { Icon, createAlert } from '@myrealtrip/design-system';
import { helper } from '@myrealtrip/frontend-weblog';
import { WebViewContext, useDetermineMobileContext } from 'mrt-components';
import { LocalStorageKey } from 'mrt-constants';
import { useDeepLink, useHideByCriteria } from 'mrt-hooks';
import { DeepLinkData, PageLogData } from 'mrt-types';
import CookieManager, { CookieKey } from 'mrt-utils/cookieManager';

import CouponBannerContent from './CouponBannerContent';
import DefaultBannerContent from './DefaultBannerContent';

const { sendClickLog, sendImpressionLog } = helper;

interface Props {
  deepLinkData: DeepLinkData;
  productThumbnail: string;
  productTitle: string;
  bottomPosition?: 'onFloatButton' | 'default';
  hideCriteriaRef?: React.RefObject<HTMLElement>;
  pageLogData: PageLogData;
  isCouponContentType?: boolean;
  mainText?: ReactNode;
}

const bottomPositionEnum = {
  onFloatButton: 80,
  default: 10,
};

const COUPON_PROMOTION_LINK = {
  deepLink: 'mrt://web?url=https://www.myrealtrip.com/instants/promotions/mrtcoupon-app',
  webLink: 'https://www.myrealtrip.com/promotions/mrtcoupon-app',
};

function AppInstallBanner({
  deepLinkData,
  productThumbnail,
  productTitle,
  bottomPosition = 'default',
  hideCriteriaRef,
  pageLogData,
  isCouponContentType = false,
  mainText,
}: Props) {
  const { isWebView } = useContext(WebViewContext);
  const isMobile = useDetermineMobileContext();
  const { isPassing } = useHideByCriteria(hideCriteriaRef);
  const [isDisplay, setIsDisplay] = useState(false);
  const { templateId, deepLink, webLink, afSub1 } = deepLinkData;
  const isMarketing = CookieManager.get(CookieKey.PARTNERSHIP_CODE) ?? null;

  const { openDeepLink } = useDeepLink({
    templateId,
    link: {
      deepLink: isCouponContentType ? COUPON_PROMOTION_LINK.deepLink : deepLink,
      webLink: isCouponContentType ? COUPON_PROMOTION_LINK.webLink : webLink,
    },
    defaultMediaSourceValue: 'mrt_app_install_banner',
    afSub1,
  });

  const handleClickBanner = useCallback(() => {
    sendClickLog({
      ...pageLogData,
      eventName: 'upper_banner',
      itemKind: 'button',
      itemName: 'install_click',
    });

    openDeepLink('redirect');
  }, [openDeepLink, pageLogData]);

  const handleClickConfirm = () => {
    sendClickLog({
      ...pageLogData,
      eventName: 'close_popup',
      itemKind: 'button',
      itemName: 'close',
    });

    setLocalStorageItem(LocalStorageKey.APP_INSTALL_BANNER, 'true', 168);
    setIsDisplay(false);
  };

  const handleClickCancle = () => {
    sendClickLog({
      ...pageLogData,
      eventName: 'close_popup',
      itemKind: 'button',
      itemName: 'go_back',
    });
  };

  const handleOpenAlert = () => {
    const message = (
      <>
        지금 닫으면 다시 볼 수 없어요!
        <br />
        정말 포기하시겠어요?
      </>
    );
    const messageAlertProps = {
      confirmButtonText: '네. 필요없어요',
      cancelButtonText: '돌아가기',
      onConfirmClick: handleClickConfirm,
      onCancelClick: handleClickCancle,
    };

    createAlert(message, messageAlertProps);

    const itemName = 'app_install_close';

    sendImpressionLog(
      {
        ...pageLogData,
        eventName: 'close_popup',
        itemKind: 'popup',
        itemName,
      },
      `popup_${itemName}`,
    );
  };

  useEffect(() => {
    const canDisplay = getLocalStorageItem(LocalStorageKey.APP_INSTALL_BANNER) !== 'true';

    if (canDisplay) {
      const itemName = 'app_install';

      sendImpressionLog(
        {
          ...pageLogData,
          eventName: 'upper_banner',
          itemKind: 'banner',
          itemName,
        },
        `banner_${itemName}`,
      );
    }

    setIsDisplay(canDisplay);
  }, [pageLogData]);

  const canDisplay = isMobile && isDisplay && !isWebView && !isMarketing;

  if (!canDisplay) {
    return null;
  }

  return (
    <Container isHideBanner={isPassing} bottomPosition={bottomPositionEnum[bottomPosition]}>
      <CloseButton onClick={handleOpenAlert}>
        <span>다음에 할래요</span>
        <Icon name="ic_close_9.14x9.14_gray_500" />
      </CloseButton>

      {isCouponContentType ? (
        <CouponBannerContent onClick={handleClickBanner} />
      ) : (
        <DefaultBannerContent
          productThumbnail={productThumbnail}
          productTitle={productTitle}
          onClick={handleClickBanner}
          mainText={mainText}
        />
      )}
    </Container>
  );
}

export default AppInstallBanner;

const Container = styled.div<{ isHideBanner: boolean; bottomPosition: number }>`
  transition: bottom 0.4s ease;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ isHideBanner, bottomPosition }) => {
    if (isHideBanner)
      return css`
        bottom: -200px;
      `;
    else {
      return css`
        bottom: calc(env(safe-area-inset-bottom) + ${bottomPosition}px);
        bottom: calc(constant(safe-area-inset-bottom) + ${bottomPosition}px);
      `;
    }
  }};
  width: 100%;
  padding: 0 8px;
  z-index: 10;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 8px 16px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--gray-500);
  margin-bottom: 6px;
  gap: 4.43px;
`;
