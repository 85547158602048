const location = {
  popularCity: '인기 도시',
  hotelPromotion: '자세히 보기',
  contents: {
    title: '마이 리얼 매거진',
    subtitle: '여행 전 필독! 꿀 정보 대방출',
  },
  more: '더 보기',
  city: {
    recommend: {
      title: '추천 상품',
      key: 'recommend_offers',
      event: { category: '도시', action: '상품_추천' },
    },
    promote: {
      title: '마이리얼트립 ONLY',
      key: 'promote_offers',
      event: { category: '도시', action: '상품_마리트Only' },
    },
    landmarks: {
      title: '관광 명소',
      key: 'landmarks',
      event: { category: '도시', action: '관광명소' },
    },
    experience_categories: {
      title: '투어&티켓 베스트셀러',
      key: 'best_offers',
      more: '투어&티켓',
      group: 'experience',
      event: { category: '도시', action: '상품_베스트셀러' },
    },
    hotels: {
      title: '인기 호텔',
      subtitle: '지금 호텔 예약하면 최대 12% 캐시백!',
      key: 'hotels',
      more: '호텔',
      event: { category: '도시', action: '상품_인기호텔' },
    },
    stays: {
      title: '인기 국내 숙소',
      key: 'stays',
      more: '국내 숙소',
      event: { category: '도시', action: '상품_인기국내숙소' },
    },
    lodging: {
      title: '인기 한인민박',
      key: 'lodging_offers',
      more: '한인민박',
      group: 'lodging',
      event: { category: '도시', action: '상품_인기민박' },
    },
    package: {
      title: '인기 패키지',
      key: 'package_offers',
      more: '패키지',
      group: 'hot_deal',
      event: { category: '도시', action: '상품_인기 패키지' },
    },
    themes: {
      key: 'theme_offers',
      more: true,
      event: { category: '도시', action: '상품_테마' },
    },
  },
  country: {
    popular: {
      title: '도시별 인기 투어&티켓',
      key: 'city_offers',
      more: '투어&티켓 더보기',
      group: 'experience',
      event: { category: '나라', action: '상품_인기투어티켓' },
    },
    lodging: {
      title: '인기 한인민박',
      key: 'lodging_offers',
      more: '한인민박',
      group: 'lodging',
      event: { category: '나라', action: '상품_인기민박' },
    },
    themes: {
      key: 'theme_offers',
      more: true,
      event: { category: '나라', action: '상품_테마' },
    },
  },
};

export default location;
