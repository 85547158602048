const language = {
  reservation: {
    bannerContent: 'Everything you can do locally, explore now!',
    exploreCity: 'Explore City',
    travel: 'Are you traveling to {{city}}?',
    schedule: 'Schedule',
    resNo: 'Reservation No',
    resDate: 'Reservation Date',
    resStatus: 'Reservation',
    resCharge: 'Charge',
  },
  profile: {
    oauth: {
      facebook: 'Connect with Facebook',
      naver: 'Connect with Naver',
      kakao: 'Connect with Kakao',
    },
  },
};

export default language;
