import React from 'react';
import PropTypes from 'prop-types';

import FlightAirplaneInfo from './FlightAirplaneInfo';
import FlightFreeBaggageInfo from './FlightFreeBaggageInfo';
import FlightPassengerInfo from './FlightPassengerInfo';
import FlightTimeInfo from './FlightTimeInfo';

import styles from './FlightTicketBody.module.scss';

function FlightTicketBody(props) {
  const { data, isMobile } = props;

  return (
    <div className={styles.flightTicketBody}>
      <div className={styles.airplaneAndTimeInfo}>
        <FlightAirplaneInfo data={data} isMobile={isMobile} />
        <FlightTimeInfo data={data} isMobile={isMobile} />
      </div>
      {isMobile ? null : (
        <div className={styles.passengerAndFreeBaggageInfo}>
          <FlightPassengerInfo data={data} />
          <FlightFreeBaggageInfo data={data} isMobile={isMobile} />
        </div>
      )}
    </div>
  );
}

FlightTicketBody.propTypes = {
  data: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default FlightTicketBody;
