/* eslint-disable camelcase */
import { RESPONSIVE_IMAGE } from './types';
import reivewGeneralCoupon_1x from './review_general_coupon.png';
import reivewGeneralCoupon_2x from './review_general_coupon@2x.png';
import reivewGeneralCoupon_3x from './review_general_coupon@3x.png';

export const reviewGeneralCoupon: RESPONSIVE_IMAGE = {
  src: reivewGeneralCoupon_1x,
  srcSet: `${reivewGeneralCoupon_1x}, ${reivewGeneralCoupon_2x} 2x, ${reivewGeneralCoupon_3x} 3x`,
};
