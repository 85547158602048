import { GlobalSearch } from 'mrt-types';

import styled from '@emotion/styled';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

interface Props {
  data: GlobalSearch.Link;
}

function VerticalSwiperItem({ data }: Props) {
  const { image, linkUrl, titles } = data;
  const { url } = image;

  return (
    <Anchor href={linkUrl}>
      <Icon src={url} alt="" />
      {titles.map(({ text, color }) => (
        <Title key={text} color={color}>
          {text}
        </Title>
      ))}
    </Anchor>
  );
}

export default VerticalSwiperItem;

const Anchor = styled.a`
  display: flex;
  flex-direction: column;
  min-width: 72px;
  height: 76px;
  border-radius: 8px;
  align-items: center;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
`;

const Title = styled.span<{ color: string }>`
  display: block;
  margin-top: 4px;
  color: ${({ color }) => color || globalVariables.gray_700}; // gray-700
  font-size: 14px;
  font-weight: 600;
`;
