import { Fragment } from 'react';

import PropTypes from 'prop-types';

function ParseNewLine(props) {
  const { text, className, split } = props;
  const textArray = text.split(split);

  if (textArray.length === 1) {
    return textArray;
  }

  return textArray.reduce((accu, str) => {
    if (!accu) {
      return str;
    }

    return (
      <Fragment key={str}>
        {accu}
        <br className={className} />
        {str}
      </Fragment>
    );
  }, '');
}

ParseNewLine.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  split: PropTypes.string,
};

ParseNewLine.defaultProps = {
  className: '',
  split: '\n',
};

export default ParseNewLine;
