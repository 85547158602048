import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { childrenType } from '../../../types';
import { cardThemeEnum, cardPurposeEnum } from '../../../enum';
import styles from './CardContainer.module.scss';

const CardContainer = forwardRef((props, ref) => {
  const forwardedRef = ref || null;
  const {
    className,
    link,
    theme,
    purpose,
    children,
    onClick,
    openNewTab,
    fixedWidth,
  } = props;
  const containerClass = classNames(
    styles.container,
    { [styles.fixedWidth]: fixedWidth },
    styles[theme],
    styles[purpose],
    { 'swiper-slide': purpose === cardPurposeEnum.swiper },
    className,
  );

  const newTab = theme === 'hotel' || openNewTab ? { target: '_blank' } : {};

  return (
    <a
      ref={forwardedRef}
      href={link}
      className={containerClass}
      onClick={onClick}
      {...newTab}
    >
      {children}
    </a>
  );
});

CardContainer.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(cardThemeEnum)),
  purpose: PropTypes.oneOf(Object.values(cardPurposeEnum)),
  children: childrenType,
  onClick: PropTypes.func,
  openNewTab: PropTypes.bool,
  fixedWidth: PropTypes.bool,
};

CardContainer.defaultProps = {
  link: null,
  theme: cardThemeEnum.offer,
  purpose: cardPurposeEnum.swiper,
  children: null,
  onClick: () => null,
  openNewTab: false,
  fixedWidth: true,
};

export default CardContainer;
