import classNames from 'classnames';
import PropTypes from 'prop-types';

import { cardTypeEnum } from '../../../enum';
import toCurrency from '../../../utils/toCurrency';
import LazyImageLoader from '../LazyImageLoader';
import CardCouponSticker from './CardCouponSticker';

import styles from './CardThumbnail.module.scss';
import CardThumbnailTagSticker from './CardThumbnailTagSticker';

function CardThumbnail(props) {
  const { type, image, width, height, coupon, tags } = props;

  const style = { width: `${width}px`, height: `${height}px` };
  const hasCoupon = coupon > 0;
  const couponLabel = `${toCurrency(coupon)}원 쿠폰`;
  const canRenderTagSticker = Array.isArray(tags) && tags.length > 0;
  const canRenderCouponSticker = !canRenderTagSticker && hasCoupon;

  return (
    <div className={classNames(styles.container, styles[type])} style={style}>
      <LazyImageLoader className={styles.image} imageUrl={image} />
      {canRenderTagSticker && <CardThumbnailTagSticker tags={tags} />}
      {canRenderCouponSticker && <CardCouponSticker label={couponLabel} />}
    </div>
  );
}

CardThumbnail.propTypes = {
  type: PropTypes.oneOf(Object.values(cardTypeEnum)),
  image: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  coupon: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.string),
};

CardThumbnail.defaultProps = {
  type: cardTypeEnum.vertical,
  image: null,
  width: null,
  height: null,
  coupon: 0,
  tags: [],
};

export default CardThumbnail;
