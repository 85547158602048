export default {
  caution:
    'Inquiries related to hotel (reservation. change. cancellation) are procceeded at the affiliate. MyRealTrip does not carry any responsibility for reserved accomodations.',
  contact: {
    title: 'Inquiries related to hotel',
    email: {
      label: 'E-mail',
      booking: {
        value: 'customer.service@booking.com',
      },
      expedia: {
        value: 'travel@support.expedia.co.kr',
      },
      agoda: {
        value: 'krservice@agoda.com',
      },
    },
    internal: {
      booking: {
        label: 'Domestic line (9AM-5PM)',
        value: '02-3483-3225',
      },
      expedia: {
        label: 'Domestic line (9AM-7PM)',
        value: '02-3480-0118',
      },
      agoda: {
        label: 'Domestic line (9AM-9:45PM)',
        value: '02-6022-2443',
      },
    },
    overseas: {
      label: 'International line (24 hours)',
      value: '+44-20-3684-7925',
    },
  },
};
