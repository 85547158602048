import { useEffect, useReducer } from 'react';
import debounce from 'lodash/debounce';

function windowSizeReducer(state, action) {
  if (action.type === 'resize') {
    return action.payload;
  }

  return state;
}

function useWindowSize() {
  const [size, dispatch] = useReducer(windowSizeReducer, {
    width: window.innerWidth || 0,
    height: window.innerHeight || 0,
  });

  useEffect(() => {
    function handleResize() {
      dispatch({
        type: 'resize',
        payload: { width: window.innerWidth, height: window.innerHeight },
      });
    }

    const lazilyHandleResize = debounce(handleResize, 100);

    window.addEventListener('resize', lazilyHandleResize);

    return function detachResizeHandler() {
      window.removeEventListener('resize', lazilyHandleResize);
    };
  }, [dispatch]);

  return size;
}

export default useWindowSize;
