import { PropsWithChildren } from 'react';

import {
  QueryClientProvider as ReactQueryQueryClientProvider,
  QueryClient,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  CODE_LIST_FOR_USING_SERVER_ERROR_MESSAGE,
  ERROR_EXCEPTION_NOT_EXPRESED_TOAST,
} from 'mrt-constants';
import { MrtAxiosException, openErrorToastByCode, openErrorToastByMessage } from 'mrt-error';

export function handleError(error: unknown) {
  if (!(error instanceof MrtAxiosException)) return;

  if (ERROR_EXCEPTION_NOT_EXPRESED_TOAST.includes(error.code)) return;

  const { code = '', message } = error;

  if (CODE_LIST_FOR_USING_SERVER_ERROR_MESSAGE.includes(code) && message) {
    openErrorToastByMessage({ message });

    return;
  }

  openErrorToastByCode({
    code,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15000,
      onError: handleError,
    },
    mutations: {
      onError: handleError,
    },
  },
});

function QueryClientProvider({ children }: PropsWithChildren) {
  return (
    <ReactQueryQueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools position="bottom-right" />
    </ReactQueryQueryClientProvider>
  );
}

export default QueryClientProvider;
