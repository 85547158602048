import { Children } from 'react';

import flatten from 'lodash/flatten';

import styled from '@emotion/styled';
import NotificationItem from '@layout/app/components/NotificationPopup/NotificationItem';
import useFetchNotification from '@layout/app/hooks/useFetchNotification';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import Spinner from '@myrealtrip/design-system/dist/components/Spinner';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

export default function NotificationItems() {
  const {
    isLoading,
    isError,
    data,
    isFetchingNextPage,
  } = useFetchNotification();
  const mergedNotifications = flatten(
    data?.map(({ notifications }) => notifications),
  );

  const isEmpty = mergedNotifications.length === 0;

  if (isLoading) {
    return (
      <LoadingContainer full>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  if (isEmpty || isError) {
    return (
      <EmptyContainer>
        <EmptyIcon name="ic_nav_bell_24x24_line_gray_600" />
        <EmptyTextContainer>
          <EmptyText>새로운 알림이 없습니다.</EmptyText>
          <EmptyText>마이리얼트립과 즐거운 여행을 계획해보세요!</EmptyText>
        </EmptyTextContainer>
      </EmptyContainer>
    );
  }

  return (
    <Container>
      {Children.toArray(
        mergedNotifications.map((notification) => (
          <NotificationItem item={notification} />
        )),
      )}
      {isFetchingNextPage && (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      )}
    </Container>
  );
}

const Container = styled.div``;

const EmptyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const EmptyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  text-align: center;
  gap: 5px 0;
`;
const EmptyText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${globalVariables.gray_400};
`;
const EmptyIcon = styled(Icon)`
  width: 34px;
  height: 34px;
`;

const LoadingContainer = styled.div<{ full?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ full }) => (full ? '100%;' : 'auto;')};
  padding: ${({ full }) => (full ? '0;' : '10px 0;')};
`;
const LoadingSpinner = styled(Spinner)``;
