const language = {
  reservation: {
    bannerContent: '현지에서 할 수 있는 모든 것, 지금 둘러보세요!',
    exploreCity: '도시 구경하기',
    travel: '{{city}} 여행 떠나시나요?',
    schedule: '일정',
    resNo: '예약번호',
    resDate: '예약일',
    resStatus: '예약',
    resCharge: '요금',
  },
  profile: {
    oauth: {
      facebook: '페이스북 연동',
      naver: '네이버 연동',
      kakao: '카카오 연동',
    },
    auth: {
      facebook: '페이스북으로 인증',
      kakao: '카카오로 인증',
      naver: '네이버로 인증',
      title: '프로필 편집',
      desc: '여행자님의 소중한 개인정보를 안전하게 보호하기 위해\n다시 한번 인증해주세요.',
      password: {
        label: '비밀번호',
        placeholder: '비밀번호를 입력해 주세요.',
        button: '비밀번호로 인증',
      },
    },
  },
};

export default language;
