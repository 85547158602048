import button from './buttons';
import banner from './banner';
import booking_assistant from './booking_assistant';
import reservation from './reservation';
import search from './search';
import footer from './footer';
import header from './header';
import hotel from './hotel';
import stay from './stay';
import offer from './offer';
import tooltip from './tooltip';
import common from './common';
import offerList from './offerList';
import category from './category';
import accommodation from './accommodation';
import main from './main';
import promotion from './promotion';
import policy from './policy';
import experience from './experience';
import country from './country';
import checklist from './checklist';
import signup from './signup';
import partner from './partner';
import hotdeal from './hotdeal';
import location from './location';
import friends from './friends';
import about from './about';
import career from './career';
import traveler from './traveler';
import signin from './signin';
import refund from './refund';
import contents from './contents';

export default {
  button,
  banner,
  booking_assistant,
  offer,
  reservation,
  search,
  footer,
  header,
  hotel,
  stay,
  tooltip,
  common,
  offerList,
  category,
  accommodation,
  main,
  promotion,
  policy,
  experience,
  country,
  checklist,
  signup,
  partner,
  hotdeal,
  location,
  friends,
  about,
  career,
  traveler,
  signin,
  refund,
  contents,
};
