import { apiClient } from '@layout/app/api/apiClient';

export type AccountResponse = {
  data: {
    number_of_coupons: number;
    point: number;
  };
  result: {
    status: number;
    message: string;
  };
};

export const fetchAccount = () => {
  return apiClient.get<AccountResponse>(`${process.env.HOME_URL}/traveler/account/gnb`);
};
