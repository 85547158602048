import PropTypes from 'prop-types';

import { hotelCardTypeEnum } from '../../../enum';
import { childrenType, swiperType } from '../../../types';
import Grid from '../Grid';
import CardSwiper from './CardSwiper';

function CardSwiperWithGrid(props) {
  const { keyName, type, swiperOptions, children } = props;

  return (
    <Grid isFullWidth>
      <CardSwiper type={type} keyName={keyName} swiperOptions={swiperOptions}>
        {children}
      </CardSwiper>
    </Grid>
  );
}

CardSwiperWithGrid.propTypes = {
  keyName: PropTypes.string,
  children: childrenType.isRequired,
  swiperOptions: swiperType,
  type: PropTypes.oneOf(Object.values(hotelCardTypeEnum)),
};

CardSwiperWithGrid.defaultProps = {
  keyName: null,
  swiperOptions: {},
  type: hotelCardTypeEnum.common,
};

export default CardSwiperWithGrid;
