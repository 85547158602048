import { useDetermineMobileContext } from 'mrt-components';
import media from 'mrt-utils/mediaQuery';

import styled from '@emotion/styled';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import Copyright from './Copyright';
import ISMS from './ISMS';
import LanguageSelector from './LanguageSelector';
import OutLinkContainer from './OutLinkContainer';
import PolicyList from './PolicyList';

export default function FooterBottomBox() {
  const isMobile = useDetermineMobileContext();

  return (
    <Container>
      <TopBox>
        <PolicyList />
        {isMobile && (
          <BottomBox>
            <Copyright />
            <ISMS />
          </BottomBox>
        )}
        <OutLinkContainer />
        <LanguageSelector />
      </TopBox>
      {!isMobile && (
        <BottomBox>
          <Copyright />
          <ISMS />
        </BottomBox>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  border-top: 1px solid ${globalVariables.gray_100};

  ${media.mobile} {
    padding-top: 0;
    border-top: 0;
  }
`;

const TopBox = styled.div`
  display: flex;

  ${media.mobile} {
    flex-direction: column;
  }
`;

const BottomBox = styled.div`
  display: flex;

  ${media.mobile} {
    flex-direction: column;

    margin-bottom: 24px;
  }
`;
