import { isPromise } from './isPromise';

/**
 * - `Promise`: 이미지 로딩
 * - `Error`: 이미지 로딩 중 에러
 * - `true`: 이미지 로딩 완료
 */
type CacheValue = Promise<string> | Error | true;

interface ResourceCache {
  [src: string]: CacheValue;
}

/**
 * React Concurrent 모드로 이미지 로드 및 중단할 수 있는 Resource 입니다.
 *
 * 이미지는 내부 리소스의 캐시 되지 않기 때문에 value 는 단순이 Boolean 값입니다.
 *
 */
class ImageResource {
  private cache: ResourceCache;

  constructor() {
    this.cache = {};
  }

  /**
   * 이미지 로드가 이미 진행중이거나 에러가 발생한 경우
   */
  private isImageLoadedError = (cachedValue: CacheValue): cachedValue is Error => {
    return isPromise(cachedValue) || cachedValue instanceof Error;
  };

  /**
   *
   * 캐시에서 source 별로 이미지를 읽습니다. 캐시된 경우 source가 반환됩니다.
   * 캐시되지 않은 경우 이미지를 로드하고 캐시합니다.
   * 이미지가 로드되지 않은 경우 Error 를 반환합니다.
   *
   * @returns {string | Error} 이미지 로드가 완료된 경우 이미지의 src를 반환합니다. 외에는 Error를 반환합니다.
   *
   * @param src 이미지 경로
   */
  public read = (src: string) => {
    const cachedValue = this.cache[src];

    if (this.isImageLoadedError(cachedValue)) {
      throw cachedValue;
    }

    /**
     * 캐시에 이미지가 있는 경우
     */
    if (cachedValue === true) {
      return src;
    }

    if (!cachedValue) {
      throw new Error('preload 되지 않은 이미지가 로드 되었습니다.');
    }

    this.preloadImage(src);

    throw this.cache[src] as Error;
  };

  /**
   * source 에서 이미지를 preload 합니다
   *
   * @param src the image source to load
   */
  public preloadImage = (src: string) => {
    const cachedValue = this.cache[src];

    /**
     * 이미지 로드가 이미 진행중이거나 에러가 발생한 경우
     */
    if (this.isImageLoadedError(cachedValue)) {
      throw cachedValue;
    }

    /**
     * 캐시에 이미지가 있는 경우
     */
    if (cachedValue === true) {
      return src;
    }

    const promise = new Promise<string>((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        this.cache[src] = true;
        resolve(src);
      };

      img.onerror = () => {
        this.cache[src] = new Error(`이미지 로드 중에 에러가 발생 했습니다. "${src}"`);
        reject();
      };

      img.src = src;
    });

    this.cache[src] = promise;

    return promise;
  };

  /**
   * 캐시에서 모든 이미지를 지웁니다.
   */
  public clear = () => {
    this.cache = {};
  };
}

const imageResource = new ImageResource();

export { imageResource };
