import moment from 'moment';
import qs from 'qs';

const hotelParams = qs.stringify({
  'place[latitude]': 33.4996213,
  'place[longitude]': 126.5311884,
  'place[country_code]': 'KR',
  'place[city_name]': '제주도',
  checkin: moment().add(1, 'months').format('YYYY-MM-DD'),
  checkout: moment().add(1, 'months').add(1, 'days').format('YYYY-MM-DD'),
  number_of_rooms: 1,
  number_of_adults: 2,
  number_of_children: 0,
});

const rentcarLink =
  'https://www.myrealtrip.com/rentacar/products?city[code]=JEJU&city[label]=%EC%A0%9C%EC%A3%BC';
const rentcarDeepLink = `mrt://rentacarweb/list?title=${encodeURIComponent('국내 렌터카')}&url=${encodeURIComponent(
  'https://www.myrealtrip.com/instants/rentacar/products?city[code]=JEJU&city[label]=제주',
)}`;

const hotelLink = `https://www.myrealtrip.com/accommodations/hotels?${hotelParams}`;
const lodgingLink =
  'https://www.myrealtrip.com/accommodations/lodgings?city=Jeju&country=Korea%2C%20Republic%20of';

const section = {
  rentcar: {
    title: '렌터카는 준비하셨나요?',
    desc: '마리트 단독 최대 40% 할인! 고급자차 보험 포함',
    linkText: '제주 렌터카 전체보기',
    linkPath: rentcarLink,
    linkAction: '클릭_렌터카섹션_전체보기버튼',
    deeplink: rentcarDeepLink,
    deeplinkAction: 'click_rentacar',
  },
  hotels: {
    title: '숙소는 예약하셨나요?',
    desc: '가격 비교해서 최저가 찾고 10% 추가 할인까지 받으세요!',
    linkText: '제주 호텔 전체보기',
    linkPath: `https://www.myrealtrip.com/accommodations/hotels?${hotelLink}`,
    linkAction: '클릭_호텔섹션_전체보기버튼',
    deeplink: `mrt://hotelweb?url=${encodeURIComponent(
      'https://www.myrealtrip.com/accommodations/hotels?',
    )}${hotelParams}${encodeURIComponent(
      '&utm_source=cross_popup&utm_medium=flight_deadend_popup&utm_campaign=flight_deadend_popup',
    )}`,
    deeplinkAction: 'click_hotel',
  },
  magazines: {
    title: '어디 갈지 아직 못 정하셨다구요?',
    desc: '마리트가 추천하는 제주 여행의 모든 것!',
  },
  lodgings: {
    title: '제주에서만 느낄 수 있는 감성 숙소',
    desc: '풀빌라, 독채, 게스트하우스까지! 원하는 숙소를 골라보세요.',
    linkText: '제주 숙소 전체보기',
    linkPath: lodgingLink,
    linkAction: '클릭_펜션게하섹션_전체보기버튼',
    deeplink: `mrt://lodgings?country=Korea%2C%20Republic%20of&city=Jeju`,
    deeplinkAction: 'click_pension',
  },
};

export default section;
