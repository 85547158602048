import { isoAlpha2CodeNames } from 'mrt-constants';

import { SelectFieldProps, SelectUnitOptions, SingleSelectField } from '../BaseFields';

const COUNTRIES_OPTIONS: SelectUnitOptions = [
  { value: '', content: '국가를 선택해주세요', isPlaceholder: true },
  ...Object.entries(isoAlpha2CodeNames)
    .sort((p, n) => (p[1] < n[1] ? -1 : 1))
    .map(([code, content]) => ({ value: code, content })),
];

interface CountrySelectFieldOptions extends Omit<SelectFieldProps, 'options'> {
  options?: SelectUnitOptions;
}

function CountrySelectField({
  label,
  options,
  defaultValue,
  ...props
}: CountrySelectFieldOptions): JSX.Element {
  return (
    <SingleSelectField
      {...props}
      options={options || COUNTRIES_OPTIONS}
      label={label ?? '국가'}
      defaultValue={defaultValue}
    />
  );
}

export default CountrySelectField;
