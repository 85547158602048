const copyrightDefault = `상호명 (주)마이리얼트립 | 대표 이동건 | 개인정보보호책임자 김형석 | 사업자등록번호 209-81-55339 <a target='_blank' href='https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2098155339'>사업자정보확인</a> | 통신판매업신고번호 2019-서울서초-0260 | 관광사업등록번호 : 제2019-3호<br/>주소 서울특별시 서초구 강남대로 311, 드림플러스 강남 18층 (서초동, 한화생명보험빌딩) | 이메일 help@myrealtrip.com | 마케팅 문의 marketing@myrealtrip.com<br>제휴 문의 partnership@myrealtrip.com<br><br>
자사는 서울특별시관광협회 공제영업보증보험에 가입되어 있습니다.<br class="show-on-tablet" /> 영업보증보험: 1.5억원 / 기획여행보증: 2억원<br/>`;

const footer = {
  about: '소개',
  'about-service': '서비스 소개',
  'about-company': '회사소개',
  'about-career': '채용',
  'about-announcement': '공고',
  'about-howToUse': '이용방법',
  'about-press': '언론 보도',
  partner: '파트너',
  'partner-registGuide': '파트너 홈',
  'partner-b2b': '마케팅/제휴 문의',
  'partner-realGuide': '리얼파트너',
  'partner-blog': '파트너 블로그',
  help: '지원',
  'help-customerCenter': '고객지원실 운영안내',
  'help-faq': '공지사항/FAQ',
  'help-policy': '이용 약관',
  'help-privacy': '개인정보 처리방침',
  'help-refund': '취소 및 환불 정책',
  'help-price': '최저가 보장제',
  'help-question-workingHour': `연중무휴 09:00-18:00\n(점심시간 12:00-13:00)`,
  'help-question-weekend': '주말/공휴일 포함, 한국시간 기준',
  'help-question-air-refund': '※ 항공권 환불 - 연중무휴 09:00-17:00 접수 가능',
  'help-question-air-change': '※ 항공권 변경 - 평일 09:00-17:00 접수 가능',
  'help-holiday': '연중무휴',
  'help-lunch': '점심',
  'help-chat': '1:1 채팅상담',
  'help-call': '유선상담 1670-8208',
  'help-call2': '1670-8208',
  copyright: `
    ${copyrightDefault}
    마이리얼트립은 통신판매중개자이며 통신판매의 당사자가 아닙니다. 따라서 상품·거래정보 및 거래에 대하여 책임을 지지않습니다.<br/>
    부득이한 사정에 의해 확정된 여행일정이 변경되는 경우 여행자의 사전 동의를 받습니다.
  `,
};

export default footer;
