import qs from 'qs';

import { MrtAxiosOptions, mrtAxiosV2 } from 'mrt-libs';
import { ThemeFiltersResponseData, SearchOfferRecommendResponseData } from 'mrt-types';
import { SearchOffersResponse } from 'mrt-types/globalSearch';

import { ResponseType } from '../types';

export const fetchSearchOffers = (word: string, options?: MrtAxiosOptions) => {
  const { isWebView } = options || {};

  return mrtAxiosV2({ isWebView }).get<SearchOffersResponse>(`/search/offers/v1/ac/${word}`);
};

export interface ThemeFiltersResponse extends ResponseType {
  readonly data: ThemeFiltersResponseData;
}

export const fetchThemeFilter = (cityKeyName: string) => {
  return mrtAxiosV2().get<ThemeFiltersResponse>(
    `/search/product-detail/v1/theme-filter/web?cityKeyName=${cityKeyName}`,
  );
};

export interface SearchOfferRecommendRequest {
  offerId: number;
  cityKeyName: string;
  extCategories: string;
  refUrl: string | null;
}
interface SearchOfferRecommendResponse extends ResponseType {
  readonly data: SearchOfferRecommendResponseData;
}

export const fetchSearchOfferRecommend = (
  param: SearchOfferRecommendRequest,
  options?: MrtAxiosOptions,
) => {
  const { isWebView } = options || {};
  const refUrl = param.refUrl ? decodeURIComponent(param.refUrl) : null;
  const stringfiedQuery = qs.stringify(
    { ...param, refUrl },
    { addQueryPrefix: true, skipNulls: true },
  );

  return mrtAxiosV2({ isWebView }).get<SearchOfferRecommendResponse>(
    `/search/product-detail/v1/reco/web${stringfiedQuery}`,
  );
};
