import React, { Component } from 'react';
import PropTypes from 'prop-types';

const withPopup = (Wrapper) => {
  return class Popup extends Component {
    constructor(props) {
      super(props);

      this.state = {
        show: false,
      };
    }
    componentDidMount = () => {
      document.addEventListener('click', this.clickHandler);
    }
  
    componentWillUnmount = () => {
      document.removeEventListener('click', this.clickHandler);
    }
  
    clickHandler = (e) => {
      const { onClose } = this.props;
      
      onClose(e);
    }
  
    render() {
      return (
        <Wrapper {...this.props} />
      );
    }
  };
};

export default withPopup;
