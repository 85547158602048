export enum OfferCategory {
  ACCOMMODATION = 'accommodation',
  ACTIVITY = 'activity',
  AIRTEL = 'airtel',
  ENTERTAINMENT = 'entertainment',
  GUIDE_TOUR = 'guide_tour',
  KOREAN_LODGING = 'korean_lodging',
  PACKAGE = 'package',
  PENSION = 'pension',
  RENTACAR = 'rentacar',
  RESTAURANT = 'restaurant',
  SNAP = 'snap',
  TICKET = 'ticket',
  TRANSPORT = 'transport',
  UTILITY = 'utility',
}

export enum OfferType {
  LODGINGS = 'Lodgings',
  ISTANBULTICKET = 'IstanbulTicket',
  LODGING = 'LODGING',
  DELIVERYTICKET = 'DeliveryTicket',
  HOTDEAL = 'HotDeal',
  TICKET = 'Ticket',
  PENSION = 'Pension',
  ETICKET = 'ETicket',
  TOUR = 'Tour',
}

export enum OfferTagNames {
  FREE_REFUND = '무료취소',
  PRICE_GUARANTEE = '최저가 보장제',
  PRICE_GUARANTEE_NEW = '단독특가', // 최저가 보장제 문구 변경 실험용, 지표확인 후 기존 PRICE_GUARANTEE 유지 or 변경 예정
  IGNORE_WAIT_CONFIRM = '즉시확정',
  MRT_ORIGIN = '마리트 최초',
}

export enum OfferTags {
  FREE_REFUND = 'free_refund',
  PRICE_GUARANTEE = 'price_guarantee',
  IGNORE_WAIT_CONFIRM = 'ignore_wait_confirm',
  MRT_ORIGIN = 'mrt_origin',
  INSTALLMENT_PAYMENT = 'installment_payment',
}

export const tagFlags2Names = ({
  isFreeRefund = false,
  isGuarantee = false,
  isPriceGuarantee = false,
  isIgnoreWaitConfirm = false,
}) => {
  const names: string[] = [];
  if (isFreeRefund) names.push(OfferTagNames.FREE_REFUND);
  if (isPriceGuarantee || isGuarantee) names.push(OfferTagNames.PRICE_GUARANTEE);
  if (isIgnoreWaitConfirm) names.push(OfferTagNames.IGNORE_WAIT_CONFIRM);

  return names;
};
