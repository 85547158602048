import { OrderFormResponseType } from 'mrt-apis';
import { MrtAxiosV3ErrorOptions, mrtAxiosV3 } from 'mrt-libs';
import { AfterFuncPayload } from 'mrt-types';

export const createLodgingPreOrderForm = (options: MrtAxiosV3ErrorOptions) => () => {
  const { payload } = options.api || {};

  return mrtAxiosV3(options).post<OrderFormResponseType>(
    '/order/v2/traveler/orders/order-form',
    payload,
  );
};

export const afterCreateLodgingPreOrderForm = (
  afterFuncPayload: AfterFuncPayload<OrderFormResponseType>,
) => {
  const { data, isWebView } = afterFuncPayload;

  if (data?.data?.orderFormId) {
    const formId = data.data.orderFormId ?? '';

    if (isWebView) {
      window.location.href = `${process.env.HOME_URL}/instants/order/${formId}`;

      return;
    }
    window.location.href = `${process.env.HOME_URL}/order/${formId}`;
  }
};
