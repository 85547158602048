import { Icon } from '@myrealtrip/design-system';

import styled from '@emotion/styled';
import { HeaderThemeType } from '@layout/constants/header';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';

export default function LocalNavMoreButton() {
  const { theme } = useLayoutHeaderContext();
  const iconName = theme === 'white' ? 'ic_arrow_down_md_gray_700' : 'ic_arrow_down_md_white';

  return (
    <Container type="button" colorTheme={theme}>
      <ButtonText>더보기</ButtonText>
      <ArrowIcon name={iconName} />
    </Container>
  );
}

const Container = styled.button<{ colorTheme: HeaderThemeType }>`
  /* reset button style */
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  width: 96px;
  height: 50px;
  margin-left: 14px;
  color: ${({ colorTheme }) => `var(${colorTheme === 'white' ? '--gray-700' : 'white'})`};
`;

const ButtonText = styled.span`
  margin-right: 2px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.4px;
`;

const ArrowIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`;
