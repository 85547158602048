import React from 'react';
import { oneOf, number } from 'prop-types';
import classNames from 'classnames';
import range from 'lodash-es/range';

import Grid from './Grid';
import SkeletonLoader from './SkeletonLoader';
import styles from './OfferVerticalCardLoader.module.scss';

function OfferVerticalCardLoader(props) {
  const { type, count } = props;
  const cards = range(count || (type === 'swiper' ? 4 : 8));
  const containerStyles = classNames(styles.container, styles[type]);

  return (
    <Grid customClass={containerStyles} isFullWidth={type === 'swiper'}>
      {cards.map(index => (
        <div key={index} className={styles.card}>
          <SkeletonLoader className={styles.thumbnail} />
          <div className={styles.content}>
            <SkeletonLoader className={styles.category} />
            <SkeletonLoader className={styles.title} />
            <SkeletonLoader className={styles.guide} />
            <div className={styles.bottom}>
              <SkeletonLoader className={styles.review} />
              <SkeletonLoader className={styles.price} />
            </div>
          </div>
        </div>
      ))}
    </Grid>
  );
}

OfferVerticalCardLoader.propTypes = {
  count: number,
  type: oneOf(['swiper', 'list']),
};

OfferVerticalCardLoader.defaultProps = {
  count: null,
  type: 'swiper',
};

export default OfferVerticalCardLoader;
