import getInstance from './getInstance';

export interface Params {
  price: number;
  currency: string;
  quantity: number;
  [key: string]: unknown;
}

function logBrazePurchase({ price, currency, quantity, ...rest }: Params) {
  try {
    const instance = getInstance();

    if (typeof instance?.logPurchase === 'function') {
      const isSuceess = instance.logPurchase('all', price, currency, quantity, rest);

      if (!isSuceess) {
        throw new Error(
          `failed braze logCustomEvent :>> ${JSON.stringify({
            price,
            currency,
            quantity,
            ...rest,
          })}`,
        );
      }
    }
  } catch (e) {
    console.error('error', e);
  }
}

export default logBrazePurchase;
