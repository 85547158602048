import React from 'react';
import { useMainHOC } from 'app/context/main';

const Link = ({ children, isRoma, currentRoma, ...props }) => {
  if (isRoma !== currentRoma) {
    return (
      <a {...props} data-turbolinks="false">
        {children}
      </a>
    );
  }
  return <a {...props}>{children}</a>;
};

export default useMainHOC(({ state }) => ({
  currentRoma: state.isRoma,
}))(Link);
