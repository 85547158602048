const language = {
  emailSignin: 'Continue with Email',
  title: 'Welcome Back!',
  subtitle: 'Everything of trip, MyRealTrip',
  kakaoOAuth: 'Sign in with Kakao',
  kakaoOAuthForPartner: 'Sign in with Kakao',
  appleOAuthForPartner: 'Facebook',
  facebookOAuth: 'Facebook',
  naverOAuth: 'Naver',
  emailLogin: 'Email',
  footerDescription: 'Are you not a member at MyRealTrip yet?',
  footerLinktext: 'Sign up',
  keep: 'Keep me logged in',
  findPassword: 'Forgot your password?',
  placeholder: {
    password: 'Enter password',
  },
};

export default language;
