import { useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { hotelCardTypeEnum } from '../../../enum';
import { childrenType, swiperType } from '../../../types';
import Carousel from '../Carousel';

import styles from './CardSwiper.module.scss';

function CardSwiper(props) {
  const { swiperOptions, type, keyName, children } = props;
  const swiper = useRef();

  const isHotelMeta = type === hotelCardTypeEnum.meta;
  const nextButtonStyle = isHotelMeta ? null : { right: '-10px' };
  const prevButtonStyle = isHotelMeta ? null : { left: '-10px' };

  const options = {
    setWrapperSize: true,
    spaceBetween: isHotelMeta ? 20 : 0,
    slidesPerView: 4,
    slidesPerGroup: 4,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    watchOverflow: true,
    threshold: 10,
    breakpoints: {
      767: {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        spaceBetween: isHotelMeta ? 8 : 0,
        freeMode: true,
        slidesOffsetBefore: 16,
        slidesOffsetAfter: 16,
        grabCursor: true,
      },
    },
    ...swiperOptions,
  };

  return (
    <div className={classNames(styles.container, styles[type])}>
      <Carousel
        ref={swiper}
        keyName={keyName}
        options={options}
        prevButtonStyle={prevButtonStyle}
        nextButtonStyle={nextButtonStyle}
      >
        {children}
      </Carousel>
    </div>
  );
}

CardSwiper.propTypes = {
  type: PropTypes.oneOf(Object.values(hotelCardTypeEnum)),
  keyName: PropTypes.string,
  children: childrenType.isRequired,
  swiperOptions: swiperType,
};

CardSwiper.defaultProps = {
  type: hotelCardTypeEnum.common,
  keyName: null,
  swiperOptions: {},
};

export default CardSwiper;
