import { forwardRef, useEffect, useRef } from 'react';
import {} from 'navermaps';

import { Dialog, useWindowSize } from '@myrealtrip/design-system';

import icon from '../../../../assets/images/icon-location.svg';

import * as styles from './ProductNaverMap.style';

interface PropsType {
  center: naver.maps.LatLngObjectLiteral;
  size?: naver.maps.SizeObjectLiteral;
  zoom?: number;
  modalKey?: string;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  className?: string;
}

const ProductNaverMap = forwardRef<HTMLDivElement, PropsType>(
  (
    {
      center: { lat, lng },
      size: propSize,
      zoom: propZoom,
      modalKey = '',
      isOpen,
      setOpen,
      className = '',
    },
    ref,
  ) => {
    const mapRef = useRef<HTMLDivElement>(null);
    const [width, height] = useWindowSize();

    useEffect(() => {
      if (mapRef.current) {
        (() => {
          const zoom = propZoom ?? 18;
          const size = propSize ?? { width, height: height - 48 };

          const map = new naver.maps.Map(mapRef.current, {
            center: new naver.maps.LatLng(lat, lng),
            zoom,
          });

          map.setSize(size);

          new naver.maps.Marker({
            position: new naver.maps.LatLng(lat, lng),
            map,
            icon,
          });
        })();
      }
    }, [height, lat, lng, propSize, propZoom, width]);

    return (
      <Dialog.Mobile
        modalKey={modalKey}
        css={styles.container}
        className={className}
        ref={ref}
        isOpen={isOpen}
        toggle={setOpen}
        titleText="지도"
      >
        <div css={styles.map} ref={mapRef} id="mrt-naver-map" />
      </Dialog.Mobile>
    );
  },
);

export default ProductNaverMap;
