import { getUserInfo, UserInfo } from 'mrt-utils';

import { UserLogDataType } from '@myrealtrip/frontend-weblog';
import { helper } from '@myrealtrip/frontend-weblog';

const { fetchUserData } = helper;

export async function getUserData(isWebView: boolean): Promise<UserInfo | UserLogDataType | null> {
  const data = isWebView ? getUserInfo() : await fetchUserData();

  if (!data) return null;

  return data;
}
