export const Ages = [
  { key: '10대', value: '10' },
  { key: '20대', value: '20' },
  { key: '30대', value: '30' },
  { key: '40대', value: '40' },
  { key: '50대', value: '50' },
  { key: '60대 이상', value: '60' },
];

/** 여행자 정보 저장 허용 갯수 */
export const LIMIT_SAVE_TRAVELER_INFO = 10;
/** 여행자 정보 저장 허용 갯수 - agency 계정은 제한이 100개 */
export const LIMIT_SAVE_TRAVELER_INFO_FOR_AGENCY = 100;

export const Mode = {
  default: 'default',
  new: 'new',
  edit: 'edit',
} as const;

export const RequireField = {
  korName: 'korName', // 한글 이름(일반 회원 가입) or 닉네임(SNS 간편 로그인)
} as const;

export const OptionalFields = {
  engFirstName: 'engFirstName', // 영문 이름
  engLastName: 'engLastName', // 영문 성
  email: 'email', // 이메일
  phoneIcc: 'phoneIcc', // 휴대전화icc ('+82')
  phoneNumber: 'phoneNumber', // 휴대전화번호 ('01012345678')
  birthDate: 'birthDate', // 생년월일 ('1990-01-01')
  gender: 'gender', // 성별
  passportNumber: 'passportNumber', // 여권번호 ('M12345678' or 'M123A4567')
  passportExpireDate: 'passportExpireDate', // 여권만료일 ('2050-01-01')
  passportNationality: 'passportNationality', // 여권발행국 ('KR', 'JP')
  drivingLicenseType: 'drivingLicenseType', // 운전면허 종류 ('1종 보통' | '1종 대형' | '2종 보통' | '2종 소형')
};

export const EnterLaterFields = {
  korNameEnterLater: 'korNameEnterLater', // 한글 이름 EnterLater
  engFirstNameEnterLater: 'engFirstNameEnterLater', // 영문 이름 EnterLater
  engLastNameEnterLater: 'engLastNameEnterLater', // 영문 성 EnterLater
  emailEnterLater: 'emailEnterLater', // 이메일 EnterLater
  phoneIccEnterLater: 'phoneIccEnterLater', // 휴대전화icc ('+82') EnterLater
  phoneNumberEnterLater: 'phoneNumberEnterLater', // 휴대전화번호 ('01012345678') EnterLater
  birthDateEnterLater: 'birthDateEnterLater', // 생년월일 ('1990-01-01') EnterLater
  genderEnterLater: 'genderEnterLater', // 성별 EnterLater
  passportNumberEnterLater: 'passportNumberEnterLater', // 여권번호 ('M12345678' or 'M123A4567') EnterLater
  passportExpireDateEnterLater: 'passportExpireDateEnterLater', // 여권만료일 ('2050-01-01') EnterLater
  passportNationalityEnterLater: 'passportNationalityEnterLater', // 여권발행국 ('KR', 'JP') EnterLater
  drivingLicenseTypeEnterLater: 'drivingLicenseTypeEnterLater', // 운전면허 종류 ('1종 보통' | '1종 대형' | '2종 보통' | '2종 소형') EnterLater
};

export const AllFiledWithoutEnterLater = { ...RequireField, ...OptionalFields };
export const AllFields = { ...AllFiledWithoutEnterLater, ...EnterLaterFields };

export const DEFAULT_TRAVELER_UI_INFO = {
  id: '',
  korName: '',
  engFirstName: '',
  engLastName: '',
  email: '',
  phoneIcc: '',
  phoneNumber: '',
  birthDate: '',
  gender: '',
  passportNumber: '',
  passportExpireDate: '',
  passportNationality: '',
  drivingLicenseType: '',
  title: '',
  travelerId: '',
  travelerOrder: '',
  representative: false,
  mode: Mode.default,
  invalid: {
    korName: true,
    engFirstName: true,
    engLastName: true,
    email: true,
    phoneIcc: true,
    phoneNumber: true,
    birthDate: true,
    gender: true,
    passportNumber: true,
    passportExpireDate: true,
    passportNationality: true,
    drivingLicenseType: true,
  },
  save: true,
  enterLaterChecked: false,
  allEnterLater: false,
  enableValidation: false,
  displayName: '새 여행자',
  prevData: {
    korName: false,
    engFirstName: false,
    engLastName: false,
    email: false,
    phoneIcc: false,
    phoneNumber: false,
    birthDate: false,
    gender: false,
    passportNumber: false,
    passportExpireDate: false,
    passportNationality: false,
    drivingLicenseType: false,
  },
};

// 여행자 정보 나중입력 체크박스 선택 시, 아래 값으로 임시 값을 채운 상태로 결제
export const TEMP_TRAVELER_UI_INFO = {
  korName: '여행자',
  engFirstName: 'myrealtrip',
  engLastName: 'myrealtrip',
  email: 'myrealtrip@myrealtrip.com',
  phoneIcc: '+82',
  phoneNumber: '01000000000',
  birthDate: '2023-01-01',
  gender: '-',
  passportNumber: 'M00000000',
  passportExpireDate: '2023-01-01',
  passportNationality: 'KR',
  drivingLicenseType: 'TYPE_2_SMALL',
};
