import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';

import styled from '@emotion/styled';
import { resetButton } from '@layout/styles/reset';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';
import { helper } from '@myrealtrip/frontend-weblog';

const { sendClickLog } = helper;

type Props = {
  label: string;
  desc?: string;
  iconName?: string;
  link?: string;
  onClick?: () => void;
};

export default function ProfilePopupLink({ label, desc, iconName, link, onClick }: Props) {
  const handleClick = () => {
    if (link) {
      sendClickLog({
        eventName: 'topmenu',
        screenName: WeblogScreenName.COMMON,
        pageCategory: WeblogPageCategory.COMMON,
        itemKind: 'menu',
        itemName: label,
        targetUrl: link,
      });
    }

    onClick?.();
  };

  return (
    <Container href={link} onClick={handleClick}>
      {iconName && <LinkIcon name={iconName} />}
      <LinkLabel>{label}</LinkLabel>
      {desc && <LinkDesc>{desc}</LinkDesc>}
    </Container>
  );
}

const Container = styled.a`
  ${resetButton};

  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  font-size: 15px;
  font-weight: 500;

  &:hover {
    background-color: ${globalVariables.gray_60};
  }
`;

const LinkLabel = styled.span`
  color: ${globalVariables.gray_700};
`;

const LinkIcon = styled(Icon)`
  margin-right: 16px;
`;

const LinkDesc = styled.span`
  margin-left: auto;
  color: ${globalVariables.blue_500};
`;
