import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Utils } from 'app/common';

function withResize(WrappedComponent) {
  const screenSize = {
    desktop: 1200,
    labtop: 992,
    tablet: 768,
    mobile: 320,
  };

  class WithResize extends Component {
    constructor(props) {
      super(props);

      this.state = this.getScreenSize();
      this.resize = debounce(this.handleWindowResize, 100);
    }

    componentDidMount() {
      this.handleWindowResize();
      window.addEventListener('resize', this.resize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.resize);
    }

    isDesktop = (width) => screenSize.desktop <= width;

    isLaptop = (width) =>
      width < screenSize.desktop && width >= screenSize.labtop;

    isTablet = (width) =>
      width < screenSize.labtop && width >= screenSize.tablet;

    // 디바이스 대응을 768미만과 이상으로만 구분짓기로 했으므로
    // 신규작업 같은 경우 isMobile로만 대응
    isMobile = (width) => width < screenSize.tablet;

    getScreenSize = () => {
      const width = window.innerWidth;

      return {
        screenWidth: width,
        isDesktop: this.isDesktop(width),
        isLaptop: this.isLaptop(width),
        isTablet: this.isTablet(width),
        isMobile: this.isMobile(width),
      };
    };

    handleWindowResize = () => {
      this.setState(this.getScreenSize());
    };

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  }

  WithResize.displayName = `WithResize(${Utils.getDisplayName(
    WrappedComponent,
  )})`;

  return WithResize;
}

export default withResize;
