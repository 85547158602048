import { getMobileAppVersion } from './getMobileAppVersion';

export default function detectWebViewByUA(userAgent: string) {
  const UAString = userAgent || navigator.userAgent;

  const appVersionInfo = getMobileAppVersion(UAString);
  const hasVersionInfo =
    Array.isArray(appVersionInfo) && appVersionInfo.length > 0;

  return hasVersionInfo;
}
