import ExperienceImages from '../../images/category/experience';
import AirtelImages from '../../images/category/airtel';
import * as TotalImages from '../../images/category/total';
import i18n from '../i18n';

const experiences = ['guide_tour', 'ticket', 'activity', 'snap', 'restaurant', 'entertainment', 'utility'];
const airtels = ['package', 'airtel', 'transport', 'rentacar', 'accommodation'];
const categories = {
  total: {
    key: 'total',
    icon: TotalImages.totalColor,
    iconBlue: TotalImages.totalBlue,
    name: i18n.t('category:total'),
  },
  experience: experiences.map(key => ({
    key,
    icon: ExperienceImages[key],
    iconBlue: ExperienceImages[`${key}Blue`],
    name: i18n.t(`category:experience.${key}`),
  })),
  hot_deal: airtels.map(key => ({
    key,
    icon: AirtelImages[key === 'package' ? `${key}s` : key],
    iconBlue: AirtelImages[`${key === 'package' ? `${key}s` : key}Blue`],
    name: i18n.t(`category:airtel.${key}`),
  })),
};

export default categories;
