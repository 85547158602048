import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import addLazyImageObserver from '../../utils/addLazyImageObserver';
import styles from './LazyImageLoader.module.scss';

export const lazyIndicator = 'MRT-LazyImageLoader__indicator';

function LazyImageLoader(props) {
  const { imageUrl, title, className } = props;
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const image = useRef();

  useEffect(() => {
    if (image.current) {
      addLazyImageObserver(image.current);
    }
  }, []);

  function handleImageLoad() {
    setIsImageLoaded(true);
  }

  const imageClasses = classNames(
    styles.image,
    lazyIndicator,
    {
      [styles.visible]: isImageLoaded,
    },
    className,
  );
  const placeholderClasses = classNames(styles.placeholder, className, {
    [styles.visible]: !isImageLoaded,
  });

  return (
    <div className={styles.container}>
      {imageUrl && (
        <Fragment>
          <img
            ref={image}
            alt={title}
            className={imageClasses}
            data-src={imageUrl}
            onLoad={handleImageLoad}
          />
        </Fragment>
      )}
      <div className={placeholderClasses} />
    </div>
  );
}

LazyImageLoader.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};

LazyImageLoader.defaultProps = {
  imageUrl: '',
  title: '',
  className: '',
};

export default LazyImageLoader;
