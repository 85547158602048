import { useEffect } from 'react';

import { IconLoading } from '@myrealtrip/design-system';

import * as styles from './FallbackLoading.style';

interface PropsType {
  lockScreen?: boolean;
}

export default function FallbackLoading({ lockScreen = false }: PropsType) {
  useEffect(() => {
    if (lockScreen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (lockScreen) {
        document.body.style.overflow = 'auto';
      }
    };
  }, [lockScreen]);

  return <IconLoading isFullScreen css={styles.container} />;
}
