import { useEffect, useState } from 'react';

import { useDetermineMobileContext } from 'mrt-components';
import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import CookieManager, { CookieKey } from 'mrt-utils/cookieManager';
import media from 'mrt-utils/mediaQuery';

import styled from '@emotion/styled';
import { LangOption, LANG_OPTIONS, DEFAULT_VALUE } from '@layout/constants/languageSelect';
import { helper } from '@myrealtrip/frontend-weblog';

import DesktopSelect from './DesktopSelect';
import MobileSelect from './MobileSelect';

const { sendClickLog } = helper;

export default function LanguageSelector() {
  const isMobile = useDetermineMobileContext();
  const [selectedLang, setSelectedLang] = useState<LangOption>(DEFAULT_VALUE);

  const handleLangChange = (draftValue: LangOption) => {
    const [, domain] = window.location.hostname.split('.');

    const nextCookieValue = draftValue.value;
    CookieManager.set(CookieKey.MRT_LNG, nextCookieValue, {
      domain: `.${domain}.com`,
    });

    sendClickLog({
      eventName: 'footer',
      screenName: WeblogScreenName.COMMON,
      pageCategory: WeblogPageCategory.COMMON,
      menuTitle: '언어선택',
      selectedLanguage: nextCookieValue,
    });

    setSelectedLang(draftValue);
    window.location.reload();
  };

  useEffect(() => {
    const cookieValue = CookieManager.get(CookieKey.MRT_LNG) ?? 'ko';
    const nextValue = LANG_OPTIONS.find((option) => option.value === cookieValue) ?? DEFAULT_VALUE;

    setSelectedLang(nextValue);
  }, []);

  return (
    <Container>
      {isMobile && <MobileSelect onChange={handleLangChange} currentLang={selectedLang} />}
      {!isMobile && <DesktopSelect onChange={handleLangChange} currentLang={selectedLang} />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: 28px;

  ${media.mobile} {
    margin-left: 0;
    order: 1;
  }
`;
