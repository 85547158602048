export const LAYOUT = {
  header: 'Header',
  pageContent: 'PageContent',
};

export const COLOR = {
  white: 'White',
  transparent: 'Transparent',
  blue: 'Blue',
};

type InputLayout = typeof LAYOUT[keyof typeof LAYOUT];

type InputColor = typeof COLOR[keyof typeof COLOR];

export interface Variation {
  layout: InputLayout;
  color: InputColor;
}
