const button = {
  appDownload: 'Download App',
  apply: 'Apply',
  init: 'Initialize',
  selectComplete: 'Selected',
  applyFilter: 'Apply Filter',
  initFilter: 'Initialize Filter',
};

export default button;
