import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { helper } from '@myrealtrip/frontend-weblog';
import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';

import { HeaderThemeType, HeaderTheme } from '@layout/constants/header';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';
import { resetButton } from '@layout/styles/reset';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

const { sendClickLog } = helper;

const baseLogData = {
  screenName: WeblogScreenName.COMMON,
  pageCategory: WeblogPageCategory.COMMON,
};

type Props = {
  className?: string;
  label: string;
  pathname: string | (() => string);
};

export default function GlobalNavRoundButton({ className, label, pathname }: Props) {
  const handleClick = () => {
    let targetUrl = '';
    if (typeof pathname === 'function') {
      targetUrl = pathname();
    } else {
      targetUrl = pathname.startsWith('http') ? pathname : `${process.env.HOME_URL}${pathname}`;
    }

    sendClickLog({
      ...baseLogData,
      eventName: 'topmenu',
      itemKind: 'menu',
      itemName: label,
      targetUrl,
    });

    window.location.href = targetUrl;
  };

  const { theme } = useLayoutHeaderContext();

  return (
    <Container className={className}>
      <Button type="button" colorTheme={theme} onClick={handleClick}>
        {label}
      </Button>
    </Container>
  );
}

const BUTTON_THEME_STYLE = {
  [HeaderTheme.blue]: css`
    color: ${globalVariables.gray_700};
    background-color: ${globalVariables.gray_50};

    &:hover {
      background-color: ${globalVariables.gray_100};
    }
  `,
  [HeaderTheme.white]: css`
    color: ${globalVariables.white};
    background-color: ${globalVariables.blue_500};

    &:hover {
      background-color: ${globalVariables.blue_400};
    }
  `,
  [HeaderTheme.transparent]: css`
    color: ${globalVariables.gray_700};
    background-color: ${globalVariables.gray_50};

    &:hover {
      background-color: ${globalVariables.gray_100};
    }
  `,
};

const Container = styled.div`
  position: relative;
  height: 40px;
`;

const Button = styled.button<{ colorTheme: HeaderThemeType }>`
  ${resetButton};

  transition: background-color 0.15s ease;
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  padding: 0 24px;
  border-radius: 12px;

  ${({ colorTheme }) => BUTTON_THEME_STYLE[colorTheme]}
`;
