import getInstance from './getInstance';
import { CustomEventTitle } from './types';

export interface Params {
  offer_id?: string;
  /** @deprecated Legacy OfferId Field */
  offerId?: string;
  [key: string]: unknown;
}

function logBrazeEvent(title: CustomEventTitle, params: Params) {
  try {
    const instance = getInstance();

    if (typeof instance?.logCustomEvent === 'function') {
      const isSuccess = instance.logCustomEvent<Params>(title, params);

      if (!isSuccess) {
        throw new Error(
          `failed braze logCustomEvent :>> ${JSON.stringify({
            title,
            params,
          })}`,
        );
      }
    }
  } catch (e) {
    console.error('error', e);
  }
}

export default logBrazeEvent;
