const DEFAULT_VALUE = '0';

function attemptInsertComma(input: string | number) {
  return `${input}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const decimalPointMap: Record<
  string,
  (target: string | number | null | undefined) => number
> = {
  roundDown: (target) => Math.floor(Number(target)),
  roundUp: (target) => Math.ceil(Number(target)),
  round: (target) => Math.round(Number(target)),
};

export default function toCurrency(
  target: string | number | null | undefined,
  decimalPoint?: 'roundDown' | 'roundUp' | 'round',
): string {
  if (!target) {
    return DEFAULT_VALUE;
  }

  if (!Number(target)) {
    return `${target}`;
  }

  const handleDecimalPoint = decimalPointMap[decimalPoint ?? 'roundDown'];

  return attemptInsertComma(handleDecimalPoint(target));
}

export function toCurrencyStrict(
  target: string | number | null | undefined,
  decimalPoint?: 'roundDown' | 'roundUp' | 'round',
): string {
  if (!target) {
    return DEFAULT_VALUE;
  }

  if (!Number(target)) {
    return DEFAULT_VALUE;
  }

  const handleDecimalPoint = decimalPointMap[decimalPoint ?? 'roundDown'];

  return attemptInsertComma(handleDecimalPoint(target));
}
