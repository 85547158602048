import { css } from '@emotion/react';

export const resetButton = css`
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
`;
