export default {
  modal_menus: {
    beforeSignIn: [
      {
        key: 'header:howToUse',
        link: 'howToUse',
        gtm: 'gtm-lnb-howtouse',
      },
      {
        key: 'header:registerPartner',
        link: 'registGuide',
        gtm: 'gtm-gnb-becomeguide-before-login',
      },
    ],
    afterSignIn: [
      {
        key: 'header:message',
        link: 'message',
        gtm: 'gtm-gnb-message',
      },
      {
        key: 'header:wishlist',
        link: 'wishlist',
        gtm: 'gtm-gnb-wishlist',
      },
      {
        key: 'header:myTrip',
        link: 'myTrip',
        gtm: 'gtm-gnb-traveler',
      },
      {
        key: 'header:invitationFriends',
        link: 'invitationFriends',
        gtm: 'gtm-gnb-invite-friend',
      },
    ],
  },
  common_menus: {
    beforeSignIn: [
      {
        key: 'search',
        link: 'search',
        type: 'icon',
        image: 'search',
        width: '22px',
        height: '22px',
        gtm: 'gtm-gnb-search',
      },
      {
        key: 'header:registerPartner',
        link: 'registGuide',
        type: 'text',
        gtm: 'gtm-gnb-becomeguide-before-login',
      },
      {
        key: 'header:signIn',
        link: 'signIn',
        type: 'text',
        gtm: 'gtm-gnb-signin',
      },
      {
        key: 'header:signUp',
        link: 'signUp',
        type: 'button',
        gtm: 'gtm-gnb-signup',
      },
    ],
    afterSignIn: [
      {
        key: 'search',
        link: 'search',
        type: 'icon',
        image: 'search',
        width: '22px',
        height: '22px',
        gtm: 'gtm-gnb-search',
      },
      {
        key: 'message',
        link: 'message',
        type: 'icon',
        image: 'message',
        width: '23px',
        height: '18px',
        gtm: 'gtm-gnb-messenger',
      },
    ],
  },
  profile_menus: [
    {
      key: 'section-1',
      items: [
        {
          key: 'wishlist',
          image: 'wishlist',
          label: 'header:wishlist',
          link: 'wishlist',
          gtm: 'gtm-gnb-wishlist',
        },
        {
          key: 'myTrip',
          image: 'reservation',
          label: 'header:myTrip',
          link: 'myTrip',
          gtm: 'gtm-gnb-traveler',
        },
      ],
    },
    {
      key: 'section-2',
      items: [
        {
          key: 'invitationFriends',
          image: 'giftBox',
          label: 'header:invitationFriends',
          link: 'invitationFriends',
          gtm: 'gtm-gnb-invite-friend',
        },
      ],
    },
    {
      key: 'section-4',
      items: [
        {
          key: 'signOut',
          label: 'header:signOut',
          link: 'signOut',
          gtm: 'gtm-gnb-becomeguide-after-login',
        },
      ],
    },
  ],
};
