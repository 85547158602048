import { useCallback, useEffect, useRef } from 'react';

import { helper } from '@myrealtrip/frontend-weblog';

import useIntersect from './useIntersect';

const { sendImpressionLog } = helper;

interface OptionType {
  threshold?: number;
  delay?: number;
  callback?: (log: Record<string, unknown>, eventKey: string) => void;
}

const DEFAULT_THRESHOLD = 0.5;
const DEFAULT_DELAY = 200;

const defaultOptions = { threshold: DEFAULT_THRESHOLD, delay: DEFAULT_DELAY };

export default function useImpressionLog(
  log: Record<string, unknown>,
  eventKey: string,
  options?: Partial<OptionType>,
) {
  const timer: React.MutableRefObject<any> | null = useRef(null);
  const { threshold, delay, callback } = { ...defaultOptions, ...options };
  const intersectionOption: Partial<IntersectionObserverInit> = { threshold };
  const { isIntersecting, subscribe, unsubscribe } = useIntersect(intersectionOption);

  const handleClearTimeout = useCallback(() => {
    clearTimeout(timer.current);
    timer.current = null;
  }, []);
  const handleImpression = useCallback(() => {
    timer.current = setTimeout(() => {
      if (callback) {
        callback(log, eventKey);
      } else {
        sendImpressionLog(log, eventKey);
      }

      unsubscribe();
      handleClearTimeout();
    }, delay);
  }, [callback, delay, eventKey, handleClearTimeout, log, unsubscribe]);

  useEffect(() => {
    if (isIntersecting) {
      handleImpression();
    } else {
      handleClearTimeout();
    }

    return handleClearTimeout;
  }, [handleClearTimeout, handleImpression, isIntersecting, log]);

  return { subscribe };
}
