// TODO(dotenv): 기존 TS 변수선언 형태 삭제(3.0)
enum Mode {
  LOCAL = 'local',
  DEVELOPMENT = 'dev',
  TEST = 'test',
  STAGE = 'stage',
  PRODUCTION = 'production',
}

enum ConfigKey {
  SERVICE_DOMAIN = 'SERVICE_DOMAIN',
  LEGACY_API_DOMAIN = 'LEGACY_API_DOMAIN',
  PARTNER_DOMAIN = 'PARTNER_DOMAIN',
  AUTH_DOMAIN = 'AUTH_DOMAIN',
  MEMBER_DOMAIN = 'MEMBER_DOMAIN',
  API_DOMAIN = 'API_DOMAIN',
  HOTELS_API_DOMAIN = 'HOTELS_API_DOMAIN',
  POLICY_DOMAIN = 'POLICY_DOMAIN',
  INICIS_SDK = 'INICIS_SDK',
  GOOGLE_MAP_KEY = 'GOOGLE_MAP_KEY',
  NAVER_MAP_KEY = 'NAVER_MAP_KEY',
  NAVER_CLIENT_ID = 'NAVER_CLIENT_ID',
  S3_FRONT_ASSET_DOMAIN = 'S3_FRONT_ASSET_DOMAIN',
  FLIGHT_DOMAIN = 'FLIGHT_DOMAIN',
  SENDBIRD_APP_ID = 'SENDBIRD_APP_ID',
  AIRBRIDGE_WEB_TOKEN = 'AIRBRIDGE_WEB_TOKEN',
  AIRBRIDGE_APP_NAME = 'AIRBRIDGE_APP_NAME',
}

const targetEnv = (process.env.TARGET_ENV || 'dev')
  .replace('mrtdev', 'dev')
  .replace('mrttest', 'test');

export const env = (() => {
  switch (true) {
    case targetEnv.includes('dev'):
      return Mode.DEVELOPMENT;
    case targetEnv.includes('test'):
      return Mode.TEST;
    default:
      return targetEnv as Mode;
  }
})();

const GOOGLE_MAP_KEY = 'AIzaSyBAoo822AgkqBDrPE5nr4w_ZRIAF1lISEQ';

const DEV_NAVER_MAP_KEY = 'x1tfdyfnkp';
const PROD_NAVER_MAP_KEY = '6wfc8raza9';

const DEV_NAVER_CLIENT_ID = 'fHBZ7ARSBsopEo18BUAN';
const TEST_NAVER_CLIENT_ID = 'Bkz3FmN8w5alJHG0HWxq';
const PROD_NAVER_CLIENT_ID = 'fPN2wSDH3IWPrt52jtsG';

const DEV_MRT_DOMAIN = `https://www.${targetEnv}-myrealtrip.com`;
const TEST_MRT_DOMAIN = `https://www.${targetEnv}-myrealtrip.com`;
const STAGE_MRT_DOMAIN = 'https://www.stage-myrealtrip.com';
const PROD_MRT_DOMAIN = 'https://www.myrealtrip.com';

const DEV_LEGACY_API_DOMAIN = `https://api.${targetEnv}-myrealtrip.com`;
const TEST_LEGACY_API_DOMAIN = `https://api.${targetEnv}-myrealtrip.com`;
const STAGE_LEGACY_API_DOMAIN = 'https://api.stage-myrealtrip.com';
const PROD_LEGACY_API_DOMAIN = 'https://api.myrealtrip.com';

const DEV_API_DOMAIN = `https://api3.${targetEnv}-myrealtrip.com`;
const TEST_API_DOMAIN = `https://api3.${targetEnv}-myrealtrip.com`;
const STAGE_API_DOMAIN = 'https://api3.stage-myrealtrip.com';
const PROD_API_DOMAIN = 'https://api3.myrealtrip.com';

const DEV_HOTELS_API_DOMAIN = `https://hotels-api.${targetEnv}-myrealtrip.com`;
const TEST_HOTELS_API_DOMAIN = `https://hotels-api.${targetEnv}-myrealtrip.com`;
const STAGE_HOTELS_API_DOMAIN = 'https://api-hotels.stage-myrealtrip.com';
const PROD_HOTELS_API_DOMAIN = 'https://hotels-api.myrealtrip.com';

const TEST_INICIS_SDK = 'https://stgstdpay.inicis.com/stdjs/INIStdPay.js';
const PROD_INICIS_SDK = 'https://stdpay.inicis.com/stdjs/INIStdPay.js';

const DEV_POLICY_DOMAIN = `https://terms.dev-myrealtrip.com`;
const PROD_POLICY_DOMAIN = 'https://terms.myrealtrip.com';

const DEV_S3_FRONT_ASSET_DOMAIN = 'https://d259u2vi7a6l42.cloudfront.net';
const PROD_S3_FRONT_ASSET_DOMAIN = 'https://dffoxz5he03rp.cloudfront.net';

const DEV_PARTNER_DOMAIN = `https://partner.${targetEnv}-myrealtrip.com`;
const TEST_PARTNER_DOMAIN = `https://partner.${targetEnv}-myrealtrip.com`;
const STAGE_PARTNER_DOMAIN = `https://partner.stage-myrealtrip.com`;
const PROD_PARTNER_DOMAIN = 'https://partner.myrealtrip.com';

const DEV_AUTH_DOMAIN = `https://auth.${targetEnv}-myrealtrip.com`;
const TEST_AUTH_DOMAIN = `https://auth.${targetEnv}-myrealtrip.com`;
const STAGE_AUTH_DOMAIN = `https://auth.stage-myrealtrip.com`;
const PROD_AUTH_DOMAIN = 'https://auth.myrealtrip.com';

const DEV_MEMBER_DOMAIN = `https://member.${targetEnv}-myrealtrip.com`;
const TEST_MEMBER_DOMAIN = `https://member.${targetEnv}-myrealtrip.com`;
const STAGE_MEMBER_DOMAIN = `https://member.stage-myrealtrip.com`;
const PROD_MEMBER_DOMAIN = 'https://member.myrealtrip.com';

const TEST_FLIGHT_DOMAIN = `https://flights.${targetEnv.replace('dev', 'test')}-myrealtrip.com`;
const PROD_FLIGHT_DOMAIN = `https://flights.myrealtrip.com`;

const DEV_SENDBIRD_APP_ID = 'DC591A53-B567-4082-83A4-E8B550A3E4AE';
const TEST_SENDBIRD_APP_ID = '6C7CD015-85C6-4811-93D7-C808776B220F';
const PROD_SENDBIRD_APP_ID = '85D30E6C-3FE3-42B9-97FD-967AD829FA3E';

const DEV_AIRBRIDGE_WEB_TOKEN = 'a5e660987cb24f18ab456c1a27a17ff3';
const TEST_AIRBRIDGE_WEB_TOKEN = 'a5e660987cb24f18ab456c1a27a17ff3';
const PROD_AIRBRIDGE_WEB_TOKEN = '69626cbd65b94aae83f4e89bd988d2f7';

const DEV_AIRBRIDGE_APP_NAME = 'myrealtripdev';
const TEST_AIRBRIDGE_APP_NAME = 'myrealtripdev';
const PROD_AIRBRIDGE_APP_NAME = 'myrealtrip';

type Config = Record<Mode, Record<ConfigKey, string>>;

const configPreset: Config = {
  [Mode.LOCAL]: {
    SERVICE_DOMAIN: DEV_MRT_DOMAIN,
    PARTNER_DOMAIN: DEV_PARTNER_DOMAIN,
    AUTH_DOMAIN: DEV_AUTH_DOMAIN,
    MEMBER_DOMAIN: DEV_MEMBER_DOMAIN,
    LEGACY_API_DOMAIN: DEV_LEGACY_API_DOMAIN,
    API_DOMAIN: DEV_API_DOMAIN,
    HOTELS_API_DOMAIN: DEV_HOTELS_API_DOMAIN,
    POLICY_DOMAIN: DEV_POLICY_DOMAIN,
    INICIS_SDK: TEST_INICIS_SDK,
    GOOGLE_MAP_KEY,
    NAVER_MAP_KEY: DEV_NAVER_MAP_KEY,
    NAVER_CLIENT_ID: DEV_NAVER_CLIENT_ID,
    S3_FRONT_ASSET_DOMAIN: DEV_S3_FRONT_ASSET_DOMAIN,
    FLIGHT_DOMAIN: TEST_FLIGHT_DOMAIN,
    SENDBIRD_APP_ID: DEV_SENDBIRD_APP_ID,
    AIRBRIDGE_WEB_TOKEN: DEV_AIRBRIDGE_WEB_TOKEN,
    AIRBRIDGE_APP_NAME: DEV_AIRBRIDGE_APP_NAME,
  },
  [Mode.DEVELOPMENT]: {
    SERVICE_DOMAIN: DEV_MRT_DOMAIN,
    PARTNER_DOMAIN: DEV_PARTNER_DOMAIN,
    AUTH_DOMAIN: DEV_AUTH_DOMAIN,
    MEMBER_DOMAIN: DEV_MEMBER_DOMAIN,
    LEGACY_API_DOMAIN: DEV_LEGACY_API_DOMAIN,
    API_DOMAIN: DEV_API_DOMAIN,
    HOTELS_API_DOMAIN: DEV_HOTELS_API_DOMAIN,
    POLICY_DOMAIN: DEV_POLICY_DOMAIN,
    INICIS_SDK: TEST_INICIS_SDK,
    GOOGLE_MAP_KEY,
    NAVER_MAP_KEY: DEV_NAVER_MAP_KEY,
    NAVER_CLIENT_ID: DEV_NAVER_CLIENT_ID,
    S3_FRONT_ASSET_DOMAIN: DEV_S3_FRONT_ASSET_DOMAIN,
    FLIGHT_DOMAIN: TEST_FLIGHT_DOMAIN,
    SENDBIRD_APP_ID: DEV_SENDBIRD_APP_ID,
    AIRBRIDGE_WEB_TOKEN: DEV_AIRBRIDGE_WEB_TOKEN,
    AIRBRIDGE_APP_NAME: DEV_AIRBRIDGE_APP_NAME,
  },
  [Mode.TEST]: {
    SERVICE_DOMAIN: TEST_MRT_DOMAIN,
    PARTNER_DOMAIN: TEST_PARTNER_DOMAIN,
    AUTH_DOMAIN: TEST_AUTH_DOMAIN,
    MEMBER_DOMAIN: TEST_MEMBER_DOMAIN,
    LEGACY_API_DOMAIN: TEST_LEGACY_API_DOMAIN,
    API_DOMAIN: TEST_API_DOMAIN,
    HOTELS_API_DOMAIN: TEST_HOTELS_API_DOMAIN,
    POLICY_DOMAIN: PROD_POLICY_DOMAIN,
    INICIS_SDK: TEST_INICIS_SDK,
    GOOGLE_MAP_KEY,
    NAVER_MAP_KEY: DEV_NAVER_MAP_KEY,
    NAVER_CLIENT_ID: TEST_NAVER_CLIENT_ID,
    S3_FRONT_ASSET_DOMAIN: DEV_S3_FRONT_ASSET_DOMAIN,
    FLIGHT_DOMAIN: TEST_FLIGHT_DOMAIN,
    SENDBIRD_APP_ID: TEST_SENDBIRD_APP_ID,
    AIRBRIDGE_WEB_TOKEN: TEST_AIRBRIDGE_WEB_TOKEN,
    AIRBRIDGE_APP_NAME: TEST_AIRBRIDGE_APP_NAME,
  },
  [Mode.STAGE]: {
    SERVICE_DOMAIN: STAGE_MRT_DOMAIN,
    PARTNER_DOMAIN: STAGE_PARTNER_DOMAIN,
    AUTH_DOMAIN: STAGE_AUTH_DOMAIN,
    MEMBER_DOMAIN: STAGE_MEMBER_DOMAIN,
    LEGACY_API_DOMAIN: STAGE_LEGACY_API_DOMAIN,
    API_DOMAIN: STAGE_API_DOMAIN,
    HOTELS_API_DOMAIN: STAGE_HOTELS_API_DOMAIN,
    POLICY_DOMAIN: PROD_POLICY_DOMAIN,
    INICIS_SDK: PROD_INICIS_SDK,
    GOOGLE_MAP_KEY,
    NAVER_MAP_KEY: PROD_NAVER_MAP_KEY,
    NAVER_CLIENT_ID: PROD_NAVER_CLIENT_ID,
    S3_FRONT_ASSET_DOMAIN: PROD_S3_FRONT_ASSET_DOMAIN,
    FLIGHT_DOMAIN: PROD_FLIGHT_DOMAIN,
    SENDBIRD_APP_ID: PROD_SENDBIRD_APP_ID,
    AIRBRIDGE_WEB_TOKEN: PROD_AIRBRIDGE_WEB_TOKEN,
    AIRBRIDGE_APP_NAME: PROD_AIRBRIDGE_APP_NAME,
  },
  [Mode.PRODUCTION]: {
    SERVICE_DOMAIN: PROD_MRT_DOMAIN,
    PARTNER_DOMAIN: PROD_PARTNER_DOMAIN,
    AUTH_DOMAIN: PROD_AUTH_DOMAIN,
    MEMBER_DOMAIN: PROD_MEMBER_DOMAIN,
    LEGACY_API_DOMAIN: PROD_LEGACY_API_DOMAIN,
    API_DOMAIN: PROD_API_DOMAIN,
    HOTELS_API_DOMAIN: PROD_HOTELS_API_DOMAIN,
    POLICY_DOMAIN: PROD_POLICY_DOMAIN,
    INICIS_SDK: PROD_INICIS_SDK,
    GOOGLE_MAP_KEY,
    NAVER_MAP_KEY: PROD_NAVER_MAP_KEY,
    NAVER_CLIENT_ID: PROD_NAVER_CLIENT_ID,
    S3_FRONT_ASSET_DOMAIN: PROD_S3_FRONT_ASSET_DOMAIN,
    FLIGHT_DOMAIN: PROD_FLIGHT_DOMAIN,
    SENDBIRD_APP_ID: PROD_SENDBIRD_APP_ID,
    AIRBRIDGE_WEB_TOKEN: PROD_AIRBRIDGE_WEB_TOKEN,
    AIRBRIDGE_APP_NAME: PROD_AIRBRIDGE_APP_NAME,
  },
};

export const Configs = configPreset[env];
