/**
 * node module import
 */
import React from 'react';
import range from 'lodash/range';
import PropTypes from 'prop-types';

// 아이콘
import Star from './StarIcon';
import StarEmpty from './StarEmptyIcon';
import StarHalf from './StarHalfIcon';

const StarRating = ({ color, rating, maxRating, size, emptyAvailable, hideEmptyStar, customSize }) => (
  <div className={`starRating starRating--${size} starRating--${color} starRating--${emptyAvailable ? 'empty' : ''}`}>
    {range(maxRating).map((idx) => {
      if (rating - idx > 0.5) {
        return <Star key={idx} color={color} size={size} />;
      }
      if (rating - idx > 0) {
        return <StarHalf key={idx} color={color} size={size} />;
      }

      if (hideEmptyStar) {
        return null;
      }

      return <StarEmpty key={idx} color={color} size={size} emptyAvailable={emptyAvailable} />;
    })}
  </div>
);

StarRating.propTypes = {
  color: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxRating: PropTypes.number,
  size: PropTypes.string,
  emptyAvailable: PropTypes.bool,
  hideEmptyStar: PropTypes.bool,
  customSize: PropTypes.number,
};

StarRating.defaultProps = {
  color: 'blue',
  rating: 0,
  maxRating: 5,
  size: 'm', // xl: 24, l: 16, m: 12, s: 10, xs: 8
  customSize: 0,
  emptyAvailable: false,
  hideEmptyStar: false,
};
export default StarRating;
