import { ChangeEvent, InputHTMLAttributes, useEffect, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DEFAULT_COLORS } from '@myrealtrip/web-ui';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  invalid?: boolean;
  maxWidth?: number;
  alterValue?: (value: string) => string;
}

function InputUnit({ defaultValue, onChange, onBlur, alterValue, ...props }: Props): JSX.Element {
  const { maxLength, maxWidth } = props;
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (defaultValue) setInputValue(`${defaultValue}`);
  }, [defaultValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = alterValue ? alterValue(e.target.value) : e.target.value;

    if (maxLength) value = value.substring(0, maxLength);
    setInputValue(value);
    onChange?.(value);
  };

  const handleBlur = () => {
    if (!onBlur) return;
    onBlur(inputValue);
  };

  return (
    <Input
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
      maxWidth={maxWidth}
    />
  );
}

const Input = styled.input<Pick<Props, 'invalid' | 'maxWidth'>>`
  width: 100%;
  padding: 11px 12px;
  color: var(--gray-1000);
  border: 0;
  box-shadow: inset 0 0 0 1px var(--gray-300);
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: -0.2px;
  border-radius: 4px;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};

  :hover {
    box-shadow: inset 0 0 0 1px var(--gray-500);
  }

  :focus-visible {
    outline: none;
  }

  :focus {
    box-shadow: inset 0 0 0 2px var(--blue-500);
  }

  ::placeholder {
    color: var(--gray-400);
  }

  ${({ invalid }) =>
    invalid &&
    css`
      box-shadow: inset 0 0 0 2px var(--yellow-600);

      :hover {
        box-shadow: inset 0 0 0 2px var(--yellow-600);
      }

      :focus {
        box-shadow: inset 0 0 0 2px var(--blue-500);
      }
    `};

  :disabled {
    background-color: ${DEFAULT_COLORS.gray.s60};
  }
`;

export default InputUnit;
