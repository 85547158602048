import axios from 'axios';

const webpackDevServerAxios = axios.create({
  baseURL: `https://www.${(process.env.TARGET_ENV || 'dev').replace(
    'mrtdev',
    'dev',
  )}-myrealtrip.com:3035`,
});

function sendErrMsgToSlack(errorMessage: string) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  webpackDevServerAxios
    .post('/slack', {
      errorText: errorMessage,
    })
    .then(() => {
      console.log('Alert to slack successfully');
    });
}

export default sendErrMsgToSlack;
