import { createRef } from 'react';

export const LODGING_NAV_TABS = {
  roomSelect: {
    id: 'roomSelect',
    label: '객실선택',
    ref: createRef(),
    intersectionRatio: 0,
  },
  roomInfo: {
    id: 'roomInfo',
    label: '숙소정보',
    ref: createRef(),
    intersectionRatio: 0,
  },
  roomReview: {
    id: 'roomReview',
    label: '후기',
    ref: createRef(),
    intersectionRatio: 0,
  },
};

export const AGE_OPTIONS = new Array(18).fill(0).map((_, index) => ({
  id: index,
  label: `${index}세`,
}));

export enum FilterIds {
  ROOM_TYPE = 'roomTypes',
  MIN_SCORE = 'minAverageScore',
  MIN_PRICE = 'minPrice',
  MAX_PRICE = 'maxPrice',
  FREE_SERVICE = 'freeServices',
  CONVENIENCE = 'optionConveniences',
  AMENITY = 'amenities',
  CONFIRM = 'immediateConfirm',
  RESERVATION = 'immediateReservation',
}

export enum RoomTypes {
  ALL = '모든 객실',
  FEMALE_DORMITORY = '여성 도미토리',
  MALE_DORMITORY = '남성 도미토리',
  MIXED_DORMITORY = '혼성 도미토리',
  SINGLE_ROOM = '1인실',
  DOUBLE_ROOM = '2인실',
  FAMILY_ROOM = '3인 이상 가족룸',
  WHOLE_HOUSE = '집 전체',
}

export enum RoomTypesKeys {
  ALL = 'ALL',
  FEMALE_DORMITORY = 'FEMALE_DORMITORY',
  MALE_DORMITORY = 'MALE_DORMITORY',
  MIXED_DORMITORY = 'MIXED_DORMITORY',
  SINGLE_ROOM = 'SINGLE_ROOM',
  DOUBLE_ROOM = 'DOUBLE_ROOM',
  FAMILY_ROOM = 'FAMILY_ROOM',
  WHOLE_HOUSE = 'WHOLE_HOUSE',
}

export enum FreeServicesKeys {
  INTERNATIONAL_CALL = 'INTERNATIONAL_CALL',
  KEEP_LUGGAGE = 'KEEP_LUGGAGE',
  PICKUP = 'PICKUP',
  WASH_CLOTHES = 'WASH_CLOTHES',
  PARKING = 'PARKING',
  TOUR = 'TOUR',
}

export enum FreeServicesTypes {
  INTERNATIONAL_CALL = '국제전화',
  KEEP_LUGGAGE = '짐보관',
  PICKUP = '픽업',
  WASH_CLOTHES = '세탁',
  PARKING = '주차',
  TOUR = '투어',
}

export enum FreeServicesIcons {
  INTERNATIONAL_CALL = 'ic_internationalcall_28x28_gray_700',
  KEEP_LUGGAGE = 'ic_baggage_28x28_gray_700',
  PICKUP = 'ic_pickup_28x28_gray_700',
  WASH_CLOTHES = 'ic_washclothes_28x28_gray700',
  PARKING = 'ic_parking_28x28_gray_700',
  TOUR = 'ic_tour_28x28_gray_700',
}

export enum AmenitiesKeys {
  WIFI = 'WIFI',
  AIR_CONDITIONER = 'AIR_CONDITIONER',
  WASHING_MACHINE = 'WASHING_MACHINE',
  BATHROOM = 'BATHROOM',
  TOILET = 'TOILET',
  KITCHEN = 'KITCHEN',
  REFRIGERATOR = 'REFRIGERATOR',
  TV = 'TV',
  CLOTHES_DRYER = 'CLOTHES_DRYER',
  HAIR_DRYER = 'HAIR_DRYER',
  TOWEL = 'TOWEL',
  SHAMPOO = 'SHAMPOO',
  RINSE = 'RINSE',
  BODY_WASH = 'BODY_WASH',
  TOOTHPASTE = 'TOOTHPASTE',
  SOAP = 'SOAP',
  ELEVATOR = 'ELEVATOR',
  PARKING = 'PARKING',
  BBQ = 'BBQ',
  POOL = 'POOL',
  GYM = 'GYM',
}

export enum AmenitiesTypes {
  WIFI = 'Wi-Fi',
  AIR_CONDITIONER = '에어컨',
  WASHING_MACHINE = '세탁기',
  BATHROOM = '욕실',
  TOILET = '화장실',
  KITCHEN = '주방',
  REFRIGERATOR = '냉장고',
  TV = 'TV',
  CLOTHES_DRYER = '건조기',
  HAIR_DRYER = '헤어드라이기',
  TOWEL = '수건',
  SHAMPOO = '샴푸',
  RINSE = '린스',
  BODY_WASH = '바디워시',
  TOOTHPASTE = '치약',
  SOAP = '비누',
  ELEVATOR = '엘리베이터',
  PARKING = '주차장(건물내)',
  BBQ = '바베큐시설',
  POOL = '수영장',
  GYM = '헬스장',
}

export enum AmenitiesIcons {
  WIFI = 'ic_wifi_28x28_gray_700',
  AIR_CONDITIONER = 'ic_airconditioner_28x28_gray_700',
  WASHING_MACHINE = 'ic_washingmachine_28x28_gray_700',
  BATHROOM = 'ic_bathroom_28x28_gray_700',
  TOILET = 'ic_toilet_28x28_gray_700',
  KITCHEN = 'ic_kitchen_28x28_gray_700',
  REFRIGERATOR = 'ic_refrigerator_28x28_gray_700',
  TV = 'ic_tv_28x28_gray_700',
  CLOTHES_DRYER = 'ic_clothesdryer_28x28_gray_700',
  HAIR_DRYER = 'ic_hairdryer_28x28_gray_700',
  TOWEL = 'ic_towel_28x28_gray_700',
  SHAMPOO = 'ic_shampoo_28x28_gray_700',
  RINSE = 'ic_rinse_28x28_gray_700',
  BODY_WASH = 'ic_bodywash_28x28_gray_700',
  TOOTHPASTE = 'ic_toothpaste_28x28_gray_700',
  SOAP = 'ic_soap_28x28_gray_700',
  ELEVATOR = 'ic_elevator_28x28_gray_700',
  PARKING = 'ic_parking_28x28_gray_700',
  BBQ = 'ic_bbq_28x28_gray_700',
  POOL = 'ic_pool_28x28_gray_700',
  GYM = 'ic_gym_28x28_gray_700',
}

export enum ConvenienceKeys {
  AIR_CONDITIONER = 'AIR_CONDITIONER',
  TOILET = 'TOILET',
  BATHROOM = 'BATHROOM',
  HEATER = 'HEATER',
  CLOSET = 'CLOSET',
  HANGER = 'HANGER',
  TABLE = 'TABLE',
  CLOTHES_STAND = 'CLOTHES_STAND',
  TV = 'TV',
  KITCHEN = 'KITCHEN',
}

export enum ConvenienceType {
  AIR_CONDITIONER = '에어컨',
  TOILET = '전용 화장실',
  BATHROOM = '전용 욕실',
  HEATER = '난방',
  CLOSET = '옷장',
  HANGER = '행거',
  TABLE = '테이블',
  CLOTHES_STAND = '옷걸이',
  TV = 'TV',
  KITCHEN = '취사시설',
}

export const CONVENIENCE_ITEMS = [
  { label: ConvenienceType.AIR_CONDITIONER, value: '1' },
  { label: ConvenienceType.TOILET, value: '2' },
  { label: ConvenienceType.BATHROOM, value: '3' },
  { label: ConvenienceType.HEATER, value: '4' },
  { label: ConvenienceType.CLOSET, value: '5' },
  { label: ConvenienceType.HANGER, value: '6' },
  { label: ConvenienceType.TABLE, value: '7' },
  { label: ConvenienceType.CLOTHES_STAND, value: '8' },
  { label: ConvenienceType.TV, value: '9' },
  { label: ConvenienceType.KITCHEN, value: '10' },
];

export enum ConvenienceIcons {
  AIR_CONDITIONER = 'ic_airconditioner_28x28_gray_700',
  TOILET = 'ic_toilet_28x28_gray_700',
  BATHROOM = 'ic_bathroom_28x28_gray_700',
  HEATER = 'ic_heater_28x28_gray_700',
  CLOSET = 'ic_closet_28x28_gray_700',
  HANGER = 'ic_clothesstand_28x28_gray_700',
  TABLE = 'ic_table_28x28_gray700',
  CLOTHES_STAND = 'ic_hanger_28x28_gray_700',
  TV = 'ic_tv_28x28_gray_700',
  KITCHEN = 'ic_kitchen_28x28_gray_700',
}

export enum BedTypeKeys {
  SINGLE = 'SINGLE',
  DOUBLE = 'DOUBLE',
  BUNK = 'BUNK',
  TWIN = 'TWIN',
}

export enum BedTypes {
  SINGLE = '싱글 침대',
  DOUBLE = '더블 침대',
  BUNK = '2층 침대',
  TWIN = '트윈 침대',
}

export enum MealTypeKeys {
  BREAKFAST_AND_DINNER = 'BREAKFAST_AND_DINNER',
  BREAKFAST = 'BREAKFAST',
  DINNER = 'DINNER',
}

export enum MealTypes {
  BREAKFAST_AND_DINNER = '조식 · 석식',
  BREAKFAST = '조식',
  DINNER = '석식',
}

export const DEFAULT_FILTER_SETTINGS = {
  [FilterIds.CONFIRM]: false,
  [FilterIds.ROOM_TYPE]: [RoomTypesKeys.ALL],
  [FilterIds.MIN_SCORE]: 0,
  [FilterIds.MIN_PRICE]: 0,
  [FilterIds.MAX_PRICE]: 300000,
  [FilterIds.FREE_SERVICE]: [],
  [FilterIds.CONVENIENCE]: [],
  [FilterIds.AMENITY]: [],
};

export const DEFAULT_FILTER_ENABLES = {
  [FilterIds.AMENITY]: false,
  [FilterIds.CONVENIENCE]: false,
  [FilterIds.MIN_SCORE]: false,
  [FilterIds.MIN_PRICE]: false,
  [FilterIds.MAX_PRICE]: false,
  [FilterIds.FREE_SERVICE]: false,
  [FilterIds.CONFIRM]: false,
  [FilterIds.ROOM_TYPE]: false,
};

export const DEFAULT_ROOM_TYPES_CHECKED = {
  [RoomTypesKeys.ALL]: true,
  [RoomTypesKeys.FEMALE_DORMITORY]: false,
  [RoomTypesKeys.MALE_DORMITORY]: false,
  [RoomTypesKeys.MIXED_DORMITORY]: false,
  [RoomTypesKeys.SINGLE_ROOM]: false,
  [RoomTypesKeys.DOUBLE_ROOM]: false,
  [RoomTypesKeys.FAMILY_ROOM]: false,
  [RoomTypesKeys.WHOLE_HOUSE]: false,
};

export const DEFAULT_FREE_SERVICE_CHECKED = {
  [FreeServicesKeys.INTERNATIONAL_CALL]: false,
  [FreeServicesKeys.KEEP_LUGGAGE]: false,
  [FreeServicesKeys.PARKING]: false,
  [FreeServicesKeys.PICKUP]: false,
  [FreeServicesKeys.TOUR]: false,
  [FreeServicesKeys.WASH_CLOTHES]: false,
};

export const DEFAULT_AMENITY_CHECKED = {
  [AmenitiesKeys.AIR_CONDITIONER]: false,
  [AmenitiesKeys.BATHROOM]: false,
  [AmenitiesKeys.BBQ]: false,
  [AmenitiesKeys.BODY_WASH]: false,
  [AmenitiesKeys.CLOTHES_DRYER]: false,
  [AmenitiesKeys.ELEVATOR]: false,
  [AmenitiesKeys.GYM]: false,
  [AmenitiesKeys.HAIR_DRYER]: false,
  [AmenitiesKeys.KITCHEN]: false,
  [AmenitiesKeys.PARKING]: false,
  [AmenitiesKeys.POOL]: false,
  [AmenitiesKeys.REFRIGERATOR]: false,
  [AmenitiesKeys.RINSE]: false,
  [AmenitiesKeys.SHAMPOO]: false,
  [AmenitiesKeys.SOAP]: false,
  [AmenitiesKeys.TOILET]: false,
  [AmenitiesKeys.TOOTHPASTE]: false,
  [AmenitiesKeys.TOWEL]: false,
  [AmenitiesKeys.TV]: false,
  [AmenitiesKeys.WASHING_MACHINE]: false,
  [AmenitiesKeys.WIFI]: false,
};

export const DEFAULT_CONVENIENCE_CHECKED = {
  [ConvenienceKeys.AIR_CONDITIONER]: false,
  [ConvenienceKeys.TOILET]: false,
  [ConvenienceKeys.BATHROOM]: false,
  [ConvenienceKeys.HEATER]: false,
  [ConvenienceKeys.CLOSET]: false,
  [ConvenienceKeys.HANGER]: false,
  [ConvenienceKeys.TABLE]: false,
  [ConvenienceKeys.CLOTHES_STAND]: false,
  [ConvenienceKeys.TV]: false,
  [ConvenienceKeys.KITCHEN]: false,
};
