import { ChangeEvent } from 'react';

import styled from '@emotion/styled';
import {
  LangOption,
  LANG_OPTIONS,
  DEFAULT_VALUE,
} from '@layout/constants/languageSelect';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

type Props = {
  currentLang: LangOption;
  onChange: (draftValue: LangOption) => void;
};

export default function MobileSelect({ currentLang, onChange }: Props) {
  const handleMobileSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    const nextValue =
      LANG_OPTIONS.find((option) => option.value === value) ?? DEFAULT_VALUE;

    onChange(nextValue);
  };

  return (
    <Container>
      <SelectLabelIcon name="ic-md-globe" />
      <Select value={currentLang.value} onChange={handleMobileSelectChange}>
        {LANG_OPTIONS.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
      <SelectArrowIcon name="ic_arrow_down_12x12_gray_300" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 118px;
  height: 40px;
  padding: 0 10px;
  background-color: ${globalVariables.white};
  border: 1px solid ${globalVariables.gray_300};
  border-radius: 4px;
`;

const SelectLabelIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;
const SelectArrowIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  maring-left: auto;
`;

const Select = styled.select`
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  padding: 0;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${globalVariables.gray_500};
`;
