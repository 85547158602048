import { number, string, shape, bool } from 'prop-types';

export const lnbItemType = shape({
  id: number,
  code: string,
  title: string,
  position: number,
  level: number,
  lnb_required_badge: bool,
  link: shape({
    mobile: string,
    desktop: string,
  }),
});
