import { useCallback, useEffect, useMemo, useRef } from 'react';

import { helper, TrackEventType, LogDataType, LogType } from '@myrealtrip/frontend-weblog';

const { sendPageViewLog, connect, sendClickLog } = helper;

type SendLogWithCallbackOptions =
  | {
      sendLogOnce?: boolean;
      disableConnect?: boolean;
      connectStreamType?: 'before' | 'after';
    }
  | undefined;

// 각 eventType에 대응하는 sendEventLog 메서드
const logHelpers: Partial<Record<TrackEventType, (logData: LogDataType) => void>> = {
  [TrackEventType.CLICK]: sendClickLog,
  [TrackEventType.PAGE_VIEW]: sendPageViewLog,
};

export default function useSendLogWithCallback(
  eventType: TrackEventType, // 이벤트 타입: 로깅 이벤트 함수지정, connector 실행 시 이벤트 대조 등에 활용
  logData: LogDataType, // 로그 데이터
  callback: (logResponse: LogType) => void, // connector 로 실행할 콜백함수
  options: SendLogWithCallbackOptions = {
    sendLogOnce: false, // 최초 1회만 실행할지 여부
    disableConnect: false, // connect를 미실행할지 여부 (웹뷰 등)
    connectStreamType: 'before',
  },
) {
  // connect() callback 등록 시, disconnect() 제거함수 저장 + 저장됬다면 최초 1회 로그전송 발생한 것
  const disconnector = useRef<() => void>();

  // Hooks 옵션
  // sendLogOnce : 최초 1회만 로깅할지 여부
  // disableConnect : connect() 미실행 여부
  const { sendLogOnce, disableConnect, connectStreamType } = options;

  // 최초 1회만 로깅하는 조건 (pageview, layerview 및 sendLogOnce 옵션)
  const isSendLogOnce = useMemo(
    () => [TrackEventType.PAGE_VIEW, TrackEventType.LAYER_VIEW].includes(eventType) || sendLogOnce,
    [eventType, sendLogOnce],
  );

  // connect() 등록할 callback
  const connector = useCallback(
    (logResponse: LogType) => {
      // 등록된 콜백함수는 모든 로깅시 실행되므로, 실행 전 특정 이벤트 조건을 먼저 검사
      // TODO(weblog): performance 등 eventName이 커스텀되는 경우가 있어 이벤트 추가시 조건문 보완 필요 (고유키 props 등)
      const { event_name: resEventName, event_type: resEventType } = logResponse;
      if (logData.eventName !== resEventName || eventType !== resEventType) return;

      callback(logResponse);
    },
    [callback, eventType, logData],
  );

  // 로깅 전송 함수
  // 1. 함수 미실행 조건: a) 고유키가 안맞는 경우, b) 최초 1회만 실행하는 경우 (최초1회 조건에 해당하면서 이미 로깅함수가 실행된 경우 종료)
  // 2. disconnector가 없으면 로깅함수 실헹 전. 최초 로깅시만 connect()를 연결(disableConnect 커넥터를 안 쓰는 경우 빈 함수 연결)
  // 3. eventType에 맞는 sendEventLog(logHelpers) 실행
  const sendLog = async () => {
    if (isSendLogOnce && disconnector.current) return;
    if (!disconnector.current)
      disconnector.current = disableConnect
        ? () => undefined
        : await connect(connector, connectStreamType);

    await logHelpers[eventType]?.(logData);
  };

  // 초기화 함수. disconnector를 실행하여 불필요한 connector를 비움
  const resetDisconnector = () => {
    if (disconnector.current) {
      disconnector.current();
      disconnector.current = undefined;
    }
  };

  // Unmount 시, resetDisconnector() 실행
  useEffect(() => {
    return () => resetDisconnector();
  }, []);

  // 페이지 떠날 시, resetDisconnector() 실행 (location.href 등)
  window.onpagehide = resetDisconnector;

  return { sendLog };
}
