import GlobalNavButton from './GlobalNavButton';
import GlobalNavLink from './GlobalNavLink';

type Props = {
  className?: string;
  type: 'link' | 'button';
  label: string;
  outline?: boolean;
  pathname?: string;
  redDot?: boolean;
  onClick?: () => void;
};

export default function GlobalNavButtonItem({
  className,
  type,
  label,
  pathname,
  redDot,
  outline,
  onClick,
}: Props) {
  return (
    <>
      {type === 'link' && (
        <GlobalNavLink
          className={className}
          label={label}
          pathname={pathname ?? ''}
          outline={outline}
          redDot={redDot}
        />
      )}
      {type === 'button' && (
        <GlobalNavButton
          className={className}
          label={label}
          onClick={onClick}
          outline={outline}
          redDot={redDot}
        />
      )}
    </>
  );
}
