import React from 'react';

import PropTypes from 'prop-types';

import ContentsCarousel from '../../../Contents/ContentsCarousel';
import { handleMagazinClick } from '../../utils/jejuSendEvent';
import section from '../../utils/section';
import useRequest from '../../utils/useRequest';
import CrossSellJejuSection from './CrossSellJejuSection';

function CrossSellJejuMagazines(props) {
  const { isWebView, utm } = props;
  const { isLoading, data } = useRequest(
    `${process.env.API_V2_URL}/flight/cities/contents?key_name=Jeju`,
  );
  const sectionData = section.magazines;
  const contents =
    data &&
    data.data &&
    data.data.contents.map((content) => ({
      ...content,
      thumbnail: content.thumbnail.urls.large,
      authorName: content.author.name,
      authorDesc: content.author.desc,
      authorImage: content.author.image?.urls.medium,
    }));

  if (!contents || !contents?.length) {
    return null;
  }

  return (
    <CrossSellJejuSection isLoading={isLoading} {...props} {...sectionData}>
      <div>
        <ContentsCarousel
          isWebView={isWebView}
          contents={contents}
          onClick={() => handleMagazinClick(isWebView)}
          openNewTab
          qs={utm}
        />
      </div>
    </CrossSellJejuSection>
  );
}

CrossSellJejuMagazines.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isWebView: PropTypes.bool.isRequired,
  utm: PropTypes.string.isRequired,
};

export default CrossSellJejuMagazines;
