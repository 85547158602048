// local json file을 read
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import ko from './ko.json';

export default {
  ko: {
    translation: ko,
  },
};
