import { Configs, SIGN_IN_URL } from 'mrt-constants';

import { getReturnUrlV2 } from './getReturnUrl';

function removeTrailingSlash(url: string) {
  return url.replace(/\/$/, ''); // 정규표현식을 사용하여 마지막 슬래시 제거
}

export default function getSigninWebLink(returnTo?: string) {
  let encodedReturnUrl = '';

  if (returnTo) {
    encodedReturnUrl = encodeURIComponent(returnTo || '');
  } else if (Configs.SERVICE_DOMAIN !== removeTrailingSlash(window.location.href)) {
    encodedReturnUrl = getReturnUrlV2();
  }

  return `${SIGN_IN_URL}${encodedReturnUrl ? `?return_to=${encodedReturnUrl}` : ''}`;
}
