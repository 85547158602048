const about = {
  menu: {
    about: '회사소개',
    recruit: '채용',
    blog: '기술블로그',
  },
  subtitle: {
    prefix: '마이리얼트립은 국내 최초, 최대, 최고의 ',
    suffix: '자유여행 플랫폼입니다.',
  },
  about: {
    title: '우리는 모든 경험을 연결하여 여행을 혁신합니다.',
    description:
      '마이리얼트립은 여행을 떠나기 위해 필요한 모든 것을 한 곳에서 검색하고 예약할 수 있는 국내 최고의 Travel Super App 입니다. 2012년 가이드 투어 서비스를 시작으로 2018년 항공서비스까지 성공적으로 출시하며 매년 300% 이상의 성장을 지속하고 있습니다. 마이리얼트립은 여행 준비, 항공, 숙박, 현지 교통, 현지 경험 (투어, 티켓, 특가여행, 패키지) 등 모든 여행 경험을 연결하여 새로운 여행 방식을 만들어갑니다.',
    histories: [
      {
        key: 'tour',
        year: '2012',
        content: '마이리얼트립 출시\n가이드 투어 상품 출시',
      },
      {
        key: 'ticket',
        year: '2014',
        content: '티켓, 패스 상품 출시',
      },
      {
        key: 'hotel',
        year: '2016',
        content: '실시간 호텔 예약 서비스 시작',
      },
      {
        key: 'airtel',
        year: '2017',
        content: '특가여행, 한인민박 상품 출시',
      },
      {
        key: 'flight',
        year: '2018',
        content: '항공권 판매 시작',
      },
      {
        key: 'package',
        year: '2019',
        content: '패키지 여행 상품 출시',
      },
      {
        key: 'lantour',
        year: '2020',
        content: '랜선투어 상품 출시',
      },
    ],
  },
  current: {
    title: '국내 최고의 Travel Tech 회사로 \n빠르게 성장 중입니다.',
    description:
      '2012년에 출시된 마이리얼트립은 누적 여행자 {{count}}만 명을 넘어서며\n 빠르게 성장하고 있습니다. 이는 여행업계에서 가장 빠른 성장 속도입니다.',
    traveler: {
      title: '누적 여행자 수',
      content: '지금까지 {{count}}만 명이 넘는 여행자가 마이리얼트립을 통해 여행을 경험하였습니다.',
    },
    offer: {
      title: '투어&티켓 상품 수',
      content: '전세계 {{city}}개의 도시에서 {{count}}여 개의 상품을 판매하고 있습니다.',
    },
    review: {
      title: '누적 리뷰 수',
      content: '{{count}}만 명이 넘는 여행자들이 마이리얼트립 상품에 대한 리뷰를 등록했습니다.',
    },
  },
  press: '언론에서 전하는 마이리얼트립',
  message: '마이리얼트립과 함께 \n여행 시장의 혁신을 만들어 갈 동료를 모십니다.',
  detail: '자세히 알아보기',
  contact: {
    title: '궁금한 점이 있으시면 \n언제든 연락주세요.',
    direction: '찾아오시는 길',
    address:
      '서울특별시 서초구 강남대로 311, 드림플러스 강남 18층 (서초동, 한화생명보험빌딩) (주)마이리얼트립',
    googleMap: '네이버 지도로 보기',
    business: 'B2B 제휴',
    marketing: '광고 및 제휴',
  },
};

export default about;
