import React from 'react';
import PropTypes from 'prop-types';

import { buttonThemeEnum, buttonModifierEnum } from '../../../../enum';
import icon from '../../../../assets/images/common/ic-outlink-xs-line-gray-500.svg';
import Link from '../../../shared/Link';
import Grid from '../../../shared/Grid';
import styles from './CrossSellJejuOutlink.module.scss';

function CrossSellJejuOutlink(props) {
  const { link, label, isBlank, onClick } = props;

  return (
    <Grid>
      <Link
        href={link}
        theme={buttonThemeEnum.outline}
        modifiers={buttonModifierEnum.block}
        target={isBlank ? '_blank' : null}
        onClick={onClick}
      >
        <span className={styles.label}>{label}</span>
        {isBlank && <img className={styles.icon} src={icon} alt="" />}
      </Link>
    </Grid>
  );
}

CrossSellJejuOutlink.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isBlank: PropTypes.bool,
  onClick: PropTypes.bool,
};

CrossSellJejuOutlink.defaultProps = {
  isBlank: false,
  onClick: () => null,
};

export default CrossSellJejuOutlink;
