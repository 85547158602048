const promotion = {
  recommend: {
    message: '예약이 정상적으로 완료됐습니다.',
    tour: '{{city}} 인기 상품',
    onlyMRT: '마이리얼트립에서 인기있는 상품을 만나보세요.',
    showOther: '다른 상품 더 보기',
    showRecommend: '추천 상품 보기',
  },
  goEvent: '이벤트 자세히 보기',
};

export default promotion;
