import i18next from 'i18next';
import languages from './languages';

const i18nInstance = i18next.createInstance().init({
  lng: 'ko',
  fallbackLng: "ko",
  debug: false,
  keySeparator: false, // 단일키를 사용하기 위함 , { "zz.xx.xx" } => ok,   { "zzz" : { "xx" : {"xx"}}} => no
  resources: languages,
});

/**
 * @param {(string | string[])} key [에러 코드 값1, 에러 코드 값2] 에러 코드 값1이 존재하지 않는다면 에러 코드 값2가 반환
 * @param {*} [values={}] options
 * @param {string} [defaultValue='']
 * @returns string
 * @example
 * import i18next from 'mrt-i18next'
 *
 * i18n(['accommodation.error.notFoundProduct', 'common.error.serverError']);
 */

function i18n(key: string | string[], values: any = {}, defaultValue = ''): string {
  if (!i18nInstance.exists(key)) return defaultValue;
  return i18nInstance.t(key, values);
}

export default i18n;
