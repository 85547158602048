import { RecoilRoot } from 'recoil';

import { QueryClientProvider } from 'mrt-components';
import i18n from 'mrt-components/utils/i18n';
import { GlobalSearch as GlobalSearchType } from 'mrt-types';

import GlobalSearchContainer from './GlobalSearchContainer';

const { LAYOUT, COLOR } = GlobalSearchType;

const DEFAULT_VARIATION: GlobalSearchType.Variation = {
  layout: LAYOUT.header,
  color: COLOR.white,
};

interface Props {
  placeholder?: string;
  variations?: GlobalSearchType.Variation;
  isShowSearchInput?: boolean;
  isSuggestionDialogOpen?: boolean;
  onSuggestionDialogToggle?: (isOpen: boolean) => void;
}

function GlobalSearch({
  variations = DEFAULT_VARIATION,
  placeholder = i18n.t('search:searchPlaceholder'),
  isShowSearchInput = true,
  isSuggestionDialogOpen,
  onSuggestionDialogToggle,
}: Props) {
  return (
    <RecoilRoot>
      <QueryClientProvider>
        <GlobalSearchContainer
          variations={variations}
          placeholder={placeholder}
          isShowSearchInput={isShowSearchInput}
          isSuggestionDialogOpen={isSuggestionDialogOpen}
          onSuggestionDialogToggle={onSuggestionDialogToggle}
        />
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default GlobalSearch;
