import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { buttonThemeEnum, sizeEnum } from '../../enum';
import { childrenType } from '../../types';
import buttonStyles from './Button.module.scss';
import styles from './Link.module.scss';

const Link = React.forwardRef((props, ref) => {
  const {
    theme,
    size,
    modifiers,
    disabled,
    style,
    children,
    className,
    onClick,
    ...linkProps
  } = props;
  const modifierArray = modifiers.split(',').filter(Boolean);
  const buttonClass = classNames(
    styles.link,
    styles[size],
    buttonStyles.button,
    buttonStyles[theme],
    buttonStyles[size],
    ...modifierArray.map((modifier) => buttonStyles[modifier]),
    { [buttonStyles.disabled]: disabled },
    className,
  );
  const handleClick = (e) => {
    if (disabled) {
      e.stopPropagation();
      e.preventDefault();

      return;
    }

    if (onClick) onClick(e);
  };

  /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
  return (
    <a
      ref={ref}
      className={buttonClass}
      disabled={disabled}
      style={style}
      onClick={handleClick}
      {...linkProps}
    >
      {children}
    </a>
  );
});
/* eslint-enable */

Link.propTypes = {
  href: PropTypes.string.isRequired,
  theme: PropTypes.oneOf([
    buttonThemeEnum.primary,
    buttonThemeEnum.secondary,
    buttonThemeEnum.outline,
    buttonThemeEnum.gray,
    buttonThemeEnum.link,
    buttonThemeEnum.blank,
  ]),
  size: PropTypes.oneOf([
    sizeEnum.xLarge,
    sizeEnum.large,
    sizeEnum.medium,
    sizeEnum.small,
  ]),
  /** Comma separated buttonModifierEnum */
  modifiers: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.shape({}),
  children: childrenType,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  theme: buttonThemeEnum.link,
  size: sizeEnum.medium,
  modifiers: '',
  disabled: false,
  style: {},
  children: null,
  className: '',
};

export default React.memo(Link);
