import { useEffect, useRef } from 'react';

import { helper } from '@myrealtrip/frontend-weblog';

const { sendLayerViewLog } = helper;

export default function useLayerViewLog(log: Record<string, any> | null, isShow = true) {
  const sentLog = useRef(false);

  useEffect(() => {
    if (!log || !isShow) {
      sentLog.current = false;
      return;
    }

    if (!sentLog.current) {
      sendLayerViewLog(log);

      sentLog.current = true;
    }
  }, [isShow, log]);
}
