const friends = {
  title: '친구 초대하면\n무제한 포인트를 드립니다!',
  subtitle: '친구에게 5,000원 쿠폰을 선물하고\n2,000 포인트를 받으세요.',
  inviteUnit: '{{count}}명',
  howTo: {
    title: '참여 방법',
    point: {
      title: '공유한 링크를 통해/친구가 가입하면?',
      description: {
        prefix: '현금처럼 즉시 확정할 수 있는',
        suffix: '2,000 포인트 바로 적립!',
      },
      caution: {
        prefix: '*초대받은 친구가 가입 후',
        suffix: '본인 인증까지 마쳐야 합니다.',
      },
    },
    sns: {
      title: '원하는 SNS로 친구에게/이벤트 공유하기',
      copied: '주소가 복사 되었습니다.',
      alert: '친구를 초대하고 포인트를 받으려면 휴대폰 번호 인증이 필요합니다.',
      facebook: '페이스북 공유하기',
      kakao: '카카오톡 공유하기',
      link: '링크 공유하기',
    },
  },
  rank: {
    title: '이번 달 마이리얼트립 절친은 누구?',
    subtitle: '가장 많은 친구를 초대한 절친 5분을 소개합니다.',
    rank: '순위',
    email: '이메일 아이디',
    inviteCount: '초대 수',
    point: '포인트',
  },
  status: {
    title: '나의 친구 초대 현황',
    invitedFriends: '초대 받고 가입한 친구',
    invitedFriendsUnit: '명',
    totalPoint: '총 적립 포인트',
  },
  banner: {
    title: {
      prefix: '친구 초대하면 ',
      strong: '무제한 포인트',
      suffix: '를 드립니다!',
    },
    description: {
      prefix: '친구에게 5,000원 쿠폰을 선물하고',
      suffix: '2,000 포인트를 받으세요.',
    },
    receivePoint: '포인트 받기',
  },
  notice: {
    title: '꼭! 읽어 주세요',
    items: [
      '1. 친구초대 URL을 통하여 가입하여야 하며, 이미 가입된 회원을 초대하실 수 없습니다.',
      '2. 초대하신 회원이 본인인증을 마친 경우에만 초대 완료로 인정됩니다.',
      '3. 총 적립 포인트는 ‘친구초대’를 통해 적립된 누적 포인트 수치 입니다.',
      '4. 적립된 포인트는 마이리얼트립 서비스 내 투어, 티켓, 민박, 특가여행 구매 시, 쿠폰과 중복으로 현금처럼 사용할 수 있습니다. (실시간 항공권  호텔 제휴할인에는 해당사항이 없습니다.)',
      '5. 적립된 포인트의 유효기간은 발급일로부터 1년간 유효하며, 유효기간 내 미사용 시 재발급되지 않습니다. (20/08/20부터 적용)',
    ],
    policy: '친구 초대 약관',
    confirm: '확인',
  },
};

export default friends;
