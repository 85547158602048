const promotion = {
  recommend: {
    message: 'Successfully booked',
    tour: 'Recommended tour in {{city}}',
    onlyMRT: 'Only in MyRealTrip',
    showOther: 'Show Other Tours',
    showRecommend: 'Show Recommended Tours',
  },
  goEvent: 'Show Details',
};

export default promotion;
