const experience = {
  allRegion: 'All destination',
  header: 'Where are you heading?',
  totalCities: 'All destinations',
  recent: 'Recently viewed offer',
  information: 'Want more travel tips?',
  facebook: {
    title: 'MyRealTrip’s Facebook Page',
    desc: `Check out MyRealTrip’s Facebook page for useful information and tips for each travel destination.`,
  },
  instagram: {
    title: 'MyRealTrip’s Instagram',
    desc:
      'Enjoy beautiful pictures of different cities around the world, shared by our guides and travelers on our Instagram page.',
  },
  naver: {
    title: 'MyRealTrip’s Naver Blog',
    desc: 'View real-time travel information, reviews, and the list of recommended guides on MyRealTrip’s blog.',
  },
  products: '{{offers}} travel around the world {{reviews}} travelers review!',
  use: 'Learn how to use MyRealTrip',
  logo: 'MyRealTrip',
  more: 'Show more',
};

export default experience;
