export default {
  caution:
    '호텔 예약 관련 문의 (예약.변경.취소 등)는 예약하신 제휴 파트너에서 진행되며 예약한 숙소 이용에 대한 문제 발생시 마이리얼트립은 책임지지 않습니다.',
  contact: {
    title: '호텔 예약 관련 문의',
    email: {
      label: '이메일',
      booking: {
        value: 'customer.service@booking.com',
      },
      expedia: {
        value: 'travel@support.expedia.co.kr',
      },
      agoda: {
        value: 'krservice@agoda.com',
      },
    },
    internal: {
      booking: {
        label: '국내 (9AM-5PM)',
        value: '02-3483-3225',
      },
      expedia: {
        label: '국내 (9AM-7PM)',
        value: '02-3480-0118',
      },
      agoda: {
        label: '국내 (9AM-9:45PM)',
        value: '02-6022-2443',
      },
    },
    overseas: {
      label: '국외 (24시간)',
      value: '+44-20-3684-7925',
    },
  },
};
