export type CookieLangValue = 'ko' | 'en';
export type LangOption = { value: CookieLangValue; label: string };

export const LANG_OPTIONS: LangOption[] = [
  {
    value: 'ko',
    label: '한국어',
  },
  {
    value: 'en',
    label: 'English',
  },
];

export const DEFAULT_VALUE: LangOption = {
  label: '한국어',
  value: 'ko',
};
