const policy = {
  agreement: '이용 약관',
  traveler: '여행자 약관',
  partner: '파트너 약관',
  location: '위치정보 이용약관',
  video: '영상정보처리기기 운영관리 방침',
  fourteen: {
    title: '만 14세 이상 확인 (필수)',
    content:
      '정보통신망 이용촉진 및 정보보호 등에 관한 법률에서 만 14세 미만 아동의 개인정보 수집 시 법정대리인의 동의를 받도록 규정하고 있습니다.\n본인이 만 14세 미만 아동으로 법정대리인 동의 없이 회원가입한 경우, 회원탈퇴 또는 서비스 이용에 제한이 있을 수 있습니다.',
    button: '동의하고 시작하기',
  },
  provide: {
    recipient: '제공 받는자',
    purpose: '제공 목적',
    info: '제공 정보',
  },
  affiliate: {
    lodging: '민박 업체',
    land: '랜드 업체',
  },
};

export default policy;
