import { number, string, bool, shape, arrayOf } from 'prop-types';

export const quickLinkVerticalType = shape({
  id: number,
  code: string,
  title: string,
  position: number,
  badge: string,
  badge_style: string,
  link: shape({
    mobile: string,
    desktop: string,
  }),
  cms_icon_id: number,
  cms_icon_code: string,
  cms_icon_url: string,
});

export const quickLinkItemType = shape({
  id: number,
  cityName: string,
  title: string,
  badge: string,
  badge_style: string,
  position: number,
  link: shape({
    mobile: string,
    desktop: string,
  }),
  cms_icon_id: number,
  cms_icon_code: string,
  cms_icon_url: string,
});

export const quickLinkCityType = shape({
  id: number,
  city_name: string,
  position: number,
  quicklink_items: arrayOf(quickLinkItemType),
});
