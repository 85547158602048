import React from 'react';
import PropTypes from 'prop-types';

import styles from './HotelReviewScore.module.scss';

function HotelReviewScore(props) {
  const { score } = props;

  return <span className={styles.container}>{score}</span>;
}

HotelReviewScore.propTypes = {
  score: PropTypes.number,
};

HotelReviewScore.defaultProps = {
  score: 0,
};

export default HotelReviewScore;
