import React from 'react';

export default ({ emptyAvailable }) => (
  <svg
    className={emptyAvailable ? 'empty-star' : ''}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      fill="#DEE2E6"
      fillRule="evenodd"
      d="M6 9.121L2.292 11 3 7.02 0 4.202l4.146-.581L6 0l1.854 3.621 4.146.58-3 2.82L9.708 11z"
    />
  </svg>
);
