import { BreakPoint } from 'mrt-constants';

const mediaQuery = (maxWidth: number) => `
  @media (max-width: ${maxWidth}px)
`;

const media = {
  tablet: mediaQuery(BreakPoint.TABLET),
  mobile: mediaQuery(BreakPoint.MOBILE),
  custom: mediaQuery,
};

export default media;
