import { helper } from '@myrealtrip/frontend-weblog';
import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';

import GlobalNavNonLineButton from './GlobalNavNonLineButton';
import GlobalNavOutLineButton from './GlobalNavOutLineButton';

const { sendClickLog } = helper;

type Props = {
  className?: string;
  label: string;
  redDot?: boolean;
  outline?: boolean;
  pathname: string;
};

export default function GlobalNavLink({ className, label, pathname, redDot, outline }: Props) {
  const handleClick = () => {
    const targetUrl =
      pathname.startsWith('http') && !pathname.includes('www')
        ? pathname
        : `${process.env.HOME_URL}${pathname}`;

    sendClickLog({
      eventName: 'topmenu',
      screenName: WeblogScreenName.COMMON,
      pageCategory: WeblogPageCategory.COMMON,
      itemKind: 'menu',
      itemName: label,
      targetUrl,
    });

    window.location.href = targetUrl;
  };

  return (
    <>
      {outline && (
        <GlobalNavOutLineButton
          className={className}
          label={label}
          redDot={redDot}
          onClick={handleClick}
        />
      )}
      {!outline && (
        <GlobalNavNonLineButton
          className={className}
          label={label}
          redDot={redDot}
          onClick={handleClick}
        />
      )}
    </>
  );
}
