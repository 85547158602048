const hotdeal = {
  leave: 'Where to?',
  selectArea: 'Select Area',
  selectLocation: 'Select a city or country.',
  closePopup: 'Close',
  initialize: 'Reset',
  complete: 'Complete',
  noTrips: 'There are no trips\navailable for your reservation',
  searchAgain: 'Please reseet the filter and try again',
  resetFilter: 'Reset filter',
  showOffer: '{{category}} Go to product',
  lookingForOffer: 'What are you looking for?',
  date: 'Date',
  sort: {
    popular: 'Top Rated',
    reviews: 'Reviews',
    price: 'Price',
    recent: 'Newest',
  },
};

export default hotdeal;
