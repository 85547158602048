import React, { useEffect } from 'react';

import bannerImage from '../../assets/images/cross-sell/money@2x.png';
import { sendCustomEvent } from '../../utils/sendGAEvent';
import styles from './HotelEventBanner.module.scss';

function sendEvent(action) {
  sendCustomEvent('스카이스캐너 랜딩', action);
}

function HotelEventBanner() {
  useEffect(() => {
    sendEvent('배너 노출');
  }, []);

  return (
    <div className={styles.container}>
      <img className={styles.icon} src={bannerImage} alt="hotel_event" />
      <p className={styles.text}>
        <span>지금 호텔 예약하고</span>
        <br />
        <strong>현금 72,000원 </strong>
        <span>받아 가세요.</span>
      </p>
      <a
        className={styles.link}
        href="https://www.myrealtrip.com/campaigns/hotel_cross"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => sendEvent('배너 클릭')}
      >
        현금 받기
      </a>
    </div>
  );
}

export default HotelEventBanner;
