import { ElementType, useMemo } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon } from '@myrealtrip/design-system';
import { media } from '@myrealtrip/modash';
import { BreakPoint } from 'mrt-constants';
import { toCurrency } from 'mrt-utils';

interface LoyaltyPointTextProps {
  point?: number | null;
  isHide?: boolean;
  isGray?: boolean;
  isFlight?: boolean;
  isConfirmedNoDateTna?: boolean;
  isGiven?: boolean;
  isWithIcon?: boolean;
  fontSize?: number;
  className?: string;
  as?: ElementType;
}

function LoyaltyPointText({
  point,
  isHide,
  isGray,
  isFlight,
  isConfirmedNoDateTna,
  isGiven,
  isWithIcon,
  fontSize = 12,
  className,
  as,
}: LoyaltyPointTextProps): JSX.Element {
  // 조건계산
  // - point 유효조건 : null이면 값 유실 케이스, undefined면 point 컬럼 누락
  const isValidPoint = useMemo(() => point || point === 0, [point]);
  // - Icon 노출조건 : (point값 유효) && (isWithIcon 옵션 true)
  const isActiveIcon = isValidPoint && isWithIcon;

  // 색상, 아이콘
  const customStyle = useMemo(() => {
    if (!isValidPoint || !!isGray)
      return {
        color: '--gray-600',
        iconName: 'ic_point_check_gray600',
      };
    return {
      color: '--blue-500',
      iconName: 'ic_point_check_blue500',
    };
  }, [isGray, isValidPoint]);

  const pointText = useMemo(() => {
    const pointCurrency = toCurrency(point);

    switch (true) {
      case !isValidPoint: // 포인트 조회 실패
        return '적립 예정 포인트를 불러오지 못했어요.';
      case !!isGiven: // 사용완료
        return `${pointCurrency}원 적립 완료`;
      case !!isFlight: // 발권완료 + 항공상품
        return `모든 항공권 탑승 시 ${pointCurrency}원 적립 예정`;
      case isConfirmedNoDateTna: // 예약확정 + 비지정일
        return `사용 완료시 ${pointCurrency}원 적립`;
      default: // 예약대기 or 예약확정
        return `${pointCurrency}원 적립 예정`;
    }
  }, [isFlight, isGiven, isConfirmedNoDateTna, isValidPoint, point]);

  if (isHide) return <></>;

  return (
    <TextContainer color={customStyle.color} fontSize={fontSize} className={className} as={as}>
      <TextWrapper>
        {isActiveIcon ? <CheckIcon name={customStyle.iconName} /> : null}
        {pointText}
      </TextWrapper>
    </TextContainer>
  );
}

interface StyledProps {
  color: string;
  fontSize: number;
  className?: string;
  as?: ElementType;
}

const TextContainer = styled.div<StyledProps>`
  color: ${({ color }) => `var(${color})`};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  ${({ fontSize }) =>
    fontSize <= 12
      ? css`
          line-height: 1.24;
          letter-spacing: -2%;
          font-weight: 500;
        `
      : css`
          line-height: 1.38;
          letter-spacing: -1%;
          font-weight: 600;
        `}

  ${media.custom(BreakPoint.MOBILE, 'min')} {
    font-weight: ${({ fontSize }) => (fontSize > 12 ? 700 : 500)};
  }
`;

const TextWrapper = styled.p`
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  letter-spacing: -1%;
`;

const CheckIcon = styled(Icon)`
  margin-right: 5px;
  padding-bottom: 2px;
`;

export default LoyaltyPointText;
