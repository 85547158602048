import GlobalNavNonLineButton from './GlobalNavNonLineButton';
import GlobalNavOutLineButton from './GlobalNavOutLineButton';

type Props = {
  className?: string;
  label: string;
  redDot?: boolean;
  outline?: boolean;
  onClick?: () => void;
};

export default function GlobalNavButton({ className, label, redDot, outline, onClick }: Props) {
  return (
    <>
      {outline && (
        <GlobalNavOutLineButton
          className={className}
          label={label}
          redDot={redDot}
          onClick={onClick}
        />
      )}
      {!outline && (
        <GlobalNavNonLineButton
          className={className}
          label={label}
          redDot={redDot}
          onClick={onClick}
        />
      )}
    </>
  );
}
