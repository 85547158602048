import { useCallback } from 'react';

const MOBILE_FLIGHT_MAIN_PATH = '/air/b2c/AIR/MBL/AIRMBLTRP0100100010.k1';

/**
 * @description 항공 관련 특수 로직은 useFlight 훅에서 관리한다.
 * */
export default function useFlight(isFlightMode: boolean) {
  /**
   * @description 모바일 환경의 항공홈에서 헤더 마운트 시, 항공 콘텐츠의 검색 컨테이너 상단 여백을 헤더 높이만큼 margin-top 주어 조정한다.
   */
  const initialMobileSearchContainerStyle = useCallback(() => {
    const isMobileFligthMainPage =
      isFlightMode && window.location.pathname === MOBILE_FLIGHT_MAIN_PATH;

    if (isMobileFligthMainPage) {
      const fligthSearchContainer = document.querySelector(
        '.k1_main_header_wrap',
      );

      // FIXME: 항공 개발팀에 의뢰하여 항공 측에서 스타일 처리 가능한지 확인 필요
      const baseStyle = fligthSearchContainer?.getAttribute('style') ?? '';
      const injectStyle = 'margin-top: -99px !important;';
      const mergedStyle = `${
        baseStyle.endsWith(';') ? baseStyle : `${baseStyle};`
      } ${injectStyle}`;

      fligthSearchContainer?.setAttribute('style', mergedStyle);
    }
  }, [isFlightMode]);

  return {
    initialMobileSearchContainerStyle,
  };
}
