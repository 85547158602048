import React, { Component } from 'react';
import axios from 'axios';
import Swiper from 'react-id-swiper';

import { Utils } from 'app/common';
import { Link } from 'app/components/share';
import 'app/styles/swiper.scss';
import './Banner.scss';

class MypageBanner extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      banners: [],
      isBeginning: true,
      isEnd: false,
    };
    this.cookieName = 'MRT-APP-VERSION';
  }

  componentDidMount = () => {
    this.getBannerInfo();
    this.setSwiperStatus();
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.listener);
  };

  setSwiperStatus = () => {
    this.setState({
      isBeginning: this.swiper && this.swiper.isBeginning,
      isEnd: this.swiper && this.swiper.isEnd,
    });
  };

  getBannerInfo = async () => {
    try {
      const response = await axios.get(
        `${process.env.API_V2_URL}/flight/contents/reservation_info`,
      );
      const { result, data } = response.data;

      if (result.status === 200) {
        const isWebview = this.checkWebview();
        this.desktops = [];
        this.mobiles = [];

        data.banners.forEach((banner) => {
          this.desktops.push({
            image: banner.desktop_image.original,
            link: banner.desktop_web_uri,
          });

          let mobileUri = banner.mobile_web_uri;
          if (isWebview) {
            mobileUri = isWebview === 'android' ? banner.android_deeplink : banner.ios_deeplink;
          }

          this.mobiles.push({
            image: banner.mobile_image.original,
            link: mobileUri,
          });
        });

        const width = window.innerWidth;
        const info = width >= 768 ? this.desktops : this.mobiles;
        this.setState({
          banners: [...info],
        });
        this.setSwiperStatus();
      }
    } catch (error) {
      console.log(error);
    }
  };

  checkWebview() {
    let isWebview = Utils.getCookie(this.cookieName);
    if (isWebview) {
      isWebview = isWebview.toLowerCase();

      if (isWebview.indexOf('android') > -1) {
        return 'android';
      }
      if (isWebview.indexOf('ios') > -1) {
        return 'ios';
      }
    }
    return false;
  }

  moveSlide = (type) => {
    this.swiper[`slide${type}`]();
    this.setSwiperStatus();
  };

  render() {
    const { banners, isBeginning, isEnd } = this.state;

    if (banners.length === 0) {
      return null;
    }

    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        renderBullet: (index, className) => `<span class="${className} swiper-page-dot"></span>`,
      },
      spaceBetween: 8,
      slidesPerView: 2,
      slidesPerGroup: 2,
      breakpoints: {
        991: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
      shouldSwiperUpdate: true,
      on: {
        slideChange: () => this.setSwiperStatus(),
      },
      autoplay: {
        delay: 5000,
      },
    };

    return (
      <div className="mrt-component">
        <div className="mrt-mypage-banner">
          <Swiper
            {...params}
            ref={(node) => {
              if (node) {
                this.swiper = node.swiper;
              }
            }}
          >
            {banners.map((banner) => (
              <div key={banner.image}>
                <Link key={banner.image} href={banner.link}>
                  <img className="banner-image" alt="" src={banner.image} />
                </Link>
              </div>
            ))}
          </Swiper>
          {!isBeginning && (
            <div
              className="swiper-button-prev swiper-arrow swiper-arrow--left"
              onClick={() => this.moveSlide('Prev')}
            />
          )}
          {!isEnd && (
            <div
              className="swiper-button-next swiper-arrow swiper-arrow--right"
              onClick={() => this.moveSlide('Next')}
            />
          )}
        </div>
      </div>
    );
  }
}

export default MypageBanner;
