/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import { css } from '@emotion/react';
import { Children, forwardRef, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import { GlobalSearch } from 'mrt-types';

import styled from '@emotion/styled';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';
import { helper } from '@myrealtrip/frontend-weblog';

import useSearchOffers from '../hooks/apis/useSearchOffers';
import { searchBanner } from '../recoil';
import useSearchManager from '../useSearchManager';
import sendSearchCombineLog from '../utils/sendSearchCombineLog';

const { sendClickLog, sendImpressionLog } = helper;

interface Props {
  data: GlobalSearch.Banner;
  isSelected: boolean;
}

const SuggestionKeywordItem = forwardRef<HTMLAnchorElement, Props>(({ data, isSelected }, ref) => {
  const { setRecentKeywords } = useSearchManager();
  const setSearchBanner = useSetRecoilState(searchBanner);
  const { data: response } = useSearchOffers();
  const { image, titles, subTitles = [], linkUrl, loggingMeta } = data;
  const { url } = image;
  const { clicks = [], impressions = [] } = loggingMeta ?? {};
  const [clickLog] = clicks;
  const [impressionLog] = impressions;
  const reducer = (prev: string, current: string) => prev + current;
  const searchWord = titles.map(({ text }) => text).reduce(reducer);
  const targetUrl =
    linkUrl || `${process.env.HOME_URL}/search-bridge?q=${encodeURIComponent(searchWord)}`;

  const sendLog = () => {
    try {
      if (response) {
        sendClickLog({
          screenName: WeblogScreenName.COMMON,
          pageCategory: WeblogPageCategory.COMMON,
          eventName: clickLog.eventName,
          ...clickLog.data,
        });
        sendSearchCombineLog({
          sections: response.sections,
          additionalLog: {
            searchWord,
            targetUrl,
          },
        });
      }
    } catch (e) {
      // ignore
    }
  };

  const handleClickItem = () => {
    sendLog();
    setRecentKeywords(data);
  };

  useEffect(() => {
    if (isSelected) {
      setSearchBanner(data);
    }
    if (impressionLog) {
      sendImpressionLog(
        {
          screenName: WeblogScreenName.COMMON,
          pageCategory: WeblogPageCategory.COMMON,
          eventName: impressionLog.eventName,
          ...impressionLog.data,
        },
        `suggestionKeywordItem_${searchWord}`,
      );
    }
  }, [data, impressionLog, isSelected, searchWord, setSearchBanner]);

  return (
    <Anchor
      id={searchWord}
      href={targetUrl}
      ref={ref}
      isSelected={isSelected}
      onClick={handleClickItem}
    >
      <Icon src={url} />
      <TitleContainer>
        {Children.toArray(titles.map(({ text, color }) => <Title color={color}>{text}</Title>))}
        {Children.toArray(
          subTitles.map(({ text, color }) => <SubTitle color={color}>{text}</SubTitle>),
        )}
      </TitleContainer>
    </Anchor>
  );
});

SuggestionKeywordItem.displayName = 'SuggestionKeywordItem';

export default SuggestionKeywordItem;

const Anchor = styled.a<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${globalVariables.gray_50};
    `};

  :hover {
    background-color: ${globalVariables.gray_50}; /* gray-50 */
  }
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 8px;
`;

const TitleContainer = styled.span`
  display: inline-block;
  max-width: 100%;
`;

const Title = styled.span<{ color?: string }>`
  font-size: 15px;
  color: ${({ color }) => color || globalVariables.black};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SubTitle = styled.span<{ color?: string }>`
  flex-shrink: 0;
  margin-left: 8px;
  font-size: 12px;
  color: ${({ color }) => color || globalVariables.black};
`;
