import { ResponseType } from 'mrt-apis';
import { mrtAxiosV2, MrtAxiosOptions, MrtAxiosV3ErrorOptions, mrtAxiosV3 } from 'mrt-libs';
import { AfterFuncPayload, OrderFormResponseData, SessionTimeResponseData } from 'mrt-types';

export interface OrderFormResponse extends ResponseType {
  readonly data: OrderFormResponseData;
}

/**
 * 견적서 id를 통한 주문서 생성
 * @GET
 * @interface OrderFormResponse
 * @function fetchOrderForm
 */

export const createOrderFormByEstimateProposalId = (options: MrtAxiosV3ErrorOptions) => {
  const { payload } = options.api || {};

  return mrtAxiosV3(options).post<OrderFormResponse>(
    `/order/v2/traveler/orders/order-form-proposal`,
    payload,
  );
};

export const afterCreateOrderFormByEstimateProposalId = (
  afterFuncPayload: AfterFuncPayload<OrderFormResponse>,
) => {
  const { data, isWebView, afterFuncPayload: payload } = afterFuncPayload;
  const { label } = payload;

  const orderFormId = data?.data.orderFormId;
  const url = encodeURIComponent(`${process.env.HOME_URL}/instants/order/${orderFormId}`);
  const title = encodeURIComponent(label);

  window.location.href = isWebView
    ? `mrt://checkout?url=${url}&title=${title}`
    : `/order/${orderFormId}`;
};

/**
 * 주문서 조회
 * @GET
 * @interface OrderFormResponse
 * @function fetchOrderForm
 */
export const fetchOrderForm = (orderFormId: string, options?: MrtAxiosOptions) => {
  const { isWebView = false } = options || {};

  return mrtAxiosV2({ isWebView }).get<OrderFormResponse>(
    `order/v2/traveler/orders/order-form/${orderFormId}`,
  );
};

/**
 * 주문서 조회(Guest)
 * @GET
 * @interface OrderFormResponse
 * @function fetchGuestOrderForm
 */
export const fetchGuestOrderForm = (orderNo: string, options?: MrtAxiosOptions) => {
  const { isWebView = false } = options || {};

  return mrtAxiosV2({ isWebView }).get<OrderFormResponse>(
    `order/guest/orders/order-form/${orderNo}`,
  );
};

/**
 * 주문서 조회(Flight)
 * @GET
 * @interface OrderFormResponse
 * @function fetchFlightOrderForm
 */
export const fetchFlightOrderForm = (orderNo: string, options?: MrtAxiosOptions) => {
  const { isWebView = false } = options || {};
  return mrtAxiosV2({ isWebView }).get<OrderFormResponse>(
    `/flight/api/payment/order-form/${orderNo}`,
  );
};

/* 주문서 세션 시간 조회
 * @GET
 * @interface SessionTimeRequest
 * @interface SessionTimeResponse
 * @function fetchSessionTime
 */

export interface SessionTimeResponse extends ResponseType {
  readonly data: SessionTimeResponseData;
}

export const fetchSessionTime = (orderFormId: string, options?: MrtAxiosOptions) => {
  const { isWebView = false } = options || {};

  return mrtAxiosV2({ isWebView }).get<SessionTimeResponse>(
    `order/v2/traveler/orders/order-form/${orderFormId}/session-time`,
  );
};

/**
 * 주문서 세션 시간 연장
 * @GET
 * @interface SessionTimeRequest
 * @interface SessionTimeResponse
 * @function extendSessionTime
 */

export const extendSessionTime = (orderFormId: string, options?: MrtAxiosOptions) => {
  const { isWebView = false } = options || {};

  return mrtAxiosV2({ isWebView }).put<SessionTimeResponse>(
    `order/v2/traveler/orders/order-form/${orderFormId}/session-time/refresh`,
  );
};
