import Constant from './constant';

export const setCookie = (name, value, exp) => {
  const date = new Date();
  date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
};

export const getCookie = (name) => {
  const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);

  return value ? value[2] : null;
};

export const createLink = (link) => {
  let href = null;

  if ((link === 'signIn' || link === 'signUp') && process.env.FLIGHT_MODE === 'YES') {
    const redirectUri = encodeURIComponent(window.location.href);
    href = `${Constant.link[link]}?return_to=${redirectUri}`;
  } else {
    href = Constant.link[link];
  }

  return href;
};

export const insertAfter = (ref, node) => {
  if (ref.nextSibling) {
    ref.parentNode.insertBefore(node, ref.nextSibling);
  } else {
    ref.parentNode.appendChild(node);
  }
};

export const debounce = (func, wait, immediate) => {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    }, wait);
    if (immediate && !timeout) {
      func.apply(context, args);
    }
  };
};

export const paramsToObj = () => {
  const params = window.location.search.substr(1).split('&');
  const paramsObj = {};

  params.forEach((param) => {
    const paramData = param.split('=');
    const paramKey = decodeURIComponent(paramData[0]);
    const paramValue = decodeURIComponent(paramData[1]);

    if (paramKey.indexOf('[]') >= 0) {
      const listKey = paramKey.substring(0, paramKey.indexOf('[]'));
      if (listKey in paramsObj) {
        paramsObj[listKey].push(paramValue);
      } else {
        paramsObj[listKey] = [paramValue];
      }
    } else {
      paramsObj[paramKey] = paramValue;
    }
  });

  if (!paramsObj.group_category) {
    paramsObj.group_category = 'experience';
  }

  return paramsObj;
};

export const objToParams = (obj) => {
  const keys = Object.keys(obj);

  const params = keys.reduce((accu, key) => {
    const value = obj[key];

    if (!value || !key) {
      return accu;
    }

    let query = accu ? `${accu}&` : '?';

    if (value instanceof Array) {
      const arrayValue = value.map(
        (v) => `${encodeURIComponent(`${key}[]`)}=${encodeURIComponent(v)}`,
      );
      query += `${arrayValue.join('&')}`;
    } else {
      query += `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }

    return query;
  }, '');

  return params;
};

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
