import React from 'react';

import PropTypes from 'prop-types';

import OfferCardSwiper from '../../../shared/Cards/OfferCardSwiper';
import { handleThemeClick } from '../../utils/jejuSendEvent';
import useRequest from '../../utils/useRequest';
import CrossSellJejuSection from './CrossSellJejuSection';

function CrossSellJujuSectionThemes(props) {
  const { isWebView, utm } = props;
  const { isLoading, data } = useRequest(
    `${process.env.API_V2_URL}/flight/cities/theme_offers?key_name=Jeju`,
  );
  if (isLoading) {
    return <CrossSellJejuSection isLoading />;
  }

  const themes = data && data.data && data.data.theme_offers;

  if (!themes || !themes?.length) {
    return null;
  }

  return themes.map((theme) => {
    const { id, title, subtitle, target_offers: offers } = theme;
    if (id === 877) {
      return null;
    }
    const eventLabel = isWebView ? title : id;

    return (
      <CrossSellJejuSection
        key={id}
        title={title}
        desc={subtitle}
        linkText="전체보기"
        linkPath={`https://www.myrealtrip.com/themes/${id}`}
        linkAction="클릭_테마섹션_전체보기버튼"
        deeplink={`mrt://offers?theme_id=${id}`}
        deeplinkAction="click_theme"
        eventLabel={eventLabel}
        {...props}
      >
        <OfferCardSwiper
          isWebView={isWebView}
          offers={offers}
          keyName={`cross-theme-${id}`}
          qs={utm}
          onClick={() => handleThemeClick(isWebView, isWebView ? id : title)}
          hideWishlist
          openNewTab
        />
      </CrossSellJejuSection>
    );
  });
}

CrossSellJujuSectionThemes.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isWebView: PropTypes.bool.isRequired,
  utm: PropTypes.string.isRequired,
};

export default CrossSellJujuSectionThemes;
