import { OndaPlaceType, OndaPlaceSearchType } from 'mrt-constants';

export const stayRouteEnum = Object.freeze({
  list: '/',
  location: '/location',
  rooms: '/rooms',
  images: '/images',
});

export const stayCategoryEnum = Object.freeze({
  ALL: {
    name: '전체',
  },
  HOTEL: {
    name: '호텔',
  },
  PENSION: {
    name: '펜션',
  },
  POOLVILLA: {
    name: '풀빌라',
  },
  CAMPING: {
    name: '캠핑',
  },
  GUESTHOUSE: {
    name: '게하',
  },
});

const stayCategoryCode = Object.keys(stayCategoryEnum);
const categoryCodeMap = stayCategoryCode.reduce(
  (map, key) => ({ ...map, [key]: key }),
  {},
);

export const stayCategoryCodeEnum = Object.freeze(categoryCodeMap);

export const stayFacilityEnum = Object.freeze({
  GOLF_FIELD: {
    name: '골프장',
    icon: 'ic_golf_28x28_gray_700',
  },
  PUBLIC_SHOWER: {
    name: '공용샤워실',
    icon: 'ic_shower_28x28_gray_700',
  },
  PUBLIC_SPA: {
    name: '공용스파',
    icon: 'ic_bathroom_28x28_gray_700',
  },
  PUBLIC_KITCHEN: {
    name: '공용주방',
    icon: 'ic_kitchen_28x28_gray_700',
  },
  PUBLIC_TOILET: {
    name: '공용화장실',
    icon: 'ic_toilet_28x28_gray_700',
  },
  KARAOKE: {
    name: '노래방',
    icon: 'ic_sing_28x28_gray_700',
  },
  BASKETBALL_FIELD: {
    name: '농구장',
    icon: 'ic_basketball_28x28_gray_700',
  },
  RESTAURANT: {
    name: '레스토랑',
    icon: 'ic_restaurant_28x28_gray_700',
  },
  ROOFTOPS: {
    name: '루프탑',
    icon: 'ic_rooftop_28x28_gray_700',
  },
  CONVENIENCE_STORE: {
    name: '매점/편의점',
    icon: 'ic_store_28x28_gray_700',
  },
  BARBEQUE_GRILLS: {
    name: '바베큐장',
    icon: 'ic_bbq_28x28_gray_700',
  },
  BUSINESS_CENTER: {
    name: '비즈니스센터',
    icon: 'ic_businessbag_28x28_gray_700',
  },
  SAUNA: {
    name: '사우나',
    icon: 'ic_sauna_28x28_gray_700',
  },
  SEMINAR_ROOMS: {
    name: '세미나실',
    icon: 'ic_seminar_28x28_gray_700',
  },
  SWIMMING_POOL: {
    name: '수영장',
    icon: 'ic_pool_28x28_gray_700',
  },
  SPA: {
    name: '스파/월풀',
    icon: 'ic_bathroom_28x28_gray_700',
  },
  BALLROOMS: {
    name: '연회장',
    icon: 'ic_bowtie_28x28_gray_700',
  },
  HOT_SPRING: {
    name: '온천',
    icon: 'ic_sauna_28x28_gray_700',
  },
  WATER_SLIDE: {
    name: '워터슬라이드',
    icon: 'ic_pool_28x28_gray_700',
  },
  WATERPARK: {
    name: '워터파크',
    icon: 'ic_pool_28x28_gray_700',
  },
  KINDER_ATTRACTIONS: {
    name: '유아시설',
    icon: 'ic_teddybear_28x28_gray_700',
  },
  JOKGU_JANG: {
    name: '족구장',
    icon: 'ic_soccerball_28x28_gray_700',
  },
  JJIMJIL_BANG: {
    name: '찜질방',
    icon: 'ic_sauna_28x28_gray_700',
  },
  FOOTBALL_FUTSAL_FIELD: {
    name: '축구/풋살장',
    icon: 'ic_soccerball_28x28_gray_700',
  },
  CAFE: {
    name: '카페',
    icon: 'ic_cafe_28x28_gray_700',
  },
  KIDS_PLAYROOM: {
    name: '키즈플레이룸',
    icon: 'ic_teddybear_28x28_gray_700',
  },
  TABLE_TENNIS: {
    name: '탁구장',
    icon: 'ic_pingpong_28x28_gray_700',
  },
  FITNESS: {
    name: '피트니스',
    icon: 'ic_gym_28x28_gray_700',
  },
});

export const stayServiceEnum = Object.freeze({
  ALLDAY_SERVICE: {
    name: '24시간 프론트',
    icon: 'ic_24front_28x28_gray_700',
  },
  PRIVATE_LOCKER: {
    name: '개인사물함',
    icon: 'ic_locker_28x28_gray_700',
  },
  SMOKING_ALLOWED: {
    name: '객실내흡연',
    icon: 'ic_smoking_28x28_gray_700',
  },
  AIRPORT_SHUTTLE: {
    name: '공항 셔틀',
    icon: 'ic_pickup_28x28_gray_700',
  },
  NO_SMOKING: {
    name: '금연',
    icon: 'ic_nosmoking_28x28_gray_700',
  },
  BASIC_SPICE: {
    name: '기본양념',
    icon: 'ic_sauce_28x28_gray_700',
  },
  ROOM_SERVICE: {
    name: '룸서비스',
    icon: 'ic_roomservice_28x28_gray_700',
  },
  MASSAGE: {
    name: '마사지',
    icon: 'ic_massage_28x28_gray_700',
  },
  FREE_PARKING: {
    name: '무료주차',
    icon: 'ic_parking_28x28_gray_700',
  },
  BAR: {
    name: '바/라운지',
    icon: 'ic_cocktail_28x28_gray_700',
  },
  PETS_ALLOWED: {
    name: '반려동물 동반가능',
    icon: 'ic_animal_28x28_gray_700',
  },
  VALET_PARKING: {
    name: '발렛파킹',
    icon: 'ic_parking_28x28_gray_700',
  },
  BOARD_GAMES: {
    name: '보드게임',
    icon: 'ic_boardgame_28x28_gray_700',
  },
  EMERGENCY_PHARMS: {
    name: '상비약',
    icon: 'ic_medicine_28x28_gray_700',
  },
  SHUTTLES: {
    name: '셔틀버스',
    icon: 'ic_pickup_28x28_gray_700',
  },
  MOVIES: {
    name: '영화관람',
    icon: 'ic_movie_28x28_gray_700',
  },
  BIKE_RENTAL: {
    name: '자전거대여',
    icon: 'ic_bicycle_28x28_gray_700',
  },
  BARRIER_FREE: {
    name: '장애인편의시설',
    icon: 'ic_wheelchair_28x28_gray_700',
  },
  BREAKFAST: {
    name: '조식서비스',
    icon: 'ic_breakfast_28x28_gray700',
  },
  PARKING_AVAILABLE: {
    name: '주차가능',
    icon: 'ic_parking_28x28_gray_700',
  },
  BAGGAGE_KEEPING: {
    name: '짐보관',
    icon: 'ic_baggage_28x28_gray_700',
  },
  COOKING_ALLOWED: {
    name: '취사가능',
    icon: 'ic_kitchen_28x28_gray_700',
  },
  CAMP_FIRES: {
    name: '캠프파이어',
    icon: 'ic_campfire_28x28_gray_700',
  },
  EVENT_AND_PARTIES: {
    name: '프로포즈/파티/이벤트',
    icon: 'ic_party_28x28_gray_700',
  },
  PRINTER: {
    name: '프린터 사용',
    icon: 'ic_print_28x28_gray_700',
  },
  PICK_UP: {
    name: '픽업',
    icon: 'ic_pickup_28x28_gray_700',
  },
  WIFI: {
    name: 'WIFI',
    icon: 'ic_wifi_28x28_gray_700',
  },
});

export const staySortEnum = Object.freeze({
  recommendation: 'WEIGHT_DESC',
  lowPrice: 'PRICE_ASC',
  highPrice: 'PRICE_DESC',
  review: 'REVIEW_DESC',
  rating: 'RATING_DESC',
});

export const staySortOptionMap = Object.freeze({
  [staySortEnum.recommendation]: '추천순',
  [staySortEnum.review]: '많은 후기순',
  [staySortEnum.rating]: '높은 평점순',
  [staySortEnum.lowPrice]: '낮은 가격순',
  [staySortEnum.highPrice]: '높은 가격순',
});

export const stayToolbarEnum = Object.freeze({
  list: 'list',
  map: 'map',
  filter: 'filter',
  sort: 'sort',
});

export const stayAppEventEnum = Object.freeze({
  sendEvent: 'sendEvent',
  searchHotel: 'search_hotel',
  viewHotelDetail: 'mrt_screen_view',
  clickMapView: 'click_mapview',
  applyFilter: 'apply_filter',
  clickPromotion: 'click_promo',
  selectHotel: 'select_hotel',
  clickImage: 'click_image',
  clickPrice: 'click_price',
  clickMorePrice: 'click_moreprice',
  clickMap: 'click_map',
  clickLandmark: 'click_landmark',
  clickFacility: 'click_facility',
  clickInformation: 'click_information',
  selectOffer: 'select_offer',
  selectMoreHotel: 'select_hotel_more',
});

// FIXME: app event 구현 시에 확장 검토
export const stayAppScreenEnum = Object.freeze({
  list: 'stay_list',
  filter: 'stay_filter',
  detail: 'stay_detail',
});

export const stayDetailSectionIdEnum = Object.freeze({
  top: 'StayDetail__top',
  price: 'StayDetail__price',
  information: 'StayDetail__information',
  review: 'StayDetail__review',
  recommendation: 'StayDetail__recommendation',
  bottom: 'StayDetail__bottom',
  introduction: 'StayDetail__introduction',
  guide: 'StayDetail__guide',
  regulation: 'StayDetail__regulation',
});

export const stayPlaceTypeEnum = Object.freeze({
  // 지역 태그 검색
  GEO: 'GEO',
  // 위경도 값으로 검색하는 경우 (5km 반경)
  POINT_OF_INTERESTS: 'POINT_OF_INTERESTS',
  // 장소 이름으로 검색하는 경우
  NAME: 'NAME',
  // 숙소명으로 검색하는 경우
  GID: 'GID',
  PREMISE_ID: 'PREMISE_ID', // depreciated
  // 마리트 및 구글 레이어 타입
  LAYER: 'LAYER',
  GPLACE: 'GPLACE',
});

export const stayCouponTypeEnum = Object.freeze({
  MRT: 'MRT', // 마리트 금액대별 쿠폰
  PUB: 'PUB', // 대국민숙박 쿠폰
  PRD: 'PRD', // 상품별쿠폰
  NONE: 'NONE', // 쿠폰 미적용
});

export const DEFAULT_STAY_MAX_CALENDAR_DATE = 90;

export const PENSION_POPULAR_PLACES = [
  {
    name: '제주도',
    countryCode: 'kr',
    latitude: null,
    longitude: null,
    gid: 0,
    additionalInformation: '대한민국',
    type: OndaPlaceType.GPLACE,
    placeId: 'ChIJRUDITFTjDDURMb8emNI2vGY',
    searchType: OndaPlaceSearchType.PLACE,
    label: '대한민국',
  },
  {
    name: '가평',
    countryCode: 'kr',
    latitude: null,
    longitude: null,
    gid: 0,
    additionalInformation: '대한민국 경기도',
    type: OndaPlaceType.GPLACE,
    placeId: 'ChIJp3mNK-ooYzURCQHqedpn6CU',
    searchType: OndaPlaceSearchType.PLACE,
    label: '대한민국 경기도',
  },
  {
    name: '강릉',
    countryCode: 'kr',
    latitude: null,
    longitude: null,
    gid: 0,
    additionalInformation: '대한민국 강원도',
    type: OndaPlaceType.GPLACE,
    placeId: 'ChIJWw9PleHlYTURRh09nFHGt4A',
    searchType: OndaPlaceSearchType.PLACE,
    label: '대한민국 강원도',
  },
  {
    name: '여수',
    countryCode: 'kr',
    latitude: null,
    longitude: null,
    gid: 0,
    additionalInformation: '대한민국 전라남도',
    type: OndaPlaceType.GPLACE,
    placeId: 'ChIJr6uLHx-UbTURi26I5drZAok',
    searchType: OndaPlaceSearchType.PLACE,
    label: '대한민국 전라남도',
  },
  {
    name: '경주',
    countryCode: 'kr',
    latitude: null,
    longitude: null,
    gid: 0,
    additionalInformation: '대한민국 경상북도',
    type: OndaPlaceType.GPLACE,
    placeId: 'ChIJHQMN-EZOZjURCcjQz-6WFTc',
    searchType: OndaPlaceSearchType.PLACE,
    label: '대한민국 경상북도',
  },
  {
    name: '속초',
    countryCode: 'kr',
    latitude: null,
    longitude: null,
    gid: 0,
    additionalInformation: '대한민국 강원도',
    type: OndaPlaceType.GPLACE,
    placeId: 'ChIJsT1we_S82F8RyD8ltFjA9Ho',
    searchType: OndaPlaceSearchType.PLACE,
    label: '대한민국 경상북도',
  },
];
