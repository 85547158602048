const header = {
  title: 'Help Individuals Experience the World, MyRealTrip',
  registGuide: 'Guide Registration',
  signInUp: 'Sign in and Sign up',
  signIn: 'Sign in',
  signUp: 'Sign up',
  manageProfile: 'My Profile',
  alarmCenter: 'Notification',
  message: 'Message',
  wishlist: 'Wishlist',
  myTrip: 'My Trip',
  invitationFriends: 'Invite friends',
  myMemberGrade: 'My Member Grade',
  helpChat: '1:1 Chat',
  signOut: 'Log out',
  profileManage: 'Profile Setting',
  home: 'Home',
  experience: 'Tour&Ticket',
  domestic: 'Domestic',
  flight: 'Flight',
  hotel: 'Accommodation',
  hotdeal: 'Package',
  checklist: 'Travel Services',
  coupons: 'Coupons',
  insurance: 'Travel Insurance',
  rentcar: 'Jeju RentCars',
  golf: 'Golf',
  searchTutorialTitle: 'Search smarter with MyRealTrip',
  searchTutorialContent:
    'Search anything from destination, ticket, transportation pass, to guided tours.',
  tutorialClose: ' Do not show this again',
  tutorialSearchNow: 'Search now',
  popularCity: 'Popular cities',
  notiMore: 'See more',
  allRegion: 'All destinations',
  partnerPage: 'Partner page',
  lodgingPartner: 'Korean lodging partner page',
  registerPartner: 'Partner registration',
  signInPartner: 'Sign in Partner ',
  affiliation: 'View B2B Reservations',
  emptyNotification1: 'No new notifications',
  emptyNotification2: 'Plan your own trip with us!',
  searchPlaceholder: 'Search by destination or activity',
  point: 'Points',
  coupon: 'Coupon',
  reservation: 'Reservations',
  notification: 'Notification',
  drawerButtonAlt: 'Check my points, coupons, profile, and reservation history',
  searchButtonAlt: 'Go to search',
  profilePhotoAlt: 'Profile Picture',
  defaultUsername: 'Member',
  notificationCloseAlt: 'Close this notification',
};

export default header;
