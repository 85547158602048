import React from 'react';

import PropTypes from 'prop-types';

import OfferCardSwiper from '../../../shared/Cards/OfferCardSwiper';
import { handleLodgingClick } from '../../utils/jejuSendEvent';
import section from '../../utils/section';
import useRequest from '../../utils/useRequest';
import CrossSellJejuSection from './CrossSellJejuSection';

function CrossSellJejuLodgings(props) {
  const { isWebView, utm } = props;
  const { isLoading, data } = useRequest(
    `${process.env.API_V2_URL}/flight/cities/lodging_offers?key_name=Jeju`,
  );

  const sectionData = section.lodgings;
  const offers = data && data.data && data.data.lodging_offers;

  if (!offers || !offers?.length) {
    return null;
  }

  return (
    <CrossSellJejuSection isLoading={isLoading} {...props} {...sectionData}>
      <OfferCardSwiper
        offers={offers}
        onClick={() => handleLodgingClick(isWebView)}
        keyName="cross-lodgings"
        hideWishlist
        openNewTab
        isWebView={isWebView}
        qs={utm}
      />
    </CrossSellJejuSection>
  );
}

CrossSellJejuLodgings.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isWebView: PropTypes.bool.isRequired,
  utm: PropTypes.string.isRequired,
};

export default CrossSellJejuLodgings;
