import { offerSectionEnum } from '../../enum';

const offer = {
  sideBar: {
    perOne: '/1',
    won: 'won',
    night: '/night',
    person: 'Person',
    perPerson: '/person',
    buy: 'Buy',
    reservation: 'Reservation',
    question: 'Get help',
    uncheckWishlist: 'Add to wishlist',
    checkWishlist: 'Added to wishlist',
    wishListDescription:
      '{{num}} people have added this product to their wishlist. ',
    takeTime: 'Duration',
    banner: 'Banner',
    instantBooking: 'Can be used immediately after purchase.',
    voucher: 'Mobile or print voucher',
    eTicket: 'e-ticket',
    delivery: 'Shipping ticket',
    startPrice: '~',
    minTravelers: 'Minimum number of departure {{count}}',
    coupon: 'Coupon discount',
  },
  category: {
    ticket: 'Select tickets',
    offer: 'Products',
    course: 'Course',
    require: 'Essential guidance',
    howToUse: 'How to use',
    review: 'Review',
    option: 'Select options',
  },
  review: {
    traveler: 'Traveler review image',
    star: 'Ratings',
    number: 'Review {{reviewCount}}',
    event: 'Review event',
    more: 'More reviews',
    allReview: 'See all customer reviews',
    reviewer: 'Reviewer',
    age: '{{age}}s',
    bannerTitle: 'Write a reveiw and get a discount coupon!',
    world:
      'Reviews from travelers around the world who have tried this product.',
    bannerContent:
      'All writers will receive a 5,000 won discount coupon and a 30,000 won discount coupon for the first review.',
  },
  description: {
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute',
    optionPeople: '{{num}}',
    option: 'option',
    personnel: 'personnel',
    offerNum: 'Product code: {{num}}',
    selectDatePeople: 'Select a date and {{option}} below.',
    selectDateText: 'Seltect a date.',
    selectOptionText: 'Select a {{option}}.',
    questionPrice: 'What is the Veiw amount?',
    selectDate: 'Select date',
    selectOption: 'Select {{detailOption}}',
    getPrice: 'Veiw amount',
    updatePrice: 'Update price',
    include: 'Included',
    exclude: 'Excluded',
    meetingTime: 'Meeting time',
    meetingPoint: 'Meeting point',
    showMapDetail: 'Show map',
    hideMapDetail: 'Hide map',
    totalPrice: 'Total price',
    zoom: 'Zoom in',
    flexible: 'Flexible',
    showReview: 'Show all reviews',
    ask: 'Ask',
    bestPurpose: 'Best selling',
    priceTable: 'Price tag',
    phone: 'Contact: {{contacts}}',
    email: 'E-mail: {{email}}',
    kakao: 'Kakao talk ID: {{kakao}}',
    recommendOffer: 'Travelers who viewed this product also viewed',
    recommendHotel: '{{city}} Recommend Hotel',
  },
  transport: {
    car: 'Car',
    walk: 'Walk',
  },
  calendar: {
    info:
      ' Select a travel date. (However, please submit a date of use for a e-ticket,and a pick up date for a received actual product.',
    lodgingInfo: 'Select check-in and check-out dates',
  },
  purpose: {
    alone: 'A trip alone',
    friends: 'A trip with friends',
    lover: 'A trip with a lover',
    honeymoon: 'Honeymoon travel',
    partner: 'A trip with a spouse',
    parents: 'A trip with parents',
    children: 'A trip with children',
    official: 'A business trip',
    etc: 'Trip',
  },
  cancelRefund: {
    title: 'Cancellation and Refund regulation',
    detail: 'Detail',
  },
  realGuide: {
    title: 'A Real Partner you can trust',
    content:
      'This is an exemplary partner who has maintained an average rating of 4.8+ over the past year.',
  },
  guarantee: {
    title: 'Best price guarantee',
    detail: 'Detail',
    content:
      'If it is not the lowest price, MyRealTrip will refund you twice the difference.',
  },
  course: {
    title: 'Course',
    takeTime: 'Duration {{time}}{{unit}}',
    more: 'More course',
  },
  locale: {
    ko: 'Korean, ',
    en: 'English, ',
    es: 'Spanish, ',
    zh: 'Chinese, ',
    fr: 'French, ',
    de: 'German, ',
    zhCN: 'Chinese (Simplified), ',
    zhTW: 'Chinese (Traditional), ',
    ja: 'Japanese, ',
    etc: 'Etc',
  },
  special: {
    freeRefund: 'Free Refund',
    priceGuarantee: 'Best Price Guarantee',
    ignoreWaitConfirm: 'Instant Booking',
    happycall: 'Happy call',
    needLogin:
      'You can download it after Log-in.\nClick the Confirm button to go to the login page.',
  },
  duration: {
    hour: 'hour',
    day: 'day',
    minute: 'minute',
  },
  coupon: {
    idle: 'Get {{title}}',
    success: '{{title}} downloaded',
    error: `Didn't receive the coupon. please try again.`,
  },
  options: {
    total: 'Total Price',
    payment: 'Purchase',
    selectTicket: 'Selct Tickets',
    paymentWithPrice: 'Pay {{price}}',
    alertTicket: 'Please select tickets.',
    error: 'Ticket information could not be loaded.\nPlease try again later.',
    selectDate: 'Please select date',
    selectOption: 'Please select options',
    selectPerson: 'Please select number of people',
  },
  section: {
    [offerSectionEnum.ticket]: 'Select tickets',
    [offerSectionEnum.introduction]: 'Products',
    [offerSectionEnum.detail]: 'Information',
    [offerSectionEnum.course]: 'Course',
    [offerSectionEnum.require]: 'Essential guidance',
    [offerSectionEnum.guidance]: 'How to use',
    [offerSectionEnum.refund]: 'Refund',
    [offerSectionEnum.review]: 'Review',
  },
  time: {
    hour: '{{time}}h',
    minute: '{{time}}min',
    day: '{{time}}day',
  },
  meta: {
    eticket: 'e-ticket',
    voucher: 'Mobile or print voucher',
    delivery: 'Delivery ticket',
    expired: 'Use within {{date}}',
    privateTour: 'Private tour',
    groupTour: 'Group tour',
    car: 'Move car',
    walk: 'Walking',
    duration: '{{time}}',
    flexible: 'Time adjustable',
    minTravelers: '(Min travelers: {{value}})',
  },
  refund: {
    title: 'Buy with confidence!',
    content:
      'Regardless of the expiration date, unused tickets are <strong>100% refund</strong>.',
  },
  info: {
    price: 'Price per person',
    include: 'Includes',
    exclude: 'Excludes',
  },
  use: {
    subtitle: {
      tour: {
        time: 'Meeting time',
        place: 'Meeting place',
        guide: 'Essential guidance',
      },
      other: {
        time: 'Use time',
        place: 'Location guide',
        guide: 'How to use',
      },
    },
    addInfo: 'Additional information',
  },
  more: 'more',
  detail: 'Details more',
  collapse: 'collapse',
  reviewPhotos: 'Photos within reviews of traveler',
};
export default offer;
