/* eslint-disable @typescript-eslint/naming-convention */

import { css } from '@emotion/react';

import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import media from 'mrt-utils/mediaQuery';

import styled from '@emotion/styled';
import { HeaderTheme, HeaderThemeType } from '@layout/constants/header';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';
import { helper } from '@myrealtrip/frontend-weblog';

const { sendClickLog } = helper;

type Props = {
  label: string;
  link: string;
  showIcon?: boolean;
  showNewBadge?: boolean;
  isActive?: boolean;
  iconUrl?: string;
  badge?: {
    badgeName: string;
    badgeStyle: 'solid' | 'outline';
  };
  onClick?: () => void;
};

export default function LocalNavItem({
  label,
  link,
  showIcon,
  isActive,
  iconUrl,
  badge,
  showNewBadge,
  onClick,
}: Props) {
  const { theme } = useLayoutHeaderContext();
  const canShowCmsIcon = showIcon && iconUrl;
  const canShowCmsBadge = badge && badge.badgeName;
  const canShowNewBadge = !badge && showNewBadge;

  const { badgeName, badgeStyle } = badge ?? {};

  const handleClick = () => {
    sendClickLog({
      eventName: 'gnb',
      screenName: WeblogScreenName.COMMON,
      pageCategory: WeblogPageCategory.COMMON,
      itemKind: 'menu',
      itemName: label,
      targetUrl: link,
    });

    onClick?.();
  };

  return (
    <Container colorTheme={theme} isActive={isActive}>
      <Anchor href={link} onClick={handleClick}>
        {canShowCmsIcon && <CmsIcon src={iconUrl} alt="" />}
        <Title>{label}</Title>
      </Anchor>
      {canShowCmsBadge && <Badge outline={badgeStyle === 'outline'}>{badgeName}</Badge>}
      {canShowNewBadge && <NewBadge name="ic-badge-new" />}
    </Container>
  );
}

const getContainerTheme = ({
  isActive,
  colorTheme,
}: {
  isActive?: boolean;
  colorTheme: HeaderThemeType;
}) => {
  return {
    [HeaderTheme.blue]: css`
      * {
        color: ${globalVariables.white};
      }

      &:after {
        content: '';
        opacity: ${isActive ? '1;' : '0;'};
        visibility: ${isActive ? 'visible;' : 'hidden;'};
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        background-color: ${globalVariables.white};
        transition: opacity 0.3s ease;
      }

      &:hover {
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
    `,
    [HeaderTheme.white]: css`
      * {
        color: ${globalVariables.gray_700};
      }

      &:after {
        content: '';
        opacity: ${isActive ? '1;' : '0;'};
        visibility: ${isActive ? 'visible;' : 'hidden;'};
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        background-color: ${globalVariables.blue_300};
        transition: opacity 0.3s ease;
      }

      &:hover {
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
    `,
    [HeaderTheme.transparent]: css`
      * {
        color: ${globalVariables.white};
      }

      &:after {
        content: '';
        opacity: ${isActive ? '1;' : '0;'};
        opacity: ${isActive ? 'visible;' : 'hidden;'};
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        background-color: ${globalVariables.white};
        transition: opacity 0.3s ease;
      }

      &:hover {
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
    `,
  }[colorTheme];
};

const Container = styled.div<{
  colorTheme: HeaderThemeType;
  isActive?: boolean;
}>`
  position: relative;
  display: flex;
  margin: 0 14px;

  ${({ isActive, colorTheme }) => getContainerTheme({ isActive, colorTheme })};

  &:first-of-type {
    margin-left: 0;
  }

  ${media.mobile} {
    height: 100%;

    &:first-of-type {
      margin-left: 12px;
    }
  }
`;

const Anchor = styled.a`
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${media.mobile} {
    white-space: normal;
  }
`;

const CmsIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;
const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${globalVariables.gray_700};
`;
const Badge = styled.span<{ outline?: boolean }>`
  position: absolute;
  right: 0;
  top: -6px;
  padding: 2px 5px;
  border-radius: 9px;
  border: 1px solid ${globalVariables.red_400};
  background-color: ${({ outline }) =>
    outline ? `${globalVariables.white};` : `${globalVariables.red_400};`};
  font-size: 9px;
  font-weight: 700;
  line-height: 12px;
  color: ${({ outline }) =>
    outline ? `${globalVariables.red_400};` : `${globalVariables.white};`};
  letter-spacing: -0.4px;
  white-space: nowrap;
`;
const NewBadge = styled(Icon)`
  position: absolute;
  right: -10px;
  top: 4px;

  ${media.mobile} {
    right: -2px;
  }
`;
