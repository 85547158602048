import { AxiosError } from 'axios';
import {
  MrtAxiosException as IMrtAxiosException,
  ResponseType,
  Result,
} from 'mrt-apis';

export type MrtAxiosExceptionParam = Result & {
  isMrtError: boolean;
  error: Error | AxiosError<ResponseType>;
  stack?: string;
};

export class MrtAxiosException extends Error implements IMrtAxiosException {
  isMrtError: boolean;

  error: Error | AxiosError<ResponseType>;

  status: number;

  code: string;

  message: string;

  constructor({
    isMrtError,
    error,
    status,
    code,
    message,
    stack,
  }: MrtAxiosExceptionParam) {
    super(message || error.message);

    this.name = 'MrtAxiosException';
    this.message = message;
    this.stack = stack;
    this.isMrtError = isMrtError;
    this.status = status;
    this.code = code;
    this.error = error;
  }
}
