const offerList = {
  landmarkCount: '{{count}}of products',
  lowPrice: 'Best Price Guarantee',
  reviewEvent: 'Review event',
  filter: {
    filter: 'Filter',
    use: 'Use immediately',
    tag: 'Tag products',
    date: 'Date',
  },
  order: {
    popular: 'Popular',
    reviews: 'Reveiws',
    price: 'Price : Hight to Low',
    recent: 'Newest',
  },
  guideType: {
    all: 'All',
    group: 'Group tour',
    private: 'Private tour',
  },
  empty: {
    filter: {
      label: 'There are no trips\navailable for your reservation',
      desc: 'Please reseet the filter and try again.',
    },
    list: {
      label: 'No product',
      desc: 'Explore other travel items.',
    },
  },
  offerTag: 'Product tag',
  applyFilter: 'Apply filter',
  initFilter: 'Reset filter',
  nowUseDetail: 'Learn more',
  nowUseDesc: 'Can be used immediately after purchase.',
  selectTag: 'Please select your product tag.',
  duration: {
    minute: 'take {{value}}minutes',
    hour: 'take {{value}}hours',
    day: 'take {{value}}days',
    flexible: 'Flexible',
  },
};

export default offerList;
