import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import grayIcon from '../../images/icons/ic-close-md-gray-500.svg';
import whiteIcon from '../../images/icons/ic-close-md-white.svg';
import styles from './CloseButton.module.scss';
import { sizeEnum } from '../../enum';

function CloseButton(props) {
  const { white, size, onClick } = props;
  const containerClass = classNames(styles.container, styles[size]);

  return (
    <button type="button" className={containerClass} onClick={onClick}>
      <img src={white ? whiteIcon : grayIcon} alt="close" />
    </button>
  );
}

CloseButton.propTypes = {
  /** 버튼 클릭 이벤트 */
  onClick: PropTypes.func.isRequired,
  /** 흰색 버튼  */
  white: PropTypes.bool,
  /** Size */
  size: PropTypes.oneOf([sizeEnum.small, sizeEnum.medium]),
};

CloseButton.defaultProps = {
  white: false,
  size: sizeEnum.medium,
};

export default CloseButton;
