import { useEffect, useState } from 'react';

function useRemote(importer: Promise<unknown>) {
  const [isLoad, setIsLoad] = useState(false);
  useEffect(() => {
    importer.then(() => {
      setIsLoad(true);
    });
  }, [importer]);

  return {
    isLoad,
  };
}

export default useRemote;
