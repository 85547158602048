const about = {
  menu: {
    about: 'About Us',
    recruit: 'Recruit',
    blog: 'Tech blog',
  },
  subtitle: {
    prefix: 'MyRealTrip is the first, the biggest and the best',
    suffix: 'free travel platform in Korea',
  },
  about: {
    title: 'We help travelers experience the world based on their tastes.',
    description:
      'MyRealTrip is the best free travel platform where you can make reservation for flight tickets, accommodations, tour&ticket and special deal travel. MyRealTrip helps travelers travel based on their tastes and does its best to provide more valuable experience to travelers.',
    histories: [
      {
        key: 'tour',
        year: '2012',
        content: 'MyRealTrip released\nGuided tour released',
      },
      {
        key: 'ticket',
        year: '2014',
        content: 'Ticket & Pass released',
      },
      {
        key: 'hotel',
        year: '2016',
        content: 'Real-time hotel booking service launched',
      },
      {
        key: 'airtel',
        year: '2017',
        content: 'Special deal travel & Lodging luanched',
      },
      {
        key: 'flight',
        year: '2018',
        content: 'Flight ticket booking service launched',
      },
      {
        key: 'package',
        year: '2019',
        content: 'Package travel service launched',
      },
      {
        key: 'lantour',
        year: '2020',
        content: 'Virtual Tour service launched',
      },
    ],
  },
  current: {
    title: 'We are growing fast \nas the best free travel platform of Korea.',
    description:
      'Started in 2012, MyRealTrip is growing fast. MyRealTrip has provided service to over {{count}} thousand travelers, which is the fastest growth in the travel industry.',
    traveler: {
      title: 'The cumulative number of travelers',
      content:
        'Until now, over {{count}}thousand travelers experienced the world through MyRealTrip',
    },
    offer: {
      title: 'The number of tour & ticket',
      content: 'About {{count}} products are selling in {{city}} cities around the world.',
    },
    review: {
      title: 'The cumulative number of reviews',
      content: 'Over {{count}} thousand travlers wrote reviews about products of MyRealTrip.',
    },
  },
  press: 'Press',
  message: 'We are looking for colleagues \nwho will innovate travel industry with MyRealTrip.',
  detail: 'Learn detail',
  contact: {
    title: `Don't hesitate \nto contact us.`,
    direction: 'Direction',
    address:
      'MyRealTrip Inc., 18th floor, Dreamplus Gangnam, 311, Gangnam-daero, Seocho-gu, Seoul, Republic of Korea',
    googleMap: 'Naver Map',
    business: 'B2B affiliate',
    marketing: 'Marketing affiliate',
  },
};

export default about;
