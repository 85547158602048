/* eslint-disable @typescript-eslint/naming-convention */

import moment from 'moment';

import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';

import styled from '@emotion/styled';
import useMarkAsReadNotification from '@layout/app/hooks/useMarkAsReadNotification';
import { NotificationItem as NotificationItemType } from '@layout/constants/notification';
import { resetButton } from '@layout/styles/reset';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';
import { helper } from '@myrealtrip/frontend-weblog';

const { sendClickLog } = helper;

type Props = {
  item: NotificationItemType;
};

export default function NotificationItem({ item }: Props) {
  const { title, created_at, message, id, web_link, unread, fresh } = item;
  const convertCreatedAt = moment(created_at * 1000).format('YYYY-MM-DD');
  const { mutate } = useMarkAsReadNotification();

  const handleClick = () => {
    try {
      sendClickLog({
        eventName: 'notification_item',
        screenName: WeblogScreenName.COMMON,
        pageCategory: WeblogPageCategory.COMMON,
        itemKind: 'notification',
        itemId: id,
        itemName: title,
        targetUrl: web_link,
      });

      mutate(id);
    } catch (error) {
      // ignore
    }

    window.location.href = web_link;
  };

  return (
    <Container fresh={fresh}>
      <Anchor type="button" onClick={handleClick}>
        <Header>
          <Title>
            {title}
            {unread && <UnreadDot />}
          </Title>
          <Date>{convertCreatedAt}</Date>
        </Header>
        <Content>{message}</Content>
      </Anchor>
    </Container>
  );
}

const Container = styled.div<{ fresh: boolean }>`
  position: relative;
  min-height: 87px;
  padding: 10px 15px 15px;
  background-color: ${({ fresh }) => (fresh ? 'rgba(100, 181, 246, 0.1)' : globalVariables.white)};

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    display: block;
    width: calc(100% - 30px);
    height: 1px;
    transform: translateX(-50%);
    background: ${globalVariables.gray_100};
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }
`;

const Anchor = styled.button`
  ${resetButton};

  display: block;
  width: 100%;
  min-height: inherit;
`;

const Header = styled.span`
  display: flex;
  justify-content: space-between;
`;
const Title = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${globalVariables.blue_300};
`;
const UnreadDot = styled.span`
  display: inline-block;
  height: 6px;
  width: 6px;
  margin-left: 5px;
  border-radius: 3px;
  background-color: ${globalVariables.blue_200};
`;
const Date = styled.span`
  font-size: 12px;
  color: ${globalVariables.gray_500};
`;
const Content = styled.p`
  font-size: 14px;
  color: ${globalVariables.gray_700};
  text-align: left;
`;
