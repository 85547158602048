import useWindowSize from './useWindowSize';
import { breakpointEnum } from '../enum';

function useResponsive() {
  const { width } = useWindowSize();

  return {
    isMobile: width < breakpointEnum.phone,
    isDesktop: width >= breakpointEnum.phone,
  };
}

export default useResponsive;
