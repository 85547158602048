import axios, { CancelToken } from 'axios';
import moment from 'moment';
import { HotelPlaceType } from 'mrt-constants';
import { hotelFacilityEnum, roomAmenityEnum, hotelAgencyEnum } from '../enum';

const httpClient = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
  timeout: 30000,
});

// TODO: hotels-api는 종료된 상태로 관련 코드 삭제 필요
const searchApiClient = axios.create({
  baseURL: process.env.HOTELS_API_URL,
  withCredentials: true,
  timeout: 30000,
});

const otaOrder = Object.freeze({
  [hotelAgencyEnum.booking]: 1,
  [hotelAgencyEnum.agoda]: 2,
  [hotelAgencyEnum.expedia]: 3,
});

function generateCancelTokenSource() {
  return CancelToken.source();
}

function toRoomShape(room, facilities = []) {
  const {
    name,
    hotelAgencyKind,
    isFreeCancelable,
    pricePerNight,
    totalPrice,
    benefitPrice,
    afterBenefitTotalPrice,
    ...otherFields
  } = room; // totalPrice(최저가) - benefitPrice(추가할인) = afterBenefitTotalPrice(N박 총 금액)
  const id = `${hotelAgencyKind}_${name}_${totalPrice}`;
  const amenities = [];
  const policies = [];

  // 호텔 부대시설을 전체 객실에 반영
  if (facilities.includes(roomAmenityEnum.freeBreakfast)) {
    amenities.push(roomAmenityEnum.freeBreakfast);
  }

  if (facilities.includes(roomAmenityEnum.freeWifi)) {
    amenities.push(roomAmenityEnum.freeWifi);
  }

  if (isFreeCancelable) {
    policies.push(roomAmenityEnum.freeCancellation);
  }

  return {
    ...otherFields,
    id,
    name,
    hotelAgencyKind,
    pricePerNight,
    totalPrice,
    benefitPrice,
    afterBenefitTotalPrice,
    amenities,
    policies,
  };
}

function orderByAfterBenefitTotalPrice(roomA = {}, roomB = {}) {
  if (roomA.afterBenefitTotalPrice === roomB.afterBenefitTotalPrice) {
    return otaOrder[roomA.hotelAgencyKind] - otaOrder[roomB.hotelAgencyKind];
  }

  return roomA.afterBenefitTotalPrice - roomB.afterBenefitTotalPrice;
}

function findMinimumPriceRoom(rooms = []) {
  return rooms[0] || {};
}

function toUniqueArray(items = []) {
  const uniqueItems = [];

  items.forEach((item) => {
    if (!uniqueItems.includes(item)) {
      uniqueItems.push(item);
    }
  });

  return uniqueItems;
}

function toUniqueArrayById(items = []) {
  const uniqueItems = [];

  items.forEach((item) => {
    const isDuplicateItem = uniqueItems.some((uniqueItem) => uniqueItem.id === item.id);

    if (isDuplicateItem) {
      return;
    }

    uniqueItems.push(item);
  });

  return uniqueItems;
}

function mergeFrontDeskCategories(categories = []) {
  const frontDeskCategories = [
    hotelFacilityEnum.reception,
    hotelFacilityEnum.frontDesk24,
    hotelFacilityEnum.frontDesk,
  ];
  const mergedCategories = [];
  let isExists = false;

  categories.forEach((category) => {
    if (!frontDeskCategories.includes(category)) {
      mergedCategories.push(category);

      return;
    }

    if (!isExists) {
      isExists = true;
      mergedCategories.push(category);
    }
  });

  return mergedCategories;
}

function toSortedImageUrls(images = []) {
  const mainImageIndex = images.findIndex((image) => image.isMain);

  if (mainImageIndex === -1) {
    return images.map((image) => image.url);
  }

  const restImages = images.slice();

  restImages.splice(mainImageIndex, 1);

  const sortedImages = [images[mainImageIndex], ...restImages];

  return sortedImages.map((image) => image.url);
}

function toReviewShape(totalCount, reviewItems, categories) {
  const handledTotalCount = totalCount || 0;
  const handledReviewItems = reviewItems || [];
  const handledCategories = categories || [];
  const summaryIndex = categories.findIndex((category) => category.questionLiteral === 'total');
  const hasSummary = summaryIndex !== -1;
  const defaultSummaryShape = {
    score: 0,
    scoreDescription: '',
  };
  const handledSummary = hasSummary
    ? {
        score: categories[summaryIndex].score,
        scoreDescription: categories[summaryIndex].reviewScoreWord,
      }
    : defaultSummaryShape;
  const filteredCategories = hasSummary
    ? [
        ...handledCategories.slice(0, summaryIndex),
        ...handledCategories.slice(summaryIndex + 1, handledCategories.length),
      ]
    : handledCategories;
  const mappedCategories = filteredCategories.map((category) => ({
    id: category.questionLiteral,
    name: category.question,
    score: category.score,
  }));
  const mappedReviewItems = handledReviewItems
    .sort((a, b) => b.createdAt - a.createdAt)
    .map((reviewItem) => {
      const {
        id,
        name,
        averageScore: score,
        country,
        createdAt,
        authorType,
        headline,
        pros,
        cons,
      } = reviewItem;

      return {
        id: id || `${name}_${score}_${createdAt}`,
        author: name,
        type: authorType || '',
        country,
        date: moment(createdAt).format('YYYY-MM-DD'),
        score,
        headline: headline || '',
        pros,
        cons,
      };
    });

  return {
    totalCount: handledTotalCount,
    summary: handledSummary,
    categories: mappedCategories,
    items: mappedReviewItems,
  };
}

export function toHotelItemShape(rawItem) {
  const {
    korName: name,
    hotelTeaser: introduction,
    distance: distanceInKm,
    star: numberOfStars,
    rooms,
    images,
    facilities,
    bookingDotComReviews,
    bookingDotComReviewScores,
    numberOfReviews,
    // 제거될 API response field
    // return 시 덮어씌워지는 것을 방지하기 위한 처리
    review: _,
    ...otherFields
  } = rawItem;
  const imageUrls = toSortedImageUrls(images);
  const uniqueFacilities = mergeFrontDeskCategories(toUniqueArray(facilities));
  const handledRooms = rooms || [];
  const refinedRooms = handledRooms
    .map((room) => toRoomShape(room, uniqueFacilities))
    .sort(orderByAfterBenefitTotalPrice);
  const filteredRooms = toUniqueArrayById(refinedRooms);
  const minimumPriceRoom = findMinimumPriceRoom(filteredRooms);
  const reviewWithFallback = toReviewShape(
    numberOfReviews,
    bookingDotComReviews,
    bookingDotComReviewScores,
  );

  return {
    name,
    introduction,
    distance: distanceInKm * 1000,
    numberOfStars,
    review: reviewWithFallback,
    rooms: filteredRooms,
    minimumPriceRoom,
    imageUrls,
    facilities: uniqueFacilities,
    isRefreshing: false,
    ...otherFields,
  };
}

function toHotelLandmarkShape(rawLandmark) {
  const { id, locale_names: localeNames, lat, lng, distance } = rawLandmark;
  const { ko: name, en: engName } = localeNames;

  return {
    id,
    name,
    engName,
    latitude: lat,
    longitude: lng,
    distance,
  };
}

function toPlaceSuggestionShape(rawItem) {
  const {
    name,
    country,
    country_name: countryName,
    city_name: cityName,
    region,
    hotel_id: hotelId,
    latitude,
    longitude,
    type,
  } = rawItem;

  const newSuggestion = {
    id: `${name}_${latitude}_${longitude}`,
    name,
    country,
    countryName,
    region,
    cityName,
    placeName: null,
    hotelId,
    latitude,
    longitude,
    type,
    suggestionType: HotelPlaceType.CITY,
    countryCode: country,
    label: '',
  };

  switch (type) {
    case 'city':
      newSuggestion.cityName = name;
      newSuggestion.suggestionType = HotelPlaceType.CITY;
      newSuggestion.label = `${cityName || ''}`;
      break;
    case 'country':
      newSuggestion.countryName = name;
      newSuggestion.suggestionType = HotelPlaceType.COUNTRY;
      newSuggestion.label = `${countryName || ''}`;
      break;
    default:
      newSuggestion.placeName = name;
      newSuggestion.suggestionType = HotelPlaceType.PLACE;
      newSuggestion.label = [name, cityName, countryName].filter(Boolean).join(', ');
  }

  return newSuggestion;
}

let cancelTokenSource = null;

export async function list(params = {}) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel();
  }

  cancelTokenSource = generateCancelTokenSource();

  const response = await searchApiClient.get('/v1/hotels', {
    params,
    cancelToken: cancelTokenSource.token,
  });

  if (response.status === 204) {
    return {
      totalCount: 0,
      hotels: [],
      minPrice: 0,
      maxPrice: 0,
    };
  }

  const { totalCount, hotels: rawHotels, minPrice, maxPrice, themes } = response.data;
  const hotels = (toUniqueArrayById(rawHotels) || []).map(toHotelItemShape);
  const handledTotalCount = totalCount || 0;
  const handledMinPrice = minPrice || 0;
  const handledMaxPrice = maxPrice || 0;

  return {
    totalCount: handledTotalCount,
    hotels,
    minPrice: handledMinPrice,
    maxPrice: handledMaxPrice,
    themes: themes.availableThemes,
  };
}

export async function staticList(params = {}) {
  const response = await searchApiClient.get('/v1/hotels/skel', {
    params,
  });

  if (response.status === 204) {
    return {
      totalCount: 0,
      hotels: [],
      minPrice: 0,
      maxPrice: 0,
    };
  }

  const { totalCount, hotels: rawHotels, minPrice, maxPrice } = response.data;
  const hotels = (toUniqueArrayById(rawHotels) || []).map(toHotelItemShape);
  const refreshingHotels = hotels.map((hotel) => ({
    ...hotel,
    isRefreshing: true,
  }));
  const handledTotalCount = totalCount || 0;
  const handledMinPrice = minPrice || 0;
  const handledMaxPrice = maxPrice || 0;

  return {
    totalCount: handledTotalCount,
    hotels: refreshingHotels,
    minPrice: handledMinPrice,
    maxPrice: handledMaxPrice,
  };
}

export async function byId(hotelId, params, cancelToken) {
  const response = await searchApiClient.get(`/v1/hotels/${hotelId}`, {
    params,
    cancelToken,
  });

  if (response.status === 204) {
    throw new Error('Cannot retrieve hotel information');
  }

  const rawHotel = response.data || {};

  return toHotelItemShape(rawHotel);
}

export async function suggest(keyword) {
  const response = await searchApiClient.get('/v1/autocomplete', {
    params: { text: keyword },
  });
  const queries = response.data;

  return queries.map(toPlaceSuggestionShape);
}

export async function landmarks(params = {}) {
  const { latitude, longitude, count = 10 } = params;
  const response = await httpClient.get(`/accommodations/hotels/landmarks`, {
    params: {
      latitude,
      longitude,
      count,
    },
  });
  const rawLandmarks = response.data;

  return rawLandmarks.map(toHotelLandmarkShape);
}

export async function nearByHotels(hotelId, rawParams = {}) {
  const { city, latitude, longitude, countryCode } = rawParams;
  const params = {
    'place.cityName': city,
    'place.type': 'CITY',
    'place.latitude': latitude,
    'place.longitude': longitude,
    'place.countryCode': countryCode,
  };
  const response = await searchApiClient.get('/v1/hotels/featured', { params });

  if (response.status === 204) {
    return [];
  }

  const { hotels } = response.data;
  const filteredHotels = hotels.filter((hotel) => hotel.id !== hotelId);
  const sortedHotels = filteredHotels.sort((hotelA, hotelB) => hotelB.weight - hotelA.weight);
  const limitedHotels = sortedHotels.slice(0, 12);

  return limitedHotels.map(toHotelItemShape);
}

export async function nearByOffers(hotelId, params = {}) {
  const response = await httpClient.get(`/accommodations/hotels/id/${hotelId}/nearby/offers`, {
    params,
  });

  return response.data;
}

export async function featured(params) {
  try {
    const response = await searchApiClient.get('/v1/hotels/featured', {
      params,
    });

    if (response.status === 204) {
      return [];
    }

    const { hotels: rawHotels } = response.data;

    return (toUniqueArrayById(rawHotels) || []).map(toHotelItemShape);
  } catch (error) {
    return [];
  }
}
