import React, { PureComponent } from 'react';

import { number, string, oneOfType } from 'prop-types';

import './SkeletonLoader.scss';

class SkeletonLoader extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      boxShadow: null,
    };
  }

  componentDidMount() {
    if (this.container) {
      this.computeBoxShadow();
    }
  }

  computeBoxShadow() {
    const { width } = this.props;

    const barWidth = parseInt(width || this.container.clientWidth, 10) * 0.2;
    const boxShadow = `0 0 ${barWidth}px ${barWidth}px #e9ecef`;

    this.setState({
      boxShadow,
    });
  }

  render() {
    const { boxShadow } = this.state;
    const { className, ...styleProps } = this.props;

    return (
      <div
        ref={(ref) => {
          this.container = ref;
        }}
        className={`SkeletonLoader ${className}`}
        style={{ ...styleProps }}
      >
        <div className="SkeletonLoader__Bar">
          <div className="SkeletonLoader__Indicator" style={{ boxShadow }} />
        </div>
      </div>
    );
  }
}

SkeletonLoader.propTypes = {
  className: string,
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  borderRadius: number,
};

SkeletonLoader.defaultProps = {
  className: '',
  height: null,
  width: null,
  borderRadius: null,
};

export default SkeletonLoader;
