import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchSearchOffers } from 'mrt-apis/search';

import { searchTerm } from '../../recoil/searchTerm';
import { useQueryDebounce } from '../useQueryDebounce';

const useSearchOffers = () => {
  const searchKeyword = useRecoilValue(searchTerm);
  const debounceTerm = useQueryDebounce(searchKeyword, 300);

  const { data, ...options } = useQuery(
    ['fetchSearchOffers', { debounceTerm }],
    () => fetchSearchOffers(debounceTerm, { isWebView: false }),
    {
      enabled: !!debounceTerm,
    },
  );

  return { data: data?.data?.data, ...options };
};

export default useSearchOffers;
