const reservation = {
  reserve: '예약하기',
  ticket_reserve: '구매하기',
  order: {
    title: '결제하기',
    expired: '유효기간',
    totalPrice: '총 금액',
    checkin: '체크인',
    checkout: '체크아웃',
    date: '여행일',
    useDate: '사용일',
    warning: {
      delivery:
        '선택한 날짜를 다시 한번 확인해주세요! 잘못 입력 시 환불이 불가할 수 있습니다.',
      pension:
        '인원 추가,바베큐 시설 추가 등은 결제 금액에 포함되지 않고 현장 결제가 필요할 수 있으니 숙소 정책을 꼭 확인해 주세요.',
    },
    tourTitle: '1인당 가격',
  },
};

export default reservation;
