export const WithdrawalReason = {
  NO_TRAVEL: 'no_travel',
  NO_REASON_SIGN_IN: 'no_reason_sign_in',
  NO_VISIT: 'no_visit',
  DIFFICULT_USAGE: 'difficult_usage',
  CONCERN_PRIVACY: 'concern_privacy',
  ETC: 'etc',
} as const;

export const WithdrawReasonLabel = {
  [WithdrawalReason.NO_TRAVEL]: '여행을 자주 떠나지 않아서요.',
  [WithdrawalReason.NO_REASON_SIGN_IN]: '굳이 회원가입을 하지 않아도 사이트를 이용할 수 있어서요.',
  [WithdrawalReason.NO_VISIT]: '자주 방문하지 않아서요.',
  [WithdrawalReason.DIFFICULT_USAGE]: '어떻게 이용해야 할 지를 몰라서요.',
  [WithdrawalReason.CONCERN_PRIVACY]: '개인정보 침해가 우려되어서요.',
  [WithdrawalReason.ETC]: '다른 이유가 있어요.(아래에 자세히 적어주세요.)',
};

export const SnsProvider = {
  KAKAO: 'kakao',
  NAVER: 'naver',
  FACEBOOK: 'facebook',
} as const;
