// https://github.com/michaelwittig/node-i18n-iso-countries/blob/master/langs/ko.json
export const isoAlpha2CodeNames = {
  AF: '아프가니스탄',
  AL: '알바니아',
  DZ: '알제리',
  AS: '아메리칸 사모아',
  AD: '안도라',
  AO: '앙골라',
  AI: '앵 귈라',
  AQ: '남극 대륙',
  AG: '앤티가 바부 다',
  AR: '아르헨티나',
  AM: '아르메니아',
  AW: '아루바',
  AU: '호주',
  AT: '오스트리아',
  AZ: '아제르바이잔',
  BS: '바하마',
  BH: '바레인',
  BD: '방글라데시',
  BB: '바베이도스',
  BY: '벨라루스',
  BE: '벨기에',
  BZ: '벨리즈',
  BJ: '베냉',
  BM: '버뮤다',
  BT: '부탄',
  BO: '볼리비아',
  BA: '보스니아 헤르체고비나',
  BW: '보츠와나',
  BV: '부베 섬',
  BR: '브라질',
  IO: '영국령 인도양 지역',
  BN: '브루나이 다루 살람',
  BG: '불가리아',
  BF: '부키 나 파소',
  BI: '부룬디',
  KH: '캄보디아',
  CM: '카메룬',
  CA: '캐나다',
  CV: '카보 베르데',
  KY: '케이맨 제도',
  CF: '중앙 아프리카 공화국',
  TD: '차드',
  CL: '칠레',
  CN: '중국',
  CX: '크리스마스 섬',
  CC: '코코스 군도',
  CO: '콜롬비아',
  KM: '코모로',
  CG: '콩고',
  CD: '콩고 민주 공화국',
  CK: '쿡 제도',
  CR: '코스타리카',
  CI: '코트 디부 아르',
  HR: '크로아티아',
  CU: '쿠바',
  CY: '키프로스',
  CZ: '체코 공화국',
  DK: '덴마크',
  DJ: '지부티',
  DM: '도미니카 연방',
  DO: '도미니카 공화국',
  EC: '에콰도르',
  EG: '이집트',
  SV: '엘살바도르',
  GQ: '적도 기니',
  ER: '에리트레아',
  EE: '에스토니아',
  ET: '에티오피아',
  FK: '포클랜드 제도 (말 비나 스)',
  FO: '페로 제도',
  FJ: '피지',
  FI: '핀란드',
  FR: '프랑스',
  GF: '프랑스 령 기아나',
  PF: '프랑스 령 폴리네시아의',
  TF: '프랑스 남부 지역',
  GA: '가봉',
  GM: '감비아',
  GE: '조지아',
  DE: '독일',
  GH: '가나',
  GI: '지브롤터',
  GR: '그리스',
  GL: '그린란드',
  GD: '그레나다',
  GP: '과들루프',
  GU: '괌',
  GT: '과테말라',
  GN: '기니',
  GW: '기니 비사우',
  GY: '가이아나',
  HT: '아이티',
  HM: '허드 섬 및 맥도널드 제도',
  VA: '성좌 (바티칸 시국)',
  HN: '온두라스',
  HK: '홍콩',
  HU: '헝가리',
  IS: '아이슬란드',
  IN: '인도',
  ID: '인도네시아 공화국',
  IR: '이란, 이슬람 공화국',
  IQ: '이라크',
  IE: '아일랜드',
  IL: '이스라엘',
  IT: '이탈리아',
  JM: '자메이카',
  JP: '일본',
  JO: '요르단',
  KZ: '카자흐스탄',
  KE: '케냐',
  KI: '키리바시',
  KP: '조선 민주주의 인민 공화국',
  KR: '대한민국',
  KW: '쿠웨이트',
  KG: '키르기즈스탄',
  LA: '라오스 인민 민주주의 공화국',
  LV: '라트비아',
  LB: '레바논',
  LS: '레소토',
  LR: '라이베리아',
  LY: '리비아 아랍 자 마히리 야',
  LI: '리히텐슈타인',
  LT: '리투아니아',
  LU: '룩셈부르크',
  MO: '마카오',
  MG: '마다가스카르',
  MW: '말라위',
  MY: '말레이시아',
  MV: '몰디브',
  ML: '말리',
  MT: '몰타',
  MH: '마샬 군도',
  MQ: '마르티니크',
  MR: '모리타니',
  MU: '모리셔스',
  YT: '마 요트',
  MX: '멕시코',
  FM: '미크로네시아,',
  MD: '몰도바, 공화국',
  MC: '모나코',
  MN: '몽골리아',
  MS: '몬세 라트',
  MA: '모로코',
  MZ: '모잠비크',
  MM: '미얀마',
  NA: '나미비아',
  NR: '나우루',
  NP: '네팔',
  NL: '네덜란드',
  NC: '뉴 칼레도니아',
  NZ: '뉴질랜드',
  NI: '니카라과',
  NE: '니제르',
  NG: '나이지리아',
  NU: '니우에',
  NF: '노퍽 섬',
  MK: '북마케도니아 공화국',
  MP: '북 마리아나 제도',
  NO: '노르웨이',
  OM: '오만',
  PK: '파키스탄',
  PW: '팔라우',
  PS: '팔레스타인 자치구, 점령 자',
  PA: '파나마',
  PG: '파푸아 뉴기니',
  PY: '파라과이',
  PE: '페루',
  PH: '필리핀 제도',
  PN: '핏 케언',
  PL: '폴란드',
  PT: '포르투갈',
  PR: '푸에르토 리코',
  QA: '카타르',
  RE: '레위니옹',
  RO: '루마니아',
  RU: '러시아 연방',
  RW: '르완다',
  SH: '세인트 헬레나',
  KN: '세인트 키츠 네비스',
  LC: '세인트 루시아',
  PM: '생 피에르 미 클롱',
  VC: '세인트 빈센트 그레나딘',
  WS: '사모아',
  SM: '산 마리노',
  ST: '상투 메 프린시 페',
  SA: '사우디 아라비아',
  SN: '세네갈',
  SC: '세이셸',
  SL: '시에라 리온',
  SG: '싱가포르',
  SK: '슬로바키아',
  SI: '슬로베니아',
  SB: '솔로몬 제도',
  SO: '소말리아',
  ZA: '남아프리카',
  GS: '사우스 조지아 및 사우스 샌드위치 제도',
  ES: '스페인',
  LK: '스리랑카',
  SD: '수단',
  SR: '수리남',
  SJ: '스발 바르와 얀 메이 엔',
  SZ: '스와질란드',
  SE: '스웨덴',
  CH: '스위스',
  SY: '시리아',
  TW: '대만',
  TJ: '타지키스탄',
  TZ: '탄자니아, 유엔',
  TH: '태국',
  TL: '동 티모르',
  TG: '가다',
  TK: '토켈 라우',
  TO: '통가',
  TT: '트리니다드 토바고',
  TN: '튀니지',
  TR: '터키',
  TM: '투르크 메니스탄',
  TC: '터크스 케이커스 제도',
  TV: '투발루',
  UG: '우간다',
  UA: '우크라이나',
  AE: '아랍 에미리트',
  GB: '영국',
  US: '미국',
  UM: '미국령 군소 제도',
  UY: '우루과이',
  UZ: '우즈베키스탄',
  VU: '바누아투',
  VE: '베네수엘라',
  VN: '베트남',
  VG: '영국령 버진 아일랜드',
  VI: '미국령 버진 아일랜드',
  WF: '월리스 푸 투나',
  EH: '서사하라',
  YE: '예멘 아랍 공화국',
  ZM: '잠비아',
  ZW: '짐바브웨',
  AX: '올란드 제도',
  BQ: '보네르, 신트 유스 타티 우스, 사바',
  CW: '쿠라 사오',
  GG: '건지 섬',
  IM: '아일 오브 맨',
  JE: '저지',
  ME: '몬테네그로',
  BL: '생 바르 텔레 미',
  MF: '세인트 마틴 (프랑스어 부분)',
  RS: '세르비아',
  SX: '신트 마틴 (네덜란드어 부분)',
  SS: '남 수단',
  XK: '코소보',
};
