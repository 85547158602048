import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CountryPhoneNumbers } from 'mrt-constants';

import {
  FieldWrapper,
  InputUnit,
  SelectFieldProps,
  SelectUnit,
  TextFieldProps,
} from '../BaseFields';

interface PhoneNumberFieldProps {
  label?: string;
  caption?: string;
  phoneIcc: Omit<SelectFieldProps, 'options' | 'label' | 'caption'>;
  phoneNumber: Omit<TextFieldProps, 'label' | 'caption'>;
  align?: 'vertical' | 'horizontal';
}

const PHONE_ICC_OPTIONS = Object.entries(CountryPhoneNumbers).map(([value, content]) => ({
  value,
  content,
}));

function PhoneNumberField({
  label,
  caption,
  phoneIcc,
  phoneNumber,
  align = 'vertical',
}: PhoneNumberFieldProps): JSX.Element {
  const { invalid: iccInvalid, invalidCaption: iccInvalidCaption, ...iccProps } = phoneIcc;
  const {
    invalid: numberInvalid,
    invalidCaption: numberInvalidCaption,
    ...numberProps
  } = phoneNumber;

  return (
    <FieldWrapper
      name={numberProps.name || iccProps.name}
      invalid={iccInvalid || numberInvalid}
      invalidCaption={iccInvalidCaption || numberInvalidCaption}
      label={label}
      caption={caption}
    >
      <Wrapper align={align}>
        <SelectUnit
          {...iccProps}
          options={PHONE_ICC_OPTIONS}
          invalid={iccInvalid}
          defaultValue={iccProps?.defaultValue || '+82'}
        />
        <InputUnit {...numberProps} invalid={numberInvalid} />
      </Wrapper>
    </FieldWrapper>
  );
}

export default PhoneNumberField;

const Wrapper = styled.p<Pick<PhoneNumberFieldProps, 'align'>>`
  display: flex;
  flex-direction: ${({ align }) => (align === 'horizontal' ? 'row' : 'column')};
  gap: 8px;

  ${({ align }) =>
    align === 'horizontal' &&
    css`
      > :first-child {
        flex: 1;
      }
      > :nth-child(2) {
        flex: 2;
      }
    `}
`;
