import { Children } from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants/weblog';

import styled from '@emotion/styled';
import { helper } from '@myrealtrip/frontend-weblog';

const { sendClickLog } = helper;

export type PopupVerticalNavItem = {
  link: string;
  label: string;
  iconUrl?: string;
};

interface Props {
  isOpen: boolean;
  verticalItems: PopupVerticalNavItem[];
  showIcon: boolean;
}

export default function LocalNavItemsPopup({ isOpen, verticalItems, showIcon }: Props) {
  const handleNavClick =
    ({ label, link }: Pick<PopupVerticalNavItem, 'label' | 'link'>) =>
    () => {
      sendClickLog({
        eventName: 'gnb',
        screenName: WeblogScreenName.COMMON,
        pageCategory: WeblogPageCategory.COMMON,
        itemKind: 'menu',
        itemName: label,
        targetUrl: link,
      });
    };

  return (
    <AnimatePresence>
      {isOpen && (
        <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          {Children.toArray(
            verticalItems.map(({ link, label, iconUrl }) => (
              <NavItem href={link} onClick={handleNavClick({ link, label })}>
                {showIcon && iconUrl && <CmsIcon src={iconUrl} />}
                <Title>{label}</Title>
              </NavItem>
            )),
          )}
        </Container>
      )}
    </AnimatePresence>
  );
}

const Container = styled(motion.div)`
  position: absolute;
  left: -6px;
  top: 51px;
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: 8px 0;
  border-radius: 0px 0px 12px 12px;
  background-color: var(--white);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05), 0px 8px 24px rgba(0, 0, 0, 0.2);
  z-index: 101;
`;

const NavItem = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  flex: 0 0 50px;
  padding: 0 20px;
  box-sizing: border-box;

  &:hover {
    background-color: var(--gray-60);
  }
`;

const CmsIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: var(--gray-700);
`;
