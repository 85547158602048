import { memo } from 'react';

import {
  LayoutFooterContextProvider,
  LayoutFooterContextType,
} from '@layout/modules/LayoutFooterContext';
import LayoutProvider from '@layout/providers/LayoutProvider';

import Footer from '../app/pages/Footer';

const MrtFooter = (props: LayoutFooterContextType) => {
  return (
    <LayoutProvider>
      <LayoutFooterContextProvider initialState={props}>
        <Footer />
      </LayoutFooterContextProvider>
    </LayoutProvider>
  );
};

export default memo(MrtFooter);
