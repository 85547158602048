import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Utils } from 'app/common';

import styles from './Notify.module.scss';
import CloseButton from 'app/components/shared/CloseButton';

class Notify extends Component {
  static propTypes = {
    cookieKey: PropTypes.string.isRequired,
    cookieFireDate: PropTypes.number,
    onButtonClick: PropTypes.func,
  };

  static defaultProps = {
    cookieFireDate: 1,
    onButtonClick: () => null,
  };

  constructor(props) {
    super(props);

    this.state = {
      checked: false,
    };
  }

  neverShow = () => {
    const { cookieKey, onButtonClick, cookieFireDate } = this.props;
    Utils.setCookie(cookieKey, true, cookieFireDate);
    this.setState({
      checked: true,
    });
    onButtonClick();
  };

  render() {
    const { checked } = this.state;
    const { onButtonClick } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.title}>[공지] 서비스 점검 안내</span>
          <div className={styles.close}>
            <CloseButton onClick={onButtonClick} />
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.row}>
            <p>
              안녕하세요 여행자님. <br />
              마이리얼트립에서 더 나은 서비스 제공을 위한 서비스 점검을 안내 드립니다.
            </p>
            <p>
              <ul className={styles.list}>
                <li>
                  점검 일시: <strong>12월 21일(수) 04:30 ~ 06:00</strong>
                  <br />
                  (작업 일정에 따라 조금 더 길어질 수도 있습니다.)
                </li>
                <li>
                  서비스 불가 내역: 국제선, 국내선 서비스 전체
                  <br />
                  (운임 조회, 예약, 결제, 발권, 예약 조회, 취소/환불)
                </li>
              </ul>
            </p>
            <p>점검시간 동안에는 전체 서비스가 이용이 불가하니, 점검시간 이후 확인 부탁드립니다.</p>
          </div>
        </div>
        <footer className={styles.footer}>
          <label>
            <input
              className={styles.input}
              type="checkbox"
              checked={checked}
              onChange={this.neverShow}
            />
            <span>하루 동안 보지 않기</span>
          </label>
        </footer>
      </div>
    );
  }
}

export default Notify;
