import { Children, useEffect, useRef } from 'react';

import { motion, useInView } from 'framer-motion';
import { useAnimation } from 'framer-motion';

import styled from '@emotion/styled';

const NUMBERS = Array.from({ length: 21 }, (_, i) => i % 10);

export type BlendMaskStyles = {
  display: boolean;
  height?: number;
  color?: string;
};
interface Props {
  number: number;
  index: number;
  fontSize: number;
  duration: number;
  delay: number;
  letterWidth: number;
  verticalReverse: boolean;
  blendMask: BlendMaskStyles;
  once?: boolean;
  className?: string;
}

function AnimatedNumber({
  className,
  number,
  index,
  fontSize,
  duration,
  delay,
  letterWidth,
  verticalReverse,
  blendMask,
  once,
}: Props) {
  const animateRef = useRef(null);
  const inView = useInView(animateRef, { once });
  const controls = useAnimation();
  const distance = fontSize;
  const totalY = -(Math.floor(NUMBERS.length / 2) - 1) * distance;
  const currentY = verticalReverse ? (10 - number) * distance : -(number * distance);
  const finalY = totalY - distance + currentY;
  const initialY = verticalReverse ? -distance * (NUMBERS.length - 1) - fontSize : fontSize;
  const initialSet = { y: initialY };
  const animationSet = {
    y: finalY,
    transition: {
      delay: delay * index,
      duration,
    },
  };

  useEffect(() => {
    if (inView && animateRef.current) {
      controls.set(initialSet);
      controls.start(animationSet);
    }
  }, [animationSet, controls, inView, initialSet]);

  return (
    <Container ref={animateRef} className={className} fontSize={fontSize}>
      {blendMask.display && <TopBlendMask height={blendMask.height} color={blendMask.color} />}
      <MotionNumbers initial={initialSet} animate={controls} layout>
        {Children.toArray(
          NUMBERS.map((v) => (
            <MotionNumber fontSize={fontSize} letterWidth={letterWidth}>
              {v}
            </MotionNumber>
          )),
        )}
      </MotionNumbers>
      {blendMask.display && <BottomBlendMask height={blendMask.height} color={blendMask.color} />}
    </Container>
  );
}

export default AnimatedNumber;

const Container = styled.div<{ fontSize: number }>`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  height: ${({ fontSize }) => fontSize}px;
  overflow: hidden;
`;

const MotionNumbers = styled(motion.div)`
  display: flex;
  flex-direction: column;
  // 성능 최적화
  will-change: transform;
  // GPU 가속으로 성능향상
  transform: translate3d(0, 0, 0);
`;

const MotionNumber = styled.span<{ fontSize: number; letterWidth: number }>`
  text-align: center;
  font-size: ${({ fontSize }) => fontSize}px;
  width: ${({ letterWidth }) => letterWidth}em;
  height: ${({ fontSize }) => fontSize}px;
  display: flex;
  align-items: center;
`;

export const BlendMask = styled.div<Pick<BlendMaskStyles, 'height' | 'color'>>`
  position: absolute;
  width: 100%;
  height: ${({ height }) => height ?? 6}px;
  left: 0;
  z-index: 1;
`;

export const TopBlendMask = styled(BlendMask)`
  top: 0px;
  background: ${({ color }) =>
    `linear-gradient(0, rgba(255, 255, 255, 0) 0%, ${color ?? 'white'} 100%)`};
`;

export const BottomBlendMask = styled(BlendMask)`
  bottom: 0;
  background: ${({ color }) =>
    `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${color ?? 'white'} 100%)`};
`;
