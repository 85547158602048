const career = {
  subtitle: '마이리얼트립과 함께 더 가치 있는 여행 문화를 만들어 나갈 동료를 기다립니다.',
  member: {
    title: '고객 중심으로 생각하고, \n능동적인 자세로 협업하는 프로페셔널',
    items: [
      {
        key: '01',
        title: {
          ko: '고객 중심',
          en: 'Start with Customers',
        },
        content:
          '우리는 고객을 가장 정확하게 이해하는 사람들이다. 고객이 느끼는 불편함에 끊임없이 관심을 갖고 의사결정의 중심에 그것을 반영한다.',
      },
      {
        key: '02',
        title: {
          ko: '프로페셔널',
          en: 'Be a Professional',
        },
        content:
          '우리는 언제나 회사 전체의 이익을 최우선으로 생각한다. 나의 결정이 회사의 방향성과 어떻게 연결되어 있는지 정확하게 이해하며 내 팀을 넘어서 다른 조직에 미칠 영향까지 모두 고려하여 행동한다.',
      },
      {
        key: '03',
        title: {
          ko: '최고 수준 요구',
          en: 'Demand Highest Standards',
        },
        content:
          '우리는 일의 모든 과정에서 지속적으로 탁월한 성과를 달성한다. 불확실하고 어려운 상황들에 구애받지 않으며 강한 의지를 갖고 일을 추진하며 최고 수준의 결과를 만들어낸다.',
      },
      {
        key: '04',
        title: {
          ko: '빠른 실행력',
          en: 'Move with Speed',
        },
        content:
          '우리는 경쟁에서 이기기 위해 민첩하게 움직여야 한다는 것을 안다. 도전을 두려워하지 않으며 빠른 실험과 시도를 바탕으로 비즈니스를 성장시킨다.',
      },
      {
        key: '05',
        title: {
          ko: '임팩트에 대한 집중',
          en: 'Focus on Impact',
        },
        content:
          '우리는 목표를 달성하기 위한 가장 임팩트있는 방법을 치열하게 고민한다. 이를 바탕으로 우리의 시간을 어떤 일에 할애할지 우선순위를 설정한다.',
      },
    ],
  },
  benefit: {
    title: 'Benefit',
    subtitle: '구성원들의 성장을 돕고 \n일과 삶의 균형을 추구합니다.',
    items: [
      {
        key: 'benefit1',
        title: '유연한 근무 환경',
        content: '자유롭게 선택하는 출퇴근 시간과 업무 몰입을 위한 재택근무제도',
      },
      {
        key: 'benefit2',
        title: '여행 포인트 지원',
        content: '연 100만원 마이리얼트립 포인트와 지인을 위한 연 60만원 상당의 쿠폰 지원',
      },
      {
        key: 'benefit3',
        title: '다양한 휴가제도',
        content: '연 15일 연차, 생일 반차, 가족행사 휴가, 경조사 휴가 등',
      },
      {
        key: 'benefit4',
        title: '건강검진, 실손보험 지원',
        content: '연 1회 건강검진, 본인 포함 직계 가족 4인까지 실손보험 지원',
      },
      {
        key: 'benefit5',
        title: '업무장비 지원',
        content: '업무에 필요한 최신 장비와\n업무추진비 지원',
      },
      {
        key: 'benefit6',
        title: '자기계발비 지원',
        content: '체력단련, 도서구매, 문화생활 등을\n위해 연 180만원까지 지원',
      },
      {
        key: 'benefit7',
        title: '직무 교육비 지원',
        content: '직무 관련 강의, 세미나 등\n교육비 80% 지원',
      },
      {
        key: 'benefit8',
        title: '식사 제공',
        content: '점심 식비 지원과\n주 1회 조식 제공',
      },
    ],
  },
  apply: {
    title: 'Career',
    subtitle: '마이리얼트립과 함께 \n여행 시장의 혁신을 만들어 갈 동료를 모십니다.',
    showCareer: '채용중인 분야 보기',
  },
};

export default career;
