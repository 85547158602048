/* eslint-disable @typescript-eslint/naming-convention */

import { useState } from 'react';

import styled from '@emotion/styled';
import { helper } from '@myrealtrip/frontend-weblog';
import { WeblogPageCategory, WeblogScreenName, COUPON_URL, POINT_URL } from 'mrt-constants';
import { WISH_URL } from 'mrt-constants';
import { toCurrency } from 'mrt-utils';

import NotificationPopup from '@layout/app/components/NotificationPopup';
import ProfileCardButton from '@layout/app/components/ProfileCardButton';
import useFetchAccount from '@layout/app/hooks/useFetchAccount';
import useFetchHasFreshMessage from '@layout/app/hooks/useFetchHasFreshMessage';
import useSignOut from '@layout/app/hooks/useSignOut';
import { getTravelerLink } from '@layout/app/utils/getTravelerLink';
import i18n from '@layout/app/utils/i18n';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import SideBarLink from './SideBarLink';
import SideBarSkeleton from './SideBarSkeleton';

const { sendClickLog } = helper;

const baseLogData = {
  screenName: WeblogScreenName.COMMON,
  pageCategory: WeblogPageCategory.COMMON,
};

export default function LoggedInItems() {
  const { isFlightMode, isExposureNotification } = useLayoutHeaderContext();
  const { handleSignOut } = useSignOut();

  const { isFetching, isError, data } = useFetchAccount({ isFlightMode });
  const { number_of_coupons, point } = data ?? {};
  const canShowUserInfo = !isFetching && !isError;

  const [isNotificationPopupOpen, setIsNotificationPopupOpen] = useState(false);

  const { data: freshMessageData, isError: isFreshMessageError } =
    useFetchHasFreshMessage(isExposureNotification);
  const hasFreshMessage = freshMessageData?.new_messages && !isFreshMessageError;

  const handleNotificationOpen = () => {
    sendClickLog({
      ...baseLogData,
      eventName: 'topmenu',
      itemKind: 'notification',
      itemName: '알림',
    });

    setIsNotificationPopupOpen(true);
  };
  const handleNotificationClose = () => setIsNotificationPopupOpen(false);

  const pointAmount = isFlightMode ? '' : `${toCurrency(String(point))}원`;
  const couponAmount = isFlightMode ? '' : `${toCurrency(String(number_of_coupons))}장`;

  const handleOpenCsHelper = () => {
    sendClickLog({
      ...baseLogData,
      eventName: 'topmenu',
      itemKind: 'menu',
      itemName: '1:1 채팅 상담',
      targetUrl: 'https://myrealtrip.channel.io/',
    });

    window.csHelper?.open();
  };

  return (
    <Container>
      {isFetching && <SideBarSkeleton />}
      {!isFetching && (
        <>
          <Section>
            <ProfileCardButton showDetail />
            {canShowUserInfo && (
              <UserInfo>
                <PointItem href={POINT_URL}>
                  <UserInfoTitle>{i18n.t('header:point')}</UserInfoTitle>
                  {pointAmount && <UserInfoDesc>{pointAmount}</UserInfoDesc>}
                </PointItem>
                <CouponItem href={COUPON_URL}>
                  <UserInfoTitle>{i18n.t('header:coupon')}</UserInfoTitle>
                  {couponAmount && <UserInfoDesc>{couponAmount}</UserInfoDesc>}
                </CouponItem>
              </UserInfo>
            )}
          </Section>
          <Section>
            <SideBarLink
              label={i18n.t('header:myMemberGrade')}
              iconName="ico_loyalty"
              link={getTravelerLink('/member/grade-benefit')}
            />
          </Section>
          <Section>
            <SideBarLink
              label={i18n.t('header:allRegion')}
              iconName="ico_location"
              link={getTravelerLink('/regions')}
            />
          </Section>
          <Section>
            <SideBarLink
              label={i18n.t('header:wishlist')}
              iconName="ico_bookmark"
              link={WISH_URL}
            />
            <SideBarLink
              label={i18n.t('header:reservation')}
              iconName="ico_luggage"
              link={getTravelerLink('/reservations')}
            />
            <SideBarLink
              label={i18n.t('header:message')}
              iconName="ico_message"
              link={getTravelerLink('/messenger/channels')}
              redDot={hasFreshMessage}
            />
            {!isFlightMode && (
              <SideBarLink
                label={i18n.t('header:notification')}
                iconName="ico_notification"
                onClick={handleNotificationOpen}
              />
            )}
          </Section>
          <Section>
            <SideBarLink
              label={i18n.t('header:helpChat')}
              iconName="ico__chat"
              onClick={handleOpenCsHelper}
            />
          </Section>
          <Section noBorder>
            <SideBarLink label={i18n.t('header:signOut')} onClick={handleSignOut} />
          </Section>
        </>
      )}
      {isNotificationPopupOpen && (
        <NotificationPopup isOpen={isNotificationPopupOpen} onClose={handleNotificationClose} />
      )}
    </Container>
  );
}
const Container = styled.div``;

const UserInfo = styled.div`
  display: flex;
  margin-top: 15px;
`;
const PointItem = styled.a`
  display: inline-flex;
  width: 60%;
`;
const CouponItem = styled.a`
  display: inline-flex;
  width: 40%;
`;
const UserInfoTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${globalVariables.gray_500};
`;
const UserInfoDesc = styled.span`
  margin-left: 4px;
  font-size: 14px;
  font-weight: 500;
  color: ${globalVariables.blue_500};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Section = styled.div<{ noBorder?: boolean }>`
  padding: 20px 16px;
  border-bottom: ${({ noBorder }) => (noBorder ? '0' : `1px solid ${globalVariables.gray_100}`)};
`;
