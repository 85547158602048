import { css } from '@emotion/react';

import { BreakPoint } from 'mrt-constants';

export const container = css`
  background-color: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 16px;
  z-index: 100;
  transition: transform 0.2s ease;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  & > div:last-of-type {
    padding-right: 0px;
  }
`;

export const content = css`
  width: 1060px;
  margin: 0 auto;
  display: flex;
  white-space: pre;

  @media (max-width: ${BreakPoint.MOBILE}px) {
    width: 100%;
  }

  .swiper-slide {
    width: auto !important;
    margin-right: 0 !important;
  }
`;

export const contentWithShareBtn = css`
  @media (max-width: ${BreakPoint.MOBILE}px) {
    padding-right: 88px;
  }
`;

export const shareBtnContainer = css`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 88px;
  height: 100%;
  z-index: 1;
  border-bottom: 1px solid var(--gray-200);
`;

export const gradient = css`
  width: 30px;
  height: 100%;
  object-fit: contain;
  background: linear-gradient(270deg, var(--white) 0, hsla(0, 0%, 100%, 0));
`;

export const shareBtn = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 100%;
  background-color: var(--white);
`;

export const show = css`
  transform: translateY(0%);
`;

export const hide = css`
  transform: translateY(-100%);
`;

export const borderd = css`
  box-shadow: inset 0 -1px 0 0 var(--gray-200);
`;
