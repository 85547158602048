import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import media from 'mrt-utils/mediaQuery';

import styled from '@emotion/styled';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import { helper } from '@myrealtrip/frontend-weblog';

const { sendClickLog } = helper;

const SNS_ITEMS = [
  {
    id: 'facebook',
    link: 'https://www.facebook.com/myRealTrip',
    description: '페이스북으로 이동하기',
    iconName: 'ic_facebook_20x20_gray_400',
  },
  {
    id: 'naver',
    link: 'https://blog.naver.com/myrealtrip',
    description: '네이버 블로그로 이동하기',
    iconName: 'ic_naver_20x20_gray_400',
  },
  {
    id: 'instagram',
    link: 'https://instagram.com/myrealtrip',
    description: '인스타그램으로 이동하기',
    iconName: 'ic_instagram_20x20_gray_400',
  },
  {
    id: 'youtube',
    link: 'https://www.youtube.com/channel/UC2oeFByIBQjkKXmQjE0U5rQ',
    description: '유투브로 이동하기',
    iconName: 'ic_youtube_20x20_gray_400',
  },
];

export default function SNSList() {
  const handleClick =
    ({ description, link }) =>
    () => {
      sendClickLog({
        eventName: 'footer',
        screenName: WeblogScreenName.COMMON,
        pageCategory: WeblogPageCategory.COMMON,
        menuTitle: description,
        targetUrl: link,
      });
    };

  return (
    <Container>
      {SNS_ITEMS.map(({ id, link, description, iconName }) => (
        <LinkItem key={id}>
          <Link
            href={link}
            onClick={handleClick({ description, link })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon name={iconName} />
            <Desc>{description}</Desc>
          </Link>
        </LinkItem>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;

  ${media.mobile} {
    margin-top: 14px;
    margin-left: -10px;
  }
`;

const LinkItem = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  margin: 0 10px;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  height: 100%;
`;

const LinkIcon = styled(Icon)`
  width: 20px;
  height: 20px;

  ${media.mobile} {
    width: 24px;
    height: 24px;
  }
`;

const Desc = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip-path: polygon(0 0, 0 0, 0 0);
`;
