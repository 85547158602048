const common = {
  vertical: {
    experience: 'Tour&Ticket',
    hotel: 'Hotel',
    lodging: 'Lodging',
    hot_deal: 'Package',
  },
  dialog: {
    selectDate: 'Select date',
  },
  price: '{{value}}Won',
  lowPrice: 'Best Price Guarantee',
  reviewEvent: 'Review event',
  share: {
    mailSubject: 'How do you like this offer from MyRealTrip? Share!',
  },
  count: '{{count}}Unit',
  review: 'Review guideline',
  oneNightMinimum: 'Best price for one night',
  showHotelMore: '{{value}} Show more hotel',
  priceUnit: 'Won',
  nowUse: 'Use now',
  showOfferMore: 'Show more offer',
  letsShow: 'See more',
  oneNight: 'One night',
  onePerson: 'One person',
  confirm: 'confirm',
  cancel: 'cancel',
  email: 'Email',
  phone: 'Phone No',
};

export default common;
