// 항공 예약내역 페이지에 배너 삽입

import React from 'react';

import { MainProvider } from '../../context/main';
import MypageBanner from '../../containers/banner/MypageBanner';
import { renderReactComponent } from './share';

export default function renderMyPageBanner(targetId = 'root') {
  renderReactComponent(
    <MainProvider>
      <MypageBanner />
    </MainProvider>,
    document.getElementById(targetId),
  );
}
