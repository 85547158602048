import React from 'react';
import Dotdotdot from 'react-dotdotdot';

import useImpressionLog from 'mrt-hooks/useImpressionLog';

import { helper } from '@myrealtrip/frontend-weblog';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { browserEnum, webLogScreenNameEnum, webLogPageCategoryEnum } from '../../enum';
import getBrowserInfo from '../../utils/getBrowserInfo';
import ParseNewLine from '../shared/ParseNewLine';

import styles from './ContentsCarouselItem.module.scss';

const { sendClickLog } = helper;

function ContentsCarouselItem(props) {
  const {
    id,
    title,
    subtitle,
    authorName,
    authorImage,
    authorDesc,
    thumbnail,
    cityName,
    onClick,
    openNewTab,
    isWebView,
    qs,
    sectionHorizontalIndex,
    verticalIndex,
  } = props;
  const lineClamp = getBrowserInfo() === browserEnum.ie ? 3 : 2;
  const hasAuthorDesc = Boolean(authorDesc);

  const handleClick = () => {
    onClick(id);
    sendClickLog({
      screenName: webLogScreenNameEnum.city,
      pageCategory: webLogPageCategoryEnum.city,
      eventName: 'contents',
      itemKind: 'contents',
      tabName: 'tour',
      itemId: id,
      sectionHorizontalIndex: sectionHorizontalIndex + 1,
      sectionVerticalIndex: verticalIndex,
      screenCityName: cityName,
      targetUrl: link,
    });
  };

  const { subscribe } = useImpressionLog(
    {
      screenName: webLogScreenNameEnum.city,
      pageCategory: webLogPageCategoryEnum.city,
      eventName: 'contents',
      itemKind: 'contents',
      tabName: 'tour',
      itemId: id,
      sectionHorizontalIndex: sectionHorizontalIndex + 1,
      sectionVerticalIndex: verticalIndex,
      screenCityName: cityName,
    },
    `city_magazine_${cityName}_${id}`,
  );

  const otherProps = openNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};

  const webUrl = isWebView
    ? `${process.env.HOME_URL}/instants/contents/${id}?contents_id=${id}`
    : `${process.env.HOME_URL}/contents/${id}?contents_id=${id}`;
  let link = isWebView ? `mrt://web?url=${encodeURIComponent(webUrl)}` : webUrl;

  if (qs) {
    link += `&${qs}`;
  }

  return (
    <a
      href={link}
      ref={subscribe}
      className={classNames(styles.container, 'swiper-slide')}
      style={{ backgroundImage: `url(${thumbnail})` }}
      onClick={handleClick}
      {...otherProps}
    >
      <div className={styles.header}>
        <h3 className={styles.title}>
          <Dotdotdot clamp={lineClamp}>
            <ParseNewLine text={title} split="<br>" />
          </Dotdotdot>
        </h3>
        <h4 className={styles.subtitle}>{subtitle}</h4>
      </div>
      <div className={styles.author}>
        <img src={authorImage} className={styles.authorImage} alt="" />
        <span className={styles.authorName}>{authorName}</span>
        {hasAuthorDesc && (
          <span className={styles.authorName}>
            &nbsp;
            {`･ ${authorDesc}`}
          </span>
        )}
      </div>
    </a>
  );
}

ContentsCarouselItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  authorName: PropTypes.string.isRequired,
  authorImage: PropTypes.string.isRequired,
  authorDesc: PropTypes.string,
  thumbnail: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  openNewTab: PropTypes.bool,
  isWebView: PropTypes.bool,
  qs: PropTypes.string,
  verticalIndex: PropTypes.number,
  sectionHorizontalIndex: PropTypes.number,
  cityName: PropTypes.string,
};

ContentsCarouselItem.defaultProps = {
  subtitle: '',
  authorDesc: '',
  onClick: () => null,
  openNewTab: false,
  isWebView: false,
  qs: null,
  verticalIndex: 0,
};

export default ContentsCarouselItem;
