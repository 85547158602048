import { mobileOSEnum } from '../enum';
import getMobileOS from './getMobileOS';

function getMobileEventScreenKey() {
  const mobileOS = getMobileOS();
  let screenKey = '';

  if (mobileOS === mobileOSEnum.android) {
    screenKey = 'firebase_screen';
  } else if (mobileOS === mobileOSEnum.ios) {
    screenKey = 'ios_screen';
  }

  return screenKey;
}

export default getMobileEventScreenKey;
