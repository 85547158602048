import { Children } from 'react';

import { useDetermineMobileContext } from 'mrt-components';

import styled from '@emotion/styled';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';

import LocalNavItem from './LocalNavItem';
import RestLocalNavItems from './RestLocalNavItems';

const MAX_DISPLAY_VERTICAL_LENGTH = 9;

export default function MainPageLocalNavItems() {
  const { mainVerticalItems, subVerticalItems } = useLayoutHeaderContext();
  const isMobile = useDetermineMobileContext();

  const filterdMainVerticalItems = mainVerticalItems.filter(
    ({ level, title }) => level === 1 && title,
  );
  const filterdSubVerticalItems = subVerticalItems.filter(({ title }) => Boolean(title));
  const mergedVerticalItems = [...filterdMainVerticalItems, ...filterdSubVerticalItems].sort(
    (a, b) => a.position - b.position,
  );
  const displayVerticalItems = mergedVerticalItems.slice(0, MAX_DISPLAY_VERTICAL_LENGTH);
  const restVerticalItems = mergedVerticalItems
    .slice(displayVerticalItems.length)
    .map(({ link, title, cms_icon_url }) => ({
      link: link.desktop,
      label: title,
      iconUrl: cms_icon_url,
    }));

  return (
    <Container>
      {Children.toArray(
        displayVerticalItems?.map(({ title, cms_icon_url, badge, badge_style, link }) => (
          <LocalNavItem
            label={title}
            showIcon
            iconUrl={cms_icon_url}
            badge={{ badgeName: badge, badgeStyle: badge_style }}
            link={isMobile ? link.mobile_web : link.desktop}
          />
        )),
      )}
      {restVerticalItems.length > 0 && (
        <RestLocalNavItems verticalItems={restVerticalItems} showIcon />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 100%;
`;
