/* eslint-disable react/display-name */
import { css } from '@emotion/react';
import { useState, useEffect, ChangeEvent, MouseEvent, forwardRef, KeyboardEvent } from 'react';
import { useRecoilState } from 'recoil';

import { useDetermineMobileContext } from 'mrt-components';
import { SEARCH_INPUT_HEIGHT } from 'mrt-constants';
import { GlobalSearch } from 'mrt-types';

import styled from '@emotion/styled';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import { searchTerm } from '../recoil';

const SEARCH_ICON_GRAY = 'ic_search_20x20_gray_500';
const SEARCH_ICON_WHITE = 'ic_search_sm_white';

const { LAYOUT, COLOR } = GlobalSearch;
interface InputVariationType {
  searchIconName: string;
  border: string;
  borderRadius: number;
  hoverBgColor: string;
  hoverBorder: string;
  bgColor: string;
  fontColor: string;
  containerHeight: number;
  left: number;
  textAlign: string;
  imgSize: number;
}

function getSearchInputVariation({
  variations,
  isMobile,
}: {
  variations: GlobalSearch.Variation;
  isMobile: boolean;
}): InputVariationType {
  const { layout, color } = variations || {};
  let containerHeight = SEARCH_INPUT_HEIGHT.medium;
  let border = 'none';
  let borderRadius = 4;
  let left = 18;
  let textAlign = 'left';
  let imgSize = 20;
  let hoverBgColor = globalVariables.gray_80;
  let hoverBorder = 'none';

  if (layout === LAYOUT.pageContent) {
    if (!isMobile) {
      containerHeight = SEARCH_INPUT_HEIGHT.large;
      left = 102;
      imgSize = 24;
    }
    border = '1px solid rgba(255, 255, 255, 0.4)';
    borderRadius = 8;
    textAlign = 'center';
    hoverBgColor = 'rgba(255, 255, 255, 0.2)';
    hoverBorder = '1px solid rgba(255, 255, 255, 0.5)';
  }

  const defaultVariation = {
    searchIconName: SEARCH_ICON_GRAY,
    border,
    borderRadius,
    hoverBgColor,
    hoverBorder,
    bgColor: globalVariables.gray_60,
    fontColor: globalVariables.gray_700,
    containerHeight,
    left,
    textAlign,
    imgSize,
  };

  switch (color) {
    case COLOR.white:
      return defaultVariation;
    case COLOR.transparent:
      return {
        searchIconName: SEARCH_ICON_WHITE,
        border,
        borderRadius,
        hoverBgColor: 'rgba(245,246,247,.25)',
        hoverBorder,
        bgColor: 'rgba(245, 246, 257, 0.15)',
        fontColor: globalVariables.white,
        containerHeight,
        left,
        textAlign,
        imgSize,
      };
    case COLOR.blue:
      return {
        searchIconName: SEARCH_ICON_WHITE,
        border,
        borderRadius,
        hoverBgColor: globalVariables.blue_500,
        hoverBorder,
        bgColor: globalVariables.blue_500,
        fontColor: globalVariables.white,
        containerHeight,
        left,
        textAlign,
        imgSize,
      };
    default:
      return defaultVariation;
  }
}

interface Props {
  variations: GlobalSearch.Variation;
  placeholder: string;
  popupSide?: boolean;
  onPopupToggle?: (isOpen: boolean) => void;
  onDialogToggle?: (isOpen: boolean) => void;
  onPressKeyboard: (key: string) => void;
  onClearInput?: () => void;
  onClick?: () => void;
}

const SearchInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      variations,
      placeholder,
      popupSide,
      onPopupToggle,
      onDialogToggle,
      onPressKeyboard,
      onClearInput,
      onClick,
    },
    ref,
  ) => {
    const [searchKeyword, setSearchKeyword] = useRecoilState(searchTerm);
    const isMobile = useDetermineMobileContext();
    const [isFocused, setIsFocused] = useState(false);
    const isEmpty = searchKeyword === '';

    const inputVariation = getSearchInputVariation({
      variations,
      isMobile,
    });

    const handleKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearchKeyword(value);
    };

    const handleKeywordClearClick = () => {
      onClearInput?.();
      setSearchKeyword('');
    };

    const handleInputFocus = () => {
      onPopupToggle?.(true);
      setIsFocused(true);
    };

    const handleInputBlur = () => {
      setIsFocused(false);
    };

    const handleContainerClick = () => {
      onDialogToggle?.(true);
    };

    const handleMouseDown = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
    };

    const handlePressKeyboard = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 229) {
        return;
      }

      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
      }
      onPressKeyboard(e.key);
    };

    useEffect(() => {
      if (isFocused && !popupSide) {
        onClick?.();
      }
    }, [isFocused, onClick, popupSide]);

    return (
      <Container
        isFocused={isFocused}
        inputVariation={inputVariation}
        onClick={handleContainerClick}
      >
        <SearchIconContainer isFocused={!isEmpty || isFocused} inputVariation={inputVariation}>
          <SearchIcon
            inputVariation={inputVariation}
            name={isFocused ? SEARCH_ICON_GRAY : inputVariation.searchIconName}
          />
        </SearchIconContainer>
        <KeywordInput
          id="globalSearchInput"
          ref={ref}
          value={searchKeyword}
          isEmpty={isEmpty}
          isFocused={isFocused}
          placeholder={placeholder}
          inputVariation={inputVariation}
          onChange={handleKeywordChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onKeyDown={handlePressKeyboard}
          autoComplete="off"
        />
        {!isEmpty && isFocused && (
          <ClearButton
            type="button"
            onMouseDown={handleMouseDown}
            onClick={handleKeywordClearClick}
          >
            <Icon name="ic_clear_xs_filled_black30" />
          </ClearButton>
        )}
      </Container>
    );
  },
);

export default SearchInput;

const Container = styled.div<{
  isFocused: boolean;
  inputVariation: InputVariationType;
}>`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 48px;
  ${({ inputVariation }) => css`
    height: ${inputVariation.containerHeight}px;
    border: ${inputVariation.border};
    border-radius: ${inputVariation.borderRadius}px;
  `}
  ${({ isFocused }) =>
    isFocused &&
    css`
      box-shadow: 0px 0px 1px rgba(52, 58, 64, 0.2), 0px 1px 4px rgba(52, 58, 64, 0.15);
    `}
`;

const SearchIconContainer = styled.div<{
  isFocused: boolean;
  inputVariation: InputVariationType;
}>`
  position: absolute;
  ${({ inputVariation, isFocused }) =>
    css`
      left: ${isFocused ? 18 : inputVariation.left}px;
    `};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 48px;
`;

const SearchIcon = styled(Icon)<{ inputVariation: InputVariationType }>`
  ${({ inputVariation }) => css`
    width: ${inputVariation.imgSize}px;
    height: ${inputVariation.imgSize}px;
  `}
`;

const KeywordInput = styled.input<{
  isEmpty: boolean;
  isFocused: boolean;
  inputVariation: InputVariationType;
}>`
  width: 100%;
  height: 100%;
  ${({ isFocused, inputVariation }) =>
    css`
      background: ${isFocused ? globalVariables.white : inputVariation.bgColor};
    `}
  border: none;
  font-size: 15px;
  font-weight: 500;
  ${({ inputVariation, isFocused, isEmpty }) =>
    css`
      text-align: ${isFocused || !isEmpty ? 'left' : inputVariation.textAlign};
    `};
  padding: ${({ isFocused }) => (isFocused ? '0 28px 0 48px' : '0 16px 0 48px')};
  color: ${({ isFocused, inputVariation }) =>
    isFocused ? globalVariables.gray_700 : inputVariation.fontColor}; // gray-700
  transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1);

  ::placeholder {
    ${({ inputVariation, isFocused }) =>
      css`
        color: ${isFocused ? globalVariables.white : inputVariation.fontColor};
      `}
  }

  :hover {
    ${({ inputVariation, isFocused }) =>
      isFocused ||
      css`
        background-color: ${inputVariation.hoverBgColor};
      `};
    ${({ inputVariation, isFocused }) =>
      isFocused ||
      css`
        border: ${inputVariation.hoverBorder};
      `};
  }
`;

const ClearButton = styled.button`
  /* reset button style */
  position: absolute;
  right: 12px;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  height: 100%;

  flex: 0 0 36px;
`;
