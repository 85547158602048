import { createToast } from '@myrealtrip/design-system';
import { mrtApiCall } from 'mrt-apis';

import { fetchSignout } from '@layout/app/api/signout';

export default function useSignOut() {
  const handleSignOut = async () => {
    const { data, error } = await mrtApiCall(fetchSignout);
    const { status, message } = data?.result || {};

    if (status === 204 && !error) {
      window.location.reload();
    } else {
      createToast(message || '로그아웃에 실패했습니다', {
        color: 'warning',
      });
    }
  };

  return { handleSignOut };
}
