function callAndroidNativeHandler(methodName, params) {
  const hasNativeHandler = typeof window.AndroidTraveler[methodName] === 'function';

  if (!hasNativeHandler) {
    return;
  }

  try {
    const stringifiedParams = typeof params === 'string' ? params : JSON.stringify(params);

    window.AndroidTraveler[methodName](stringifiedParams);
  } catch (error) {
    console.error(`failed to call native handler ${methodName}`, error);
  }
}

function callIosNativeHandler(methodName, params) {
  const hasNativeHandler = Boolean(window.webkit.messageHandlers[methodName]);

  if (!hasNativeHandler) {
    return;
  }

  try {
    const stringifiedParams = typeof params === 'string' ? params : JSON.stringify(params);

    window.webkit.messageHandlers[methodName].postMessage(stringifiedParams);
  } catch (error) {
    console.error(`failed to call native handler ${methodName}`, error);
  }
}

export default function callNativeHandler(methodName, params = {}) {
  if (window.AndroidTraveler) {
    callAndroidNativeHandler(methodName, params);
  } else if (window.webkit && window.webkit.messageHandlers) {
    callIosNativeHandler(methodName, params);
  }
}
