import React from 'react';
import PropTypes from 'prop-types';

import FlightSeatInfo from './FlightSeatInfo';

import { getCountString } from '../../utils/domesticReservationUtils';

import styles from './FlightPassengerInfo.module.scss';

function FlightPassengerInfo(props) {
  const { data, isMobile } = props;

  return (
    <div className={styles.flightPassengerInfo}>
      <span className={styles.passengerInfo}>
        {getCountString(
          data.ADT_PAX_COUNT,
          data.CHD_PAX_COUNT,
          data.INF_PAX_COUNT,
        )}
      </span>
      {isMobile ? null : <FlightSeatInfo data={data} isMobile={isMobile} />}
    </div>
  );
}

FlightPassengerInfo.propTypes = {
  data: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default FlightPassengerInfo;
