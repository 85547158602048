export enum Platform {
  DESKTOP = 'desktop',
  PHONE = 'phone',
  IOS = 'ios',
  ANDROID = 'android',
}

export enum BreakPoint {
  MOBILE = 768,
  TABLET = 1100,
}

export enum ViewSize {
  MOBILE = 375,
  DESKTOP = 1440,
}
