const category = {
  header: '{{location}}에서 어떤 상품을 찾으세요?',
  total: '전체',
  experience: {
    guide_tour: '가이드 투어',
    ticket: '티켓/패스',
    activity: '액티비티',
    snap: '스냅촬영',
    restaurant: '레스토랑',
    entertainment: '즐길거리',
    utility: '여행편의',
  },
  airtel: {
    package: '패키지',
    airtel: '항공+호텔',
    transport: '항공권/승선권',
    rentacar: '렌터카',
    accommodation: '숙박특가',
  },
};

export default category;
