export const DGM_DEVICE_TYPE = Object.freeze({
  IN_APP: 'IN_APP',
  MOBILE_WEB: 'MOBILE_WEB',
});

export const COUPON_TYPE = Object.freeze({
  DGM_COUPON: 'dgmCoupon',
  MRTCOUPON: 'mrtCoupon',
});

export const DGM_COUPON_CODES = Object.freeze({
  DGM20000: '2021DGM20000COUPON',
  DGM30000: '2021DGM30000COUPON',
  MRT7000: 'ACCOMO7000COUPON',
});

export const DGM_COUPON_RESULT_TYPE = Object.freeze({
  SUCCESS: 'success',
  DUPLICATED: 'duplicated',
  FAIL: 'fail',
});
