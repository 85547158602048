export enum ProductStatus {
  TEMP = 'TEMP',
  WAIT = 'WAIT',
  READY = 'READY',
  ONSALE = 'ONSALE', // 판매
  HOLD = 'HOLD', // 휴면
  NOT_SALE = 'NOT_SALE', // 판매중단
}

/** (구) 상품타입 */
export enum ProductType {
  ACCOMMODATION = 'ACCOMMODATION',
  TRANSPORT = 'TRANSPORT',
  TOURACTIVITY = 'TOURACTIVITY',
}

/** 표준 카테고리(1depth) */
export enum StandardCategoryPriority {
  TOUR = 'TOUR',
  CLASS = 'CLASS',
  SNAP = 'SNAP',
  ACTIVITY = 'ACTIVITY',
  TICKET = 'TICKET',
  CONVENIENCE = 'CONVENIENCE',
  ORDER_MADE = 'ORDER_MADE',
}

/** 사용을 제거해 나가는 중 */
export enum ProductCategory {
  ALL = 'ALL',
  FLIGHT = 'FLIGHT',
  STAY = 'STAY',
  LODGING = 'LODGING',
  RENTER_CAR = 'RENTER_CAR',
  ACCOMMODATION = 'ACCOMMODATION', // 2.0 국내숙소 예약 타입
  PENSION = 'PENSION',
  HOTEL = 'HOTEL',
  RESORT = 'RESORT',
  CONDOMINIUM = 'CONDOMINIUM',
  POOLVILLA = 'POOLVILLA',
  CAMPING = 'CAMPING',
  GLAMPING = 'GLAMPING',
  CARAVAN = 'CARAVAN',
  HANOK = 'HANOK',
  HOSTEL = 'HOSTEL',
  HOSTEL_V2 = 'HOSTEL_V2',
  GUESTHOUSE = 'GUESTHOUSE',
  APARTMENT = 'APARTMENT',
  RESIDENCE = 'RESIDENCE',
  MOTEL = 'MOTEL',
  BNB = 'BNB',
  TOUR = 'TOUR',
  TICKET = 'TICKET',
  GUIDE_TOUR = 'GUIDE_TOUR',
  ENTERTAINMENT = 'ENTERTAINMENT',
  RESTAURANT = 'RESTAURANT',
  ACTIVITY = 'ACTIVITY',
  UTILITY = 'UTILITY',
  SNAP = 'SNAP',
  PACKAGE = 'PACKAGE',
  HOTEL_V2 = 'HOTEL_V2',
  CAPSULE_HOTEL = 'CAPSULE_HOTEL',
  APARTHOTEL = 'APARTHOTEL',
  RESORT_V2 = 'RESORT_V2',
  CONDOMINIUM_RESORT = 'CONDOMINIUM_RESORT',
  CONDO = 'CONDO',
  ALL_INCLUSIVE_PROPERTY = 'ALL_INCLUSIVE_PROPERTY',
  GUEST_HOUSE_V2 = 'GUEST_HOUSE_V2',
  HOSTAL = 'HOSTAL',
  HOME_STAY = 'HOME_STAY',
  HOSTEL_BACKPACKER = 'HOSTEL_BACKPACKER',
  BED_AND_BREAKFAST = 'BED_AND_BREAKFAST',
  MOTEL_V2 = 'MOTEL_V2',
  INN = 'INN',
  APARTMENT_V2 = 'APARTMENT_V2',
  SERVICED_APARTMENT = 'SERVICED_APARTMENT',
  RESIDENCE_V2 = 'RESIDENCE_V2',
  TOWNHOUSE = 'TOWNHOUSE',
  PENSION_V2 = 'PENSION_V2',
  PRIVATE_VACATION_HOME = 'PRIVATE_VACATION_HOME',
  VILLA = 'VILLA',
  POOLVILLA_V2 = 'POOLVILLA_V2',
  PRIVATE_VILLA = 'PRIVATE_VILLA',
  PRIVATE_HOUSE = 'PRIVATE_HOUSE',
  LODGE = 'LODGE',
  CAMP_SITE = 'CAMP_SITE',
  SAFARI_TENTALOW = 'SAFARI_TENTALOW',
  HOLIDAY_PARK = 'HOLIDAY_PARK',
  CARAVAN_V2 = 'CARAVAN_V2',
  GLAMPING_V2 = 'GLAMPING_V2',
  CASTLE_PALACE = 'CASTLE_PALACE',
  TREE_HOUSE = 'TREE_HOUSE',
  HOUSE_BOAT = 'HOUSE_BOAT',
  AGRITOURISM = 'AGRITOURISM',
  COTTAGE = 'COTTAGE',
  RANCH = 'RANCH',
  CABIN = 'CABIN',
  SAFARI = 'SAFARI',
  CRUISE = 'CRUISE',
  CHALET = 'CHALET',
  RYOKAN = 'RYOKAN',
  MACHIYA = 'MACHIYA',
  RIAD = 'RIAD',
  HANOK_V2 = 'HANOK_V2',
  COUNTRY_HOUSE = 'COUNTRY_HOUSE',
  POUSADA = 'POUSADA',
  PARADOR = 'PARADOR',
  CLASS = 'CLASS',
  FOODIE = 'FOODIE',
  RENTAL = 'RENTAL',
  SPA_HEALING = 'SPA_HEALING',
  TRANSPORTATION = 'TRANSPORTATION',
  JEJU_PLUS = 'JEJU_PLUS',
  KIDS = 'KIDS',
}

export enum SystemProvider {
  /** 입점숙소 */
  BNB = 'BNB',
  /** 국내숙소 */
  STY = 'STY',
  /** 통합숙소 */
  ACM = 'ACM',
  /** 렌터카 */
  CAR = 'CAR',
  /** 투어티켓 */
  TNA = 'TNA',
  /** 투어티켓(3.0) */
  EXP = 'EXP',
  /** 항공권 */
  AIR = 'AIR',
  /** 패키지 */
  PKG = 'PKG',
}

export const MerchantId = {
  /** EPS / (주) 익스피디아 */
  EPS: 'EPS',
  /** AGODA COMPANY PTE LTD / 아고다 */
  AGODA: 'AGODA',
  /** ...그외 연동사(필요시 추가) */
};
