const language = {
  title: '파트너가 되어\n현지 수익을 만드세요.',
  subTitle: '마이리얼트립 파트너',
  registPartner: '파트너 등록하기',
  cover: '메인 일러스트',
  withMyreltrip: '마이리얼트립과 함께 하세요!',
  whyTogether: [
    {
      key: 'why1',
      title: '무료 등록',
      description:
        '등록비가 없으며 카드 수수료, 부가가치세를\n마이리얼트립이 부담합니다.',
    },
    {
      key: 'why2',
      title: '특별한 경험 공유',
      description:
        '나만의 현지 경험을 공유하고\n새로운 수익을 만들 수 있습니다.',
    },
    {
      key: 'why3',
      title: '마케팅 지원',
      description:
        'SNS 및 키워드 광고, 기업 제휴, 쿠폰 할인 등\n다양한 마케팅 활동을 무료로 받아보세요.',
    },
    {
      key: 'why4',
      title: '편리한 예약/정산 관리',
      description:
        '언제 어디서나 데스크탑, 모바일로 예약을\n관리하고 쉽게 정산할 수 있습니다.',
    },
  ],
  becomePartner: '개인/기업 누구나 마이리얼트립의 파트너가 될 수 있습니다.',
  tabs: [
    {
      key: 'tour',
      label: '투어',
    },
    {
      key: 'ticket',
      label: '티켓',
    },
    {
      key: 'lodging',
      label: '숙소',
    },
  ],
  tabItems: [
    {
      key: 1,
      process: [
        {
          key: 'tab1-1',
          title: '파트너 등록',
          description:
            '마이리얼트립에서 회원가입을 진행 하신 뒤, 가입한 아이디로 파트너 등록을 신청합니다.',
        },
        {
          key: 'tab1-2',
          title: '서류 및 상품 제출',
          description:
            '등록하실 투어 상품에 대한 정보를 작성하고 필요한 서류를 제출합니다.',
          caption: '※ 필수 서류: 개인 (신분증, 체류증), 기업 (사업자 등록증)',
        },
        {
          key: 'tab1-3',
          title: '서류 및 상품 확인',
          description:
            '담당자가 제출하신 서류 및 상품을 확인하고, 필요한 경우 검수 의견을 전달드립니다.',
        },
        {
          key: 'tab1-4',
          title: '첫 예약 받기',
          description: '나만의 현지 이야기로 첫번째 여행자를 만나보세요.',
        },
      ],
    },
    {
      key: 2,
      process: [
        {
          key: 'tab2-1',
          title: '파트너 등록',
          description:
            '마이리얼트립에서 회원가입을 진행 하신 뒤, 가입한 아이디로 파트너 등록을 신청합니다.',
        },
        {
          key: 'tab2-2',
          title: '서류 및 상품 제출',
          description:
            '서류 등록하실 티켓 상품에 대한 정보를 작성하고 필요한 서류를 제출합니다.',
          caption: '※ 티켓의 경우, 기업 파트너만 판매가 가능합니다',
        },
        {
          key: 'tab2-3',
          title: '서류 및 상품 확인',
          description:
            '담당자가 제출하신 서류 및 상품을 확인하고, 필요한 경우 검수 의견을 전달 드립니다.',
        },
        {
          key: 'tab2-4',
          title: '첫 예약 받기',
          description: '마이리얼트립에서 더 많은 수익을 창출하세요.',
        },
      ],
    },
    {
      key: 3,
      process: [
        {
          key: 'tab3-1',
          title: '파트너 등록',
          description:
            '마이리얼트립에서 회원가입을 진행 하신 뒤, 가입한 아이디로 파트너 등록을 신청합니다.',
        },
        {
          key: 'tab3-2',
          title: '상품 및 서류 제출',
          description:
            '등록하실 숙소 상품에 대한 정보를 작성하고 필요한 서류를 제출합니다. ',
          caption: '※ 필수 서류: 개인 (신분증, 체류증), 기업 (사업자 등록증)',
        },
        {
          key: 'tab3-3',
          title: '서류 및 상품 확인',
          description:
            '담당자가 제출하신 서류 및 상품을 확인하고, 필요한 경우 검수 의견을 전달 드립니다.',
        },
        {
          key: 'tab3-4',
          title: '첫 예약 받기',
          description: '마이리얼트립에서 첫 번째 게스트를 만나보세요.',
        },
      ],
    },
  ],
  faq: '자주 묻는 질문',
  questions: [
    {
      key: 'faq1',
      question: 'Q.개인 파트너와 기업 파트너의 차이점은 무엇인가요?',
      answer:
        "개인 파트너는 사업자 등록증 없이 1인 혹은 팀으로 활동하는 파트너입니다. 기업파트너는 '사업자등록증'을 보유한 파트너로, 투어 앤 액티비티 상품 뿐 아니라 다양한 여행 상품을 판매할 수 있습니다.",
    },
    {
      key: 'faq2',
      question: 'Q.마이리얼트립 파트너가 되려면 어떠한 자격이 필요한가요?',
      answer:
        '[투어 앤 액티비티] 현지 거주 가능 체류 증명 서류를 소지한 분만 등록이 가능합니다 (국내 파트너 무관). 일부 상품군의 경우 별도 라이센스가 필요합니다.\n[티켓] 사업자 등록증을 소지한 경우 파트너 등록이 가능하며, 개인 파트너(프리랜서)는 등록이 불가합니다.\n[숙소] 숙소를 운영하는 국가의 비자를 소지한 분만 파트너로 활동할 수 있습니다 (국내 파트너 무관). 국내의 경우 사업자 등록증의 업태와 종목에 숙박업 및 임대업이 포함되어야 합니다.',
    },
    {
      key: 'faq3',
      question: 'Q.파트너 등록에 필요한 서류는 무엇인가요?',
      answer:
        '모든 서류는 파트너 본인 명의의 서류만 업로드할 수 있습니다.\n[개인 파트너] 신분증, 현지 체류 증명 서류 (체류증, 체류 가능 비자, 영주권 등), 개인통장 사본이 필요합니다.\n[기업 파트너] 사업자 등록증 (국내 숙소 상품은 사업자 등록증의 업태와 종목에 숙박업 및 임대업 포함 필수), 법인 통장, 사업자 등록증 상의 대표자 체류 증명 서류 (해외 한정)\n※ 상품군에 따라 추가적인 서류를 요청드릴 수 있습니다.',
    },
    {
      key: 'faq4',
      question: 'Q.수익정산은 어떤 절차로 진행되나요?',
      answer:
        '마이리얼트립의 정산 방식은 모두 후정산으로 진행되며, 여행 완료일 기준 익일 오후 2시(한국 시간 기준)부터 송금 신청이 가능합니다. 마이리얼트립 [파트너 페이지] > [정산 관리] > [입금 방식 설정]에서 계좌번호 입력 후 > [현재 수익 내역]에서 수익금을 신청해주세요.',
    },
    {
      key: 'faq5',
      question: 'Q.서비스 수수료에는 무엇이 포함되어 있나요? ',
      answer:
        '마이리얼트립은 상품 판매가에 일정 수수료율을 적용하여 예약 건별로 서비스 수수료를 부과합니다. 일반 수수료율은 20%로, 자세한 사항은 파트너 등록 시 안내드리고 있습니다.\n서비스 수수료에는 플랫폼 이용료 이외에도 카드 수수료, 부가가치세, PG사 수수료, 마케팅 비용이 모두 포함되어 있고, 서비스 수수료 외 입점 비용 등 별도 비용을 부과하지 않습니다.',
    },
  ],
  faqMore: '더 궁금한게 있으신가요?',
  customerCenter: '고객지원실',
  footer: '지금 마이리얼트립에서\n여행자를 만나보세요.',
};

export default language;
