import qs from 'qs';

type ExactMatchedPath = {
  path: string;
  ignoreQuery: boolean;
};

const REQUIRED_EXACT_MATCHING_PATH_LIST: ExactMatchedPath[] = [
  {
    path: '/accommodations',
    ignoreQuery: false,
  },
  {
    path: '/promotions',
    ignoreQuery: true,
  },
  {
    path: '/search',
    ignoreQuery: false,
  },
];
const isIncludeMenuQuery = (menuUrlQueryString: string, currentPathQueryString: string) => {
  try {
    const parsedMenuQuery = Object.entries(
      qs.parse(menuUrlQueryString, {
        ignoreQueryPrefix: true,
      }),
    );
    const parsedCurrentQuery = qs.parse(currentPathQueryString, {
      ignoreQueryPrefix: true,
    });

    return parsedMenuQuery.every(([key, value]) => parsedCurrentQuery[key] === value);
  } catch (err) {
    return false;
  }
};

const isExactMatchedPath = ({
  menuPathName,
  menuSearch,
  currentPathName,
  currentSearch,
  exactMatchedPath,
}: {
  menuPathName: string;
  menuSearch: string;
  currentPathName: string;
  currentSearch: string;
  exactMatchedPath: ExactMatchedPath;
}) => {
  // ex) menuPathName: /promotions, currentPathName: /promotions/rentacar_coupon
  // ex) menuPathName: /accommodations, currentPathName: /accommodations/stays
  const pattern = menuPathName.replace(/\/$/g, '');
  const regexp = new RegExp(`^${pattern}[/]?$`, 'g');
  const { ignoreQuery } = exactMatchedPath;
  const isExactMatched = regexp.test(currentPathName);

  if (!ignoreQuery) {
    const [, parsedMenuQuery] = menuSearch.split('?');
    const isIncludeQuery = isIncludeMenuQuery(parsedMenuQuery, currentSearch);

    return isIncludeQuery && isExactMatched;
  }

  return isExactMatched;
};

const parseMenuUrl = (menuUrl: string) => {
  try {
    const { pathname, search } = new URL(menuUrl);

    return { menuPathName: pathname, menuSearch: search };
  } catch (error) {
    return null;
  }
};

const isTravelerService = (menuUrl: string) => {
  try {
    const { hostname: serviceHostName } = new URL(process.env.HOME_URL);
    const { hostname: menuHostName } = new URL(menuUrl);

    return serviceHostName === menuHostName || menuHostName.endsWith('myrealtrip.com');
  } catch (_error) {
    return false;
  }
};

export const isActiveMenu = ({
  code,
  menuUrl,
  isFlightMode,
}: {
  code: string;
  menuUrl: string;
  isFlightMode: boolean;
}) => {
  // 항공 서비스에서 동작하는 경우 항공권 메뉴 아이템 활성화 처리
  if (isFlightMode) {
    return code === 'flights';
  }

  // "홈" 매뉴의 경우 비활성화 처리
  if (code === 'home') {
    return false;
  }

  // 외부 서비스인 경우 비활성화 처리
  if (!isTravelerService(menuUrl)) {
    return false;
  }

  const { menuPathName = '', menuSearch = '' } = parseMenuUrl(menuUrl) ?? {};
  const { pathname: currentPathName, search: currentSearch } = window.location;

  // 메뉴 링크가 루트로 되어있는 경우 비활성화 처리
  if (menuPathName === '/') {
    return false;
  }

  // accommodations는 숙소와 국내펜션의 path가 중복된다
  // promotion는 프로모션 목록, 하위 페이지와 path가 중복되지 않도록 처리한다.
  const exactMatchedPath = REQUIRED_EXACT_MATCHING_PATH_LIST.find(
    ({ path }) => menuPathName.startsWith(path) && currentPathName.startsWith(path),
  );

  if (exactMatchedPath) {
    return isExactMatchedPath({
      menuPathName,
      menuSearch,
      currentPathName,
      currentSearch,
      exactMatchedPath,
    });
  }

  return currentPathName.startsWith(menuPathName);
};
