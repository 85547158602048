const checklist = {
  insurance: {
    name: '여행자보험',
    desc: {
      desktop: '공항보다 저렴하게, 3분만에 여행자보험 가입하고 마음편한 여행을 즐기세요.',
      mobile: '공항보다 저렴하게 3분만에 가입하세요.',
    },
  },
  shilladfs: {
    name: '신라인터넷면세점',
    desc: {
      desktop: '마이리얼트립 구매 회원에게 신라인터넷 면세점 적립금 10만원을 드립니다.',
      mobile: '신라인터넷면세점 적립금 10만원을 드립니다.',
    },
  },
  ssgdfs: {
    name: '신세계면세점',
    desc: {
      desktop: '마이리얼트립 구매고객에게 제휴 캐시 & 스마트 선불 카드 증정!',
      mobile: '구매고객에게 제휴캐시&스마트 선불카드 증정!',
    },
  },
  wifi: {
    name: '포켓 와이파이',
    desc: {
      desktop: '데이터 요금 폭탄 걱정없이 전세계 어디서나 와이파이를 이용하세요.',
      mobile: '데이터 요금 걱정 없이 전세계 어디서나!',
    },
  },
  rent: {
    name: '렌터카',
    desc: {
      desktop: '해외 유명 렌터카 가격 비교와 예약을 한번에! 쉽고 빠르게 예약해보세요.',
      mobile: '해외 유명 렌터카 가격 비교와 예약을 한번에!',
    },
  },
  myvisa: {
    name: '마이비자',
    desc: {
      desktop: '국가별 필요한 비자 서류를 확인하고 쉽고 빠르게 신청하세요.',
      mobile: '해외여행에 필요한 비자, 이제 쉽게 신청하세요.',
    },
  },
  usim: {
    name: '유심',
    desc: {
      desktop: '해외여행의 필수품 유심! 데이터 요금 걱정없이 편하고 저렴하게 이용해보세요!',
      mobile: '해외여행의 필수품 유심을 저렴하게!',
    },
  },
};

export default checklist;
