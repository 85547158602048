import { Children } from 'react';

import { Accordion, BREAK_POINT } from '@myrealtrip/design-system';
import { useDetermineMobileContext } from 'mrt-components';
import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import media from 'mrt-utils/mediaQuery';

import styled from '@emotion/styled';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';
import { helper } from '@myrealtrip/frontend-weblog';

const { sendClickLog } = helper;

type MenuItem = {
  label: string;
  link: string;
};

type Props = {
  title: string;
  menuItems: MenuItem[];
};

export default function SiteMapItems({ title, menuItems }: Props) {
  const isMobile = useDetermineMobileContext();

  const handleClick = (targetUrl: string) => () => {
    sendClickLog({
      eventName: 'footer',
      screenName: WeblogScreenName.COMMON,
      pageCategory: WeblogPageCategory.COMMON,
      menuTitle: title,
      targetUrl,
    });
  };

  if (isMobile) {
    return (
      <Container>
        <MenuAccordion title={title}>
          {Children.toArray(
            menuItems.map(({ label, link }) => (
              <MenuItemBox>
                <MenuLink href={link} onClick={handleClick(link)}>
                  {label}
                </MenuLink>
              </MenuItemBox>
            )),
          )}
        </MenuAccordion>
      </Container>
    );
  }

  return (
    <Container>
      <Title>{title}</Title>
      <MenuBox>
        {Children.toArray(
          menuItems.map(({ label, link }) => (
            <MenuItemBox>
              <MenuLink href={link} onClick={handleClick(link)}>
                {label}
              </MenuLink>
            </MenuItemBox>
          )),
        )}
      </MenuBox>
    </Container>
  );
}

const Container = styled.div`
  width: calc(100% / 3);

  ${media.mobile} {
    width: 100%;
  }
`;

const Title = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: ${globalVariables.gray_600};
`;

const MenuBox = styled.ul`
  margin-top: 10px;
`;

const MenuItemBox = styled.li`
  display: flex;
  height: 32px;
  align-items: center;

  ${media.mobile} {
    height: 52px;
  }
`;

const MenuLink = styled.a`
  position: relative;
  left: -6px;
  padding: 6px;
  font-size: 14px;
  color: ${globalVariables.gray_600};
  border-radius: 4px;
  transition: all 0.2s ease;

  @media (min-width: ${BREAK_POINT.MOBILE}px) {
    &:hover {
      color: ${globalVariables.blue_500};
      background-color: ${globalVariables.blue_50};
    }
  }
`;

const MenuAccordion = styled(Accordion)`
  .mrt-accordion-title {
    padding: 16px 0;

    * {
      font-size: 15px;
      font-weight: 700;
      color: ${globalVariables.gray_600};
    }

    &:after {
      width: 100%;
      left: 0;
    }
  }
`;
