const booking_assistant = {
  check_price: 'Check price',
  update_price: 'Update price',
  reserve_btn: 'Book Now',
  ticket_cancel: 'Cancel',
  option_btn: 'Confirm',
  calendar_heading: {
    common: 'Choose date of travel. (E-ticket: Date of use, Physical product: Date of pick-up)',
    lodging: 'Choose check-in and check-out dates.',
  },
  loader: {
    title: 'Price is updated based on exchange rate of every midnight.',
    loading: 'Loading price…',
  },
  select_option: 'Select Options',
  detail_price: 'Price Details',
  total_price: 'Total Price',
  help_link: 'What is Check Price?',
  option_default: 'Select Options',
  option_default_mobile: 'When you choose date, possible options show up.',
  travelers_default: 'Travelers',
  beginAt_default: 'Date',
  cancellation_policy: 'Cancellation & Refund Policy',
  error_message: '날짜 또는 옵션을 확인해주세요.',
  option_description: '▸ Option Description: ',
  price_explain: {
    title: 'What is Check Price?',
    content:
      ' The price of the products may differ depending on the date of travel, age and number of travelers. For your convenience, MyRealTrip provides you with a function which allows you to see the price before proceeding payment. For further inquiries, contact Help Center.',
  },
};

export default booking_assistant;
