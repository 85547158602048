import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@myrealtrip/design-system/dist/components/Icon';

import FlightPassengerInfo from './FlightPassengerInfo';

import { getFlightTime } from '../../utils/domesticReservationUtils';

import styles from './FlightTimeInfo.module.scss';

function FlightTimeInfo(props) {
  const { data, isMobile } = props;

  return (
    <div className={styles.timeAndPassengerInfo}>
      <div className={styles.timeInfo}>
        <div>
          <span className={styles.timeHM}>
            {data.DEP_TM.slice(0, 2)}:{data.DEP_TM.slice(2, 4)}
          </span>
          <span className={styles.airportCode}>{data.DEP_AIRPORT_CD}</span>
        </div>
        {isMobile ? (
          <div className={styles.timebar}>
            <span>-</span>
          </div>
        ) : (
          <div>
            <Icon name="img_flightarrow_direct" />
            <span className={styles.airplaneTime}>
              {getFlightTime(data.FLIGHT_TIME)}
            </span>
          </div>
        )}
        <div>
          <span className={styles.timeHM}>
            {data.ARR_TM.slice(0, 2)}:{data.ARR_TM.slice(2, 4)}
          </span>
          <span className={styles.airportCode}>{data.ARR_AIRPORT_CD}</span>
        </div>
      </div>
      {isMobile ? (
        <div>
          <FlightPassengerInfo data={data} isMobile={isMobile} />
        </div>
      ) : null}
    </div>
  );
}

FlightTimeInfo.propTypes = {
  data: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default FlightTimeInfo;
