import { Configs } from 'mrt-constants';
import { Section, View } from 'mrt-constants/globalSearch/suggestion';
import { useDidMount } from 'mrt-hooks';
import { GlobalSearch } from 'mrt-types';

import { useLocalStorage } from '@myrealtrip/react';

const key = 'recent-search';

function useSearchManager() {
  const [recentKeywords, setRecents, removeRecentKeywords] =
    useLocalStorage<GlobalSearch.Banner[]>(key);
  const recents = recentKeywords ?? [];
  const hasRecentKeywords = recents?.length > 0;

  const convertBanner = (title: string): GlobalSearch.Banner => {
    const encodeQuery = encodeURIComponent(title);
    const linkUrl = `${Configs.SERVICE_DOMAIN}/search-bridge?q=${encodeQuery}`;

    return {
      sectionType: Section.BANNER,
      viewType: View.LINK_LEFT_ICON_TEXT,
      image: { url: '' },
      titles: [{ text: title, color: '#343A40' }],
      linkUrl,
      loggingMeta: {},
    };
  };

  const getSearchWord = (banner: GlobalSearch.Banner) => {
    const { titles } = banner;
    const reducer = (prev: string, current: string) => prev + current;
    const searchWord = titles.map(({ text }) => text).reduce(reducer);

    return searchWord;
  };

  const checkEmptyKeyword = (banner: GlobalSearch.Banner) => {
    const searchWord = getSearchWord(banner);
    const isEmptyKeyword = searchWord.trim().length === 0;

    return isEmptyKeyword;
  };

  const setRecentKeywords = (banner: GlobalSearch.Banner) => {
    if (!checkEmptyKeyword(banner)) {
      const keywordTitle = getSearchWord(banner);
      const filteredKeywords = recents
        .filter((recentBanner) => {
          const searchWord = getSearchWord(recentBanner);

          return searchWord !== keywordTitle;
        })
        .slice(0, 4);
      const newRecentKeywords = [banner, ...filteredKeywords];

      setRecents([...newRecentKeywords]);
    }
  };

  const redirectSearchPage = (banner: GlobalSearch.Banner) => {
    const { titles, linkUrl } = banner;
    if (!banner || !titles) {
      return;
    }

    const searchWord = getSearchWord(banner);
    const encodeQuery = encodeURIComponent(searchWord);
    const href = linkUrl || `${Configs.SERVICE_DOMAIN}/search-bridge?q=${encodeQuery}`;

    setRecentKeywords(banner);

    window.location.href = href;
  };

  useDidMount(() => {
    const recentSearch: GlobalSearch.Banner[] = recents.map((value) => {
      if (typeof value === 'string') {
        return convertBanner(value);
      }

      return value;
    });
    if (recentSearch.length > 0) {
      setRecents(recentSearch);
    }
  });

  return {
    recentKeywords: recents,
    convertBanner,
    getSearchWord,
    setRecentKeywords,
    removeRecentKeywords,
    redirectSearchPage,
    hasRecentKeywords,
  };
}

export default useSearchManager;
