export function getDomain() {
  const { origin, host, protocol } = window.location;

  return origin || `${protocol}//${host}`;
}

export function generateUrlFromPathname(pathname) {
  const domain = getDomain();
  const hasDomain = /^http(s)?:\/\//gi.test(pathname);

  if (hasDomain) {
    return pathname;
  }

  const validPathname = pathname.startsWith('/') ? pathname : `/${pathname}`;

  return `${domain}${validPathname}`;
}

export function extractPathnameFromUrl(url) {
  const domain = getDomain();
  const regexp = new RegExp(`^${domain}`, 'gi');

  return url.replace(regexp, '');
}
