/* eslint-disable @typescript-eslint/naming-convention */

import useDetermineMobileContext from 'mrt-components/hooks/useDetermineMobileContext';
import media from 'mrt-utils/mediaQuery';

import styled from '@emotion/styled';
import NotificationItems from '@layout/app/components/NotificationPopup/NotificationItems';
import useFetchNotification from '@layout/app/hooks/useFetchNotification';
import i18n from '@layout/app/utils/i18n';
import { resetButton } from '@layout/styles/reset';
import Button from '@myrealtrip/design-system/dist/components/Buttons/Button';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import useClickOutside from '@myrealtrip/design-system/dist/hooks/useClickOutside';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function NotificationPopup({ isOpen, onClose }: Props) {
  const isMobile = useDetermineMobileContext();
  const { hasNextPage, fetchNextPage, isFetching } = useFetchNotification();
  const canShowMoreBtn = hasNextPage && !isFetching;

  const handleMoreClick = () => fetchNextPage();

  const { ref } = useClickOutside(isOpen, [], onClose);

  if (!isOpen) {
    return null;
  }

  return (
    <Container isOpen={isOpen} ref={ref}>
      <ContainerArrow />
      <Content>
        <ContentHeader>
          <ContentTitle>{i18n.t('header:notification')}</ContentTitle>
          {isMobile && (
            <CloseButton type="button" onClick={onClose}>
              <Icon name="ic_close_md_gray_500" />
            </CloseButton>
          )}
        </ContentHeader>
        <ContentBody>
          <NotificationItems />
          {canShowMoreBtn && (
            <MoreButtonContainer>
              <Button type="outline" size="medium" onClick={handleMoreClick}>
                {i18n.t('header:notiMore')}
              </Button>
            </MoreButtonContainer>
          )}
        </ContentBody>
      </Content>
    </Container>
  );
}

const Container = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 45px;
  right: 0;
  z-index: ${globalVariables.z_popup};

  width: 375px;
  height: 410px;
  border: 1px solid ${globalVariables.gray_100};
  background-color: ${globalVariables.white};
  box-shadow: 0 1px 1px 0 rgb(0, 0, 0, 0.2);

  ${media.mobile} {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;
const ContainerArrow = styled.span`
  display: block;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
  }

  &:after {
    border-color: transparent transparent ${globalVariables.white};
    border-width: 6px;
    right: 74px;
    top: -12px;
  }

  &:before {
    border-color: transparent transparent ${globalVariables.gray_200};
    border-width: 6px;
    right: 74.5px;
    top: -13px;
  }

  ${media.mobile} {
    display: none;
  }
`;

const Content = styled.div`
  height: 100%;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 14px;
  border-bottom: 1px solid ${globalVariables.gray_100};
`;

const ContentTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${globalVariables.gray_800};
`;

const CloseButton = styled.button`
  ${resetButton};
`;

const ContentBody = styled.div`
  height: 100%;
  max-height: 353px;
  overflow-y: auto;

  ${media.mobile} {
    max-height: initial;
    height: calc(100% - 57px);
  }
`;

const MoreButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin: 0 15px;
  border-top: 1px solid ${globalVariables.gray_100};
`;
