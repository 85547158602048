/* eslint-disable react/jsx-key */
import { Children } from 'react';

import { GlobalSearch } from 'mrt-types';

import styled from '@emotion/styled';

import useSearchOffers from '../hooks/apis/useSearchOffers';
import SuggestionKeywordItem from './SuggestionKeywordItem';

interface Props {
  handleRef?: (idx: number, node: HTMLAnchorElement) => void;
  selectedIdx?: number;
}

function SuggestionKeywords({ handleRef, selectedIdx }: Props) {
  const { data, isError } = useSearchOffers();
  const sections = isError ? [] : data?.sections || [];
  const banners = sections.filter(
    (section) => section.sectionType === 'BANNER',
  ) as Array<GlobalSearch.Banner>;

  if (!banners) {
    return null;
  }

  return (
    <Container>
      {Children.toArray(
        banners.map((item, idx) => (
          <SuggestionKeywordItem
            ref={(node: HTMLAnchorElement) => handleRef?.(idx, node)}
            data={item}
            isSelected={selectedIdx === idx}
          />
        )),
      )}
    </Container>
  );
}

export default SuggestionKeywords;

const Container = styled.div`
  padding-top: 4px;
`;
