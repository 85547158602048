import { useEffect, useRef } from 'react';

import { motion, useAnimation, useInView } from 'framer-motion';

import styled from '@emotion/styled';

interface Props {
  fontSize: number;
  duration: number;
  delay: number;
  index: number;
  once?: boolean;
  verticalReverse: boolean;
  className?: string;
}

function AnimatedComma({
  fontSize,
  duration,
  delay,
  index,
  once,
  verticalReverse,
  className,
}: Props) {
  const animateRef = useRef(null);
  const inView = useInView(animateRef, { once });
  const controls = useAnimation();
  const initialY = verticalReverse ? -fontSize : fontSize;
  const initialSet = { y: initialY };
  const animationSet = {
    y: 0,
    transition: {
      delay: delay * index,
      duration,
    },
  };

  useEffect(() => {
    if (inView && animateRef.current) {
      controls.set(initialSet);
      controls.start(animationSet);
    }
  }, [animationSet, controls, inView, initialSet]);

  return (
    <CommaContainer ref={animateRef} className={className}>
      <Comma fontSize={fontSize} initial={initialSet} animate={controls} layout>
        ,
      </Comma>
    </CommaContainer>
  );
}

export default AnimatedComma;

const CommaContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const Comma = styled(motion.div)<{ fontSize: number }>`
  text-align: center;
  font-size: ${({ fontSize }) => fontSize}px;
  height: ${({ fontSize }) => fontSize}px;
  display: flex;
  align-items: center;
  // 성능 최적화
  will-change: transform;
  // GPU 가속으로 성능향상
  transform: translate3d(0, 0, 0);
`;
