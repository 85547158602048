const main = {
  allRegion: '전체 여행지',
  header: '여행이 돌아왔어요!',
  travelScanner: '격리 없는 여행지',
  totalCities: '전체 여행지',
  recent: '최근 본 상품',
  information: '마이리얼트립의 여행 정보',
  facebook: {
    title: '마이리얼트립 페이스북 페이지',
    desc:
      '마이리얼트립 페이스북 페이지에서 각 여행지별로 정리된 유용한 정보와 여행자분들을 위한 이벤트를 확인해보세요.',
  },
  instagram: {
    title: '마이리얼트립 인스타그램',
    desc:
      '세계 곳곳에서 활동중인 마이리얼트립의 가이드님과 여행자분들이 보내주신 아름다운 사진을 감상해보세요.',
  },
  naver: {
    title: '마이리얼트립 네이버 블로그',
    desc:
      '마이리얼트립의 네이버 블로그에서 리얼생생 여행정보, 여행 후기, 추천 가이드 정보를 확인해보세요.',
  },
  products: '전 세계 {{offers}}개의 여행!',
  use: '마이리얼트립 이용방법 알아보기',
  logo: '마이리얼트립',
  recommendWithCity: '{{city}} 투어&티켓 추천',
  assurancePrefix: '여행자 보험, ',
  assuranceSuffix: '공항보다 저렴하게 준비해보세요!',
};

export default main;
