export const gtmEnum = Object.freeze({
  wishlist: 'gtm-gnb-wishlist',
  reservation: 'gtm-gnb-traveler',
  message: 'gtm-gnb-messenger',
  search: 'gtm-gnb-search',
  notification: 'gtm-gnb-alarm',
  account: 'gtm-gnb-account',
  invitation: 'gtm-gnb-invite-friend',
  helpChat: 'gtm-gnb-helpChat',
  region: 'gtm-lnb-region',
  signin: 'gtm-gnb-signin',
  signup: 'gtm-gnb-signup',
  signout: 'gtm-gnb-signout',
  becomeGuideBeforeLogin: 'gtm-gnb-becomeguide-before-login',
  becomeGuideAfterLogin: 'gtm-gnb-becomeguide-after-login',
  howToUse: 'gtm-lnb-howtouse',
});

export const isRomaPageEnum = Object.freeze({
  wishlist: false,
  reservation: false,
  message: false,
  search: false,
  account: false,
  point: false,
  coupon: false,
  invitation: false,
  region: false,
  signin: true,
  signup: true,
  signout: true,
  becomeGuideBeforeLogin: true,
  becomeGuideAfterLogin: true,
  howToUse: true,
});

export const roomAmenityEnum = Object.freeze({
  freeCancellation: 'freeCancellation',
  deferredPayment: 'deferredPayment',
  freeBreakfast: 'FREE_BREAKFAST',
  freeWifi: 'FREE_WIFI',
});

export const toastTypeEnum = Object.freeze({
  success: 'success',
  warning: 'warning',
  general: 'general',
});

export const sizeEnum = Object.freeze({
  xLarge: 'xLarge',
  large: 'large',
  medium: 'medium',
  small: 'small',
  xSmall: 'xSmall',
});

export const buttonThemeEnum = Object.freeze({
  primary: 'primary',
  secondary: 'secondary',
  outline: 'outline',
  gray: 'gray',
  link: 'link',
  blank: 'blank',
});

export const buttonModifierEnum = Object.freeze({
  block: 'block',
  round: 'round',
});

export const cashbackRateEnum = Object.freeze({
  tourAbove10: 0.08,
  tourBelow10: 0.06,
  onlyHotel: 0.04,
});

export const popupPositionEnum = Object.freeze({
  bottomLeft: 'bottomLeft',
  bottomRight: 'bottomRight',
  rightBottom: 'rightBottom',
  rightTop: 'rightTop',
  topRight: 'topRight',
  topLeft: 'topLeft',
});

export const hotelFacilityEnum = Object.freeze({
  etc: 'ETC',
  freeWifi: 'FREE_WIFI',
  wifi: 'WIFI',
  luggage: 'LUGGAGE_STORAGE',
  frontDesk24: 'FRONT_DESK_24',
  noFrontDesk: 'NO_FRONT_DESK',
  frontDesk: 'FRONT_DESK',
  bbq: 'BARBECUE',
  pool: 'POOL',
  elevator: 'ELEVATOR',
  laundary: 'LAUNDRY',
  restaurant: 'RESTAURANT',
  parking: 'PARKING',
  smoke: 'SMOKE',
  business: 'MEETING_ROOM',
  roomService: 'ROOM_SERVICE',
  internet: 'INTERNET',
  garden: 'GARDEN',
  lobby: 'LOBBY',
  shuttle: 'SHUTTLE',
  cafe: 'CAFE_BAR',
  terrace: 'TERRACE',
  fitness: 'FITNESSCENTER',
  spa: 'SPA',
  wedding: 'WEDDING',
  club: 'CLUB',
  kitchen: 'KITCHEN',
  bath: 'BATH',
  childcare: 'CHILDCARE',
  shopping: 'SHOPPING',
  hairSalon: 'HAIRSALON',
  atm: 'ATM',
  activity: 'ACTIVITY',
  library: 'LIBRARY',
  casino: 'CASINO',
  beach: 'BEACH',
  freeBreakfast: 'FREE_BREAKFAST',
  freeParking: 'FREE_PARKING',
  breakfast: 'BREAKFAST',
  freePickup: 'FREE_PICKUP',
});

export const hotelFreeServiceNameEnum = Object.freeze({
  [hotelFacilityEnum.freeBreakfast]: '무료 조식',
  [hotelFacilityEnum.freeWifi]: '무료 WI-FI',
  [hotelFacilityEnum.freePickup]: '무료 공항픽업',
  [hotelFacilityEnum.freeParking]: '무료 주차장',
});

export const hotelFacilityNameEnum = {
  [hotelFacilityEnum.wifi]: '와이파이',
  [hotelFacilityEnum.breakfast]: '조식',
  [hotelFacilityEnum.shuttle]: '공항셔틀',
  [hotelFacilityEnum.pool]: '수영장',
  [hotelFacilityEnum.restaurant]: '레스토랑',
  [hotelFacilityEnum.luggage]: '수하물보관',
  [hotelFacilityEnum.frontDesk24]: '24시리셉션',
  [hotelFacilityEnum.fitness]: '피트니스',
  [hotelFacilityEnum.spa]: '스파',
  [hotelFacilityEnum.business]: '비즈니스시설',
  [hotelFacilityEnum.parking]: '주차',
  [hotelFacilityEnum.laundary]: '세탁',
};

export const hotelCategoryEnum = Object.freeze({
  hotel: 'HOTEL',
  apartment: 'APARTMENT',
  hostel: 'HOSTEL',
  guesthouse: 'GUESTHOUSE_BNB',
  motel: 'MOTEL',
  pension: 'PENSION',
  resort: 'RESORT',
  camp: 'CAMP',
  etc: 'ETC',
});

export const hotelCategoryNameEnum = {
  [hotelCategoryEnum.hotel]: '호텔',
  [hotelCategoryEnum.apartment]: '아파트',
  [hotelCategoryEnum.hostel]: '호스텔',
  [hotelCategoryEnum.guesthouse]: '게스트하우스',
  [hotelCategoryEnum.motel]: '모텔',
  [hotelCategoryEnum.pension]: '펜션',
  [hotelCategoryEnum.resort]: '리조트',
  [hotelCategoryEnum.camp]: '캠핑장',
  [hotelCategoryEnum.etc]: '기타',
};

export const hotelAgencyEnum = Object.freeze({
  agoda: 'AGODA',
  booking: 'BOOKING_DOT_COM',
  expedia: 'EXPEDIA',
});

export const hotelAgencyNameEnum = Object.freeze({
  [hotelAgencyEnum.agoda]: '아고다',
  [hotelAgencyEnum.booking]: '부킹닷컴',
  [hotelAgencyEnum.expedia]: '익스피디아',
});

export const HotelPromotionEnum = Object.freeze({
  [hotelAgencyEnum.booking]: {
    title: '최대 8% 현금 캐시백',
    content: '투어&티켓 예약시 무제한 지급!',
  },
  [hotelAgencyEnum.expedia]: {
    title: '4% 할인 코드',
    content: '할인 코드 확인하고 저렴하게 예약하세요!',
  },
  [hotelAgencyEnum.agoda]: {
    title: '4% 할인 쿠폰',
    content: '할인 쿠폰 다운받고 저렴하게 예약하세요!',
  },
});

export const hotelSortEnum = Object.freeze({
  recommendation: 'WEIGHT_DESC',
  lowPrice: 'PRICE_ASC',
  highPrice: 'PRICE_DESC',
  rating: 'RATING_DESC',
  distance: 'DISTANCE_ASC',
  star: 'STAR_DESC',
});

export const hotelSortLabelEnum = Object.freeze({
  [hotelSortEnum.recommendation]: '추천순',
  [hotelSortEnum.lowPrice]: '가격 낮은순',
  [hotelSortEnum.highPrice]: '가격 높은순',
  [hotelSortEnum.rating]: '평점순',
  [hotelSortEnum.distance]: '거리순',
  [hotelSortEnum.star]: '등급순',
});

export const hotelToolbarEnum = Object.freeze({
  list: 'list',
  map: 'map',
  filter: 'filter',
  sort: 'sort',
});

// styles/_include_media.scss의 breakpoints와 동일함.
export const breakpointEnum = Object.freeze({
  desktop: 1200,
  tablet: 992,
  phone: 768,
  smallPhone: 320,
});

export const placeTypeEnum = Object.freeze({
  city: 'city',
  country: 'country',
  landmark: 'landmark',
  district: 'district',
  airport: 'airport',
  region: 'region',
  hotel: 'hotel',
});

export const keyboardAliasEnum = Object.freeze({
  arrowUp: 'ArrowUp',
  arrowDown: 'ArrowDown',
  arrowRight: 'ArrowRight',
  arrowLeft: 'ArrowLeft',
  enter: 'Enter',
  esc: 'Escape',
});

export const keyboardCodeEnum = Object.freeze({
  arrowLeft: 37,
  arrowUp: 38,
  arrowRight: 39,
  arrowDown: 40,
  enter: 13,
  esc: 27,
});

export const cacheKeyEnum = Object.freeze({
  recentSearch: 'recent-search',
});

export const modalPositionEnum = Object.freeze({
  bottom: 'bottom',
  center: 'center',
  top: 'top',
});

export const datePickerEnum = Object.freeze({
  startDate: 'startDate',
  endDate: 'endDate',
  horizontal: 'horizontal',
  vertical: 'verticalScrollable',
  range: 'range',
  single: 'single',
});

export const mobileOSEnum = Object.freeze({
  ios: 'iOS',
  android: 'android',
  window: 'window',
  unknown: 'unknown',
});

export const hotelRouteEnum = Object.freeze({
  list: '/',
  location: '/location',
  filter: '/filter',
  rooms: '/rooms',
  images: '/images',
});

export const hotelMetaEntryEnum = Object.freeze({
  web: 'accommodations',
  webview: 'instants',
});

export const apiStatusEnum = Object.freeze({
  idle: 'idle',
  loading: 'loading',
  error: 'error',
});

export const mapEventEnum = Object.freeze({
  centerChanged: 'center_changed',
  dragStarted: 'dragstart',
  dragEnded: 'dragend',
});

export const imageViewerEnum = Object.freeze({
  bigViewerImageLength: 4,
  smallViewerImageLength: 3,
});

export const stayImageViewerLengthEnum = Object.freeze({
  big: 5,
  small: 4,
});

export const stayImageViewerEnum = Object.freeze({
  bigViewerImageLength: 5,
  smallViewerImageLength: 4,
});

export const browserEnum = Object.freeze({
  ie: 'IE',
  edge: 'Edge',
  opera: 'Opera',
  chrome: 'Chrome',
  safari: 'Safari',
  firefox: 'Firefox',
  unknown: 'unknown',
});

export const headerThemeEnum = Object.freeze({
  white: 'white',
  transparent: 'transparent',
  blue: 'blue',
});

export const headerAlignEnum = Object.freeze({
  default: 'default',
  spaceBetween: 'spaceBetween',
});

export const buttonTypeEnum = Object.freeze({
  button: 'button',
  link: 'link',
});

export const searchThemeEnum = Object.freeze({
  white: 'white',
  transparent: 'transparent',
  tour: 'tour',
});

export const searchPopupTypeEnum = Object.freeze({
  default: 'default',
  fit: 'fit',
  wide: 'wide',
});

export const checklistEnum = Object.freeze({
  usim: 'usim',
  rent: 'rent',
  myvisa: 'myvisa',
  wifi: 'wifi',
});

export const refundStatusEnum = Object.freeze({
  requested: 'requested',
  waiting: 'waiting',
  completed: 'completed',
});

export const refundReasonEnum = Object.freeze({
  personal: 'traveler_personal_reason',
  overTraveler: 'over_traveler',
  lackTraveler: 'lack_traveler',
  guideSchedule: 'guide_schedule',
  guideNoResponse: 'guide_no_response',
  health: 'health_problem',
  retry: 'retry_reservation',
  disaster: 'disaster',
});

export const osEnum = Object.freeze({
  mac: 'Macintosh',
  window: 'Window',
  linux: 'Linux',
  unknown: 'unknown',
});

export const cardThemeEnum = Object.freeze({
  hotel: 'hotel',
  offer: 'offer',
  stay: 'stay',
});

export const cardTypeEnum = Object.freeze({
  vertical: 'vertical',
  horizontal: 'horizontal',
  bestSeller: 'bestSeller',
});

export const reviewTypeEnum = Object.freeze({
  mrt: 'mrt',
  istanbul: 'istanbul',
});

export const offerTypeEnum = Object.freeze({
  tour: 'Tour',
  ticket: ['IstanbulTicket', 'DeliveryTicket', 'Ticket', 'ETicket'],
  lodging: 'Lodging',
  hotdeal: 'HotDeal',
});

export const messageTypeEnum = Object.freeze({
  message: 'message',
  hotel: 'hotel',
});

export const hotelAppEventEnum = Object.freeze({
  sendEvent: 'sendEvent',
  searchHotel: 'search_hotel',
  viewHotelDetail: 'mrt_screen_view',
  clickMapView: 'click_mapview',
  applyFilter: 'apply_filter',
  clickPromotion: 'click_promo',
  selectHotel: 'select_hotel',
  clickImage: 'click_image',
  clickPrice: 'click_price',
  clickMorePrice: 'click_moreprice',
  clickMap: 'click_map',
  clickLandmark: 'click_landmark',
  clickFacility: 'click_facility',
  clickInformation: 'click_information',
  selectOffer: 'select_offer',
  selectMoreHotel: 'select_hotel_more',
});

export const hotelAppScreenEnum = Object.freeze({
  list: 'hotel_list',
  filter: 'hotel_filter',
  detail: 'hotel_detail',
});

export const checklistAppEventEnum = Object.freeze({
  clickBanner: 'click_banner',
  clickRentcar: 'click_rentcar',
  clickInsurance: 'click_insurance',
  clickTaxfree: 'click_taxfree',
  clickWifi: 'click_wifi',
  clickMyvisa: 'click_myvisa',
  clickUsim: 'click_usim',
});

export const airtelCategoryEnum = Object.freeze({
  total: 'all',
  airtel: 'airtel',
  package: 'package',
  transport: 'transport',
  rentacar: 'rentacar',
  accommodation: 'accommodation',
});

export const airtelToolbarEnum = Object.freeze({
  date: 'date',
  sort: 'sort',
});

export const lodgingToolbarEnum = Object.freeze({
  sort: 'sort',
});

export const alignEnum = Object.freeze({
  horizontal: 'horizontal',
  vertical: 'vertical',
});

export const airtelSortEnum = Object.freeze({
  popular: 'popular',
  reviews: 'reviews',
  price: 'price',
  recent: 'recent',
});

export const lodgingSortEnum = Object.freeze({
  popular: 'popular',
  reviews: 'reviews',
  price: 'price',
  recent: 'recent',
});

export const cardPurposeEnum = Object.freeze({
  swiper: 'swiper',
  grid: 'grid',
});

export const hotelCardTypeEnum = Object.freeze({
  common: 'common',
  meta: 'meta',
});

export const gaEventCategoryEnum = Object.freeze({
  common: '공통',
  home: '홈',
  tour: '투어&티켓홈',
  checklist: '여행준비',
  message: '메시지',
  reservation: '예약내역',
  search: '검색',
  accommodation: '숙소 메인',
  hotdeal: '특가여행 메인',
  country: '나라',
  city: '도시',
  tourlist: '투어티켓 리스트',
  hotellist: '호텔 리스트',
  lodginglist: '민박 리스트',
  hotdeallist: '특가여행 리스트',
  offer: '상품 상세페이지',
  purchase: '구매하기 페이지',
  complete: '구매완료 페이지',
  hotel: '호텔 상세페이지',
  hotelPromotion: '호텔 프로모션',
  staylist: '국내 숙소 리스트',
  stay: '국내 숙소 상세페이지',
});

export const webLogPageCategoryEnum = Object.freeze({
  common: '공통',
  main: '메인',
  tourTicket: '투어&티켓',
  hotelMain: '호텔',
  hotelList: '호텔메타 리스트',
  hotelMap: '호텔메타 지도뷰',
  hotelDetail: '호텔메타 상세',
  lodgeMain: '제주숙소/한인민박 홈',
  pensionList: '펜션 리스트', // deprecated
  pensionMap: '펜션 지도뷰', // deprecated
  pensionDetail: '펜션 상세', // deprecated
  domesticAccommodationList: '국내숙소 리스트',
  domesticAccommodationMap: '국내숙소 지도뷰',
  domesticAccommodationDetail: '국내숙소 상세',
  themeList: '테마 리스트',
  campaign: '프로모션 페이지',
  search: '검색 리스트',
  event: '이벤트 페이지',
  eventDGM: '숙박대전 이벤트 페이지',
  messageList: '메세지 리스트',
  messageDetail: '메세지 상세',
  city: '도시 페이지',
  country: '나라 페이지',
  offerList: '상품 리스트',
  searchResult: '검색 결과',
  offerDetail: '상품 상세',
  profileMain: '프로필 관리 메인',
  profileAuth: '프로필 편집 인증',
  refundAccountCreate: '환불계좌 등록',
  point: '내 포인트',
  usableCoupon: '내 쿠폰(사용가능한 쿠폰)',
  expiredCoupon: '내 쿠폰(지난 쿠폰)',
  reservationHistoryOngoing: '예약내역 (예정된 여행)',
  reservationHistoryVoucher: '예약내역 (티켓/패스)',
  reservationHistoryFlight: '예약내역 (항공권)',
  reservationHistoryFinish: '예약내역 (지난 여행)',
  reservationHistoryCancel: '예약내역 (취소된 여행)',
  reservationDetail: '예약 상세',
  reviewCreate: '후기 작성하기',
  receipt: '영수증 팝업',
  purchase: '결제하기',
  purchaseComplete: '결제완료',
  invite: '친구초대',
  partnerCreate: '파트너 등록하기',
  about: '회사소개',
  career: '채용',
  b2b: '제휴 문의',
  realguide: '리얼파트너',
  bestPrice: '최저가 보장제',
  terms: '이용약관',
  privacyPolicy: '개인정보취급방침',
  cancelPolicy: '취소 및 환불정책',
  regions: '전체 여행지',
  contents: '콘텐츠',
  signIn: '로그인',
  signUp: '회원가입',
  emailSignIn: '이메일 로그인',
  emailSignUp: '이메일 회원가입',
  flightsRsvDetail: '예약상세(발권상태)',
});

export const webLogScreenNameEnum = Object.freeze({
  common: 'common',
  main: 'main',
  tourTicket: 'tourticket',
  hotelMain: 'hotel_main',
  hotelList: 'hotel_list',
  hotelMap: 'hotel_map_list',
  hotelDetail: 'hotel_detail',
  lodgeMain: 'lodge_main',
  pensionList: 'pension_list', // deprecated
  pensionMap: 'pension_map_list', // deprecated
  pensionDetail: 'pension_detail', // deprecated
  domesticAccommodationList: 'domestic_accommodation_list',
  domesticAccommodationMap: 'domestic_accommodation_map_list',
  domesticAccommodationDetail: 'domestic_accommodation_detail',
  themeList: 'theme_detail_list',
  campaign: 'campaign',
  campaignAllinone2103: 'campaign_allinone_2103',
  campaignHotelSecret2104: 'campaign_hotelsecret_2104',
  eventDGM: 'campaign_dgm_2111',
  search: 'search',
  event: 'event',
  messageList: 'message_list',
  messageDetail: 'message_detail',
  city: 'city',
  country: 'country',
  offerList: 'offer_list',
  searchResult: 'search_result',
  offerDetail: 'offer_detail',
  offerDetailOption: 'offer_detail_option',
  profileMain: 'profile_main',
  profileAuth: 'profile_auth',
  refundAccountCreate: 'refund_account_create',
  point: 'point',
  usableCoupon: 'coupon',
  expiredCoupon: 'coupon',
  reservationHistoryOngoing: 'reservation_history_ongoing',
  reservationHistoryVoucher: 'reservation_history_voucher',
  reservationHistoryFlight: 'reservation_history_flight',
  reservationHistoryFinish: 'reservation_history_finish',
  reservationHistoryCancel: 'reservation_history_cancel',
  reservationDetail: 'reservation_detail',
  reviewCreate: 'review_create',
  receipt: 'receipt',
  purchase: 'purchase',
  purchaseComplete: 'purchase_complete',
  invite: 'invite',
  partnerCreate: 'partner_create',
  about: 'about',
  career: 'career',
  b2b: 'b2b',
  realguide: 'realguide',
  bestPrice: 'bestprice',
  terms: 'terms',
  privacyPolicy: 'privacy_policy',
  cancelPolicy: 'cancel_policy',
  regions: 'regions',
  contents: 'contents',
  signIn: 'login',
  signUp: 'signup',
  emailSignIn: 'email_signin',
  emailSignUp: 'email_signup',
  flightsRsvDetail: 'flights_rsv_detail',
});

export const statusColorEnum = Object.freeze({
  warning: 'warning',
  danger: 'danger',
  success: 'success',
  complete: 'complete',
});

export const themeEnum = Object.freeze({
  all: -1,
});

export const chipTypeEnum = Object.freeze({
  fill: 'fill',
  outline: 'outline',
});

export const offerSectionEnum = Object.freeze({
  ticket: 'OFFER-SECTION-TICKET',
  introduction: 'OFFER-SECTION-INTRODUCTION',
  detail: 'OFFER-SECTION-DETAIL',
  course: 'OFFER-SECTION-COURSE',
  guidance: 'OFFER-SECTION-GUIDANCE',
  refund: 'OFFER-SECTION-REFUND',
  review: 'OFFER-SECTION-REVIEW',
});

export const offerNavbarEnum = Object.freeze({
  target: 'OFFER-NAVBAR-TARGET',
  start: 'OFFER-NAVBAR-START',
  end: 'OFFER-NAVBAR-END',
});

export const providerEnum = Object.freeze({
  kakao: 'kakao',
  naver: 'naver',
  facebook: 'facebook',
});

export const tabThemeEnum = Object.freeze({
  primary: 'primary',
  gray: 'gray',
});

// FIXME: 추후 확장해서 사용
export const countryCodeEnum = Object.freeze({
  KR: 'KR',
});

export const offerFlexibleEnum = Object.freeze({
  button: 'button',
  text: 'text',
});

export const mainCrossSellComponentTypeEnum = Object.freeze({
  banner: 'banner',
  keywordOffers: 'keywordOffers',
  offer: 'offer',
  hotel: 'hotel',
});

export const userAccountConnectCaseEnum = Object.freeze({
  defaultLoginCase: 5,
  newJoinCase: 7,
});

export * from './Stays';
export * from './event/DGMCoupon';
export * from './Main';
export * from './Auth';
