import { Fragment, forwardRef } from 'react';
import { useRecoilValue } from 'recoil';

import { GlobalSearch } from 'mrt-types';

import { RecentKeywords } from '../RecentKeywords';
import SuggestionKeywords from '../SuggestionKeywords';
import VerticalSwiper from '../VerticalSwiper';
import { searchTerm } from '../recoil';

interface Props {
  variations: GlobalSearch.Variation;
  handleRef?: (idx: number, node: HTMLAnchorElement) => void;
  selectedIdx?: number;
}

const SearchSuggestionContent = forwardRef<HTMLDivElement, Props>(
  ({ variations, handleRef, selectedIdx }, ref) => {
    const searchKeyword = useRecoilValue(searchTerm);

    if (searchKeyword === '') {
      return <RecentKeywords handleRef={handleRef} selectedIdx={selectedIdx} />;
    }

    return (
      <Fragment>
        <VerticalSwiper variations={variations} ref={ref} />
        <SuggestionKeywords handleRef={handleRef} selectedIdx={selectedIdx} />
      </Fragment>
    );
  },
);

SearchSuggestionContent.displayName = 'SearchSuggestionContent';

export default SearchSuggestionContent;
