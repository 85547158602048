import { HotelPlaceType } from './place';

export const HOTEL_POPULAR_PLACES = [
  {
    id: '다낭_16.0683664014158_108.219195144338',
    name: '다낭',
    country: 'vn',
    countryCode: 'vn',
    countryName: '베트남',
    region: '다낭',
    cityName: '다낭',
    placeName: null,
    destId: null,
    latitude: 16.0683664014158,
    longitude: 108.219195144338,
    type: 'city',
    suggestionType: HotelPlaceType.CITY,
    label: '다낭',
  },
  {
    id: '파리_48.8566821749061_2.35147638246417',
    name: '파리',
    country: 'fr',
    countryCode: 'fr',
    countryName: '프랑스',
    region: '일드 프랑스',
    cityName: '파리',
    placeName: null,
    destId: null,
    latitude: 48.8566821749061,
    longitude: 2.35147638246417,
    type: 'city',
    suggestionType: HotelPlaceType.CITY,
    label: '파리',
  },
  {
    id: '홍콩_22.2826974594311_114.158271089836',
    name: '홍콩',
    country: 'hk',
    countryCode: 'hk',
    countryName: '홍콩',
    region: '',
    cityName: '홍콩',
    placeName: null,
    destId: null,
    latitude: 22.2826974594311,
    longitude: 114.158271089836,
    type: 'city',
    suggestionType: HotelPlaceType.CITY,
    label: '홍콩',
  },
  {
    id: '호찌민_10.7789001464844_106.699996948242',
    name: '호찌민',
    country: 'vn',
    countryCode: 'vn',
    countryName: '베트남',
    region: '호치민시',
    cityName: '호찌민',
    placeName: null,
    destId: null,
    latitude: 10.7789001464844,
    longitude: 106.699996948242,
    type: 'city',
    suggestionType: HotelPlaceType.CITY,
    label: '호찌민',
  },
  {
    id: '세부_10.3111000061035_123.891998291016',
    name: '세부',
    country: 'ph',
    countryCode: 'ph',
    countryName: '필리핀',
    region: '비사야 제도',
    cityName: '세부',
    placeName: null,
    destId: null,
    latitude: 10.3111000061035,
    longitude: 123.891998291016,
    type: 'city',
    suggestionType: HotelPlaceType.CITY,
    label: '세부',
  },
  {
    id: '타이베이_25.046236038208_121.517631530762',
    name: '타이베이',
    country: 'tw',
    countryCode: 'tw',
    countryName: '대만',
    region: '타이베이 시',
    cityName: '타이베이',
    placeName: null,
    destId: null,
    latitude: 25.046236038208,
    longitude: 121.517631530762,
    type: 'city',
    suggestionType: HotelPlaceType.CITY,
    label: '타이베이',
  },
];
