import 'core-js/stable';
import 'regenerator-runtime/runtime';

import detectWebViewByUA from 'mrt-utils/detectWebViewByUA';

import GTMManager from './google';
import renderDefaultComponent from './modules/renderDefaultComponent';
import renderMyPageBanner from './modules/renderMyPageBanner';
import appendTrackingScript from './modules/appendTrackingScript';
import createNotifyPopup from './modules/showNotifyPopup';
import renderSkyScannerBanner from './modules/renderSkyScannerBanner';
import { isDomesticCompletePageOnPayment } from './modules/share';

import '../styles/flight.scss';
import '../styles/components/_Star.scss';
import renderDomesticCompletePopup from './modules/renderDomesticCompletePopup';

window.createMRTComponents = (user, headerTarget, transparent, mrt) => {
  renderDefaultComponent(user, headerTarget, transparent, mrt);
};

// 항공 예약완료 페이지에서 이 함수를 실행하고 있어서 지우면 에러 발생함.
window.createBanner = () => {};

window.createMypageBanner = (targetId = 'root') => {
  renderMyPageBanner(targetId);
};

if (process.env.FLIGHT_MODE === 'YES') {
  const mainUrl = [
    '/air/b2c/AIR/INT/AIRINTTRP0100100000.k1',
    '/air/b2c/AIR/MBL/AIRMBLTRP0100100010.k1',
  ];

  GTMManager.createGAScript();
  renderSkyScannerBanner();
  createNotifyPopup(mainUrl);

  document.addEventListener('DOMContentLoaded', () => {
    if (isDomesticCompletePageOnPayment()) {
      const isWebView = detectWebViewByUA(window.navigator.userAgent);
      const isJeju = window.airDepCtyCd === 'CJU';

      renderDomesticCompletePopup(isWebView, isJeju);
    }

    appendTrackingScript();
  });
}
