/* eslint-disable @typescript-eslint/naming-convention */
import { useInfiniteQuery } from '@tanstack/react-query';

import { fetchNotifications } from '@layout/app/api/notification';
import { NotificationQuery } from '@layout/constants/notification';

export default function useFetchNotification() {
  const queryResult = useInfiniteQuery(
    ['fetchNotifications'],
    async ({ pageParam = '' }) => {
      const requestQuery: NotificationQuery | null = pageParam
        ? { flag_id: pageParam, which: 'before', limit: '20' }
        : null;
      const response = await fetchNotifications(requestQuery);

      return response.data;
    },
    {
      getNextPageParam: (lastPage) => {
        const { last_page, notifications = [] } = lastPage;
        const nextPageParam =
          notifications?.length > 0
            ? notifications[notifications.length - 1].id
            : '';

        if (!last_page) {
          return nextPageParam;
        }
      },
    },
  );

  return {
    ...queryResult,
    data: queryResult.data?.pages,
  };
}
