import React from 'react';
import PropTypes from 'prop-types';
import DotDotDot from 'react-dotdotdot';
import classNames from 'classnames';

import { cardTypeEnum, browserEnum } from '../../../enum';
import getBrowserInfo from '../../../utils/getBrowserInfo';
import styles from './CardTitle.module.scss';

function CardTitle(props) {
  const { type, title, style } = props;
  const clamp = getBrowserInfo() === browserEnum.ie && type === cardTypeEnum.horizontal ? 3 : 2;
  return (
    <h3 className={classNames(styles.container, styles[type])} style={style}>
      <DotDotDot className={styles.title} clamp={clamp}>
        {title}
      </DotDotDot>
    </h3>
  );
}

CardTitle.propTypes = {
  type: PropTypes.oneOf(Object.values(cardTypeEnum)),
  title: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
};

CardTitle.defaultProps = {
  type: cardTypeEnum.vertical,
  title: '',
  style: null,
};

export default CardTitle;
