import React from 'react';
import PropTypes from 'prop-types';

import './Button.scss';

const Button = ({
  type, label, onClick, style, ...props
}) => {
  const styles = ['mrt-button'];
  styles.push(type);

  return (
    <a {...props}>
      <button className={styles.join(' ')} onClick={onClick} style={style}>
        <span className="btn-label">{label}</span>
      </button>
    </a>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
};

Button.defaultProps = {
  type: 'outline', // 'primary', 'secondary', 'outline', 'gray', 'link'
  label: 'Button',
  style: null,
};

export default Button;

