const common = {
  vertical: {
    experience: '투어&티켓',
    hotel: '호텔',
    lodging: '한인민박',
    hot_deal: '패키지',
  },
  dialog: {
    selectDate: '날짜 선택',
  },
  price: '{{value}}원',
  lowPrice: '최저가 보장제',
  reviewEvent: '후기 이벤트',
  share: {
    mailSubject: '마이리얼트립 이 상품 어때? 공유!',
  },
  count: '{{count}}개',
  review: '후기 기준',
  oneNightMinimum: '1박 최저가',
  showHotelMore: '{{value}} 호텔 더 보기',
  priceUnit: '원',
  nowUse: '즉시확정',
  showOfferMore: '상품 더보기',
  letsShow: '보러가기',
  oneNight: '1박',
  onePerson: '1인',
  confirm: '확인',
  cancel: '취소',
};

export default common;
