import { useEffect, useRef } from 'react';

import { helper, LogDataType } from '@myrealtrip/frontend-weblog';

const { sendPageViewLog, sendLayerViewLog } = helper;

export default function usePageViewLog(view: 'page' | 'layer', log: LogDataType, condition = true) {
  const sentLog = useRef(false);

  useEffect(() => {
    if (!sentLog.current && condition) {
      (view === 'page' ? sendPageViewLog : sendLayerViewLog)(log);
      sentLog.current = true;
    }
  }, [condition, log, view]);
}
