import { css, SerializedStyles } from '@emotion/core';
import { BadgeType, ProductCardBadge } from 'mrt-types';

export const CARD_BADGE_MAP: Record<BadgeType, { label: string; style?: SerializedStyles }> = {
  PRICE_GUARANTEE: {
    label: '최저가 보장제',
    style: css`
      color: var(--yellow-700);
      background-color: var(--yellow-80);
      border: none;
    `,
  },
  COUPON_DISCOUNT: {
    label: '쿠폰 할인',
  },
  NO_WAITING: {
    label: '바로 사용',
    style: css`
      color: var(--green-700);
      background-color: var(--green-50);
      border: none;
    `,
  },
  REFUND: {
    label: '미사용 전액 환불',
    style: css`
      color: var(--green-700);
      background-color: var(--green-50);
      border: none;
    `,
  },
};

export enum ProductCardBadgeType {
  ZERO_MARGIN = 'ZERO_MARGIN',
  FIRST_COME_COUPON = 'FIRST_COME_COUPON',
  STAY_FESTA = 'STAY_FESTA',
  MRT_ORIGIN = 'MRT_ORIGIN',
  FREE_REFUND = 'FREE_REFUND',
  LOWER_PRICE_GUARANTEE = 'LOWER_PRICE_GUARANTEE',
  INSTANT = 'INSTANT',
}

export const PRODUCT_CARD_BADGES: Record<ProductCardBadgeType, ProductCardBadge> = {
  /** 세일페스타와 제로마진은 우선 동률로 우선순위를 지정 */
  [ProductCardBadgeType.ZERO_MARGIN]: {
    label: '제로마진',
    value: ProductCardBadgeType.ZERO_MARGIN,
    priority: 1,
  },
  [ProductCardBadgeType.FIRST_COME_COUPON]: {
    label: '선착순 할인쿠폰',
    value: ProductCardBadgeType.FIRST_COME_COUPON,
    priority: 2,
  },
  [ProductCardBadgeType.STAY_FESTA]: {
    label: '세일페스타',
    value: ProductCardBadgeType.STAY_FESTA,
    priority: 3,
  },
  [ProductCardBadgeType.MRT_ORIGIN]: {
    label: '마리트 최초',
    value: ProductCardBadgeType.MRT_ORIGIN,
    priority: 4,
  },
  [ProductCardBadgeType.LOWER_PRICE_GUARANTEE]: {
    label: '최저가보장제',
    value: ProductCardBadgeType.LOWER_PRICE_GUARANTEE,
    priority: 5,
  },
  [ProductCardBadgeType.FREE_REFUND]: {
    label: '무료취소',
    value: ProductCardBadgeType.FREE_REFUND,
    priority: 6,
  },
  [ProductCardBadgeType.INSTANT]: {
    label: '즉시 확정',
    value: ProductCardBadgeType.INSTANT,
    priority: 7,
  },
};
