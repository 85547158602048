import { Configs } from 'mrt-constants';

export const S3_RESOURCE_IMAGES =
  'https://mrt3-web-resources.s3.ap-northeast-2.amazonaws.com/images';

export const URL_LODGING_PRODUCTS = '/lodgings/products';
export const URL_LODGING_PRODUCT = `/products`;

export const URL_RESERVATION = '/traveler/reservations/v3';

export const URL_MESSAGE = '/messenger/channels';
export const URL_SEARCH = '/search';

export const CHECKOUT_NAVERPAY_URL = '/order/payments/naverpay';
export const CHECKOUT_TOSSPAYMENTS_URL = '/order/payments/tosspayments';
export const CHECKOUT_INICIS_URL = '/order/payments/inicis';
export const CHECKOUT_KAKAOPAY_URL = '/order/payments/kakaopay';
export const CHECKOUT_URL_RESERVATION = '/order';
export const CHECKOUT_KCP_URL = '/order/payments/kcp';

/** 회원 3.0 URL`s */
export const SIGN_IN_URL = `${Configs.AUTH_DOMAIN}/users/sign_in`;
export const EMAIL_SIGN_IN_URL = `${Configs.AUTH_DOMAIN}/users/email_sign_in`;
export const MYPAGE_URL = `${Configs.MEMBER_DOMAIN}/mypage`;
export const COUPON_URL = `${Configs.MEMBER_DOMAIN}/coupon`;
export const POINT_URL = `${Configs.MEMBER_DOMAIN}/point`;
export const WISH_URL = `${Configs.MEMBER_DOMAIN}/wish/groups`;
