import styled from '@emotion/styled';
import { helper } from '@myrealtrip/frontend-weblog';
import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import media from 'mrt-utils/mediaQuery';

import i18n from '@layout/app/utils/i18n';
import Button from '@myrealtrip/design-system/dist/components/Buttons/Button';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import ChannelTalkPreviewButton from './ChannelTalkPreviewButton';

const { sendClickLog } = helper;

export default function CustomerCenter() {
  const handleChatClick = () => {
    sendClickLog({
      screenName: WeblogScreenName.COMMON,
      pageCategory: WeblogPageCategory.COMMON,
      eventName: 'footer',
      menuTitle: '1:1 채팅상담',
      targetUrl: 'https://myrealtrip.channel.io/',
    });

    window.csHelper?.open();
  };

  return (
    <Container>
      <Title>{i18n.t('footer:help-customerCenter')}</Title>
      <InfoBox>
        <InfoText>{i18n.t('footer:help-question-workingHour')}</InfoText>
        <InfoText>{i18n.t('footer:help-question-air-refund')}</InfoText>
        <InfoText>{i18n.t('footer:help-question-air-change')}</InfoText>
        <InfoText>{i18n.t('footer:help-call')}</InfoText>
      </InfoBox>
      <ChatButtonContainer>
        <ChatButton type="outline" onClick={handleChatClick}>
          {i18n.t('footer:help-chat')}
        </ChatButton>
        <ChannelTalkPreviewButton />
      </ChatButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 50%;

  ${media.mobile} {
    width: 100%;
  }
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${globalVariables.gray_600};
`;

const InfoBox = styled.ul`
  margin-top: 10px;
`;

const InfoText = styled.li`
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 1.5;
  word-break: break-all;
  color: ${globalVariables.gray_700};
  white-space: pre-wrap;
`;

const ChatButtonContainer = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 6px;
`;

export const ChatButton = styled(Button)`
  button {
    width: 140px;
    height: 40px;
    font-size: 14px;
    padding: 9px 8px;
  }
`;
