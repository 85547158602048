import { css } from '@emotion/react';
import { useState, useEffect, Fragment, useRef, useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useDetermineMobileContext } from 'mrt-components';
import { Configs, WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import { GlobalSearch } from 'mrt-types';

import styled from '@emotion/styled';
import useClickOutside from '@myrealtrip/design-system/dist/hooks/useClickOutside';
import { helper } from '@myrealtrip/frontend-weblog';

import SearchInput from './SearchInput';
import SearchSuggestionDialog from './SearchSuggestionDialog';
import SearchSuggestionPopup from './SearchSuggestionPopup';
import useSearchOffers from './hooks/apis/useSearchOffers';
import { searchBanner, searchTerm } from './recoil';
import useSearchManager from './useSearchManager';
import sendSearchCombineLog from './utils/sendSearchCombineLog';

const { sendClickLog } = helper;

const baseSearchLog = {
  screenName: WeblogScreenName.COMMON,
  pageCategory: WeblogPageCategory.COMMON,
};
interface Props {
  placeholder: string;
  variations: GlobalSearch.Variation;
  isShowSearchInput: boolean;
  isSuggestionDialogOpen?: boolean;
  onSuggestionDialogToggle?: (isOpen: boolean) => void;
}

function GlobalSearchContainer({
  variations,
  placeholder,
  isShowSearchInput,
  isSuggestionDialogOpen,
  onSuggestionDialogToggle,
}: Props) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { redirectSearchPage, getSearchWord, convertBanner } = useSearchManager();
  const searchKeyword = useRecoilValue(searchTerm);
  const [selectedBanner, setSelectedBanner] = useRecoilState(searchBanner);
  const isMobile = useDetermineMobileContext();
  const suggestionRefs = useRef<Array<HTMLAnchorElement>>([]);
  const suggestionPopupRef = useRef<HTMLDivElement>(null);
  const [selectedIdx, setSelectedIdx] = useState(-1);
  const { data } = useSearchOffers();

  const sendLog = (searchWord: string, targetUrl?: string) => {
    try {
      const nextTargetUrl =
        targetUrl || `${process.env.HOME_URL}/search-bridge?q=${encodeURIComponent(searchWord)}`;

      if (data) {
        sendSearchCombineLog({
          sections: data.sections,
          additionalLog: {
            searchWord,
            targetUrl: nextTargetUrl,
          },
        });
      }
    } catch (e) {
      // ignore
    }
  };

  const handleSuggestionPopupToggle = (isOpen: boolean) => {
    if (isMobile) {
      return;
    }

    setIsPopupOpen(isOpen);
  };

  const handleSuggestionDialogToggle = (isOpen: boolean) => {
    if (!isMobile) {
      return;
    }

    onSuggestionDialogToggle?.(isOpen);
  };

  const { ref } = useClickOutside(isPopupOpen, [], () => handleSuggestionPopupToggle(false));

  const scrollTo = (top: number) => {
    suggestionPopupRef.current?.scrollTo({
      top,
      behavior: 'smooth',
    });
  };

  const moveScroll = (to?: number) => {
    const { current: suggestionList } = suggestionRefs;

    const currentItemHeight = suggestionList[0].offsetHeight;
    const currentTop = currentItemHeight * selectedIdx;

    scrollTo(to || currentTop);
  };

  const handlePressKeyboard = (key: string) => {
    const { current: suggestionList } = suggestionRefs;
    const listLength = suggestionList.length;

    switch (key) {
      case 'Enter': {
        // 키보드 방향키로 아이템을 선택한 경우
        if (selectedBanner) {
          const { linkUrl } = selectedBanner;
          const searchWord = getSearchWord(selectedBanner);
          const targetUrl =
            linkUrl ||
            `${Configs.SERVICE_DOMAIN}/search-bridge?q=${encodeURIComponent(searchWord)}`;

          sendLog(searchWord, targetUrl);

          redirectSearchPage(selectedBanner);
        } else {
          const banner = convertBanner(searchKeyword);
          sendLog(searchKeyword);
          redirectSearchPage(banner);
        }
        break;
      }
      case 'ArrowUp':
        if (selectedIdx > 0) {
          moveScroll();
          setSelectedIdx((prev) => prev - 1);
        } else if (listLength > 0) {
          moveScroll(0);
          setSelectedBanner(null);
          setSelectedIdx(-1);
        }
        break;
      case 'ArrowDown':
        if (listLength - 1 > selectedIdx) {
          moveScroll();
          setSelectedIdx((prev) => prev + 1);
        }
        break;
      default:
        break;
    }
  };

  const handleRef = (idx: number, node: HTMLAnchorElement) => {
    if (node) {
      suggestionRefs.current[idx] = node;
      suggestionRefs.current = suggestionRefs.current.slice(0, idx + 1);
    } else {
      suggestionRefs.current = [];
    }
  };

  const handleSendClickLog = useCallback(() => {
    sendClickLog({
      ...baseSearchLog,
      itemKind: 'button',
      eventName: 'search_bar',
      itemName: 'search_bar',
    });
  }, []);

  useEffect(() => {
    setSelectedBanner(null);
    setSelectedIdx(-1);
  }, [searchKeyword, setSelectedBanner]);

  return (
    <Fragment>
      <Container isMobile={isMobile} isShow={isShowSearchInput} ref={ref}>
        <SearchInput
          variations={variations}
          placeholder={placeholder}
          onDialogToggle={handleSuggestionDialogToggle}
          onPopupToggle={handleSuggestionPopupToggle}
          onPressKeyboard={handlePressKeyboard}
          onClick={handleSendClickLog}
        />
        {isPopupOpen && (
          <SearchSuggestionPopup
            isOpen={isPopupOpen}
            variations={variations}
            handleRef={handleRef}
            ref={suggestionPopupRef}
            selectedIdx={selectedIdx}
          />
        )}
      </Container>
      {isSuggestionDialogOpen && (
        <SearchSuggestionDialog
          isOpen={Boolean(isSuggestionDialogOpen)}
          variations={variations}
          placeholder={placeholder}
          onDialogToggle={handleSuggestionDialogToggle}
          onPressKeyboard={handlePressKeyboard}
        />
      )}
    </Fragment>
  );
}

export default GlobalSearchContainer;

const Container = styled.div<{ isMobile: boolean; isShow: boolean }>`
  position: relative;
  ${({ isShow }) =>
    !isShow &&
    css`
      display: none;
    `};
  ${({ isMobile }) =>
    !isMobile &&
    css`
      margin-right: 14px;
    `};
`;
