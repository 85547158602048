// 서버에서 내려주는 메시지를 사용하기로 약속된 코드
export const CODE_LIST_FOR_USING_SERVER_ERROR_MESSAGE = [
  // order api
  'order.product.cancel.fail',
  'order.product.reserve.fail',

  // 사모아 인증 방어 에러
  'order.samoa.not.authz',

  // coupon api
  'coupon.publish.error.invalidTemplateId',
  'coupon.publish.error.duplicated',
  'coupon.template.error.overMaxPublishableCount',
  'coupon.template.error.invalidPublishableStatus',
  'coupon.template.error.invalidActivatedDate',
  'coupon.template.error.empty',
  'coupon.common.error.invalidUser',
  'coupon.common.error.emptyParameter',

  // promotion api
  'promotion.api.error.notFoundPromotion',
  'promotion.api.error.notFoundImage',
  'promotion.api.error.notFoundPromotionTemp',
  'promotion.api.error.noPromotionExists',
  'promotion.api.error.notFoundProduct',
  'promotion.api.error.invalidDate',
  'promotion.api.error.invalidData',
  'promotion.api.error.invalidRequest',
  'promotion.error.notLogin',
  'promotion.common.error.invalidParameter',
  'promotion.common.error.duplicatedPromotionCode',
  'promotion.common.error.duplicatedPromotionContentProductGroupKey',
  'promotion.common.error.promotionCodeModifyDenied',
  'promotion.common.error.notPromotionChangeableStatus',
  'promotion.common.error.notPromotionDeletableStatus',
  'promotion.common.error.notPromotionPublishableStatus',
  'promotion.common.error.notAvailableDevice',
  'promotion.common.error.invalidUser',
  'promotion.enroll.error.wrongEnrollTemplateMessageType',
  'promotion.enroll.error.duplicatedEnrollTemplateMessageType',
  'promotion.enroll.error.invalidEnrollTemplateDate',
  'promotion.enroll.error.enrollTemplateDateModifyDenied',
  'promotion.enroll.error.emptyEnrollTemplate',
  'promotion.enroll.error.emptyEnrollApplyHistory',

  // 인증관련 에러코드
  'authentication.error.signInFailed',
  'authentication.error.signupFailed',
  'member.error.auth.invalidRequest',
  'authentication.error.unsyncSnsFailed',
];

// 정상작동이지만 토스트미노출처리를 위한 예외코드 [QueryClientProvider.tsx]
export const ERROR_EXCEPTION_NOT_EXPRESED_TOAST = ['package.error.packageUnableToCreateChannel'];
