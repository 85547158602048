import React from 'react';
import PropTypes from 'prop-types';

import HotelReviewScore from './HotelReviewScore';
import styles from './HotelReview.module.scss';

function HotelReview(props) {
  const { score, status, count } = props;

  return (
    <div className={styles.container}>
      <HotelReviewScore score={score} />
      <div className={styles.review}>
        <p className={styles.status}>{status}</p>
        <p className={styles.count}>
          <strong>{`${count.toLocaleString()}개 `}</strong>
          {'이용후기'}
        </p>
      </div>
    </div>
  );
}

HotelReview.propTypes = {
  score: PropTypes.number,
  status: PropTypes.string,
  count: PropTypes.number,
};

HotelReview.defaultProps = {
  score: 0,
  status: '',
  count: 0,
};

export default HotelReview;
