import { forwardRef } from 'react';

import { Icon } from '@myrealtrip/design-system';

import styled from '@emotion/styled';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

export type CarouselNavigationButtonDirectionType = 'prev' | 'next';

interface PropsType {
  className?: string;
  direction: CarouselNavigationButtonDirectionType;
  verticalPosition?: number;
  onClick?: (direction: CarouselNavigationButtonDirectionType) => void;
}

const CarouselNavigationButton = forwardRef<HTMLButtonElement, PropsType>(
  ({ className, direction, verticalPosition, onClick }, ref) => {
    const handleClick = () => onClick && onClick(direction);

    const IconName =
      direction === 'prev' ? 'ic_arrowleft_md_gray_700' : 'ic_arrowright_md_gray_700';

    return (
      <NavigationButton
        className={className}
        direction={direction}
        verticalPosition={verticalPosition}
        onClick={handleClick}
        ref={ref}
      >
        <Icon name={IconName} alt={`move to ${direction} slide`} />
      </NavigationButton>
    );
  },
);

CarouselNavigationButton.displayName = 'CarouselNavigationButton';

export default CarouselNavigationButton;

const NavigationButton = styled.button<{
  direction: CarouselNavigationButtonDirectionType;
  verticalPosition?: number;
}>`
  position: absolute;
  ${({ direction, verticalPosition }) =>
    direction === 'next'
      ? `right: ${verticalPosition || -20}px;`
      : `left: ${verticalPosition || -20}px;`}
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  padding: 0;
  outline: none;
  background: ${globalVariables.white};
  appearance: none;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  z-index: 10;

  &:disabled {
    opacity: 0;
    visibility: hidden;
  }
`;
