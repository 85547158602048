const SIGN_IN_DEEP_LINK = 'mrt://signin';

export default function getSigninDeepLink(returnTo?: string) {
  let encodedReturnUrl;

  if (typeof window !== 'undefined') {
    if (returnTo) {
      encodedReturnUrl = encodeURIComponent(returnTo);
    } else {
      encodedReturnUrl = encodeURIComponent(window.location.href);
    }
  } else {
    encodedReturnUrl = '';
  }

  return `${SIGN_IN_DEEP_LINK}${encodedReturnUrl ? `?return_to=${encodedReturnUrl}` : ''}`;
}
