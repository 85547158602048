const profile = {
  password: {
    changePassword: '비밀번호 변경하기',
    resetPassword: '비밀번호 재설정',
    notice: {
      title: '유의사항',
      items: [
        '- 8자~15자 길이로 만들어주세요.',
        '- 영문 대/소문자, 숫자, 특수 문자 2가지를 조합해주세요.',
        '- 3자 이상 연속/동일한 문자, 숫자는 사용할 수 없습니다.',
      ],
    },
    currentPassword: '현재 비밀번호',
    newPassword: '새 비밀번호',
    newPasswordConfirm: '새 비밀번호 확인',
    change: '변경하기',
    after: '다음에(3개월 뒤)',
    campaign: '비밀번호를 변경하고\n개인정보를 보호하세요.',
  },
};

export default profile;
