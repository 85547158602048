import axios, { AxiosResponse, AxiosError } from 'axios';

import { showErrorModal } from 'mrt-error';

/**
 * interceptor
 */
const onSuccessInterceptor = (response: AxiosResponse<any>) => {
  return response;
};

const onErrorInterceptor = (error: AxiosError) => {
  if (process.env.TARGET_ENV !== 'production') {
    showErrorModal(error);
  }

  return Promise.reject(error);
};

const S3Axios = axios.create({
  baseURL: process.env.S3_ASSET_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

S3Axios.interceptors.response.use(onSuccessInterceptor, onErrorInterceptor);

export default S3Axios;
