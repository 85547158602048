import { Children, PropsWithChildren } from 'react';

import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';

import styled from '@emotion/styled';

type Props = {
  initialSlideIndex?: number;
};

export default function LocalNavCarousel({
  children,
  initialSlideIndex,
}: PropsWithChildren<Props>) {
  const swiperOptions: SwiperProps = {
    modules: [FreeMode],
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    freeMode: true,
    grabCursor: true,
    spaceBetween: 0,
    slidesOffsetBefore: 8,
    slidesOffsetAfter: 8,
    threshold: 10,
    initialSlide: initialSlideIndex ?? 0,
  };

  return (
    <SwiperContainer {...swiperOptions}>
      {Children.map(children, (child) => (
        <SwiperSlide>{child}</SwiperSlide>
      ))}
    </SwiperContainer>
  );
}

const SwiperContainer = styled(Swiper)`
  .swiper-wrapper {
    overflow: initial;
  }
  .swiper-slide {
    width: auto;
  }
`;
