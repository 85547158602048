import { Mode, ProductRequireInfoFieldType } from 'mrt-constants';

export enum ShareChannel {
  KAKAOTALK = 'KAKAOTALK',
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  COPYLINK = 'COPYLINK',
}

export type ProductShareCode = (typeof ShareChannel)[keyof typeof ShareChannel];

export interface ShareItemType {
  code: ProductShareCode;
  icon: string;
  text: string;
  data?: KakaoShareData;
  title?: string;
  isWebView?: boolean;
}

export interface KakaoShareData {
  content: {
    title: string;
    description: string;
    imageUrl: string;
    link: {
      mobileWebUrl: string;
      webUrl: string;
    };
  };
  buttons: {
    title: string;
    link: {
      mobileWebUrl: string;
      webUrl: string;
    };
  }[];
}

export type NewProductRequireInfosType = Readonly<{
  [flag: string]: Record<string, string>[];
}>;

export type ProductRequireInfosType = Readonly<{
  PERSONAL_ALL_NAME?: {
    korName: string;
    engName: string;
  };
  PERSONAL_LICENSE_INFO?: {
    name: string;
    email: string;
    phone: string;
    birthDay: string;
    licenseType: string;
  };
  PERSONAL_KOREAN_NAME?: {
    korName: string;
  };
  PERSONAL_ENGLISH_NAME?: {
    engName?: string;
    engFirstName?: string;
    engLastName?: string;
  };
  PERSONAL_REPRESENTATIVE?: {
    korName: string;
    email: string;
    phone: string;
  };
  PERSONAL_ENGLISH_NAME_GUEST?: {
    engFirstName: string;
    engLastName: string;
  };
}>;

export type ProductRequireInfosFieldSetType =
  | ProductRequireInfoFieldType.NAME
  | ProductRequireInfoFieldType.KOR_NAME
  | ProductRequireInfoFieldType.ENG_NAME
  | ProductRequireInfoFieldType.ENG_FIRST_NAME
  | ProductRequireInfoFieldType.ENG_LAST_NAME
  | ProductRequireInfoFieldType.EMAIL
  | ProductRequireInfoFieldType.PHONE
  | ProductRequireInfoFieldType.BIRTH_DAY
  | ProductRequireInfoFieldType.LICENSE_TYPE
  | ProductRequireInfoFieldType.RECEIVER
  | ProductRequireInfoFieldType.RECEIVER_PHONE_NUMBER
  | ProductRequireInfoFieldType.ADDRESS;

export type AdditionalInfosType =
  | 'productRequireInfos'
  | 'customRequireInfos'
  | 'extraRequireInfos';

export type ModeType = keyof typeof Mode;

export type NullableString = string | null;
