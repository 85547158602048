const offerList = {
  landmarkCount: '{{count}}개의 상품',
  lowPrice: '최저가 보장제',
  reviewEvent: '리뷰 이벤트',
  filter: {
    filter: '필터',
    use: '즉시확정',
    tag: '상품 태그',
    date: '날짜',
  },
  order: {
    popular: '인기순',
    reviews: '후기순',
    price: '가격순',
    recent: '신상품순',
  },
  guideType: {
    all: '전체',
    group: '그룹 투어',
    private: '단독 프라이빗 투어',
  },
  empty: {
    filter: {
      label: '선택하신 조건으로\n예약할 수 있는 여행이 없습니다.',
      desc: '필터를 초기화하고 다시 검색해주세요.',
    },
    list: {
      label: '상품이 없습니다.',
      desc: '다른 여행 상품을 둘러보세요.',
    },
  },
  offerTag: '상품 태그',
  applyFilter: '필터 적용하기',
  initFilter: '필터 초기화하기',
  nowUseDetail: '자세히 보기',
  nowUseDesc: '구매 후 즉시 확정할 수 있습니다.',
  selectTag: '원하는 상품 태그를 선택하세요.',
  duration: {
    minute: '{{value}}분 소요',
    hour: '{{value}}시간 소요',
    day: '{{value}}일 소요',
    flexible: '조정가능',
  },
};

export default offerList;
