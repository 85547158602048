const language = {
  title: '반갑습니다!',
  kakaoOAuth: '카카오로 바로 시작',
  footerDescription: '이미 아이디가 있으신가요?',
  footerLinktext: '로그인',
  kakao: '카카오로 회원가입',
  facebook: '페이스북으로 회원가입',
  naver: '네이버로 회원가입',
  or: '또는',
  policy: {
    agreement: '회원 가입 및 운영약관 동의 (필수)',
    privacyUse: '개인정보 수집 및 이용 (필수)',
    location: '위치정보 이용약관 (선택)',
  },
  name: {
    label: '이름 *',
    placeholder: '이름을 입력해주세요.',
    validate: {
      empty: '멋진 이름이 있으시잖아요.',
      short: '이름은 두 글자 이상 입력해주세요.',
    },
  },
  email: {
    label: '이메일 *',
    placeholder: 'ID@example.com',
    validate: {
      empty: '꼭 필요해요.',
      notEmail: '이메일 주소가 맞나요?',
    },
  },
  password: {
    label: '비밀번호 *',
    placeholder: '영문, 숫자, 특수문자 2가지 조합 6~15자',
    validate: {
      empty: '비밀번호를 입력해주세요.',
      short: '비밀번호가 너무 짧습니다.',
      long: '비밀번호가 너무 깁니다.',
      combination: '영문, 숫자, 특수문자를 2가지 이상 조합해 주세요.',
      continuous: '3자리 이상 연속되거나 동일한 문자, 숫자는 사용할 수 없습니다.',
      same: '3자리 이상 연속되거나 동일한 문자, 숫자는 사용할 수 없습니다.',
      other: '영문, 숫자, 특수문자 외의 문자는 사용할 수 없습니다.',
      current: '현재 비밀번호와 다른 비밀번호를 입력해주세요.',
      done: '안전한 비밀번호입니다.',
    },
  },
  password2: {
    label: '비밀번호 확인 *',
    placeholder: '비밀번호를 한번 더 입력해주세요.',
    validate: {
      empty: '비밀번호를 다시 한번 입력해주세요.',
      notEqual: '비밀번호가 일치하지 않습니다.',
      done: '비밀번호가 일치합니다.',
    },
  },
  agreement: {
    all: '전체 약관 동의',
    marketing: '특가 항공권 및 할인 혜택 안내 동의 (선택)',
    subscription: '프로필 > 알림 설정에서 변경 가능',
  },
  notices: [
    '*선택 항목을 동의하지 않아도 서비스를 이용하실 수 있습니다.',
    '*회원 가입 시 본인이 만 14세 이상임에 동의하게 됩니다.',
  ],
  signUp: '회원가입',
  subscription:
    '마케팅 정보 수신동의를 거부 하시는 경우 마이리얼트립에서 제공되는 추천 상품, 이벤트, 할인 정보를 받아보실 수 없습니다.\n혜택을 받으시겠습니까?',
  feedback: {
    welcome: {
      title: '마이리얼트립 회원이\n되신 것을 축하드립니다!',
      description: '계정 관리를 위해 아래 가입 정보를 꼭 확인해주세요.',
    },
    exist: {
      title: '이미 같은 전화번호로\n가입한 계정이 있는 것 같아요!',
      description: '내 정보가 맞다면 이전 계정으로 로그인하세요.',
    },
    detail: {
      email: '이메일',
      phone: '전화번호',
      sns: 'SNS 연동',
    },
    login: {
      kakao: '카카오로 로그인',
      naver: '네이버로 로그인',
      facebook: '페이스북으로 로그인',
      mrtEmail: '로그인 하러 가기',
    },
    signIn: '로그인 하러 가기',
    newJoin: '새 계정으로 가입하기',
  },
  loading: {
    title: '회원 가입 중입니다.',
    description: '잠시만 기다려주세요.',
  },
  oauth: {
    kakao: '카카오',
    naver: '네이버',
    facebook: '페이스북',
  },
};

export default language;
