import { ChangeEvent, FocusEvent, SelectHTMLAttributes, useEffect, useRef, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon } from '@myrealtrip/design-system';
import { DEFAULT_COLORS } from '@myrealtrip/web-ui';

export type SelectUnitOptions = {
  value: string;
  content?: string;
  isPlaceholder?: boolean;
}[];

interface Props
  extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onChange' | 'onBlur' | 'defaultValue'> {
  options: SelectUnitOptions;
  defaultValue?: string;
  width?: number;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  invalid?: boolean;
}

function SelectUnit({
  defaultValue,
  options,
  required = true,
  width,
  onChange,
  onBlur,
  ...props
}: Props): JSX.Element {
  const isInitedDefault = useRef<boolean>(false);
  const [selectValue, setSelectValue] = useState<string>('');

  useEffect(() => {
    if (defaultValue && !isInitedDefault.current) {
      if (!options.find((o) => o.value === defaultValue)) {
        setSelectValue('');
        onChange?.('');
      } else {
        setSelectValue(defaultValue);
      }
      isInitedDefault.current = true;
    }
  }, [defaultValue, onChange, options]);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectValue(value);
    onChange?.(value);
  };

  const handleBlur = (e: FocusEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectValue(value);
    onBlur?.(value);
  };

  return (
    <SelectContainer width={width}>
      <Select
        value={selectValue}
        required={required}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      >
        {options.map(({ value, content, isPlaceholder }) => (
          <option key={value} value={value ?? ''} disabled={isPlaceholder} hidden={isPlaceholder}>
            {content ?? value}
          </option>
        ))}
      </Select>
      <ArrowIcon name="ic_select_arrow_gray_800" />
    </SelectContainer>
  );
}

const SelectContainer = styled.div<Pick<Props, 'width'>>`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

const Select = styled.select<Pick<Props, 'invalid'>>`
  width: 100%;
  padding: 11px 12px;
  background: var(--white);
  color: var(--gray-1000);
  border: 0;
  box-shadow: inset 0 0 0 1px var(--gray-300);
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: -0.2px;
  border-radius: 4px;
  cursor: pointer;

  :hover {
    box-shadow: inset 0 0 0 1px var(--gray-500);
  }

  :focus-visible {
    outline: none;
  }

  :focus {
    box-shadow: inset 0 0 0 2px var(--blue-500);
  }

  :invalid {
    color: var(--gray-400);
  }

  ${({ invalid }) =>
    invalid &&
    css`
      box-shadow: inset 0 0 0 2px var(--yellow-600);

      :hover {
        box-shadow: inset 0 0 0 2px var(--yellow-600);
      }

      :focus {
        box-shadow: inset 0 0 0 2px var(--blue-500);
      }
    `};

  :disabled {
    background-color: ${DEFAULT_COLORS.gray.s60};
  }
`;

const ArrowIcon = styled(Icon)`
  position: absolute;
  right: 17px;
  top: 50%;
  height: 5px;
  transform: translateY(-50%);
`;

export default SelectUnit;
