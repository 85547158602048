import { storageRedis } from 'mrt-apis';
import { Api } from 'mrt-types';
import { redirectSignIn } from 'mrt-utils';

export interface MrtAxiosV3ErrorOptions {
  isWebView?: boolean;
  isStoreInRedis?: boolean;
  api?: Api;
}

const mrt401Error = async (option: MrtAxiosV3ErrorOptions): Promise<any> => {
  const { isWebView, isStoreInRedis, api } = option;
  if (isStoreInRedis) {
    try {
      const { data } = await storageRedis({ data: api as Api });
      redirectSignIn({
        isStoreInRedis,
        isWebView,
        redisKey: data.key,
      });
      return;
    } catch (e) {
      // redis 저장 실패시 기존 플로우(로그인>상품상세>주문서)를 따른다.
      redirectSignIn({ isWebView });
    }
  } else {
    redirectSignIn({ isWebView });
  }
};

export default mrt401Error;
