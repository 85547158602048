import { cloneElement } from 'react';
import { createRoot } from 'react-dom/client';

export function findSearchForm(callback) {
  const iframe = document.ifrSegSelect;
  const child = iframe.document;

  if (!child.intfareSchForm) {
    setTimeout(findSearchForm, 500);

    return;
  }

  callback(child);
}

export function initComponent(id) {
  let div = document.getElementById(id);

  if (div) {
    div.innerHTML = '';
  } else {
    div = document.createElement('div');
    div.id = id;
  }

  return div;
}

export function renderReactComponent(component, target) {
  const root = createRoot(target);

  root.render(cloneElement(component, { root }), target);
}

export function isDomesticCompletePageOnPayment() {
  const { pathname, search } = window.location;
  const isCompleteForKoreanAir =
    pathname.includes('0100100040') && search.toLowerCase().includes('firstyn=y');
  const isCompleteForOthers = pathname.includes('PAY1110100071');

  return isCompleteForKoreanAir || isCompleteForOthers;
}

export const internationalCompleteLandingType = {
  organic: 'organic', // 오가닉
  organicDeep: 'organicDeep', // 오가닉 심화인증
  skyscanner: 'skyscanner', // 스카이스캐너
};

export const internationalCompletePaths = [
  {
    type: internationalCompleteLandingType.organic,
    path: 'AIRAAAPAY0100100011',
  },
  {
    type: internationalCompleteLandingType.organic,
    path: 'AIRMBLPAY0100100011',
  },
  {
    type: internationalCompleteLandingType.organicDeep,
    path: 'AIRAAAPAY0100100040',
  },
  {
    type: internationalCompleteLandingType.organicDeep,
    path: 'AIRMBLPAY0100100040',
  },
  {
    type: internationalCompleteLandingType.skyscanner,
    path: 'AIRAAARSVSKY0100100010',
  },
  {
    type: internationalCompleteLandingType.skyscanner,
    path: 'AIRMBLRSVSKY0100100010',
  },
];

export function isInternationalCompletePageOnPayment() {
  const { pathname } = window.location;

  const isTargetPath = internationalCompletePaths.some(({ path }) => pathname.includes(path));

  return isTargetPath;
}

export function getInternationalCompletePageLandingType() {
  const { pathname } = window.location;

  return internationalCompletePaths.find(({ path }) => pathname.includes(path)).type ?? null;
}
