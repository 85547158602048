import Cookies, { CookieAttributes } from 'js-cookie';

export enum CookieKey {
  APP_DOWNLOAD_BANNER = 'mrt-app-download-banner',
  API_ACCESS_TOKEN = 'api-access-token',
  MRT_LNG = 'mrtLng',
  MRT_SESSION_ID = '_mrt_session_id',
  IE_SUPPORT_GUIDE_POPUP = 'mrt_ie_support_guide_popup',
  INF_GL = 'inf_gl',
  GUIDE_ACCESS_TOKEN = 'mrtg_at',
  IS_GUIDE_APP = 'is_guide_app',
  APP_DOWN_BANNER = 'app_down_banner',
  TOP_LINE_BANNER = 'top_line_banner',
  APP_VERSION = 'app-version',
  UTM_SOURCE = 'utm_source',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_MEDIUM = 'utm_medium',
  UTM_CONTENT = 'utm_content',
  UTM_TERM = 'utm_term',
  RECENT_UTM_SOURCE = 'recent_utm_source',
  RECENT_UTM_CAMPAIGN = 'recent_utm_campaign',
  RECENT_UTM_MEDIUM = 'recent_utm_medium',
  RECENT_UTM_CONTENT = 'recent_utm_content',
  RECENT_UTM_TERM = 'recent_utm_term',
  PID = 'pid',
  DEVICE_KEY = 'device-key',
  PARTNERSHIP_CODE = 'partnership-code',
  SHORT_LINK_LOG = 'short-link-log',
}

type Key = CookieKey | string;

class CookieManager {
  static get(key: Key) {
    return Cookies.get(key);
  }

  static getJSON(key: Key) {
    return Cookies.getJSON(key);
  }

  static set(key: Key, value: string | Record<string, unknown>, options?: CookieAttributes) {
    return Cookies.set(key, value, options);
  }

  static remove(key: Key, options?: CookieAttributes) {
    Cookies.remove(key, options);
  }
}

export default CookieManager;
