import { getSigninDeepLink, getSigninWebLink } from 'mrt-utils';

export default function redirectSignIn(payload?: {
  isStoreInRedis?: boolean;
  isWebView?: boolean;
  redisKey?: string;
}) {
  const { isStoreInRedis = false, isWebView = false, redisKey } = payload || {};

  const returnTo = isStoreInRedis
    ? `${process.env.HOME_URL}/${
        isWebView ? 'instants/' : ''
      }bridge/redis?type=order&key=${redisKey}&fallbackUrl=${encodeURIComponent(
        window.location.href,
      )}`
    : undefined;

  const redirectUrl = isWebView ? getSigninDeepLink(returnTo) : getSigninWebLink(returnTo);

  window.location.href = redirectUrl;
}
