import { Utils } from 'app/common';
import { GTAG_KEY } from 'mrt-eventlog/gtag/constants';

export const createGTMScript = () => {
  const gtmNode = document.createElement('script');
  gtmNode.text = `
    (function(w,d,s,l,i) {
      w[l]=w[l]||[];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event:'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l='+l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${GTAG_KEY.gtm}');
  `;
  document.head.appendChild(gtmNode);
};

export const createGTMElement = () => {
  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${GTAG_KEY.gtm}`);
  iframe.setAttribute('height', '0');
  iframe.setAttribute('width', '0');
  iframe.setAttribute('style', 'display:none;visibility:hidden');

  noscript.appendChild(iframe);

  Utils.insertAfter(document.getElementsByTagName('body')[0], noscript);
};

export const createGAScript = () => {
  const asyncScript = document.createElement('script');
  asyncScript.src = `https://www.googletagmanager.com/gtag/js?id=${GTAG_KEY.ga}`;
  asyncScript.async = true;

  const gaScript = document.createElement('script');
  gaScript.text = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${GTAG_KEY.ga}');
  `;

  document.head.insertBefore(asyncScript, document.head.childNodes[0]);
  document.head.insertBefore(gaScript, document.head.childNodes[1]);
};
