import qs from 'qs';
import React from 'react';

import PropTypes from 'prop-types';

import { toHotelItemShape } from '../../../../api/hotel';
import { hotelSortEnum } from '../../../../enum';
import HotelCardSwiper from '../../../shared/Cards/HotelCardSwiper';
import { handleHotelClick } from '../../utils/jejuSendEvent';
import section from '../../utils/section';
import useRequest from '../../utils/useRequest';
import CrossSellJejuSection from './CrossSellJejuSection';

const params = {
  'page.page': 1,
  'page.size': 8,
  'place.type': 'CITY',
  'place.latitude': '33.4996213',
  'place.longitude': '126.5311884',
  'place.cityName': '제주도',
  'place.countryCode': 'KR',
  orderBy: hotelSortEnum.recommendation,
};

function buildHotelData(data, isWebView) {
  const hotels = data.map(toHotelItemShape);

  return hotels.map((hotel) => ({
    id: hotel.id,
    thumbnail: hotel.imageUrls[0],
    title: hotel.name,
    reviewScore: hotel.review.summary.score,
    reviewStatus: hotel.review.summary.scoreDescription,
    reviewCount: hotel.review.totalCount,
    price: hotel.minPrice,
    link: isWebView
      ? `mrt://hotelweb?url=${encodeURIComponent(
          `https://www.myrealtrip.com/accommodations/hotels/${hotel.id}`,
        )}`
      : `https://www.myrealtrip.com/accommodations/hotels/${hotel.id}`,
    star: hotel.numberOfStars,
    distance: hotel.distance,
  }));
}

function CrossSellJejuHotels(props) {
  const { isWebView } = props;
  const { isLoading, data } = useRequest(
    `${process.env.HOTELS_API_URL}/v1/hotels/featured?${qs.stringify(params)}`,
  );
  const hotels = data && data.hotels ? buildHotelData(data.hotels, isWebView) : null;
  const sectionData = section.hotels;

  if (!hotels || !hotels?.length) {
    return null;
  }

  return (
    <CrossSellJejuSection isLoading={isLoading} {...props} {...sectionData}>
      {hotels !== null && (
        <HotelCardSwiper
          keyName="cross-hotels"
          hotels={hotels}
          onClick={() => handleHotelClick(isWebView)}
        />
      )}
    </CrossSellJejuSection>
  );
}

CrossSellJejuHotels.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isWebView: PropTypes.bool.isRequired,
};

export default CrossSellJejuHotels;
