import axios, { AxiosResponse } from 'axios';

import { ResponseType } from 'mrt-apis';

// FIXME: 인증 필요한지 논의 필요 & 필요 시, token 및 withCredentials 추가.

// S3로 정책 관련 내용을 html 파일 형태로 저장.
const policyAxios = axios.create({
  baseURL: process.env.POLICY_URL,
  // withCredentials: true,
  headers: {
    'Content-Type': 'text/html',
  },
});

policyAxios.interceptors.response.use(function (response: AxiosResponse<ResponseType>) {
  const mrtResponseCode = response.data?.result?.status;
  if (mrtResponseCode && mrtResponseCode >= 300) {
    return Promise.reject({
      config: response.config,
      code: response.status,
      /* eslint-disable @typescript-eslint/no-unsafe-assignment */
      // when error
      request: response.request,
      response: {
        ...response,
        status: mrtResponseCode,
      },
    });
  }

  return response;
});

export default policyAxios;
