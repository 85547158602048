export enum RefundablePolicy {
  NOT_REFUNDABLE = 'NOT_REFUNDABLE',
  PARTIAL_REFUNDABLE = 'PARTIAL_REFUNDABLE',
  REFUNDABLE = 'REFUNDABLE',
}

// case별 참고 문서: https://myrealtrip.atlassian.net/l/c/YJR1vDmk
export const IMMEDIATE_CONFIRM_REFUNDABLE_MAP: Record<RefundablePolicy, string> = {
  [RefundablePolicy.NOT_REFUNDABLE]: '예약이 바로 확정되며, 환불이 불가합니다.',
  [RefundablePolicy.PARTIAL_REFUNDABLE]:
    '예약이 바로 확정되며, YYYY년 MM월 DD일 hh:mm (한국 시간)까지 취소 시 rate% 공제 후 환불됩니다.',
  [RefundablePolicy.REFUNDABLE]: 'YYYY년 MM월 DD일 hh:mm (한국 시간)까지 전액 환불이 가능합니다',
};

export const REFUNDABLE_MAP: Record<RefundablePolicy, string> = {
  [RefundablePolicy.NOT_REFUNDABLE]:
    '예약 확정 전까지 전액 환불이 가능하며, 확정 후에는 환불이 불가합니다.',
  [RefundablePolicy.PARTIAL_REFUNDABLE]:
    '예약 확정 전까지 전액 환불이 가능하며, 확정 후에는 YYYY년 MM월 DD일 hh:mm (한국 시간)까지 취소 시 rate% 공제 후 환불됩니다.',
  [RefundablePolicy.REFUNDABLE]: 'YYYY년 MM월 DD일 hh:mm (한국 시간)까지 전액 환불이 가능합니다.',
};

export const RequiredTerm = {
  /** 개인정보 수집 및 이용 동의 (필수) */
  PRIVACY_USAGE_TERM: 'PRIVACY_USAGE_TERM',
  /** 개인정보 제공 동의 (필수) */
  PRIVACY_TERM: 'PRIVACY_TERM',
  /** 개인정보 국외 이전 동의 (필수) - 여권번호 수집 시 */
  PRIVACY_TRANSFER_TERM: 'PRIVACY_TRANSFER_TERM',
  /** 고유식별정보 제공 동의 (필수) */
  IDENTIFY_TERM: 'IDENTIFY_TERM',
  /** 고유식별정보 수집 및 이용 동의 (필수) */
  IDENTIFY_USAGE_TERM: 'IDENTIFY_USAGE_TERM',
} as const;

export const OptionalTerm = {
  /** 특별약관 (패키지 전용) */
  SPECIAL_TERMS: 'SPECIAL_TERMS',
  /** 마케팅 이용 동의 */
  BENEFITS_TERM: 'BENEFITS_TERM',
} as const;

export const TermTitle = {
  PRIVACY_USAGE_TERM: '개인정보 수집 및 이용 동의 (필수)',
  PRIVACY_TERM: '개인정보 제공 동의 (필수)',
  PRIVACY_TRANSFER_TERM: '개인정보 국외 이전 동의 (필수)',
  IDENTIFY_TERM: '고유식별정보 제공 동의 (필수)',
  IDENTIFY_USAGE_TERM: '고유식별정보 수집 및 이용 동의 (필수)',
  SPECIAL_TERMS: '특별약관 확인 및 동의 (필수)',
  BENEFITS_TERM: '마케팅 이용 동의 (선택)',
} as const;

export const AllTerm = { ...RequiredTerm, ...OptionalTerm } as const;

export enum FlightTermEnum {
  /** 결제규정 */
  FLIGHT_PAYMENT_TERMS = 'FLIGHT_PAYMENT_TERMS',
  /** 환불규정 */
  FLIGHT_REFUND_TERM = 'FLIGHT_REFUND_TERM',
}

export const FlightTermTitle: Record<FlightTermEnum, string> = {
  [FlightTermEnum.FLIGHT_PAYMENT_TERMS]: '결제규정',
  [FlightTermEnum.FLIGHT_REFUND_TERM]: '변경 및 환불 규정',
} as const;
