/* eslint-disable sonarjs/cognitive-complexity */
import React, { PropsWithChildren, useRef, useState } from 'react';

import { CarouselNavigationButton, CarouselNavigationButtonDirectionType } from 'mrt-components';
import { Navigation, FreeMode, Pagination, Autoplay } from 'swiper';
import { SwiperSlide, SwiperProps } from 'swiper/react';

import styled from '@emotion/styled';

import { StyledSwiper } from '../Swiper';

type GradientType = { left: boolean; right: boolean };

const DEFAULT_OPTIONS: SwiperProps = {
  modules: [FreeMode, Navigation, Pagination, Autoplay], // 사용될 수 있는 옵션의 모듈을 미리 설정해둔다.
  loop: false,
  speed: 200,
  spaceBetween: 8,
  grabCursor: true,
};

interface PropsType {
  isNavigationEnabled?: boolean;
  isPaginationEnabled?: boolean;
  swiperOptions?: SwiperProps;
  className?: string;
  onNaviClick?: (direction: CarouselNavigationButtonDirectionType) => void;
}

/**
 *
 * @description navigation이 설정되어있는 Carousel
 * @isNavigationEnabled navigation 활성 flag
 * @isPaginationEnabled pagination 활성 flag
 * @swiperOptions swiper option override가 필요한 경우 사용한다. (https://swiperjs.com/swiper-api)
 * @onNaviClick 좌,우 네비게이션 버튼 클릭 시 실행될 callback
 */
function CardCarousel({
  children,
  swiperOptions,
  isNavigationEnabled = false,
  isPaginationEnabled = false,
  className,
  onNaviClick,
}: PropsWithChildren<PropsType>) {
  const [initSwiper, setInitSwiper] = useState(false);
  const [showGradient, setShowGradient] = useState<GradientType>({
    left: false,
    right: false,
  });
  const prevNaviRef = useRef<HTMLButtonElement | null>(null);
  const nextNaviRef = useRef<HTMLButtonElement | null>(null);

  const handleShowGradient = ({ isBeginning = false, isEnd = false }) => {
    if (!isNavigationEnabled) {
      setShowGradient({ left: false, right: false });

      return;
    }

    setShowGradient({ left: !isBeginning, right: !isEnd });
  };

  const handleNaviClick = (direction: CarouselNavigationButtonDirectionType) => () => {
    const isPrevDisabled = prevNaviRef?.current?.disabled;
    const isNextDisabled = nextNaviRef?.current?.disabled;

    setShowGradient((old) => ({
      ...old,
      left: old.left === !isPrevDisabled ? old.left : !isPrevDisabled,
      right: old.right === !isNextDisabled ? old.right : !isNextDisabled,
    }));

    onNaviClick?.(direction);
  };

  const naviOptions: SwiperProps = isNavigationEnabled
    ? {
        navigation: {
          prevEl: prevNaviRef.current,
          nextEl: nextNaviRef.current,
        },
      }
    : {};
  const pageOptions: SwiperProps = {
    pagination: isPaginationEnabled,
  };
  const eventOptions: SwiperProps = {
    onBeforeInit: ({ isBeginning, isEnd }) => {
      handleShowGradient({ isBeginning, isEnd });
    },
    onInit: () => setInitSwiper(true),
    onAfterInit: (swiper) => {
      if (!initSwiper) {
        return;
      }
      const { navigation } = swiper.params;

      if (typeof navigation !== 'boolean' && isNavigationEnabled) {
        if (navigation?.prevEl && navigation?.nextEl) {
          navigation.prevEl = prevNaviRef.current;
          navigation.nextEl = nextNaviRef.current;
        }
      }
    },
    onSlideChange: ({ isBeginning, isEnd }) => {
      handleShowGradient({ isBeginning, isEnd });
    },
  };

  const combineSwiperOptions: SwiperProps = {
    ...DEFAULT_OPTIONS,
    ...naviOptions,
    ...pageOptions,
    ...eventOptions,
    ...swiperOptions,
  };

  return (
    <CarouselContainer showGradient={showGradient} className={className}>
      {isNavigationEnabled && (
        <CarouselNavigationButton
          direction="prev"
          onClick={handleNaviClick('prev')}
          ref={prevNaviRef}
        />
      )}
      <StyledSwiper {...combineSwiperOptions}>
        {React.Children.map(children, (child) => (
          <SwiperSlide>{child}</SwiperSlide>
        ))}
        {isPaginationEnabled && <PaginationPadding />}
      </StyledSwiper>
      {isNavigationEnabled && (
        <CarouselNavigationButton
          direction="next"
          onClick={handleNaviClick('next')}
          ref={nextNaviRef}
        />
      )}
    </CarouselContainer>
  );
}

export default CardCarousel;

const CarouselContainer = styled.div<{
  showGradient?: GradientType;
}>`
  position: relative;

  // pagination 스타일 커스텀
  .swiper-pagination-bullets {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    background: var(--gray-400);
    margin: 0 3px !important;

    &-active {
      background: var(--gray-700);
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 64px;
    height: 100%;
    z-index: 2;
  }
  &:before {
    left: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    ${({ showGradient }) => `display: ${showGradient?.left ? 'block' : 'none'};`}
  }
  &:after {
    right: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    ${({ showGradient }) => `display: ${showGradient?.right ? 'block' : 'none'};`}
  }
`;

const PaginationPadding = styled.div`
  width: 100%;
  height: 20px;
`;
