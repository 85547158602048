import { offerSectionEnum } from '../../enum';

const offer = {
  sideBar: {
    perOne: '/1',
    won: '원',
    night: '1박',
    person: '인',
    perPerson: '1인',
    buy: '구매하기',
    reservation: '예약하기',
    question: '문의하기',
    uncheckWishlist: '저장됨에서 삭제',
    checkWishlist: '저장됨에 추가',
    wishListDescription: '{{num}}명이 이 상품을 저장됨에 추가했습니다.',
    takeTime: '시간 소요',
    banner: '배너',
    instantBooking: '구매 후 즉시 확정됩니다.',
    startPrice: '부터',
    minTravelers: '최소 출발 인원 {{count}}명',
    coupon: '쿠폰할인가',
    dormant: {
      title: '휴면 상품',
      desc: '파트너의 요청으로 현재 구매 할 수 없습니다.',
    },
  },
  category: {
    ticket: '티켓 선택',
    offer: '상품 소개',
    course: '코스 소개',
    require: '필수 안내',
    howToUse: '사용 방법',
    review: '후기',
    option: '옵션 선택',
  },
  review: {
    istanbul: `후기 {{count}}`,
    traveler: '여행자 후기 사진',
    star: '별점',
    number: '후기 {{reviewCount}}개',
    event: '후기 이벤트',
    more: '후기 더 보기',
    allReview: '후기 {{count}}개 전체 보기',
    reviewer: '리뷰어',
    age: '{{age}}대',
    bannerTitle: '후기 작성하고 할인쿠폰 받으세요!',
    world: '이 상품을 사용해본 전 세계 여행자들의 후기입니다.',
    bannerContent:
      '후기를 작성하신 모든 분께 5천원 할인쿠폰을, 상품의 첫 후기일 경우 3만원 할인쿠폰을 드립니다.',
  },
  description: {
    day: '일',
    hour: '시간',
    minute: '분',
    optionPeople: '{{num}}명',
    offerNum: '상품번호: ',
    option: '옵션',
    personnel: '인원',
    selectDatePeople: '날짜와 {{option}}을 선택하세요.',
    selectDateText: '날짜를 선택하세요.',
    selectOptionText: '{{option}}을 선택하세요.',
    questionPrice: '금액 조회하기가 무엇인가요?',
    selectDate: '날짜 선택',
    selectOption: '{{detailOption}} 선택',
    getPrice: '금액 조회하기',
    updatePrice: '금액 업데이트',
    include: '포함사항',
    exclude: '불포함사항',
    meetingTime: '만나는 시간',
    meetingPoint: '만나는 장소',
    showMapDetail: '지도 보기',
    hideMapDetail: '지도 숨기기',
    totalPrice: '총 여행 금액',
    zoom: '크게 보기',
    flexible: '조정가능',
    showReview: '후기 전체보기',
    ask: '문의하기',
    bestPurpose: '으로 구매가 많은 상품',
    priceTable: '가격표',
    phone: '전화번호: {{contacts}}',
    email: '이메일: {{email}}',
    kakao: '카카오톡ID: {{kakao}}',
    recommendOffer: '이 상품을 본 여행자가 함께 본 상품',
    viewTogether: '여행자들이 함께 본 상품',
    buyTogether: '여행자들이 함께 구매한 상품',
    recommendHotel: '{{city}} 추천 호텔',
  },
  transport: {
    car: '차량이동',
    walk: '도보이동',
  },
  calendar: {
    info: ' 여행일을 선택해 주세요. (단, e-티켓은 이용일, 실물 수령상품은 수령일 기재)',
    lodgingInfo: '체크인과 체크아웃 날짜를 선택해주세요.',
  },
  purpose: {
    alone: '혼자 가는 여행',
    friends: '친구들과 가는 여행',
    lover: '연인과 가는 여행',
    honeymoon: '허니문 여행',
    partner: '배우자와 가는 여행',
    parents: '부모님과 가는 여행',
    children: '자녀와 가는 여행',
    official: '출장, 학회로 가는 여행',
    etc: '여행',
  },
  cancelRefund: {
    title: '취소 및 환불 규정',
    detail: '자세히 보기',
  },
  realGuide: {
    title: '믿을 수 있는 리얼파트너',
    content: '최근 1년 간 만족도 평점 4.8점 이상을 유지하는 우수 파트너입니다.',
  },
  guarantee: {
    title: '최저가 보장제 상품',
    detail: '자세히 보기',
    content: '최저가가 아니라면 마이리얼트립이 차액의 두 배를 환불해 드립니다.',
  },
  course: {
    title: '코스 소개',
    takeTime: '{{time}}{{unit}} 소요',
    more: '코스 더보기',
  },
  locale: {
    ko: '한국어',
    en: '영어',
    es: '스페인어',
    zh: '중국어',
    fr: '프랑스어',
    de: '독일어',
    zhCN: '중국어(간체)',
    zhTW: '중국어(번체)',
    ja: '일본어',
    etc: '그 외',
  },
  special: {
    freeRefund: '무료취소',
    priceGuarantee: '최저가 보장제',
    ignoreWaitConfirm: '즉시 확정',
    happycall: '해피콜',
  },
  duration: {
    hour: '시간',
    day: '일',
    minute: '분',
  },
  coupon: {
    idle: '{{title}} 받기',
    success: '{{title}} 받기 완료',
    error: '쿠폰을 받지 못했습니다. 다시 시도해주세요.',
    needLogin: '로그인 후 다운로드 가능합니다.\n확인 버튼을 누르시면 로그인 페이지로 이동합니다.',
  },
  options: {
    total: '총 여행 금액',
    payment: '결제하기',
    selectTicket: '티켓 선택',
    paymentWithPrice: '{{price}} 결제하기',
    alertTicket: '티켓을 선택해주세요.',
    error: '티켓 정보를 불러오지 못했습니다.\n잠시 후에 다시 시도해주세요.',
    selectDate: '날짜를 선택해주세요',
    selectOption: '옵션을 선택해주세요',
    selectPerson: '인원을 선택해주세요',
  },
  section: {
    [offerSectionEnum.ticket]: '티켓 선택',
    [offerSectionEnum.introduction]: '상품 소개',
    [offerSectionEnum.detail]: '상품 정보',
    [offerSectionEnum.course]: '코스 소개',
    [offerSectionEnum.guidance]: '이용 안내',
    [offerSectionEnum.refund]: '환불 안내',
    [offerSectionEnum.review]: '후기',
  },
  time: {
    hour: '{{time}}시간',
    minute: '{{time}}분',
    day: '{{time}}일',
  },
  meta: {
    eticket: 'e-ticket',
    voucher: '모바일 또는 프린트 바우처',
    delivery: '배송 티켓',
    expired: '유효기간(~{{date}}) 내 사용',
    privateTour: '단독 프라이빗 투어',
    groupTour: '그룹 투어',
    car: '차량 이동',
    walk: '도보 이동',
    duration: '{{time}} 소요',
    flexible: '시간 조정 가능',
    minTravelers: '(최소 출발 인원 {{value}}명)',
  },
  refund: {
    title: '안심하고 구매하세요!',
    content:
      '유효기간과 상관없이 사용하지 않은 티켓은 <strong>100% 환불</strong> 가능한 상품입니다.',
  },
  info: {
    price: '인원별 가격',
    include: '포함 사항',
    exclude: '불포함 사항',
  },
  use: {
    subtitle: {
      tour: {
        time: '만나는 시간',
        place: '만나는 장소',
        guide: '필수 안내',
      },
      other: {
        time: '이용 시간',
        place: '위치 안내',
        guide: '사용 방법',
      },
    },
    addInfo: '추가정보',
  },
  cancel: '취소 및 환불 규정',
  detail: '상품 설명',
  more: '더 보기',
  collapse: '접기',
  reviewPhotos: '여행자 후기 사진',
};
export default offer;
