import { useEffect, useRef } from 'react';

import { helper } from '@myrealtrip/frontend-weblog';

const { sendPageViewLog } = helper;

export default function usePageViewLog(log: Record<string, any> | null) {
  const sentLog = useRef(false);

  useEffect(() => {
    if (!log) {
      return;
    }

    if (!sentLog.current) {
      sendPageViewLog(log);
      sentLog.current = true;
    }
  }, [log]);
}
