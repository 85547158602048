import { HeaderTheme, HeaderThemeType } from '@layout/constants/header';

type ThemesMap = {
  type: HeaderThemeType;
  regx: RegExp;
  mobileOnly: boolean;
};

const THEMES_MAP: ThemesMap[] = [
  { type: HeaderTheme.blue, regx: /\/about\/checklist/g, mobileOnly: false },
  { type: HeaderTheme.blue, regx: /\/airtel/g, mobileOnly: false },
  {
    type: HeaderTheme.blue,
    regx: /\/campaigns\/realpackage/g,
    mobileOnly: false,
  },
  {
    type: HeaderTheme.blue,
    regx: /\/campaigns\/mrtcoupon/g,
    mobileOnly: false,
  },
  { type: HeaderTheme.blue, regx: /\/experiences/g, mobileOnly: false },
  { type: HeaderTheme.blue, regx: /\/lodgings/g, mobileOnly: false },
  {
    type: HeaderTheme.transparent,
    regx: /\/offers\/[0-9]+\/?$/g,
    mobileOnly: true,
  },
  {
    type: HeaderTheme.transparent,
    regx: /\/contents\/[0-9]$/g,
    mobileOnly: false,
  },
  { type: HeaderTheme.transparent, regx: /\/cities$/g, mobileOnly: false },
  {
    type: HeaderTheme.transparent,
    regx: /\/countries$/g,
    mobileOnly: false,
  },
  {
    type: HeaderTheme.transparent,
    regx: /\/accommodations$/g,
    mobileOnly: false,
  },
  {
    type: HeaderTheme.transparent,
    regx: /\/accommodations\/stays\/[0-9]+\/?$/g,
    mobileOnly: true,
  },
];

const defaultThemeType = HeaderTheme.white;

export const getHeaderThemeByPathName = ({
  currentPathName,
  isMobile,
}: {
  currentPathName: string;
  isMobile: boolean;
}) => {
  const matchedTheme = THEMES_MAP.find(({ regx }) => regx.test(currentPathName));

  if (!matchedTheme) {
    return defaultThemeType;
  }

  if (matchedTheme.mobileOnly) {
    return isMobile ? matchedTheme.type : defaultThemeType;
  }

  return matchedTheme.type;
};
