import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { HeaderThemeType, HeaderTheme } from '@layout/constants/header';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';
import { resetButton } from '@layout/styles/reset';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import RedDot from './RedDot';

type Props = {
  className?: string;
  label: string;
  redDot?: boolean;
  onClick?: () => void;
};

export default function GlobalNavOutLineButton({ className, label, redDot, onClick }: Props) {
  const { theme } = useLayoutHeaderContext();

  return (
    <Container className={className}>
      <Button type="button" colorTheme={theme} onClick={onClick}>
        {label}
      </Button>
      {redDot && <RedDot />}
    </Container>
  );
}

const BUTTON_THEME_STYLE = {
  [HeaderTheme.blue]: css`
    color: ${globalVariables.white};
    border: 1px solid ${globalVariables.white};

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  `,
  [HeaderTheme.white]: css`
    color: ${globalVariables.blue_500};
    border: 1px solid ${globalVariables.blue_200};

    &:hover {
      background-color: ${globalVariables.blue_50};
    }
  `,
  [HeaderTheme.transparent]: css`
    color: ${globalVariables.white};
    border: 1px solid ${globalVariables.white};

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  `,
};

const Container = styled.div`
  position: relative;
`;

const Button = styled.button<{ colorTheme: HeaderThemeType }>`
  ${resetButton};

  height: 100%;
  margin-left: 12px;
  padding: 0 32px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.15s ease;

  ${({ colorTheme }) => BUTTON_THEME_STYLE[colorTheme]}
`;
