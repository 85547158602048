import GoogleMap, { ChangeEventValue, Coords, Props as GoogleMapProps } from 'google-map-react';
import { useRef, forwardRef, useCallback, useState } from 'react';
import {} from 'googlemaps';

import { Marker } from '@myrealtrip/design-system';
import { Lodging } from 'mrt-types';

import SearchOptionButton from './SearchOptionButton';

import * as styles from './ProductsGoogleMap.style';

export enum SearchOption {
  MOVABLE = 'MOVABLE',
  AREA = 'AREA',
}

interface PropsType extends GoogleMapProps {
  className?: string;
  center?: Coords;
  searchPopoverVisible: boolean;
  productList: Lodging.ProductSearchListItem[];
  selectedCardCallback: (order: number) => void;
  hoveredProductOrder: number;
  selectedProductOrder: number;
  handleLocationChange: (latitude: number, longitude: number, radius: number) => void;
  onGoogleApiLoaded?: () => void;
}

const OFFSET_FROM_BOTTOM = 100;

const ProductsGoogleMap = (
  {
    className,
    center,
    productList,
    selectedCardCallback,
    selectedProductOrder,
    onGoogleApiLoaded,
    hoveredProductOrder,
    handleLocationChange,
    ...googleMapProps
  }: PropsType,
  ref,
) => {
  const [optionType, setOptionType] = useState<SearchOption>(SearchOption.MOVABLE);
  const [isMovedByUser, setIsMovedByUser] = useState(false);

  const isInitialize = useRef<boolean>(false);
  const googleMapRef = useRef<google.maps.Map | null>(null);
  const isMovableType = useRef<boolean>(true);

  function handleMapChange({ center: centerOffset }: ChangeEventValue) {
    if (!isInitialize.current) {
      isInitialize.current = true;

      return;
    }

    if (!isMovedByUser) {
      return;
    }

    if (googleMapRef.current !== null && isMovableType.current) {
      const bounds = googleMapRef.current.getBounds() as google.maps.LatLngBounds;
      const ne = bounds.getNorthEast();
      const east = new google.maps.LatLng(ne.lat(), centerOffset.lng);

      const radius = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(centerOffset.lat, centerOffset.lng),
        east,
      );

      handleLocationChange(centerOffset.lat, centerOffset.lng, radius);
    }

    if (!isMovableType.current) {
      setOptionType(SearchOption.AREA);
    }
  }

  function handleDrag() {
    if (!isMovedByUser) {
      setIsMovedByUser(true);
    }
  }

  const handleMarkerClickCallback = useCallback(
    (order: number) => {
      selectedCardCallback(order);
    },
    [selectedCardCallback],
  );

  const handleResearchClick = useCallback(() => {
    if (googleMapRef.current === null) {
      return;
    }

    const centerOffset = googleMapRef.current.getCenter();
    const bounds = googleMapRef.current.getBounds() as google.maps.LatLngBounds;
    const ne = bounds.getNorthEast();
    const radius = google.maps.geometry.spherical.computeDistanceBetween(centerOffset, ne);

    handleLocationChange(centerOffset.lat(), centerOffset.lng(), radius);
    setOptionType(SearchOption.MOVABLE);
  }, [handleLocationChange]);

  const setEnableMovableOption = useCallback((checked: boolean) => {
    isMovableType.current = checked;
  }, []);

  const loadGoogleApi = () => onGoogleApiLoaded && onGoogleApiLoaded();

  return (
    <div ref={ref} className={className} css={styles.map}>
      <GoogleMap
        onGoogleApiLoaded={({ map }) => {
          googleMapRef.current = map as google.maps.Map;
          googleMapRef.current.panBy(0, OFFSET_FROM_BOTTOM);
          loadGoogleApi();
        }}
        center={center}
        defaultZoom={10}
        options={{
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
          gestureHandling: 'greedy',
          disableDefaultUI: true,
          clickableIcons: false,
        }}
        onChange={handleMapChange}
        onDrag={handleDrag}
        {...googleMapProps}
      >
        {productList.map((product, order) => {
          return (
            <Marker.Desktop
              key={product.id}
              order={order}
              rating={product.averageScore}
              price={
                product.unAvailableReason !== null
                  ? product.unAvailableReason
                  : product.priceInfo.salePrice.amount
              }
              lat={product.location.latitude}
              lng={product.location.longitude}
              selected={hoveredProductOrder === order || selectedProductOrder === order}
              onClick={handleMarkerClickCallback}
            />
          );
        })}
      </GoogleMap>
      <SearchOptionButton
        css={styles.searchOptionButton}
        optionType={optionType}
        setEnableMovableOption={setEnableMovableOption}
        onResearchClick={handleResearchClick}
      />
    </div>
  );
};

export const ProductsGoogleMapWithRef = forwardRef<HTMLDivElement, PropsType>(ProductsGoogleMap);
