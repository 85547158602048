import { Appboy } from './types';

let appboyInstance: Window['appboy'];

function getInstance(): Appboy {
  try {
    if (!appboyInstance) {
      // 기존 2.0 방식으로 전역에 initialize된 appboy가 있을 경우 가져옴
      // https://github.com/myrealtrip/myrealtrip-server/blob/production/app/views/mrt3/layouts/application.haml#L78
      // = render 'kitty/layouts/tracking_codes/braze'
      appboyInstance = window.appboy;

      if (!appboyInstance) {
        throw new Error('appboy not initialized');
      }
    }
  } catch (error) {
    console.error(error);
  }

  return appboyInstance;
}

export default getInstance;
