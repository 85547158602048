import { WishProvider } from '@myrealtrip/wish';

import PropTypes from 'prop-types';

import CardSwiper from './CardSwiper';
import CardSwiperWithGrid from './CardSwiperWithGrid';
import OfferVerticalCard from './OfferVerticalCard';
import { offerType, swiperType } from '../../../types';

function OfferCardSwiper(props) {
  const {
    keyName,
    offers,
    withGrid,
    swiperOptions,
    category,
    isWebView,
    hideWishlist,
    onClick,
    onImpression,
    openNewTab,
    themeOrder,
    qs,
    wishLogData,
  } = props;
  const Container = withGrid ? CardSwiperWithGrid : CardSwiper;

  return (
    <WishProvider>
      <Container keyName={keyName} swiperOptions={swiperOptions}>
        {offers.map((offer, index) => (
          <OfferVerticalCard
            key={offer.id}
            order={index}
            offer={offer}
            category={category}
            isWebView={isWebView}
            hideWishlist={hideWishlist ? hideWishlist : isWebView}
            onClick={onClick}
            onImpression={onImpression}
            openNewTab={openNewTab}
            themeOrder={themeOrder}
            qs={qs}
            wishLogData={wishLogData}
          />
        ))}
      </Container>
    </WishProvider>
  );
}

OfferCardSwiper.propTypes = {
  keyName: PropTypes.string,
  offers: PropTypes.arrayOf(offerType).isRequired,
  swiperOptions: swiperType,
  withGrid: PropTypes.bool,
  category: PropTypes.string,
  isWebView: PropTypes.bool,
  hideWishlist: PropTypes.bool,
  onClick: PropTypes.func,
  onImpression: PropTypes.func,
  openNewTab: PropTypes.bool,
  qs: PropTypes.string,
  themeOrder: PropTypes.number,
  wishLogData: PropTypes.shape({}).isRequired,
};

OfferCardSwiper.defaultProps = {
  keyName: 'OfferSwiper',
  swiperOptions: {},
  withGrid: true,
  category: '',
  isWebView: false,
  hideWishlist: false,
  onClick: () => null,
  onImpression: () => null,
  openNewTab: false,
  qs: null,
  themeOrder: undefined,
};

export default OfferCardSwiper;
