import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@myrealtrip/design-system/dist/components/Icon';

import { getDateString } from '../../utils/domesticReservationUtils';

import styles from './FlightTicketHeader.module.scss';

function FlightTicketHeader(props) {
  const { data, index } = props;
  const direction = index === 0;

  return (
    <div className={styles.flightTicketHeader}>
      <span className={styles.flightDirection}>
        <span>{direction ? '가는편' : '오는편'}</span>
      </span>
      <div className={styles.topBox}>
        <span className={styles.airportName}>{data.DEP_AIRPORT_NM}</span>
        <span className={styles.arrowShort}>
          <Icon name="ic_fromto_20x20_gray_400" />
        </span>
        <span className={styles.airportName}>{data.ARR_AIRPORT_NM}</span>
        <span className={styles.flightDay}>{getDateString(data.DEP_DATE)}</span>
      </div>
    </div>
  );
}

FlightTicketHeader.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
};

export default FlightTicketHeader;
