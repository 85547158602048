import { mobileOSEnum } from '../enum';

export default function getMobileOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return mobileOSEnum.window;
  }

  if (/android/i.test(userAgent)) {
    return mobileOSEnum.android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return mobileOSEnum.ios;
  }

  return mobileOSEnum.unknown;
}
