import media from 'mrt-utils/mediaQuery';

import styled from '@emotion/styled';

import CustomerCenter from './CustomerCenter';
import SiteMap from './SiteMap';

export default function FooterTopBox() {
  return (
    <Container>
      <CustomerCenter />
      <SiteMap />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-bottom: 32px;

  ${media.mobile} {
    flex-direction: column;
  }
`;
