const header = {
  title: '진짜 나다운 여행 - 마이리얼트립',
  registGuide: '가이드 등록하기',
  signInUp: '로그인 및 회원가입',
  signIn: '로그인',
  signUp: '회원가입',
  manageProfile: '프로필 관리',
  alarmCenter: '알림 센터',
  message: '메시지',
  wishlist: '저장됨',
  myTrip: '나의 여행',
  invitationFriends: '친구 초대',
  helpChat: '1:1 채팅 상담',
  signOut: '로그아웃',
  profileManage: '프로필 관리',
  home: '홈',
  experience: '투어&티켓',
  domestic: '국내여행',
  flight: '항공권',
  hotel: '숙소',
  hotdeal: '패키지',
  coupons: '할인혜택',
  checklist: '여행준비',
  insurance: '여행자보험',
  rentcar: '제주렌터카',
  golf: '국내골프',
  searchTutorialTitle: '더 똑똑해진 마이리얼트립 검색',
  searchTutorialContent:
    '여행가고 싶은 도시부터 티켓, 교통패스, 가이드 투어까지 무엇이든 검색해보세요.',
  tutorialClose: '다시 보지 않기',
  tutorialSearchNow: '지금 검색해 보기',
  popularCity: '인기 여행지',
  notiMore: '더보기',
  allRegion: '전체 여행지',
  partnerPage: '(구) 파트너 페이지',
  lodgingPartner: '파트너 페이지',
  registerPartner: '파트너 등록하기',
  affiliation: 'B2B 예약조회 바로가기',
  emptyNotification1: '새로운 알림이 없습니다.',
  emptyNotification2: '마이리얼트립과 즐거운 여행을 계획해보세요!',
  searchPlaceholder: '도시나 상품을 검색해보세요',
  point: '포인트',
  coupon: '쿠폰',
  reservation: '내 여행',
  notification: '알림',
  drawerButtonAlt: '포인트, 쿠폰, 프로필 관리, 예약내역 등의 정보를 확인',
  searchButtonAlt: '검색 페이지로 이동',
  profilePhotoAlt: '님의 프로필 사진',
  defaultUsername: '회원',
  notificationCloseAlt: '알림 창 닫기',
};

export default header;
