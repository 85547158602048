const career = {
  subtitle: 'We are looking forward to a colleague who will create a more valuable travel culture with MyRealTrip.',
  member: {
    title: 'Professionals who think customer-centric and collaborate in an active manner',
    items: [
      {
        key: '01',
        title: 'Customer-centric',
        content: `We always listen to the customer's voice and think deeply from the customer's point of view. We try to put our customers first in the process of prioritizing everything that is going on with MyRealTrip team and making decisions.`,
      },
      {
        key: '02',
        title: 'Active manner',
        content: `The goals that we are aiming at are very high. We will challenge ourselves until the day when the whole nation can 'go on their own' to suit their tastes. There is a lot of uncertainty, a lot of difficulties, but we are active and we are doing it with a positive mind.`,
      },
      {
        key: '03',
        title: 'Collaboration',
        content:
          'There is nothing you can do alone in MyRealTrip. All results and achievements are produced through collaboration. We always communicate open-mindedly, look in the same direction and try to grow together.',
      },
      {
        key: '04',
        title: 'Professional',
        content:
          'MyRealTrip grows the business by giving individuals enough responsibility and authority over management and control. We are professionals who are more capable and more self-developed in a free environment.',
      },
    ],
  },
  benefit: {
    title: 'Benefit',
    subtitle: 'We help members grow and pursues a work-life balance.',
    items: [
      {
        key: 'benefit1',
        title: 'Flexible working environment',
        content:
          'The attendance hours can be adjusted freely and the telework system is in place. This helps the compatibility of individual work and life.',
      },
      {
        key: 'benefit2',
        title: 'Travel product support',
        content:
          'The company will pay 1 million won in MyRealTrip welfare points per year and will provide up to 50,000 won in MyRealTrip coupons that can be given to acquaintances every month.',
      },
      {
        key: 'benefit3',
        title: 'Variety of vacation systems',
        content:
          'We provide annual leave of 15 days, and for events of direct family members. Reward leave and various rewards are provided to long-term employees.',
      },
      {
        key: 'benefit4',
        title: 'Self-improvement support',
        content:
          'Leisure expenses required for physical training, book purchase and cultural life are provided up to 1.8 million won per year, and job training costs such as job-related lectures and seminars are partially supported.',
      },
      {
        key: 'benefit5',
        title: 'Business equipment support',
        content: `We support the right equipment according to the individual's work style and actively support all the costs of the job.`,
      },
      {
        key: 'benefit6',
        title: 'Medical checkup support',
        content: 'Complete medical checkups are provided once a year to help with health care. (KMI)',
      },
    ],
  },
  apply: {
    title: 'Career',
    subtitle: 'Together with MyRealTrip, we invite colleagues to innovate in the travel market.',
    showCareer: 'View open roles',
  },
};

export default career;
