import {
  EstimateProposalType,
  EstimateStatus,
  ProductCategory,
  ProductType,
  SystemProvider,
  TicketType,
} from 'mrt-constants';
import {
  ReservationItemOptionType,
  ReservationTripPeriodType,
  TravelerRequireInfo,
} from 'mrt-types';

export enum ReservationHistoryStatus {
  ongoing = 'ongoing',
  finish = 'finish',
  cancel = 'cancel',
  estimate = 'estimate',
  foreignRentaCar = 'foreignRentaCar',
}

export interface ReservationCardButtonItem {
  label: string;
  code: string;
  onClickButton?: (event?: React.MouseEvent<Element>) => void;
}

export enum CrossSell {
  CROSSSELL = 'CROSS-SELL',
}

export interface ReservationCategory {
  code: SystemProvider | 'ALL';
  name: string;
  count: number;
}

export interface QueryParams {
  status: ReservationHistoryStatus | null;
  timeZone: string;
  startDate: string;
  endDate?: string;
  systemProvider?: SystemProvider | 'ALL' | undefined;
}

export interface ReservationHistoryBody {
  banners: ReservationHistoryBanner[];
  entities: ReservationHistoryEntity[];
}

export interface ReservationHistoryBanner {
  type: string;
  title: string;
  subTitle: string;
  data?: {
    unreviewedCount: number;
  };
}

export type Card = CrossSellCard & GeneralCard & GroupedCard & EstimateCard;

export interface ReservationHistoryEntity {
  date: string;
  cardTitle: string;
  cards: Card[];
}

export interface CrossSellCard {
  id: number;
  type: CrossSell;
  category: CrossSell;
  iconUrl: string;
  title: string;
  content?: string;
  landingUrl: string;
  iosLandingUrl: string;
  androidLandingUrl: string;
}

export interface ReservationCard {
  type: ProductType | CrossSell;
  category: ProductCategory | CrossSell;
  vertical: SystemProvider;
  info: ReservationCardInfo;
  point: number; // 로열티 포인트
}

export interface GeneralCard extends ReservationCard {
  attributes: ReservationCardAttr;
}

export interface GroupedCard extends ReservationCard {
  attributes: ReservationGroupCardAttr;
}

export interface EstimateCard extends ReservationCard {
  attributes: EstimateCardAttr;
}

export interface ReviewInfo {
  systemProvider: SystemProvider;
  productType: ProductType | CrossSell;
  productCategory: ProductCategory | CrossSell;
  reviewId: string;
}

export interface ReservationHistoryOfferInfo {
  id: string | number;
  title: string;
  mainPhotoUrl: string;
  location?: {
    latitude: number;
    longitude: number;
  };
  address?: string | null;
  partnerId?: number;
  allowMessage?: boolean;
  guideId?: number;
}

export interface ReservationReview {
  id: number;
  createdAt: string;
  title: string;
  subtite: string;
  url: string;
  rate: number;
}

interface ReservationCardBanner {
  type: 'button';
  title: string;
  subtitle: string;
  buttonName: string;
}

interface ReservationCardVoucher {
  reservationNo: string; // GeneralCard는 1:1 매칭, GroupedCard는 하위예약 중 1개 예약번호 해당
  voucherItems: { link: string }[];
}

export interface ReservationCardAttr {
  title: string;
  subtitle?: string;
  reservationId?: number;
  reservationNo?: string;
  status: string;
  statusTitle: string;
  productId?: string;
  address?: string;
  autoCancelable: boolean;

  // 비행시간 정보
  depDate?: string;
  depTm?: string;
  arrDate?: string;
  arrTm?: string;
  depCode?: string; // 출발지 도시 코드
  arrCode?: string; // 도착지 도시 코드
  depAirportCode?: string; // 출발지 공항 코드
  arrAirportCode?: string; // 도착지 공항 코드
  duringTime?: string;
  flight?: string;
  flightName?: string;
  flightNumber?: string;
  flightImage?: string;
  landingUrl?: string;
  cancelUrl?: string;

  // 공동운항편일 경우 공동운항사 정보
  codeshareYn?: string;
  codeshareAirCd?: string;
  codeshareAirNm?: string;
  codeshareCdImg?: string;

  // 숙소 입퇴실 정보
  checkInDate?: string;
  checkInTime?: string;
  checkOutDate?: string;
  checkOutTime?: string;

  // 후기 관련 정보
  review?: ReservationReview;

  // 사용일 입력 티켓 액션 배너 정보
  banner?: ReservationCardBanner;
  imageUrl?: string;
  landing?: string;
  offer?: ReservationHistoryOfferInfo;
  businessContact?: string;

  // 3.0 상품 여부
  isMrt3: boolean;

  // 티켓 만료일
  expiredAt?: string;
  // 티켓 사용일
  usedAt?: string;
  // 투어 시작일
  beginAt?: string;

  // 예약 시작일이 지났는지 여부
  tripStarted?: boolean;

  // 입장 확인 가능 여부
  allowCheckin?: boolean;
  // 입장 확인 날짜
  receivedAt?: string;

  // 2.0 티켓 - 유효일 / 지정일 구분
  ticketType: TicketType | null;
  used: boolean;

  gid: string;

  // 견적서 ID
  estimateProposalId: string;
  paymentUrl?: string | null;

  // T&A 상품 중 투어 상품 여부
  tourType: boolean;

  progressConfirm: boolean;
  // 실제 예약상태(BE에서 실제 상태와 다르게 status를 내려주는 경우 존재)
  originStatus: string;
  // 예약상세 바우처 목록정보
  vouchers: ReservationCardVoucher[];
}

export interface Reservation {
  reservationNo: string;
  status: string;
  statusTitle: string;
  // 로열티 포인트
  point: number;
  traveler: TravelerRequireInfo;
  vertical: SystemProvider;
  salePrice: number;
  pgAmount: number;

  subTitle: string;
  productName: string;
  optionNames: string;
  tripStartDate: string;
  tripStartTime: string;
  tripEndDate: string;
  tripEndTime: string;
  totalSalePrice: number;
  tripPeriod: ReservationTripPeriodType;
  options: ReservationItemOptionType[];
  cityName: string;
  canceledCouponDiscountAmount: number;

  /** 취소 요청 가능 여부 */
  requestCancelable: boolean;
  /** 취소 요청이 가능한 경우, 즉시 취소 여부 */
  autoCancelable: boolean;
}

interface ReservationGroupCardAttr {
  title: string;
  subtitle?: string;
  orderNo: string;
  productId?: string;
  address?: string;
  autoCancelable: boolean;

  reservations: Reservation[];

  // 비행시간 정보
  depDate?: string;
  depTm?: string;
  arrDate?: string;
  arrTm?: string;
  depCode?: string; // 출발지 도시 코드
  arrCode?: string; // 도착지 도시 코드
  depAirportCode?: string; // 출발지 공항 코드
  arrAirportCode?: string; // 도착지 공항 코드
  duringTime?: string;
  flight?: string;
  flightName?: string;
  flightNumber?: string;
  flightImage?: string;
  landingUrl?: string;
  cancelUrl?: string;

  // 공동운항편일 경우 공동운항사 정보
  codeshareYn?: string;
  codeshareAirCd?: string;
  codeshareAirNm?: string;
  codeshareCdImg?: string;

  // 숙소 입퇴실 정보
  checkInDate?: string;
  checkInTime?: string;
  checkOutDate?: string;
  checkOutTime?: string;

  // 후기 관련 정보
  review?: ReservationReview;

  // 사용일 입력 티켓 액션 배너 정보
  banner?: ReservationCardBanner;
  imageUrl?: string;
  landing?: string;
  offer?: ReservationHistoryOfferInfo;
  businessContact?: string;

  // 3.0 상품 여부
  isMrt3?: boolean;

  // 티켓 만료일
  expiredAt?: string;
  // 티켓 사용일
  usedAt?: string;
  // 투어 시작일
  beginAt?: string;

  // 예약 시작일이 지났는지 여부
  tripStarted?: boolean;

  // 입장 확인 가능 여부
  allowCheckin?: boolean;
  // 입장 확인 날짜
  receivedAt?: string;

  // 2.0 티켓 - 유효일 / 지정일 구분
  ticketType: TicketType | null;
  used: boolean;

  gid: string;

  // 견적서 ID
  estimateProposalId: string;
  paymentUrl?: string | null;

  // T&A 상품 중 투어 상품 여부
  tourType: boolean;

  progressConfirm: boolean;
  // 실제 예약상태(BE에서 실제 상태와 다르게 status를 내려주는 경우 존재)
  originStatus: string;
  // 예약상세 바우처 목록정보
  vouchers: ReservationCardVoucher[];
}

export interface EstimateCardAttr {
  title: string;
  subtitle?: string;
  reservationId?: number;
  reservationNo?: string;
  status: EstimateStatus;
  statusTitle: string;
  offer?: ReservationHistoryOfferInfo;
  /**  티켓 만료일 */
  expiredAt?: string;
  /**  투어 시작일 */
  beginAt?: string;
  /**  견적서 ID */
  estimateProposalId: string;
  estimateType: EstimateProposalType;
  gid: number;
  /** T&A 상품 중 투어 상품 여부 */
  tourType: boolean;
  changeContent?: string; // EXP견적서 변경내용
  /** 견적서 attributes엔 미존재하는 항공 필드들이나, ProductPreview.tsx 설정 공유위해 넣어둠 */
  landingUrl?: string;
  flightImage?: string;
}

export interface ReservationCardInfo {
  title: string;
  subTitle: string;
  cancelUser: string;
  reviewCreatable: boolean;
}

export interface CrossSellOfferCard {
  id: string;
  title: string;
  city_name: string;
  category_info: {
    code: string;
    name: string;
  };
  main_price: {
    krw_amount: string;
  };
  original_price: {
    krw_amount: string;
  };
  photos: CrossSellOfferPhoto[] | null;
  reviews_count: number;
}

export interface CrossSellData {
  recommendation: {
    title: string;
    subtitle: string;
    link_title: string;
    link: string;
    deeplink: string;
    offers?: CrossSellOfferCard[];
  };
}

export interface ReservationCrossSellAPIResponse {
  data: CrossSellData;
  result: {
    status: string;
    message: string;
  };
}

interface CrossSellOfferPhoto {
  description: string | null;
  offer_id: number;
  position: number;
  product_url: string;
  url_style_pattern: string;
  urls: {
    original: string;
    large: string;
    medium: string;
    small: string;
    thumb: string;
    tiny: string;
    regular_medium: string;
  };
}

export interface ReservationHistoryAPIParams extends Exclude<QueryParams, 'systemProvider'> {
  vertical: SystemProvider | 'ALL' | undefined;
}

interface ReservationHistoryRecommendation {
  title: string;
  subtitle: string;
  link_title: string;
  landing: string;
  offers: {
    title: string;
    url: string;
  }[];
}

interface ReservationHistoryResponseData {
  header?: {
    cityKeyName?: string;
    counts: ReservationCategory[];
  } | null;
  body: ReservationHistoryBody;
  footer?: {
    recommendation: ReservationHistoryRecommendation;
  };
}

export interface ReservationHistoryResponse {
  data: ReservationHistoryResponseData;
  result: {
    status: string;
    message: string;
  };
}

export interface ChatRoomAPIResponse {
  data: {
    room_id: string;
  };
  result: {
    status: string;
    message: string;
  };
}

export interface UseTicketAPIResponse {
  data: null;
  result: {
    status: string;
    message: string;
  };
}

// TODO : api 타입으로 교체 후 제거
export interface ResponseType {
  data: any;
  result: {
    status: number;
    message: string;
  };
}

export interface CheckInOrderOption {
  id: number;
  title: string;
  quantity: number;
}

export interface CheckInProductInfoData {
  id: number;
  orders: CheckInOrderOption[];
  offer: {
    title: string;
    main_photo_url: string;
  };
  total_price: {
    present_unit: string;
  };
}

export interface CheckInProductInfoResponse extends ResponseType {
  data: CheckInProductInfoData;
}

export interface CheckInDoneResponse extends ResponseType {
  data: {
    received_at: string;
  };
}

export interface CheckInBody {
  checkin_auth_code: string;
}
export interface LinkInfo {
  webLink: string;
  deepLink: string;
}

export interface CouponInfo {
  couponTemplateId: number;
}

export interface CardContent {
  cardId: number;
  order: number;
  tag: string;
  cardTitle: string;
  description: string;
  cardButtonTitle: string;
  cardButtonType: string;
  linkInfo: LinkInfo;
  couponInfo: CouponInfo;
}
export interface CheckList {
  checkListId: number;
  checkListTitle: string;
  cardContents: CardContent[];
  segmentInfo: SegmentInfo;
}

export interface SegmentInfo {
  exptTravelCityNm: string;
  exptTravelEndKstDate: string;
  exptTravelStartKstDate: string;
  userSegment: string;
  verticalType: string;
}
