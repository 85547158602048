/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * value 가 Promise 인지 확인합니다.
 *
 * @returns value가 Promise이면 true이고 그렇지 않으면 false입니다.
 *
 * @param value 확인할 값
 */
function isPromise(value: any): value is Promise<any> {
  return value && typeof value.then === 'function';
}

export { isPromise };
