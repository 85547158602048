import { mrtAxiosV3, MrtAxiosV3ErrorOptions } from 'mrt-libs';
import { AfterFuncPayload, Mrt2Offer } from 'mrt-types';

import { ResponseType } from '../types';

/* 2.0 상품 가주문서 생성 */
export interface OfferPreOrderFormResponseType extends ResponseType {
  readonly data: Mrt2Offer.PreOrderResponseData;
}

export const createOfferPreOrderForm = (options: MrtAxiosV3ErrorOptions) => () => {
  const { payload } = options.api || {};

  return mrtAxiosV3(options).post<OfferPreOrderFormResponseType>(
    `/order/v2/traveler/orders/order-form-tna`,
    payload,
  );
};

export const afterCreateOfferPreOrderForm = (
  afterFuncPayload: AfterFuncPayload<OfferPreOrderFormResponseType>,
) => {
  const { data, isWebView } = afterFuncPayload;

  const webViewPathPrefix = isWebView ? '/instants' : '';
  const orderFormId = data.data.orderFormId;

  window.location.href = `${webViewPathPrefix}/order/${orderFormId}`;
};
