import ContentLoader from 'react-content-loader';

const ProfilePopupSkeleton = (props) => (
  <ContentLoader
    speed={2}
    viewBox="0 0 230 370"
    backgroundColor="#f1f3f3"
    foregroundColor="#fafafa"
    {...props}
  >
    <rect x="107" y="28" rx="1" ry="1" width="100" height="20" />
    <circle cx="56" cy="56" r="36" />
    <rect x="107" y="59" rx="1" ry="1" width="100" height="20" />
    <rect x="22" y="110" rx="1" ry="1" width="183" height="40" />
    <rect x="22" y="174" rx="1" ry="1" width="183" height="40" />
    <rect x="22" y="238" rx="1" ry="1" width="183" height="40" />
    <rect x="22" y="302" rx="1" ry="1" width="183" height="40" />
  </ContentLoader>
);

export default ProfilePopupSkeleton;
