const reservation = {
  reserve: 'Book Now',
  ticket_reserve: 'Buy Now',
  order: {
    title: 'Payment',
    expired: 'Expired at',
    totalPrice: 'Total price',
    checkin: 'Check in',
    checkout: 'Check out',
    date: 'Travle date',
    useDate: 'Usage date',
    warning: {
      delivery:
        'Please check the selected date again! Refunds may not be available if you enter them incorrectly.',
      pension:
        'Please be sure to check the accommodation policy as additional people, adding barbecue facilities, etc. are not included in the payment amount and may require on-site payment.',
    },
    tourTitle: 'Price per person',
  },
};

export default reservation;
