import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';

import styled from '@emotion/styled';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';
import { helper } from '@myrealtrip/frontend-weblog';

import RedDot from '../GlobalNavItems/RedDot';

const { sendClickLog } = helper;

type Props = {
  label: string;
  link?: string;
  iconName?: string;
  onClick?: () => void;
  alignCenter?: boolean;
  redDot?: boolean;
};

const baseClickLogData = {
  eventName: 'topmenu',
  screenName: WeblogScreenName.COMMON,
  pageCategory: WeblogPageCategory.COMMON,
  itemKind: 'menu',
};

export default function SideBarLink({
  label,
  iconName,
  link,
  alignCenter,
  onClick,
  redDot,
}: Props) {
  const handleClick = () => {
    if (link) {
      sendClickLog({
        ...baseClickLogData,
        itemName: label,
        targetUrl: link,
      });
    }

    onClick?.();
  };

  return (
    <Container href={link} onClick={handleClick} alignCenter={alignCenter}>
      {iconName && <LinkIcon name={iconName} />}
      <LinkLabel>
        <LabelText>{label}</LabelText>
        {redDot && <RedDot />}
      </LinkLabel>
    </Container>
  );
}

const Container = styled.a<{ alignCenter?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  color: ${globalVariables.gray_700};

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  justify-content: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-start')};
`;

const LinkIcon = styled(Icon)`
  margin-right: 16px;
`;

const LinkLabel = styled.div`
  position: relative;
  width: auto;
`;

const LabelText = styled.span`
  padding-right: 10px;
`;
