import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';

import { userType, cityType, childrenType } from 'app/types';
import createUseConsumer from './createUseConsumer';

const context = createContext();
const { Provider, Consumer: MainConsumer } = context;

class MainProvider extends Component {
  static propTypes = {
    transparent: PropTypes.bool,
    user: userType,
    popularCities: PropTypes.arrayOf(cityType),
    children: childrenType.isRequired,
  };

  static defaultProps = {
    transparent: false,
    user: {},
    popularCities: [
      {
        location_name: '오사카',
        city_key_name: 'Osaka',
        country_key_name: 'Japan',
      },
      {
        location_name: '파리',
        city_key_name: 'Paris',
        country_key_name: 'France',
      },
      {
        location_name: '괌',
        city_key_name: 'Guam',
        country_key_name: 'Guam',
      },
    ],
  };

  constructor(props) {
    super(props);
    const { user } = props;

    this.state = {
      ...this.props,
      isLogin: user && user.id,
      notification: {
        isLast: true,
        items: [],
      },
    };
    this.actions = {
      setValue: (data) => {
        this.setState({
          ...data,
        });
      },
      getNotifications: (flagId) => this.getNotifications(flagId),
    };
  }

  componentDidMount = () => {
    this.initNotification();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.notification.items.length !== this.state.notification.items.length) {
      this.notification.initClickEvent();
    }
  };

  getNotifications = (flagId) => {
    const { notification } = this.state;
    const params = flagId ? `?flag_id=${flagId}&which=before` : '';
    if (window.$) {
      window.$.ajax({
        url: `/notifications${params}`,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          if (data) {
            this.setState({
              notification: {
                isLast: data.last_page,
                items: [...notification.items, ...data.notifications],
              },
            });
          }
        },
        error: (e) => {
          console.log(e.message, e);
          if (window.Raven) {
            window.console.log(e);
          }
        },
      });
    }
  };

  initNotification() {
    const { isLogin } = this.state;

    if (isLogin && process.env.NODE_ENV === 'production' && process.env.FLIGHT_MODE !== 'YES') {
      if (window.NotificationManager) {
        this.notification = new window.NotificationManager();
      }
      this.getNotifications();
    }
  }

  render() {
    const { state, actions } = this;
    const { children } = this.props;

    const value = { state, actions };

    return <Provider value={value}>{children}</Provider>;
  }
}

const useMainHOC = createUseConsumer(MainConsumer);

export { MainProvider, MainConsumer, useMainHOC };
