import React, { Component } from 'react';
import ReactSwiper from 'react-id-swiper';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { leftMdGray700Icon, rightMdGray700Icon } from '../../images/arrow';
import { childrenType, swiperType } from '../../types';

import styles from './Carousel.module.scss';

class Carousel extends Component {
  constructor(props) {
    super(props);

    this.swiperRef = React.createRef();
    this.keyName = props.keyName || 'Carousel';
  }

  componentDidMount = () => {
    const { current } = this.swiperRef;
    if (current) {
      this.swiper = current.swiper;
      window[`${this.keyName}_swiper`] = this.swiper;
    }
  };

  renderNavButton = (type) => {
    const { prevButtonStyle, nextButtonStyle } = this.props;
    const buttonClass = classNames(this.keyName, styles.button, styles[type]);
    const buttonStyle = type === 'prev' ? prevButtonStyle : nextButtonStyle;
    const leftArrowIcon = leftMdGray700Icon;
    const rightArrowIcon = rightMdGray700Icon;
    const arrow = type === 'prev' ? leftArrowIcon : rightArrowIcon;

    return (
      <button type="button" className={buttonClass} style={buttonStyle}>
        <img src={arrow} alt={type} />
      </button>
    );
  };

  renderCustomNavButton = (button) => {
    const buttonClass = classNames(this.keyName, button.props.className);

    return React.cloneElement(button, { className: buttonClass });
  };

  renderPrevButton = () => {
    const { prevButton } = this.props;

    if (prevButton) {
      return this.renderCustomNavButton(prevButton);
    }

    return this.renderNavButton('prev');
  };

  renderNextButton = () => {
    const { nextButton } = this.props;

    if (nextButton) {
      return this.renderCustomNavButton(nextButton);
    }

    return this.renderNavButton('next');
  };

  update() {
    this.swiper.update();
  }

  goSlide(index) {
    this.swiper.slideTo(index);
  }

  render() {
    const {
      hideNavButton,
      options,
      prevButtonClass,
      nextButtonClass,
      children,
    } = this.props;
    const swiperOptions = {
      navigation: {
        nextEl: `.${this.keyName}.${nextButtonClass || styles.next}`,
        prevEl: `.${this.keyName}.${prevButtonClass || styles.prev}`,
        disabledClass: `${styles.disabled}`,
      },
      renderPrevButton: () => null,
      renderNextButton: () => null,
      ...options,
    };

    // children 컴포넌트에서 className prop을 넘겨줘야 제대로 동작함
    const slideWithClassName = React.Children.map(children, (slide) => {
      const slideClass = `swiper-slide ${slide.props.className || ''}`;

      return React.cloneElement(slide, { className: slideClass });
    });

    return (
      <div className={styles.container}>
        <ReactSwiper {...swiperOptions} ref={this.swiperRef}>
          {slideWithClassName}
        </ReactSwiper>
        {!hideNavButton && this.renderPrevButton()}
        {!hideNavButton && this.renderNextButton()}
      </div>
    );
  }
}

Carousel.propTypes = {
  children: childrenType.isRequired,
  /** Carousel unique name(한 화면에서 여러개의 carousel을 렌더할 떄 사용) */
  keyName: PropTypes.string,
  /** Swiper options */
  options: swiperType,
  /** Custom prev button */
  prevButton: PropTypes.element,
  /** Custom next button */
  nextButton: PropTypes.element,
  /** Custom prev classname */
  prevButtonClass: PropTypes.string,
  /** Custom next classname */
  nextButtonClass: PropTypes.string,
  /** defalut prev button customize(기본 prev 버튼의 스타일을 바꿀 때 사용) */
  prevButtonStyle: PropTypes.objectOf(PropTypes.string),
  /** defalut next button customize(기본 next 버튼의 스타일을 바꿀 때 사용) */
  nextButtonStyle: PropTypes.objectOf(PropTypes.string),
  /** hide nav button */
  hideNavButton: PropTypes.bool,
};

Carousel.defaultProps = {
  keyName: 'Carousel',
  prevButton: null,
  nextButton: null,
  prevButtonClass: null,
  nextButtonClass: null,
  prevButtonStyle: {},
  nextButtonStyle: {},
  options: {},
  hideNavButton: false,
};

export default Carousel;
