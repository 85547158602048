import { useState } from 'react';

import styled from '@emotion/styled';
import { helper } from '@myrealtrip/frontend-weblog';
import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import { WISH_URL } from 'mrt-constants';

import GlobalNavButtonItem from '@layout/app/components/GlobalNavItems/GlobalNavButtonItem';
import NotificationPopup from '@layout/app/components/NotificationPopup';
import ProfileCardButton from '@layout/app/components/ProfileCardButton';
import ProfilePopup from '@layout/app/components/ProfilePopup';
import useFetchHasFreshMessage from '@layout/app/hooks/useFetchHasFreshMessage';
import useFetchHasFreshNotification from '@layout/app/hooks/useFetchHasFreshNotification';
import i18n from '@layout/app/utils/i18n';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';

const { sendClickLog } = helper;

const baseLogData = {
  screenName: WeblogScreenName.COMMON,
  pageCategory: WeblogPageCategory.COMMON,
};

export default function LoggedInItems() {
  const { isFlightMode, isExposureNotification } = useLayoutHeaderContext();
  const [isNotificationPopupOpen, setIsNotificationPopupOpen] = useState(false);
  const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);

  const { data: freshNotificationData, isError: isFreshNotificationError } =
    useFetchHasFreshNotification(isFlightMode);
  const hasFreshNotification = freshNotificationData?.new_exists && !isFreshNotificationError;

  const { data: freshMessageData, isError: isFreshMessageError } =
    useFetchHasFreshMessage(isExposureNotification);
  const hasFreshMessage = freshMessageData?.new_messages && !isFreshMessageError;

  const handleNotificationOpen = () => {
    sendClickLog({
      ...baseLogData,
      eventName: 'topmenu',
      itemKind: 'notification',
      itemName: '알림',
    });

    setIsNotificationPopupOpen(true);
  };
  const handleNotificationClose = () => setIsNotificationPopupOpen(false);

  const handleProfilePopupOpen = () => {
    sendClickLog({
      ...baseLogData,
      eventName: 'topmenu',
      itemKind: 'menu',
      itemName: '프로필',
    });

    setIsProfilePopupOpen(true);
  };
  const handleProfilePopupClose = () => setIsProfilePopupOpen(false);

  return (
    <Container>
      <GlobalNavButtonItem type="link" label={i18n.t('header:wishlist')} pathname={WISH_URL} />
      <GlobalNavButtonItem
        type="link"
        label={i18n.t('header:reservation')}
        pathname="/reservations"
      />
      <GlobalNavButtonItem
        type="link"
        label={i18n.t('header:message')}
        pathname="/messenger/channels"
        redDot={hasFreshMessage}
      />
      {!isFlightMode && (
        <GlobalNavButtonItem
          type="button"
          label={i18n.t('header:notification')}
          onClick={handleNotificationOpen}
          redDot={hasFreshNotification}
        />
      )}
      <ProfileContainer>
        <ProfileCardButton onClick={handleProfilePopupOpen} />
      </ProfileContainer>

      {isNotificationPopupOpen && (
        <NotificationPopup isOpen={isNotificationPopupOpen} onClose={handleNotificationClose} />
      )}
      {isProfilePopupOpen && (
        <ProfilePopup isOpen={isProfilePopupOpen} onClose={handleProfilePopupClose} />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  height: 36px;
  gap: 0px 8px;
`;

const ProfileContainer = styled.div`
  margin-left: 12px;
`;
