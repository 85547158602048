export const CountryPhoneNumbers = {
  '+1': '+1 (캐나다  미국)',
  '+7': '+7 (카자흐스탄  러시아)',
  '+20': '+20 (이집트)',
  '+27': '+27 (남아프리카 공화국)',
  '+30': '+30 (그리스)',
  '+31': '+31 (네덜란드)',
  '+32': '+32 (벨기에)',
  '+33': '+33 (프랑스)',
  '+34': '+34 (스페인)',
  '+36': '+36 (헝가리)',
  '+39': '+39 (바티칸  이탈리아)',
  '+40': '+40 (루마니아)',
  '+41': '+41 (스위스)',
  '+43': '+43 (오스트리아)',
  '+44': '+44 (영국)',
  '+45': '+45 (덴마크)',
  '+46': '+46 (스웨덴)',
  '+47': '+47 (노르웨이)',
  '+48': '+48 (폴란드)',
  '+49': '+49 (독일)',
  '+51': '+51 (페루)',
  '+52': '+52 (멕시코)',
  '+53': '+53 (쿠바)',
  '+54': '+54 (아르헨티나)',
  '+55': '+55 (브라질)',
  '+56': '+56 (칠레)',
  '+57': '+57 (콜롬비아)',
  '+58': '+58 (베네수엘라)',
  '+60': '+60 (말레이시아)',
  '+61': '+61 (호주  크리스마스 섬  코코스킬링 제도)',
  '+62': '+62 (인도네시아)',
  '+63': '+63 (필리핀)',
  '+64': '+64 (뉴질랜드)',
  '+65': '+65 (싱가포르)',
  '+66': '+66 (태국)',
  '+81': '+81 (일본)',
  '+82': '+82 (대한민국)',
  '+84': '+84 (베트남)',
  '+86': '+86 (중국)',
  '+90': '+90 (터키)',
  '+91': '+91 (인도)',
  '+92': '+92 (파키스탄)',
  '+94': '+94 (스리랑카)',
  '+95': '+95 (미얀마)',
  '+98': '+98 (이란)',
  '+212': '+212 (모로코)',
  '+213': '+213 (알제리)',
  '+216': '+216 (튀니지)',
  '+218': '+218 (리비아)',
  '+220': '+220 (감비아)',
  '+221': '+221 (세네갈)',
  '+222': '+222 (모리타니)',
  '+223': '+223 (말리)',
  '+224': '+224 (기니)',
  '+225': '+225 (코트디부와르)',
  '+226': '+226 (부르키나파소)',
  '+227': '+227 (니제르)',
  '+228': '+228 (토고)',
  '+229': '+229 (베넹)',
  '+230': '+230 (모리셔스)',
  '+231': '+231 (리베리아)',
  '+232': '+232 (시에라리온)',
  '+233': '+233 (가나)',
  '+234': '+234 (나이지리아)',
  '+235': '+235 (차드)',
  '+236': '+236 (중앙아프리카공화국)',
  '+237': '+237 (카메룬)',
  '+238': '+238 (까뽀베르데)',
  '+239': '+239 (쌍투메 프린시페)',
  '+240': '+240 (적도 기니)',
  '+241': '+241 (가봉)',
  '+242': '+242 (콩고)',
  '+243': '+243 (콩고민주공화국)',
  '+244': '+244 (앙골라)',
  '+245': '+245 (기네비쏘)',
  '+248': '+248 (세이셸)',
  '+249': '+249 (수단)',
  '+250': '+250 (르완다)',
  '+251': '+251 (이디오피아)',
  '+252': '+252 (소말리아)',
  '+253': '+253 (지부티)',
  '+254': '+254 (케냐)',
  '+255': '+255 (탄자니아)',
  '+256': '+256 (우간다)',
  '+257': '+257 (브룬디)',
  '+258': '+258 (모잠비크)',
  '+260': '+260 (잠비아)',
  '+261': '+261 (마다가스카르)',
  '+263': '+263 (짐바브웨)',
  '+264': '+264 (나미비아)',
  '+265': '+265 (말라위)',
  '+266': '+266 (레소토)',
  '+267': '+267 (보츠와나)',
  '+268': '+268 (스와질랜드)',
  '+269': '+269 (코모르)',
  '+290': '+290 (세인트 헬레나)',
  '+297': '+297 (아루바)',
  '+298': '+298 (페로 군도)',
  '+299': '+299 (그린랜드)',
  '+350': '+350 (영국령 지브롤터)',
  '+351': '+351 (포르투갈)',
  '+352': '+352 (룩셈부르크)',
  '+353': '+353 (아일랜드)',
  '+354': '+354 (아이슬란드)',
  '+355': '+355 (알바니아)',
  '+356': '+356 (몰타)',
  '+357': '+357 (사이프러스)',
  '+358': '+358 (핀란드)',
  '+359': '+359 (불가리아)',
  '+370': '+370 (리투아니아)',
  '+371': '+371 (라트비아)',
  '+372': '+372 (에스토니아)',
  '+373': '+373 (몰도바)',
  '+374': '+374 (아르메니아)',
  '+375': '+375 (벨라루스)',
  '+376': '+376 (안도라)',
  '+377': '+377 (모나코)',
  '+378': '+378 (산마리노)',
  '+380': '+380 (우크라이나)',
  '+381': '+381 (세르비아)',
  '+382': '+382 (몬테네그로)',
  '+385': '+385 (크로아티아)',
  '+386': '+386 (슬로베니아)',
  '+387': '+387 (보스니아 헤르체고비나)',
  '+389': '+389 (마케도니아)',
  '+420': '+420 (체코)',
  '+421': '+421 (슬로바키아)',
  '+423': '+423 (리히텐슈타인)',
  '+500': '+500 (포클랜드)',
  '+501': '+501 (벨리즈)',
  '+502': '+502 (과테말라)',
  '+503': '+503 (엘살바도르)',
  '+504': '+504 (온두라스)',
  '+505': '+505 (니카라과)',
  '+506': '+506 (코스타리카)',
  '+507': '+507 (파나마)',
  '+508': '+508 (세인트 피에르 미퀠론)',
  '+509': '+509 (아이티)',
  '+591': '+591 (볼리비아)',
  '+592': '+592 (가이아나)',
  '+593': '+593 (에쿠아도르)',
  '+595': '+595 (파라과이)',
  '+597': '+597 (수리남)',
  '+598': '+598 (우루과이)',
  '+672': '+672 (남극)',
  '+673': '+673 (브루나이)',
  '+674': '+674 (나우루)',
  '+675': '+675 (파푸아뉴기니)',
  '+676': '+676 (통가)',
  '+677': '+677 (솔로몬 군도)',
  '+678': '+678 (바누아투)',
  '+679': '+679 (피지)',
  '+680': '+680 (팔라우)',
  '+681': '+681 (월리스 후트나)',
  '+682': '+682 (쿠크 군도)',
  '+683': '+683 (니우에)',
  '+685': '+685 (사모아)',
  '+686': '+686 (키리바시)',
  '+687': '+687 (뉴 칼레도니아)',
  '+688': '+688 (투발루)',
  '+689': '+689 (프랑스령 폴리네시아)',
  '+690': '+690 (토켈라우)',
  '+691': '+691 (마이크로네시아)',
  '+692': '+692 (마샬 군도)',
  '+850': '+850 (북한)',
  '+852': '+852 (홍콩)',
  '+853': '+853 (마카오)',
  '+855': '+855 (캄보디아)',
  '+856': '+856 (라오스)',
  '+870': '+870 (핏케언 군도)',
  '+880': '+880 (방글라데시)',
  '+886': '+886 (대만)',
  '+960': '+960 (몰디브)',
  '+961': '+961 (레바논)',
  '+962': '+962 (요르단)',
  '+963': '+963 (시리아)',
  '+964': '+964 (이라크)',
  '+965': '+965 (쿠웨이트)',
  '+966': '+966 (사우디아라비아)',
  '+967': '+967 (예멘)',
  '+968': '+968 (오만)',
  '+971': '+971 (아랍에미리트)',
  '+972': '+972 (이스라엘)',
  '+973': '+973 (바레인)',
  '+974': '+974 (카타르)',
  '+975': '+975 (부탄)',
  '+976': '+976 (몽골)',
  '+977': '+977 (네팔)',
  '+992': '+992 (타지키스탄)',
  '+993': '+993 (투르크메니스탄)',
  '+994': '+994 (아제르바이잔)',
  '+995': '+995 (조지아)',
  '+996': '+996 (키르기스스탄)',
  '+998': '+998 (우즈베키스탄)',
};
