const hotdeal = {
  leave: '어디로 떠나세요?',
  selectArea: '지역 선택',
  selectLocation: '방문할 도시 또는 나라를 선택하세요.',
  closePopup: '팝업 닫기',
  initialize: '초기화',
  complete: '완료',
  noTrips: '선택하신 조건으로\n예약할 수 있는 여행이 없습니다.',
  searchAgain: '필터를 초기화하고 다시 검색해주세요.',
  resetFilter: '필터 초기화하기',
  showOffer: '{{category}} 상품 보러 가기',
  lookingForOffer: '어떤 상품을 찾으세요?',
  date: '날짜',
  sort: {
    popular: '추천순',
    reviews: '후기순',
    price: '가격순',
    recent: '신상품순',
  },
};

export default hotdeal;
