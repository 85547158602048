import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import { childrenType } from '../../types';
import styles from './Grid.module.scss';

function Grid(props) {
  const { isFullWidth, customClass, children } = props;
  const containerClass = classNames(styles.container);
  const rowClass = classNames(styles.row, { [styles.full]: isFullWidth }, customClass);

  return (
    <div className={containerClass}>
      <div className={rowClass}>{children}</div>
    </div>
  );
}

Grid.propTypes = {
  isFullWidth: bool,
  customClass: string,
  children: childrenType,
};

Grid.defaultProps = {
  isFullWidth: false,
  customClass: '',
  children: null,
};

export default Grid;
