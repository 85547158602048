import React, { useMemo, useCallback } from 'react';

import { helper } from '@myrealtrip/frontend-weblog';
import PropTypes from 'prop-types';

import { hotelCardTypeEnum, webLogPageCategoryEnum, webLogScreenNameEnum } from '../../../enum';
import { hotelType, swiperType } from '../../../types';
import { generateUrlFromPathname } from '../../../utils/urlUtils';
import CardSwiper from './CardSwiper';
import CardSwiperWithGrid from './CardSwiperWithGrid';
import HotelVerticalCard from './HotelVerticalCard';

const { sendClickLog, sendImpressionLog } = helper;

function _getLogData() {
  const { pathname } = window.location;

  // 숙박 메인 페이지
  if (pathname.includes('accommodations')) {
    return {
      pageCategory: webLogPageCategoryEnum.hotelMain,
      screenName: webLogScreenNameEnum.hotelMain,
      sectionVerticalIndex: 1,
    };
  }
  // 도시 검색 결과 페이지
  if (pathname.includes('cities')) {
    return {
      pageCategory: webLogPageCategoryEnum.city,
      screenName: webLogScreenNameEnum.city,
      sectionVerticalIndex: 3,
    };
  }
  // 나라 페이지
  if (pathname.includes('countries')) {
    return {
      pageCategory: webLogPageCategoryEnum.country,
      screenName: webLogScreenNameEnum.country,
    };
  }
}

function HotelCardSwiper(props) {
  const { type, keyName, hotels, withGrid, swiperOptions, category, onClick, onImpression } = props;
  const Container = withGrid ? CardSwiperWithGrid : CardSwiper;

  const logData = useMemo(
    () => ({
      ..._getLogData(),
      itemKind: 'offer',
      itemCategory: '호텔',
      itemType: 'HotelMeta',
    }),
    [],
  );

  const handleImpression = useCallback(
    ({ hotel, horizontalOrder }) => {
      const { id, title, price, reviewCount, reviewScore, star } = hotel;

      sendImpressionLog(
        {
          ...logData,
          eventName: 'pop_hotel',
          sectionName: keyName,
          sectionTitle: '인기호텔',
          sectionHorizonIndex: horizontalOrder + 1,
          itemId: id,
          itemName: title,
          itemPrice: { main: Math.floor(price), origin: 0 },
          itemReviewCount: reviewCount,
          itemReviewScore: reviewScore?.toFixed(2),
          itemGrade: star,
        },
        `pop_hotel_${id}`,
      );
    },
    [logData, keyName],
  );
  const handleClick = useCallback(
    ({ hotel, horizontalOrder, link }) => {
      const { id, title, price, reviewCount, reviewScore, star } = hotel;

      sendClickLog({
        ...logData,
        eventName: 'pop_hotel',
        sectionName: keyName,
        sectionTitle: '인기호텔',
        sectionHorizonIndex: horizontalOrder + 1,
        itemId: id,
        itemName: title,
        itemPrice: { main: Math.floor(price), origin: 0 },
        itemReviewCount: reviewCount,
        itemReviewScore: reviewScore?.toFixed(2),
        itemGrade: star,
        targetUrl: generateUrlFromPathname(link),
      });
    },
    [logData, keyName],
  );

  /**
   * defaultProps로 핸들러 함수를 할당(()=>null)할 경우 핸들러의 유효성 검증이 안됨
   * defaultProps를 할당하지않고 props으로 내려오는 핸들러의 타입체크를 통해 prop이 있을 경우 그대로 내려보냄
   * prop이 없을 경우 커스텀 핸들러를 내려주도록함
   */
  const hasOnClickProp = typeof onClick === 'function';
  const hasOnImpressionProp = typeof onImpression === 'function';
  const logHandlerProps = {
    onClick: hasOnClickProp ? onClick : handleClick,
    onImpression: hasOnImpressionProp ? onImpression : handleImpression,
  };

  return (
    <Container type={type} keyName={keyName} options={swiperOptions}>
      {hotels.map((hotel, index) => (
        <HotelVerticalCard
          key={hotel.id}
          order={index}
          hotel={hotel}
          category={category}
          {...logHandlerProps}
        />
      ))}
    </Container>
  );
}

HotelCardSwiper.propTypes = {
  type: PropTypes.oneOf(Object.values(hotelCardTypeEnum)),
  keyName: PropTypes.string,
  hotels: PropTypes.arrayOf(hotelType).isRequired,
  swiperOptions: swiperType,
  withGrid: PropTypes.bool,
  category: PropTypes.string,
  onClick: PropTypes.func,
  onImpression: PropTypes.func,
};

HotelCardSwiper.defaultProps = {
  type: hotelCardTypeEnum.common,
  keyName: 'HotelSwiper',
  swiperOptions: {},
  withGrid: true,
  category: '',
};

export default React.memo(HotelCardSwiper);
