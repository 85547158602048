import React from 'react';

import { initComponent, renderReactComponent } from './share';

import DomesticReservationComplete from '../../components/flight/popup/DomesticReservationComplete';

function renderDomesticCompletePopup(isWebView, isJeju) {
  const isMobile = window.location.pathname.includes('/MBL');
  const parentId = isMobile ? 'k1_container' : 'k1_content';
  const parentEle = document.getElementById(parentId);
  const wrapper = initComponent('domestic-complete-popup');

  wrapper.setAttribute(
    'style',
    'position: absolute;left: 0;top: 0;width: 100%;z-index: 10;',
  );
  parentEle.setAttribute('style', 'position: relative');
  parentEle.appendChild(wrapper);

  /* 전면 팝업을 표시를 위해 기존의 예약 정보는 숨김 처리가 필요 */
  const hiddenContents = document.querySelectorAll('.k1_width_fixed');

  hiddenContents.forEach((content) =>
    content.setAttribute('style', 'visibility: hidden'),
  );
  /* 전면 팝업을 표시를 위해 기존의 예약 정보는 숨김 처리가 필요 */

  renderReactComponent(
    <DomesticReservationComplete
      isWebView={isWebView}
      isJeju={isJeju}
      containerId={parentId}
    />,
    wrapper,
  );
}

export default renderDomesticCompletePopup;
