import { atom } from 'recoil';

import { GlobalSearch } from 'mrt-types';

export const searchTerm = atom<string>({
  key: 'searchTerm',
  default: '',
});

export const searchBanner = atom<GlobalSearch.Banner | null>({
  key: 'searchBanner',
  default: null,
});
