import ContentLoader from 'react-content-loader';

const GnbDrawerSkeleton = (props) => (
  <ContentLoader
    speed={2}
    width={240}
    height={500}
    viewBox="0 0 240 500"
    backgroundColor="#f1f3f3"
    foregroundColor="#fafafa"
    {...props}
  >
    <rect x="104" y="30" rx="1" ry="1" width="100" height="25" />
    <circle cx="52" cy="56" r="36" />
    <rect x="104" y="65" rx="1" ry="1" width="70" height="15" />
    <rect x="16" y="136" rx="0" ry="0" width="160" height="24" />
    <rect x="16" y="180" rx="0" ry="0" width="160" height="24" />
    <rect x="16" y="224" rx="0" ry="0" width="160" height="24" />
    <rect x="16" y="268" rx="0" ry="0" width="160" height="24" />
    <rect x="16" y="312" rx="0" ry="0" width="160" height="24" />
    <rect x="16" y="356" rx="0" ry="0" width="160" height="24" />
    <rect x="16" y="400" rx="0" ry="0" width="160" height="24" />
    <rect x="16" y="444" rx="0" ry="0" width="160" height="24" />
  </ContentLoader>
);

export default GnbDrawerSkeleton;
