import styled from '@emotion/styled';
import { OfferTagNames, OfferTags } from 'mrt-constants';

type DisplayTag = {
  label: string;
  value: OfferTags;
};

const ALLOW_DISPLAY_TAGS: DisplayTag[] = [
  {
    label: OfferTagNames.MRT_ORIGIN,
    value: OfferTags.MRT_ORIGIN,
  },
  {
    label: OfferTagNames.PRICE_GUARANTEE,
    value: OfferTags.PRICE_GUARANTEE,
  },
  {
    label: OfferTagNames.IGNORE_WAIT_CONFIRM,
    value: OfferTags.IGNORE_WAIT_CONFIRM,
  },
  {
    label: OfferTagNames.FREE_REFUND,
    value: OfferTags.FREE_REFUND,
  },
];

interface Props {
  tags: OfferTags[];
}

function CardThumbnailTagSticker({ tags }: Props) {
  const filteredTags = tags.filter((tag) =>
    ALLOW_DISPLAY_TAGS.some((displayTag) => displayTag.value === tag),
  );
  const [targetTag] = filteredTags;
  const displayTag = ALLOW_DISPLAY_TAGS.find((tag) => tag.value === targetTag);

  if (!displayTag) {
    return null;
  }

  return <Container tag={displayTag.value}>{displayTag.label}</Container>;
}

export default CardThumbnailTagSticker;

const Container = styled.span<{ tag: OfferTags }>`
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: inline-flex;
  height: 22px;
  align-items: center;
  padding: 0 5px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;

  @media screen and (max-width: 767px) {
    right: 0;
    bottom: 0;
  }

  ${({ tag }) => {
    const css = {
      [OfferTags.FREE_REFUND]: {
        backgroundColor: `var(--blue-80)`,
        color: 'var(--blue-600)',
      },
      [OfferTags.PRICE_GUARANTEE]: {
        backgroundColor: 'var(--yellow-80)',
        color: 'var(--yellow-700)',
      },
      [OfferTags.MRT_ORIGIN]: {
        backgroundColor: '#d5fcff',
        color: '#1f9bb7',
      },
      [OfferTags.IGNORE_WAIT_CONFIRM]: {
        backgroundColor: '#e6d7fe',
        color: '#8238fa',
      },
    };

    return css[tag] ?? null;
  }}
`;
