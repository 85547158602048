import { useState, useEffect } from 'react';
import axios from 'axios';

function useRequest(url) {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchApi() {
      try {
        const response = await axios.get(url);

        setData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    if (url) {
      fetchApi();
    }
  }, [url]);

  return { isLoading, data };
}

export default useRequest;
