const friends = {
  title: 'If you invite your friend, you will receive unlimited points!',
  subtitle: 'Give your friend a 5,000 won coupon and get 2,000 points.',
  inviteUnit: '{{count}}Person',
  howTo: {
    title: 'How to participate',
    point: {
      title: 'What if a friend joins through a shared link?',
      description: {
        prefix: 'Ready-to-use as cash',
        suffix: '2,000 points straight up!',
      },
      caution: {
        prefix: '*After an invited friend joined',
        suffix: 'Friend needs to verify oneself',
      },
    },
    sns: {
      title: 'Share events with friends on your social network',
      copied: 'The address has been copied.',
      alert: 'You need to authenticate your phone number to invite your friend and get points.',
      facebook: 'Share via Facebook',
      kakao: 'Share via KakaoTalk',
      link: 'Share link',
    },
  },
  rank: {
    title: "Who's my best friend on MyRealTrip this month?",
    subtitle: 'Introduce the best 5 people who invite the most friends',
    rank: 'Rank',
    email: 'Email Address',
    inviteCount: 'Number of invites',
    point: 'Point',
  },
  status: {
    title: 'My Friend Invitation status',
    invitedFriends: 'Invited friends',
    invitedFriendsUnit: 'Person',
    totalPoint: 'Total point',
  },
  banner: {
    title: {
      prefix: 'Refer a friend ',
      strong: 'unlimited points',
      suffix: 'to receive ',
    },
    description: {
      prefix: 'Send 5,000won coupon to your friend and receive 2,000 points',
      suffix: 'for every friend who signs up!',
    },
    receivePoint: 'Receive Points',
  },
  notice: {
    title: 'Please! read',
    items: [
      '1. You must sign in through the Friend Invitation URL. You cannot invite members who have already joined.',
      '2. Invitations will be accepted as complete only when the invited member has completed his or her authentication',
      "3. The total earned points are the accumulated points earned through 'Friend Invitation'",
      '4. The points set aside can be used as cash on the purchase of Tours, Tickets, Lodging, and Package within the MyRealTrip service, overlapping with coupons. (Real-time air tickets and partnership discount on hotel do not apply.)',
      '5. The period of validity of the accumulated points is valid for one year from the date of issuance, and will not be reissued if not used within the validity period. (Applicable from 20/08/20)',
    ],
    policy: 'Friend Invitation Terms and Conditions',
    confirm: 'Confirm',
  },
};

export default friends;
