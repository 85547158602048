import { ResponseType } from 'mrt-apis/types';
import { mrtAxiosV2 } from 'mrt-libs';

export interface SignoutResponseType extends ResponseType {
  readonly data: Record<string, never>;
}

export const fetchSignout = () => {
  return mrtAxiosV2({ isWebView: false }).post<SignoutResponseType>(`/member/v1/sign-out`);
};
