export function createOfferLink(offer, isWebView) {
  const { id, productUrls } = offer ?? {};

  // 3.0의 경우 prefix + number 조합으로 NaN이 return 됨.
  const isOldOffer = parseInt(id, 10);

  const { WEB = '', APP = '' } = productUrls ?? {};

  if (isWebView) {
    if (APP) {
      return APP;
    }

    // FIXME: 2.0 offer 캐시로인해 productUrls가 없는 경우를 위한 임시 대응 코드
    // 기존과 동일하게 URL을 반환하도록 처리한다.
    // 추후 모든 기존 캐시가 갱신된 후 제거 가능
    return isOldOffer ? `mrt://offers/${id}` : `mrt://product/detail/${id}`;
  }

  if (WEB) {
    return WEB;
  }

  // FIXME: 2.0 offer 캐시로인해 productUrls가 없는 경우를 위한 임시 대응 코드
  // 기존과 동일하게 URL을 반환하도록 처리한다.
  // 추후 모든 기존 캐시가 갱신된 후 제거 가능
  return isOldOffer
    ? `${process.env.HOME_URL}/offers/${id}`
    : `${process.env.HOME_URL}/products/${id}`;
}
