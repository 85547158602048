import { memo } from 'react';

import {
  LayoutHeaderContextProvider,
  LayoutHeaderContextType,
} from '@layout/modules/LayoutHeaderContext';
import LayoutProvider from '@layout/providers/LayoutProvider';

import Header from '../app/pages/Header';

const MrtHeader = (props: LayoutHeaderContextType) => {
  return (
    <LayoutProvider>
      <LayoutHeaderContextProvider initialState={props}>
        <Header />
      </LayoutHeaderContextProvider>
    </LayoutProvider>
  );
};

export default memo(MrtHeader);
