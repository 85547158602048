import { useState } from 'react';

import styled from '@emotion/styled';
import { LangOption, LANG_OPTIONS } from '@layout/constants/languageSelect';
import { resetButton } from '@layout/styles/reset';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import Popup from '@myrealtrip/design-system/dist/components/Popup';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

type Props = {
  currentLang: LangOption;
  onChange: (draftValue: LangOption) => void;
};

export default function DesktopSelect({ currentLang, onChange }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenPopup = () => setIsOpen(!isOpen);
  const handleClosePopup = () => setIsOpen(false);
  const handleClickSelect = (draftValue: LangOption) => () => {
    onChange(draftValue);

    setIsOpen(false);
  };

  return (
    <Container>
      <PopupButton type="button" onClick={handleOpenPopup}>
        <SelectLabelIcon name="ic-md-globe" />
        <SelectLabel>{currentLang.label}</SelectLabel>
        <SelectArrowIcon name="ic_arrow_down_12x12_gray_300" />
      </PopupButton>
      <SelectPopup visible={isOpen} onOutsideClick={handleClosePopup}>
        <PopupContent>
          {LANG_OPTIONS.map((option) => (
            <SelectButton
              type="button"
              key={option.value}
              selected={currentLang.value === option.value}
              onClick={handleClickSelect(option)}
            >
              {option.label}
              {currentLang.value === option.value && (
                <SelectedCheckIcon name="ic_check_md_blue_500" />
              )}
            </SelectButton>
          ))}
        </PopupContent>
      </SelectPopup>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const PopupButton = styled.button`
  ${resetButton};

  position: relative;
  display: flex;
  align-items: center;
  width: 118px;
  height: 40px;
  padding: 0 10px;
  background-color: ${globalVariables.white};
  border: 1px solid ${globalVariables.gray_300};
  border-radius: 4px;
`;

const SelectLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${globalVariables.gray_500};
`;
const SelectLabelIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;
const SelectArrowIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  margin-left: auto;
`;

const SelectPopup = styled(Popup)`
  left: -40px;
  top: -90px;
`;

const PopupContent = styled.div`
  width: 160px;
  padding: 6px 0;
`;

const SelectButton = styled.button<{ selected?: boolean }>`
  ${resetButton};

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 34px;
  padding: 0 16px;
  font-size: 15px;
  font-weight: 500;
  color: ${({ selected }) =>
    selected ? globalVariables.blue_500 : globalVariables.gray_800};
  background-color: ${globalVariables.white};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${globalVariables.gray_80};
  }
`;

const SelectedCheckIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;
