import { mrtAxiosV3 } from 'mrt-libs';
import { Api } from 'mrt-types';

import { ResponseType } from '../types';

export interface StorageInRedisRequestBody {
  data: Api;
}

export interface StorageRedisResponseType extends ResponseType {
  readonly data: {
    key: string;
    value: Api;
  };
}

export interface GetRedisByKeyResponseType extends ResponseType {
  readonly data: Api;
}

export const storageRedis = async (body: StorageInRedisRequestBody) => {
  const { data } = await mrtAxiosV3().post<StorageRedisResponseType>(
    '/v1/storage/order/create-order-form',
    body,
  );
  return data;
};

export const getRedisByKey = async (key: string) => {
  const { data } = await mrtAxiosV3().get<GetRedisByKeyResponseType>(
    `/v1/storage/order/create-order-form/${key}`,
  );
  return data;
};

export const deleteRedisByKey = async (key: string) => {
  const { data } = await mrtAxiosV3().delete<GetRedisByKeyResponseType>(
    `/v1/storage/order/create-order-form/${key}`,
  );
  return data;
};
