import React, { useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';

import DomesticReservation from './domestic/DomesticReservation';
import CrossSellJejuRentCar from './jeju/CrossSellJejuSectionRentCar';
import CrossSellJejuHotels from './jeju/CrossSellJejuSectionHotels';
import CrossSellJejuMagazines from './jeju/CrossSellJejuSectionMagazines';
import CrossSellJejuLodgings from './jeju/CrossSellJejuSectionLodgings';
import CrossSellJejuThemes from './jeju/CrossSellJujuSectionThemes';

import { handleApperEvent } from '../utils/jejuSendEvent';
import useResponsive from '../../../hooks/useResponsive';

import styles from './DomesticReservationComplete.module.scss';

let count = 0;
const utm =
  'utm_source=cross_popup&utm_medium=flight_deadend_popup&utm_campaign=flight_deadend_popup';

function DomesticReservationComplete(props) {
  const { isWebView, isJeju, containerId } = props;
  const { isMobile } = useResponsive();
  const container = useRef();

  useEffect(() => {
    handleApperEvent(isWebView);
    function resizeHandler() {
      const height = container.current.clientHeight;
      const parent = document.getElementById(containerId);

      if (height !== parent.clientHeight) {
        count = 0;
        parent.style.height = `${height}px`;
      } else {
        count += 1;
      }
      if (count < 10) {
        setTimeout(resizeHandler, 100);
      }
    }

    if (container.current) {
      resizeHandler();
    }
  }, [containerId, isWebView]);

  return (
    <div className={styles.container} ref={container}>
      <DomesticReservation isMobile={isMobile} isWebView={isWebView} />
      {isJeju && (
        <Fragment>
          <CrossSellJejuRentCar isMobile={isMobile} isWebView={isWebView} />
          <CrossSellJejuHotels isMobile={isMobile} isWebView={isWebView} />
          <CrossSellJejuMagazines
            isMobile={isMobile}
            isWebView={isWebView}
            utm={utm}
          />
          <CrossSellJejuLodgings
            isMobile={isMobile}
            isWebView={isWebView}
            utm={utm}
          />
          <CrossSellJejuThemes
            isMobile={isMobile}
            isWebView={isWebView}
            utm={utm}
          />
        </Fragment>
      )}
    </div>
  );
}

DomesticReservationComplete.propTypes = {
  isWebView: PropTypes.bool,
  isJeju: PropTypes.bool,
  containerId: PropTypes.string.isRequired,
};

DomesticReservationComplete.defaultProps = {
  isWebView: false,
  isJeju: false,
};

export default DomesticReservationComplete;
