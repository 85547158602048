import styled from '@emotion/styled';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';
import { resetButton } from '@layout/styles/reset';
import Icon from '@myrealtrip/design-system/dist/components/Icon';

type Props = {
  onClick: () => void;
};

export default function SideBarButton({ onClick }: Props) {
  const { theme } = useLayoutHeaderContext();
  const isTransparent = theme !== 'white';
  const iconName = isTransparent
    ? 'ic_hamburger_md_White'
    : 'ic_hamburger_md_gray_600';

  return (
    <Container type="button" onClick={onClick}>
      <HamburgerIcon name={iconName} />
    </Container>
  );
}

const Container = styled.button`
  ${resetButton};

  padding: 12px 16px;
`;
const HamburgerIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`;
