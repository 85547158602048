import { Children } from 'react';

import i18n from 'mrt-components/utils/i18n';

import styled from '@emotion/styled';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import useSearchManager from '../useSearchManager';
import RecentKeywordItem from './RecentKeywordItem';

interface Props {
  handleRef?: (idx: number, node: HTMLAnchorElement) => void;
  selectedIdx?: number;
}

function RecentKeywords({ handleRef, selectedIdx }: Props) {
  const { recentKeywords, removeRecentKeywords } = useSearchManager();

  const handleRecentKeywordsClear = () => {
    removeRecentKeywords();
  };

  if (recentKeywords.length === 0) {
    return null;
  }

  return (
    <Container>
      <Header>
        <HeaderTitle>{i18n.t('search:recentKeyword')}</HeaderTitle>
        <RecentKeywordAllClearButton type="button" onClick={handleRecentKeywordsClear}>
          {i18n.t('search:removeAll')}
        </RecentKeywordAllClearButton>
      </Header>
      <KeywordContainer>
        {Children.toArray(
          recentKeywords.map((keyword, idx) => (
            <RecentKeywordItem
              keyword={keyword}
              ref={(node: HTMLAnchorElement) => handleRef?.(idx, node)}
              isSelected={selectedIdx === idx}
            />
          )),
        )}
      </KeywordContainer>
    </Container>
  );
}

export default RecentKeywords;

const Container = styled.div`
  padding: 20px 0 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;
`;

const HeaderTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${globalVariables.gray_800}; // gray-800
`;

const RecentKeywordAllClearButton = styled.button`
  /* reset button style */
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  font-size: 13px;
  color: ${globalVariables.gray_500}; // gray-500
`;

const KeywordContainer = styled.div`
  margin-top: 8px;
`;
