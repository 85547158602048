import { fetchFreshMessage } from '@layout/app/api/message';
import { useQuery } from '@tanstack/react-query';

export default function useFetchHasFreshMessage(enabled: boolean) {
  const queryResult = useQuery(['fetchFreshMessage'], () => fetchFreshMessage(), {
    refetchInterval: 10000,
    refetchOnWindowFocus: false,
    enabled: typeof enabled === 'boolean' ? enabled : true,
  });

  return {
    ...queryResult,
    data: queryResult.data?.data,
  };
}
