/* eslint-disable @typescript-eslint/naming-convention */

import styled from '@emotion/styled';
import { COUPON_URL, POINT_URL } from 'mrt-constants';
import { toCurrency } from 'mrt-utils';

import ProfileCardButton from '@layout/app/components/ProfileCardButton';
import ProfilePopupSkeleton from '@layout/app/components/ProfilePopup/ProfilePopupSkeleton';
import useFetchAccount from '@layout/app/hooks/useFetchAccount';
import useSignOut from '@layout/app/hooks/useSignOut';
import { getTravelerLink } from '@layout/app/utils/getTravelerLink';
import i18n from '@layout/app/utils/i18n';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';
import useClickOutside from '@myrealtrip/design-system/dist/hooks/useClickOutside';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import ProfilePopupLink from './ProfilePopupLink';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function ProfilePopup({ isOpen, onClose }: Props) {
  const { isFlightMode } = useLayoutHeaderContext();
  const { handleSignOut } = useSignOut();
  const { ref } = useClickOutside(isOpen, [], onClose);
  const { isFetching, isError, data } = useFetchAccount({ isFlightMode });

  const { number_of_coupons, point } = data ?? {};

  const pointAmount = isFlightMode ? '' : `${toCurrency(String(point))}원`;
  const couponAmount = isFlightMode ? '' : `${toCurrency(String(number_of_coupons))}장`;

  if (!isOpen || isError) {
    return null;
  }

  return (
    <Container isOpen={isOpen} ref={ref}>
      <ContainerArrow />
      {isFetching && <ProfilePopupSkeleton />}
      {!isFetching && (
        <>
          <InfoSection isWide>
            <ProfileCardButton showDetail />
          </InfoSection>
          <InfoSection>
            <ProfilePopupLink
              label={i18n.t('header:point')}
              link={POINT_URL}
              desc={pointAmount}
              iconName="logo_mrt_v2_web_point"
            />
            <ProfilePopupLink
              label={i18n.t('header:coupon')}
              link={COUPON_URL}
              desc={couponAmount}
              iconName="ico_coupon"
            />
          </InfoSection>
          <InfoSection>
            <ProfilePopupLink
              label={i18n.t('header:myMemberGrade')}
              iconName="ico_loyalty"
              link={getTravelerLink('/member/grade-benefit')}
            />
          </InfoSection>
          <InfoSection>
            <ProfilePopupLink label={i18n.t('header:signOut')} onClick={handleSignOut} />
          </InfoSection>
        </>
      )}
    </Container>
  );
}

const Container = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 50px;
  right: 0;
  z-index: ${globalVariables.z_popup};

  width: 230px;
  border: 1px solid ${globalVariables.gray_100};
  background-color: ${globalVariables.white};
  box-shadow: 0 1px 1px 0 rgb(0, 0, 0, 0.2);
  border-radius: 1px;
`;

const ContainerArrow = styled.span`
  display: block;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
  }

  &:after {
    border-color: transparent transparent ${globalVariables.white};
    border-width: 6px;
    right: 12px;
    top: -12px;
  }

  &:before {
    border-color: transparent transparent ${globalVariables.gray_200};
    border-width: 6px;
    right: 12.5px;
    top: -13px;
  }
`;

const InfoSection = styled.div<{ isWide?: boolean }>`
  padding: ${({ isWide }) => (isWide ? '20px 16px;' : '8px 0;')};

  &:not(:last-child) {
    border-bottom: 1px solid ${globalVariables.gray_100};
  }
`;
