import { DriverLicenseType } from 'mrt-constants';

import {
  MultipleRadioField,
  MultipleRadioFieldOptions,
  MultipleRadioFieldProps,
} from '../BaseFields';

const DRIVER_LICENSE_OPTIONS: MultipleRadioFieldOptions = Object.values(DriverLicenseType).map(
  (value) => ({
    value,
    content: value,
  }),
);

interface DriverLicenseRadioFieldProps extends Omit<MultipleRadioFieldProps, 'options'> {
  options?: MultipleRadioFieldOptions;
}

function DriverLicenseRadioField({
  label,
  options,
  ...props
}: DriverLicenseRadioFieldProps): JSX.Element {
  return (
    <MultipleRadioField
      options={options || DRIVER_LICENSE_OPTIONS}
      label={label ?? '운전면허 정보'}
      {...props}
    />
  );
}

export default DriverLicenseRadioField;
