import { fetchDice } from 'mrt-apis/abTest';

import { helper } from '@myrealtrip/frontend-weblog';
import { useQuery } from '@tanstack/react-query';

const { fetchUserData } = helper;

export default function useDice(testId: string) {
  const {
    data: webLogData,
    isError: isWebLogError,
    isFetching: isWebLogFetching,
  } = useQuery(['fetchUserData'], fetchUserData);

  const {
    data: diceData,
    isError: isDiceError,
    isFetching: isDiceFetching,
  } = useQuery(['fetchDice'], () => fetchDice(webLogData?.pid || '', testId), {
    // pid가 있을때만 가능
    enabled: Boolean(webLogData?.pid),
    // 영속성 부여
    staleTime: Infinity,
  });

  const hasError = isWebLogError || isDiceError;
  const isFetching = isWebLogFetching || isDiceFetching;

  return {
    result: diceData?.data.data.result,
    isError: hasError,
    isLoading: isFetching,
  };
}
