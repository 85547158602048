import { css } from '@emotion/react';
import { useRef, useState, Fragment, forwardRef } from 'react';

import { CarouselNavigationButton, useDetermineMobileContext } from 'mrt-components';
import { GlobalSearch } from 'mrt-types';
import { Navigation, FreeMode } from 'swiper';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';

import styled from '@emotion/styled';
import Divider from '@myrealtrip/design-system/dist/components/Divider';

import useSearchOffers from '../hooks/apis/useSearchOffers';
import VerticalSwiperItem from './VerticalSwiperItem';

import 'swiper/css';
import 'swiper/css/bundle';

type GradientType = { left: boolean; right: boolean };

const DEFAULT_OPTIONS: SwiperProps = {
  modules: [FreeMode, Navigation],
  freeMode: true,
  loop: false,
  speed: 200,
  spaceBetween: 4,
  grabCursor: true,
  slidesPerGroupAuto: true,
};

const { LAYOUT } = GlobalSearch;

interface Props {
  variations: GlobalSearch.Variation;
}

const VerticalSwiper = forwardRef<HTMLDivElement, Props>(({ variations }, ref) => {
  const [initSwiper, setInitSwiper] = useState(false);
  const isMobile = useDetermineMobileContext();
  const prevNaviRef = useRef<HTMLButtonElement | null>(null);
  const nextNaviRef = useRef<HTMLButtonElement | null>(null);
  const [showGradient, setShowGradient] = useState<GradientType>({
    left: false,
    right: false,
  });
  const { data, isError } = useSearchOffers();
  const sections = isError ? [] : data?.sections || [];
  const linkGroup = sections.find(
    (section) => section.sectionType === 'LINK_GROUP',
  ) as GlobalSearch.LinkGroup;
  const links = linkGroup ? linkGroup.links : [];

  const baseSlidesPerView = variations.layout === LAYOUT.header ? 4 : 3;
  const slidesPerView = links.length > baseSlidesPerView ? 'auto' : links.length;

  const handleShowGradient = ({ isBeginning = false, isEnd = false }) => {
    if (isMobile) {
      setShowGradient({ left: false, right: false });

      return;
    }

    setShowGradient({ left: !isBeginning, right: !isEnd });
  };

  const eventOptions: SwiperProps = {
    onBeforeInit: ({ isBeginning, isEnd }) => {
      handleShowGradient({ isBeginning, isEnd });
    },
    onSlideChange: ({ isBeginning, isEnd }) => {
      handleShowGradient({ isBeginning, isEnd });
    },
  };

  const swiperOptions: SwiperProps = {
    ...DEFAULT_OPTIONS,
    navigation: {
      prevEl: prevNaviRef.current,
      nextEl: nextNaviRef.current,
    },
    ...eventOptions,
    onInit: () => setInitSwiper(true),
    onAfterInit: (swiper) => {
      if (initSwiper && typeof swiper.params.navigation !== 'boolean') {
        const { navigation } = swiper.params;

        if (navigation?.prevEl && navigation?.nextEl) {
          navigation.prevEl = prevNaviRef.current;
          navigation.nextEl = nextNaviRef.current;
        }
      }
    },
    slidesPerView,
    slidesOffsetAfter: slidesPerView === 'auto' ? 12 : 0,
    slidesOffsetBefore: slidesPerView === 'auto' ? 12 : 0,
  };

  if (!linkGroup) {
    return null;
  }

  return (
    <Fragment>
      <Container ref={ref}>
        <CarouselContainer showGradient={showGradient} isAutoPerView={slidesPerView === 'auto'}>
          {!isMobile && (
            <CarouselNavigationButton direction="prev" verticalPosition={12} ref={prevNaviRef} />
          )}
          <Swiper {...swiperOptions}>
            {links?.map((link) => (
              <SwiperSlide key={link.id}>
                <VerticalSwiperItem data={link} />
              </SwiperSlide>
            ))}
          </Swiper>
          {!isMobile && (
            <CarouselNavigationButton direction="next" verticalPosition={12} ref={nextNaviRef} />
          )}
        </CarouselContainer>
      </Container>
      <ContainerDivider type="LINE_80_10" />
    </Fragment>
  );
});

VerticalSwiper.displayName = 'VerticalSwiper';
export default VerticalSwiper;

const Container = styled.div`
  position: relative;
  padding: 8px 0;
`;

const CarouselContainer = styled.div<{
  showGradient?: GradientType;
  isAutoPerView: boolean;
}>`
  position: relative;
  ${({ isAutoPerView }) =>
    !isAutoPerView &&
    css`
      padding: 0 12px;
    `};

  // swiper-wrapper 스타일 재설정
  // /myrealtrip-server/app/assets/stylesheets/kitty/search/_swiper.scss.erb 에서 overflow: hidden 설정으로 인해 스타일 이슈 발생
  .swiper-wrapper {
    overflow: initial;
  }
  // slidePerView가 auto인 경우 카드 아이템의 너비 스타일이 제거되는 부분 보정
  // slidePerView가 숫자값으로 지정되면 계산된 너비값이 자동 적용됨
  .swiper-slide {
    width: auto;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 64px;
    height: 100%;
    z-index: 2;
  }
  &:before {
    left: 0;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 1;
    ${({ showGradient, isAutoPerView }) =>
      css`
        display: ${showGradient?.left && isAutoPerView ? 'block' : 'none'};
      `}
  }
  &:after {
    right: 0;
    background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 1;
    ${({ showGradient, isAutoPerView }) =>
      css`
        display: ${showGradient?.right && isAutoPerView ? 'block' : 'none'};
      `}
  }
`;

const ContainerDivider = styled(Divider)`
  position: relative;
  left: 11px;
  width: calc(100% - 11px);
`;
