export const FooterThemeMessage = {
  MRT_FOOTER_CHANGE: 'MRT_FOOTER_CHANGE',
} as const;

export type FooterThemeMessageType = keyof typeof FooterThemeMessage;

export interface FooterThemeMessageValue {
  disabled?: boolean;
}

export type FooterPostMessage = {
  type: FooterThemeMessageType;
  value?: FooterThemeMessageValue;
};

export interface FooterChangeRequest {
  path: string;
  platform: 'mobile' | 'desktop' | 'all';
  disabled?: boolean;
}
