/* eslint-disable @typescript-eslint/naming-convention */

import { Children } from 'react';

import { useDetermineMobileContext } from 'mrt-components';

import styled from '@emotion/styled';
import { isActiveMenu } from '@layout/app/utils/isActiveMenu';
import { LocalNavVerticalItem } from '@layout/constants/lnb';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';

import LocalNavCarousel from './LocalNavCarousel';
import LocalNavItem from './LocalNavItem';
import RestLocalNavItems from './RestLocalNavItems';

const MAX_DISPLAY_VERTICAL_LENGTH = 9;

const homeMenu: LocalNavVerticalItem = {
  id: 0,
  code: 'home',
  title: '홈',
  position: 0,
  level: 1,
  lnb_required_badge: false,
  link: {
    desktop: process.env.HOME_URL,
    mobile_web: process.env.HOME_URL,
  },
};

type NormalizedLocalNavVerticalItem = LocalNavVerticalItem & {
  determinedMenuUrl: string;
  isActive: boolean;
};

export default function DefaultLocalNavItems() {
  const { lnbVerticalItems, isFlightMode } = useLayoutHeaderContext();
  const isMobile = useDetermineMobileContext();

  const mergedVerticalItems = [homeMenu, ...lnbVerticalItems].sort(
    (a, b) => a.position - b.position,
  );
  const normalizedVerticalItems: NormalizedLocalNavVerticalItem[] = mergedVerticalItems.map(
    (item) => {
      const { link, code } = item;
      const determinedMenuUrl = isMobile ? link.mobile_web : link.desktop;
      const isActive = isActiveMenu({
        code,
        menuUrl: determinedMenuUrl,
        isFlightMode,
      });

      return { ...item, determinedMenuUrl, isActive };
    },
  );
  const displayVerticalItems = normalizedVerticalItems.slice(0, MAX_DISPLAY_VERTICAL_LENGTH);
  const restVerticalItems = normalizedVerticalItems
    .slice(displayVerticalItems.length)
    .map(({ link, title }) => ({
      link: link.desktop,
      label: title,
    }));

  const initialSlideIndex = normalizedVerticalItems.findIndex(({ isActive }) => isActive);

  return (
    <Container>
      {isMobile && (
        <LocalNavCarousel initialSlideIndex={initialSlideIndex}>
          {Children.toArray(
            normalizedVerticalItems?.map(
              ({ title, determinedMenuUrl, lnb_required_badge, isActive }) => (
                <LocalNavItem
                  label={title}
                  link={determinedMenuUrl}
                  showNewBadge={lnb_required_badge}
                  isActive={isActive}
                />
              ),
            ),
          )}
        </LocalNavCarousel>
      )}
      {!isMobile && (
        <>
          {Children.toArray(
            displayVerticalItems?.map(
              ({ title, determinedMenuUrl, lnb_required_badge, isActive }) => (
                <LocalNavItem
                  label={title}
                  link={determinedMenuUrl}
                  showNewBadge={lnb_required_badge}
                  isActive={isActive}
                />
              ),
            ),
          )}
          {restVerticalItems.length > 0 && <RestLocalNavItems verticalItems={restVerticalItems} />}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;
