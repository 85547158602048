import styled from '@emotion/styled';
import i18n from '@layout/app/utils/i18n';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

export default function Copyright() {
  return (
    <Container>
      <Text dangerouslySetInnerHTML={{ __html: i18n.t('footer:copyright') }} />
    </Container>
  );
}

const Container = styled.div`
  margin-top: 16px;
`;

const Text = styled.p`
  font-size: 12px;
  letter-spacing: -0.2px;
  color: ${globalVariables.gray_500};
  line-height: 18px;

  a {
    font-weight: 500;
    color: ${globalVariables.gray_600};
  }
`;
