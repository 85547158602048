import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

import { HeaderTheme, HeaderSize, HeaderThemeType, HeaderSizeType } from '@layout/constants/header';
import { LocalNavVerticalItem, VerticalItem } from '@layout/constants/lnb';

export type LayoutHeaderContextType = {
  isFlightMode: boolean;
  isDisabled: boolean;
  theme: HeaderThemeType;
  size: HeaderSizeType;
  user: {
    id: number;
    username: string;
    email: string;
    phoneNumber: string;
    point: string;
    numberOfCoupons: string;
    profile_image: {
      urls: {
        original: string;
        large: string;
        medium: string;
        thumb: string;
      };
    };
    isAffiliation: boolean;
    role: string | null;
  } | null;
  lnbVerticalItems: LocalNavVerticalItem[];
  mainVerticalItems: VerticalItem[];
  subVerticalItems: VerticalItem[];
  isExposureNotification: boolean;
  excludeLnb?: boolean;
  setTheme: (nextTheme: HeaderThemeType) => void;
  setSize: (nextSize: HeaderSizeType) => void;
  setDisabled: (nextDisabled: boolean) => void;
  setExcludeLnb: (nextExcludeLnb: boolean) => void;
};

const defaultState = {
  isFlightMode: process.env.FLIGHT_MODE === 'YES',
  isDisabled: false,
  theme: HeaderTheme.white,
  size: HeaderSize.default,
  user: null,
  lnbVerticalItems: [],
  mainVerticalItems: [],
  subVerticalItems: [],
  isExposureNotification: false,
  excludeLnb: false,
  setTheme: () => null,
  setSize: () => null,
  setDisabled: () => null,
  setExcludeLnb: () => null,
};

export const LayoutHeaderContext = createContext<LayoutHeaderContextType>(defaultState);

export function LayoutHeaderContextProvider({
  initialState,
  children,
}: PropsWithChildren<{
  initialState: LayoutHeaderContextType;
}>) {
  const combineState = { ...defaultState, ...initialState };
  const [currentDisabled, setCurrentDisabled] = useState<boolean>(combineState.isDisabled ?? false);
  const [currentTheme, setCurrentTheme] = useState<HeaderThemeType>(combineState.theme);
  const [currentSize, setCurrentSize] = useState<HeaderSizeType>(combineState.size);
  const [isExcludeLnb, setExcludeLnb] = useState<boolean>(combineState.excludeLnb ?? false);

  const handleTheme = useCallback((nextTheme: HeaderThemeType) => setCurrentTheme(nextTheme), []);
  const handleSize = useCallback((nextSize: HeaderSizeType) => setCurrentSize(nextSize), []);
  const handleDisabled = useCallback(
    (nextDisabled: boolean) => setCurrentDisabled(nextDisabled),
    [],
  );
  const handleExcludeLnb = useCallback(
    (nextExcludeLnb: boolean) => setExcludeLnb(nextExcludeLnb),
    [],
  );

  return (
    <LayoutHeaderContext.Provider
      value={{
        ...combineState,
        isDisabled: currentDisabled,
        theme: currentTheme,
        size: currentSize,
        excludeLnb: isExcludeLnb,
        setTheme: handleTheme,
        setSize: handleSize,
        setDisabled: handleDisabled,
        setExcludeLnb: handleExcludeLnb,
      }}
    >
      {children}
    </LayoutHeaderContext.Provider>
  );
}

export function useLayoutHeaderContext() {
  return useContext(LayoutHeaderContext);
}
