import { PhoneAgency } from 'mrt-constants';

import { SelectFieldProps, SelectUnitOptions, SingleSelectField } from '../BaseFields';

const PHONE_AGENCY_OPTIONS: SelectUnitOptions = [
  { value: '', content: '통신사', isPlaceholder: true },
  ...Object.keys(PhoneAgency).map((key) => {
    return { value: key, content: PhoneAgency[key] };
  }),
];

interface PhoneAgencySelectFieldProps extends Omit<SelectFieldProps, 'options'> {
  options?: SelectUnitOptions;
}

function PhoneAgencySelectField({
  label,
  options,
  width,
  ...props
}: PhoneAgencySelectFieldProps): JSX.Element {
  return (
    <SingleSelectField
      options={options || PHONE_AGENCY_OPTIONS}
      label={label ?? '통신사'}
      width={width ?? 140}
      {...props}
    />
  );
}

export default PhoneAgencySelectField;
