import React from 'react';
import PropTypes from 'prop-types';

import FlightSeatInfo from './FlightSeatInfo';
import FlightFreeBaggageInfo from './FlightFreeBaggageInfo';

import styles from './FlightAirplaneInfo.module.scss';

function FlightAirplaneInfo(props) {
  const { data, isMobile } = props;

  return (
    <div className={styles.airplaneAndFreeBaggageInfo}>
      <div className={styles.airplaneInfo}>
        {!isMobile ? (
          <img src={data.STOCK_AIR_CD_IMG} />
        ) : (
          <img src={data.STOCK_AIR_CD_IMG_S} />
        )}
        <div className={styles.airNameBoxM}>
          <span className={styles.airName}>{data.STOCK_AIR_NM}</span>
          <span className={styles.airplaneNumber}>
            {isMobile ? (
              <span>{data.FLTNO.slice(2, data.FLTNO.length)}</span>
            ) : (
              <span>{data.FLTNO}</span>
            )}
          </span>
          {isMobile ? (
            <FlightFreeBaggageInfo data={data} isMobile={isMobile} />
          ) : null}
        </div>
      </div>
      {isMobile ? (
        <div>
          <FlightSeatInfo data={data} isMobile={isMobile} />
        </div>
      ) : null}
    </div>
  );
}

FlightAirplaneInfo.propTypes = {
  data: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default FlightAirplaneInfo;
