import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#DEE2E6"
        d="M6 9.121L2.292 11 3 7.02 0 4.202l4.146-.581L6 0l1.854 3.621 4.146.58-3 2.82L9.708 11z"
      />
      <path
        className="starColor"
        fill="#51ABF3"
        d="M6 .151v9.01l-.077-.04L2.263 11l.699-3.98L0 4.202l4.093-.581L5.923 0 6 .151z"
      />
    </g>
  </svg>
);
