import { forwardRef, Fragment } from 'react';
import { createPortal } from 'react-dom';

import { usePreventBodyScroll, ResponsiveImage } from '@myrealtrip/design-system';

import styled from '@emotion/styled';

interface Props {
  isOpen: boolean;
  toggle: (value: boolean) => void;
  imageUrl: string;
  hideText?: string;
  closeText?: string;
  isMobile: boolean;
  onClickImage?: () => void;
  onHide?: () => void;
  onClose?: () => void;
}

const FullScreenPopup = forwardRef<HTMLDivElement, Props>(
  (
    { isOpen, toggle, imageUrl, hideText, closeText, isMobile, onClickImage, onHide, onClose },
    ref,
  ) => {
    usePreventBodyScroll(isOpen);

    const handleClickImage = () => {
      onClickImage?.();
    };

    const handleHide = () => {
      onHide?.();
    };

    const handleClosableClick = () => {
      toggle(false);
    };

    const handleClose = () => {
      onClose?.();
      handleClosableClick();
    };

    if (!isOpen) {
      return null;
    }

    return createPortal(
      <Fragment>
        <Backdrop onClick={handleClosableClick} />
        <Container ref={ref} isMobile={isMobile}>
          <Image isMobile={isMobile} src={imageUrl} onClick={handleClickImage} />
          <ActionContainer>
            {hideText && <ActionButton onClick={handleHide}>{hideText}</ActionButton>}
            {closeText && <ActionButton onClick={handleClose}>{closeText}</ActionButton>}
          </ActionContainer>
        </Container>
      </Fragment>,
      document.body,
    );
  },
);

FullScreenPopup.displayName = 'FullScreenPopup';

export default FullScreenPopup;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--gray-600);
  mix-blend-mode: multiply;
  opacity: 0.9;
  z-index: var(--z-popup);
`;

const Container = styled.div<{ isMobile: boolean }>`
  position: fixed;
  width: ${({ isMobile }) => (isMobile ? 270 : 330)}px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  z-index: var(--z-popup);
  overflow: hidden;
`;

const Image = styled(ResponsiveImage)<{ isMobile: boolean }>`
  display: block;
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? 360 : 440)}px;
`;

const ActionContainer = styled.div`
  display: flex;
`;

const ActionButton = styled.button`
  flex: 1;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.4px;
  color: var(--gray-300);
  background-color: var(--gray-900);
  outline: none;
  border: none;
  padding: 17px 0;
  opacity: 0.8;

  &:not(:last-of-type) {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 20px;
      background-color: var(--white);
      right: -1px;
      top: calc(50% - 10px);
    }
  }
`;
