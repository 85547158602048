import React from 'react';
import PropTypes from 'prop-types';

import styles from './HotelPrice.module.scss';

function computePrice(price) {
  const parsePrice = parseInt(price, 10);

  return parsePrice.toLocaleString();
}

function HotelPrice(props) {
  const { price } = props;

  return (
    <p className={styles.container}>
      <strong className={styles.price}>
        {computePrice(price)}
        <span className={styles.unit}>원</span>
      </strong>
      <small className={styles.standard}>1박 최저가</small>
    </p>
  );
}

HotelPrice.propTypes = {
  price: PropTypes.number,
};

HotelPrice.defaultProps = {
  price: 0,
};

export default HotelPrice;
