export enum ProductSortIds {
  DESC_POPULAR = '-popular',
  ASC_PRICE = 'price',
  DESC_PRICE = '-price',
  DESC_AVERAGE_SCORE = '-averageScore',
}

export const SortNames = {
  [ProductSortIds.DESC_POPULAR]: '인기순',
  [ProductSortIds.DESC_AVERAGE_SCORE]: '높은 평점순',
  [ProductSortIds.ASC_PRICE]: '낮은 가격순',
  [ProductSortIds.DESC_PRICE]: '높은 가격순',
};
