import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { hotelType } from '../../../types';
import { cardThemeEnum } from '../../../enum';
import useElementVisibility from '../../../hooks/useElementVisibility';
import CardContainer from './CardContainer';
import CardThumbnail from './CardThumbnail';
import CardTitle from './CardTitle';
import HotelReview from './HotelReview';
import HotelReviewScore from './HotelReviewScore';
import HotelPrice from './HotelPrice';
import styles from './HotelVerticalCard.module.scss';

function HotelVerticalCard(props) {
  const { hotel, category, order, onClick, onImpression } = props;
  const timer = useRef(null);
  const [visibility, subscribe, unsubscribe] = useElementVisibility({
    threshold: 0.5,
  });
  const handleClick = useCallback(() => {
    const { id, link } = hotel;

    onClick({ id, category, hotel, horizontalOrder: order, link });
  }, [category, hotel, order, onClick]);

  useEffect(() => {
    const { link } = hotel;

    if (visibility.isIntersecting) {
      timer.current = setTimeout(() => {
        onImpression({
          category,
          hotel,
          horizontalOrder: order,
          link,
        });
        unsubscribe();
        timer.current = null;
      }, 200);
    } else {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, [visibility, unsubscribe, hotel, onImpression, category, order]);

  return (
    <CardContainer
      ref={subscribe}
      link={hotel.link}
      theme={cardThemeEnum.hotel}
      onClick={handleClick}
    >
      <CardThumbnail image={hotel.thumbnail} />
      <div className={styles.body}>
        <CardTitle title={hotel.title} />
      </div>
      <div className={styles.review}>
        <HotelReview
          score={hotel.reviewScore}
          count={hotel.reviewCount}
          status={hotel.reviewStatus}
        />
      </div>
      <div className={styles.price}>
        <HotelPrice price={hotel.price} />
      </div>
      <div className={styles.score}>
        <HotelReviewScore score={hotel.reviewScore} />
      </div>
    </CardContainer>
  );
}

HotelVerticalCard.propTypes = {
  order: PropTypes.number,
  hotel: hotelType,
  category: PropTypes.string,
  onClick: PropTypes.func,
  onImpression: PropTypes.func,
};

HotelVerticalCard.defaultProps = {
  order: -1,
  hotel: null,
  category: '',
  onClick: () => null,
  onImpression: () => null,
};

function areEqual(prevProps, nextProps) {
  return prevProps.hotel.id === nextProps.hotel.id;
}

export default React.memo(HotelVerticalCard, areEqual);
