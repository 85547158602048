/* eslint-disable class-methods-use-this */

import { helper } from '@myrealtrip/frontend-weblog';

import { LoggerEventParams } from '../models/loggers';

const { sendClickLog, sendImpressionLog, sendPageViewLog, sendSearchLog } = helper;

class Logger {
  private getView() {
    return (params: LoggerEventParams) => {
      sendPageViewLog(params);
    };
  }

  private getClick() {
    return (params: LoggerEventParams) => {
      sendClickLog({
        ...params,
      });
    };
  }

  private getImpression() {
    return (key: string | number, params: LoggerEventParams) => {
      sendImpressionLog(params, key);
    };
  }

  private getCustomEvent() {
    return (eventType: string, params: LoggerEventParams) => {
      if (eventType === 'search') {
        sendSearchLog(params);
      }
    };
  }

  public getPageLogger() {
    return {
      pageView: this.getView(),
      click: this.getClick(),
      impression: this.getImpression(),
      event: this.getCustomEvent(),
    };
  }
}

const instance = new Logger();
export default instance;
