const language = {
  offer: {
    link: 'Go to see now',
  },
  share: {
    kakao: 'Share KakaoTalk',
    copy: 'Copy link',
    share: 'Share',
  },
  copied: 'Copied!',
};

export default language;
