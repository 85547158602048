const language = {
  offer: {
    link: '지금 보러가기',
  },
  share: {
    kakao: '카카오톡 공유하기',
    copy: '링크 복사하기',
    share: '공유하기',
  },
  copied: '복사 되었습니다.',
};

export default language;
