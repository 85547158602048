import {
  AmenitiesKeys,
  ExtraPerPax,
  ExtraPerReservations,
  FreeServicesKeys,
  OrderStatus,
  PaymentMethod,
  RefundablePolicy,
} from 'mrt-constants';

import { CouponUserType } from './coupon';
import { MealType } from './lodging';
import { PriceType } from './price';

export type OrderStockOptions = Readonly<{
  id: string;
  stockId: string | null;
  quantity: number;
  salePrice: number;
  startDateTime: string | null;
  endDateTime: string | null;
}>;

export interface OrderLocationDetailType {
  title: null;
  latitude: number;
  longitude: number;
  descriptions: null;
}

export interface RefundDetailType {
  refundable: RefundablePolicy;
  leftDaysFrom: number; // 환불 조건 시작 잔여일
  leftDaysTo: number; // 환불 조건 종료 잔여일
  cancelCommissionRate: number; // 공제율
}

export interface OrderProductMetaType {
  amenities: readonly AmenitiesKeys[];
  freeServices: readonly FreeServicesKeys[];
  checkOutTime: string;
  checkInTime: string;
  rules: readonly string[];
  meals: readonly MealType[];
}

export interface OrderStockUnit {
  amount: number;
  price: PriceType;
  stockUnitId: number;
  stockUnitName: string;
}

export interface OrderStockUnits {
  [key: string]: readonly OrderStockUnit[];
}

export type OrderFormProductType = Readonly<{
  productId: string;
  productTitle: string;
  thumbnailImageUrl: string;
  locationDetail: OrderLocationDetailType;
  optionId: number;
  optionTitle: string;
  partnerName: string;
  refund: RefundDetailType;
  productMeta: OrderProductMetaType;
  stockUnits: OrderStockUnits;
  startDate: string;
  endDate: string;
  totalPrice: PriceType;
  immediateConfirm: boolean;
  // 기타 예약 정보 question ("오시는 교통수단을 적어주세요")
  customExtraRequireKey: string | null;
  // 기타 예약 정보 answer (파트너가 정의한 "오시는 교통수단을 적어주세요" 에 대한 사용자 답변)
  customExtraRequireValue: string | null;
  // 사용자에게 요구하는 추가예약정보 필요한 사항(파트너가 정의)
  extraRequiresPerPax: readonly ExtraPerPax[];
  // 사용자에게 요구하는 추가예약정보 필요한 사항(파트너가 정의)
  extraRequiresPerReservation: readonly ExtraPerReservations[];
  // 사용자가 작성한 추가예약정보 (인원별)
  extraRequiresValuePerPax: unknown;
  // 사용자가 작성한 추가예약정보 (예약별)
  extraRequiresValuePerReservation: unknown;
}>;

export interface OrderFormData {
  adults: number;
  children: number;
  balancePoint: number;
  coupons: CouponUserType[];
  orderFormId: string;
  agreeMarketing: boolean;
  product: OrderFormProductType;
  userName: string;
  userEmail: string;
}

export type OrderPgParamsType = Readonly<{
  successReturnUrl: string;
  failReturnUrl: string;
}>;

export interface VirtualAccountResponse {
  accountName: string;
  accountNumber: string;
  bank: string;
  dueDateTime: string;
}

export interface OrderPaymentResponse {
  firstPaymentCreatedAt?: string;
  paymentAuthorizedAt?: string;
  paymentMethod: PaymentMethod;
  pg?: string;
  pgAuthorizedAt?: Date;
  pgTid?: string;
  pointPaymentAmount: number;
  status: OrderStatus;
  totalCouponDiscountAmount: number;
  totalOrderPaymentAmount: number;
  totalSalePrice: number;
  virtualAccount?: VirtualAccountResponse;
}

export interface Api {
  // 로그인 후 바로 진입 로직이 적용될 vertical 타입
  vertical: MrtVerticalType;
  // api함수 payload
  payload?: any;
  // 후처리 함수 payload
  afterFuncPayload?: any;
}

// Redis 저장 로직을 적용할 버티컬들
export enum MrtVertical {
  /** 입점숙소 */
  BNB = 'BNB',
  /** 렌터카 */
  CAR = 'CAR',
  /** 투어티켓 */
  TNA = 'TNA',
  /** 패키지 */
  PKG = 'PKG',
  /** 견적서 */
  ESTIMATE = 'ESTIMATE',
}

export type MrtVerticalType = keyof typeof MrtVertical;
export interface AfterFuncPayload<T> {
  data: T;
  isWebView?: boolean;
  apiPayload?: any;
  afterFuncPayload?: any;
}
