// 마케팅팀에서 사용하는 스크립트 추가

import { ntracker } from '../../utils/callMarketingScript';

function attatchNtracker(price, resNo) {
  ntracker(200, price, resNo);
}

function appendTrackingScript() {
  const internationals = [
    '/air/b2c/AIR/AAA/AIRAAARSV0100100010.k1', // pc 국제선,
    '/air/b2c/AIR/MBL/AIRMBLRSV0100100010.k1', // mobile 국제선,
  ];

  const domestics = [
    // '/air/b2cmpg/AIR/AAA/AIRAAAPAY1110100040.k1', // pc 국내선
    '/air/b2cmpg/AIR/AAA/AIRAAAPAY1110100070.k1', // pc 국내선
    // '/air/b2cmpg/AIR/MBL/AIRMBLPAY1110100040.k1', // mobile 국내선
    '/air/b2cmpg/AIR/MBL/AIRMBLPAY1110100070.k1', // mobile 국내선
  ];

  const currentPath = window.location.pathname;

  const isInternational = internationals.includes(currentPath);
  const isDomestic = domestics.includes(currentPath);

  if (isInternational) {
    attatchNtracker(window.CA, window.CK);
  } else if (isDomestic) {
    const findResInfo = () => {
      const price = document.getElementById('cardpayamt');
      const resNo = document.getElementById('rsvno');

      if (price && resNo) {
        attatchNtracker(price.value, resNo.value);
      } else {
        setTimeout(findResInfo, 1000);
      }
    };

    findResInfo();
  }
}

export default appendTrackingScript;
