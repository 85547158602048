import { useEffect, useState } from 'react';

import { ChatButton } from './CustomerCenter';
import useRemote from '../../../../../../packages/mrt-components/hooks/useRemote';

export default function ChannelTalkPreviewButton() {
  const { isLoad } = useRemote(import('remote/csHelper'));
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (isLoad) {
      (async function init() {
        const type = await window.csHelper?.getType();
        if (type && type.enablePreviousChat && type.vendorType === 'zendesk') {
          setIsShow(true);
        }
      })();
    }
  }, [isLoad]);

  useEffect(() => {});

  if (!isShow) {
    return null;
  }
  return (
    <ChatButton onClick={() => window.csHelper?.openPreview()} type="outline">
      지난 상담 내역보기
    </ChatButton>
  );
}
