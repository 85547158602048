import React, { useCallback, useEffect, useRef } from 'react';

import { WishMark } from '@myrealtrip/wish';
import { WishProvider } from '@myrealtrip/wish';

import PropTypes from 'prop-types';

import CardCategory from './CardCategory';
import CardContainer from './CardContainer';
import CardInstantBookingSticker from './CardInstantBookingSticker';
import CardPriceForVertical from './CardPriceForVertical';
import CardRating from './CardRating';
import CardThumbnail from './CardThumbnail';
import CardTitle from './CardTitle';
import { createOfferLink } from './offerCardUtil';
import { cardThemeEnum, cardPurposeEnum } from '../../../enum';
import useElementVisibility from '../../../hooks/useElementVisibility';
import { offerType } from '../../../types';

import styles from './OfferVerticalCard.module.scss';

function OfferVerticalCard(props) {
  const {
    className,
    order,
    offer,
    purpose,
    category,
    isWebView,
    openNewTab,
    onClick,
    onImpression,
    themeOrder,
    fixedWidth,
    wishLogData,
  } = props;

  const link = createOfferLink(offer, isWebView);
  const timer = useRef(null);
  const [visibility, subscribe, unsubscribe] = useElementVisibility({
    threshold: 0.5,
  });
  const handleClick = useCallback(() => {
    onClick({ category, offer, themeOrder, horizontalOrder: order, link });
  }, [category, themeOrder, offer, order, onClick, link]);

  useEffect(() => {
    if (visibility.isIntersecting) {
      timer.current = setTimeout(() => {
        onImpression({
          category,
          offer,
          themeOrder,
          horizontalOrder: order,
          link,
        });
        unsubscribe();
        timer.current = null;
      }, 200);
    } else {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, [visibility, unsubscribe, offer, themeOrder, onImpression, category, order, link]);

  const enhancedWishLogData = {
    ...wishLogData,
    horizontalOrder: order,
    itemId: offer.id,
    itemName: offer.title,
    itemCategory: offer.category,
    itemPrice: offer.price,
    itemType: offer.type,
    itemReviewCount: offer.review?.count || 0,
    targetUrl: link,
  };

  return (
    <WishProvider hideToast>
      <CardContainer
        ref={subscribe}
        className={className}
        theme={cardThemeEnum.offer}
        link={link}
        purpose={purpose}
        onClick={handleClick}
        openNewTab={openNewTab}
        fixedWidth={fixedWidth}
      >
        <div className={styles.thumbnail}>
          <CardThumbnail image={offer.image} tags={offer.tags} />
        </div>
        <div className={styles.body}>
          <CardCategory categories={[offer.category, offer.city ? offer.city.name : '']} />
          <CardTitle title={offer.title} />
          <div className={styles.info}>
            <div className={styles.review}>
              <CardRating
                score={offer.review.star}
                type={offer.review.type}
                reviewCount={offer.review.count}
              />
            </div>
            <CardPriceForVertical
              originPrice={offer.price.origin}
              mainPrice={offer.price.main}
              originForceVisible={offer.price.originForceVisible}
              type={offer.type}
            />
          </div>
          {offer.nowUse && (
            <div className={styles.immediately}>
              <CardInstantBookingSticker showText />
            </div>
          )}
        </div>
        <div className={styles.wishlist}>
          <WishMark id={offer.id} icoBookmarkLineColor="#FFFFFF" logData={enhancedWishLogData} />
        </div>
      </CardContainer>
    </WishProvider>
  );
}

OfferVerticalCard.propTypes = {
  className: PropTypes.string,
  order: PropTypes.number,
  offer: offerType,
  purpose: PropTypes.oneOf(Object.values(cardPurposeEnum)),
  category: PropTypes.string,
  isWebView: PropTypes.bool,
  onClick: PropTypes.func,
  onImpression: PropTypes.func,
  openNewTab: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  themeOrder: PropTypes.number,
  wishLogData: PropTypes.shape({}).isRequired,
};

OfferVerticalCard.defaultProps = {
  order: undefined,
  offer: null,
  purpose: cardPurposeEnum.swiper,
  category: '',
  isWebView: false,
  onClick: () => null,
  onImpression: () => null,
  openNewTab: false,
  fixedWidth: true,
};
OfferVerticalCard.propTypes = {
  className: PropTypes.string,
  order: PropTypes.number,
  offer: offerType,
  purpose: PropTypes.oneOf(Object.values(cardPurposeEnum)),
  category: PropTypes.string,
  isWebView: PropTypes.bool,
  onClick: PropTypes.func,
  onImpression: PropTypes.func,
  openNewTab: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  themeOrder: PropTypes.number,
  wishLogData: PropTypes.shape({}).isRequired,
};

OfferVerticalCard.defaultProps = {
  order: undefined,
  offer: null,
  purpose: cardPurposeEnum.swiper,
  category: '',
  isWebView: false,
  onClick: () => null,
  onImpression: () => null,
  openNewTab: false,
  fixedWidth: true,
};

function areEqual(prevProps, nextProps) {
  return prevProps.offer.id === nextProps.offer.id;
}

export default React.memo(OfferVerticalCard, areEqual);
