import { useRef, forwardRef } from 'react';
import { useRecoilValue } from 'recoil';

import { SEARCH_INPUT_HEIGHT } from 'mrt-constants';
import { GlobalSearch } from 'mrt-types';

import styled from '@emotion/styled';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import SearchSuggestionContent from '../SearchSuggestionContent';
import useSearchOffers from '../hooks/apis/useSearchOffers';
import { searchTerm } from '../recoil';
import useSearchManager from '../useSearchManager';

const { LAYOUT } = GlobalSearch;

interface Props {
  isOpen: boolean;
  variations: GlobalSearch.Variation;
  handleRef: (idx: number, node: HTMLAnchorElement) => void;
  selectedIdx: number;
}

const SearchSuggestionPopup = forwardRef<HTMLDivElement, Props>(
  ({ isOpen, variations, handleRef, selectedIdx }, ref) => {
    const { hasRecentKeywords } = useSearchManager();
    const searchKeyword = useRecoilValue(searchTerm);
    const { layout } = variations;
    const verticalSwiperContainerRef = useRef<HTMLDivElement | null>(null);
    const { data, isError } = useSearchOffers();
    const sections = isError ? [] : data?.sections || [];
    const hasValue = sections.length > 0;
    const hasKeywords = hasRecentKeywords && searchKeyword === '';
    const positionTop =
      layout === LAYOUT.header ? SEARCH_INPUT_HEIGHT.medium : SEARCH_INPUT_HEIGHT.large;

    if (!isOpen || (!hasValue && !hasKeywords)) {
      return null;
    }

    return (
      <Container positionTop={positionTop} ref={ref}>
        <SearchSuggestionContent
          variations={variations}
          handleRef={handleRef}
          selectedIdx={selectedIdx}
          ref={verticalSwiperContainerRef}
        />
      </Container>
    );
  },
);

SearchSuggestionPopup.displayName = 'SearchSuggestionPopup';

export default SearchSuggestionPopup;

const Container = styled.div<{ positionTop: number }>`
  position: absolute;
  top: ${({ positionTop }) => `${positionTop + 4}px`};
  width: 100%;
  max-height: 500px;
  border-radius: 4px;
  background: ${globalVariables.white}; // white
  box-shadow: 0px 0px 0px 1px #e9ecef, 0px 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: ${globalVariables.z_popup};

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
