let observer = null;

function initObserver() {
  observer = new window.IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const lazyImage = entry.target;

        lazyImage.src = lazyImage.dataset.src;
        observer.unobserve(lazyImage);
      }
    });
  });
}

function addLazyImageObserver(target) {
  if (!observer) {
    initObserver();
  }

  observer.observe(target);
}

export default addLazyImageObserver;
