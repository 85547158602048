import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import media from 'mrt-utils/mediaQuery';

import styled from '@emotion/styled';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';
import { resetButton } from '@layout/styles/reset';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import { helper } from '@myrealtrip/frontend-weblog';

const { sendClickLog } = helper;

export default function GnbLogo() {
  const { theme } = useLayoutHeaderContext();

  const logoName = theme === 'white' ? 'logo_mrt_v2_web' : 'logo_mrt_v2_web_white';

  const handleClick = () => {
    sendClickLog({
      eventName: 'mainlogo',
      screenName: WeblogScreenName.COMMON,
      pageCategory: WeblogPageCategory.COMMON,
    });

    window.location.href = `${process.env.HOME_URL}`;
  };

  return (
    <Container>
      <Anchor type="button" onClick={handleClick}>
        <LogoImage name={logoName} alt="myrealtrip" />
      </Anchor>
    </Container>
  );
}

const Container = styled.div``;

const Anchor = styled.button`
  ${resetButton};
`;

const LogoImage = styled(Icon)`
  width: 128px;
  vertical-align: middle;

  ${media.mobile} {
    width: 92px;
  }
`;
