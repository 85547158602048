import { sendCustomEvent } from '../../../utils/sendGAEvent';
import callNativeHandler from '../../../utils/callNativeHandler';
import getMobileEventScreenKey from '../../../utils/getMobileEventScreenKey';

function sendNativeEvent(eventName, click, theme) {
  const params = {
    [getMobileEventScreenKey()]: 'flight_xsell_fullscreen',
    city_name: 'Jeju',
  };

  if (click) {
    params.click = click;
  }

  if (theme) {
    params.theme_id = theme;
  }

  const event = {
    event_name: eventName,
    event_param: {
      ...params,
    },
  };

  callNativeHandler('sendEvent', event);
}

function sendGAEvent(action, theme) {
  sendCustomEvent('항공권 크로스셀_전체페이지 팝업', action, theme);
}

export function handleAllButtonClick(isWebView, action, theme = null) {
  if (isWebView) {
    sendNativeEvent(action, 'seeall', theme);
  } else {
    sendGAEvent(action, theme);
  }
}

export function handleApperEvent(isWebView) {
  if (isWebView) {
    sendNativeEvent('mrt_screen_view');
  } else {
    sendGAEvent('노출_전체페이지팝업');
  }
}

export function handleReservationClick(isWebView) {
  if (isWebView) {
    sendNativeEvent('click_flightinfo', 'detail');
  } else {
    sendGAEvent('클릭_예약내역보기버튼');
  }
}

export function handleRentalCarClick(isWebView) {
  if (isWebView) {
    sendNativeEvent('click_rentacar', 'card');
  } else {
    sendGAEvent('클릭_렌터카섹션_상품카드');
  }
}

export function handleHotelClick(isWebView) {
  if (isWebView) {
    sendNativeEvent('click_hotel', 'card');
  } else {
    sendGAEvent('클릭_호텔섹션_상품카드');
  }
}

export function handleMagazinClick(isWebView) {
  if (isWebView) {
    sendNativeEvent('click_contents', 'card');
  } else {
    sendGAEvent('클릭_콘텐츠섹션_콘텐츠카드');
  }
}

export function handleLodgingClick(isWebView) {
  if (isWebView) {
    sendNativeEvent('click_pension', 'card');
  } else {
    sendGAEvent('클릭_펜션게하섹션_상품카드');
  }
}

export function handleThemeClick(isWebView, theme) {
  if (isWebView) {
    sendNativeEvent('click_theme', 'card', theme);
  } else {
    sendGAEvent('클릭_테마섹션_상품카드', theme);
  }
}
