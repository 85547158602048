import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import { Section } from 'mrt-constants/globalSearch/suggestion';
import { logBrazeEvent } from 'mrt-eventlog/braze';
import { LinkGroup, Banner } from 'mrt-types/globalSearch';

import { helper } from '@myrealtrip/frontend-weblog';

const { sendImpressionLog, sendSearchLog } = helper;

const baseLog = {
  screenName: WeblogScreenName.COMMON,
  pageCategory: WeblogPageCategory.COMMON,
};

interface Param {
  sections?: Array<LinkGroup | Banner>;
  additionalLog: { [key: string]: unknown; searchWord: string; targetUrl: string };
}

export default function sendSearchCombineLog({ sections = [], additionalLog }: Param) {
  const linkGroupSection = sections.find(({ sectionType }) => sectionType === Section.LINK_GROUP);

  if (linkGroupSection) {
    const {
      loggingMeta: { impressions = [] },
    } = linkGroupSection;

    const [impression] = impressions;

    sendImpressionLog(
      {
        ...baseLog,
        eventName: impression.eventName,
        ...impression.data,
      },
      `global-search-linkgroup-impression`,
    );
  }
  sendSearchLog({
    ...baseLog,
    ...additionalLog,
    itemKind: 'search',
    eventName: 'gsearch',
  });
  logBrazeEvent('search_offer', {
    search_offer: additionalLog.searchWord,
  });
}
