import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { HeaderThemeType, HeaderTheme } from '@layout/constants/header';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';
import { resetButton } from '@layout/styles/reset';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import RedDot from './RedDot';

type Props = {
  className?: string;
  label: string;
  redDot?: boolean;
  onClick?: () => void;
};

export default function GlobalNavNonLineButton({ className, label, redDot, onClick }: Props) {
  const { theme } = useLayoutHeaderContext();

  return (
    <Container className={className}>
      <Button type="button" colorTheme={theme} onClick={onClick}>
        {label}
      </Button>
      {redDot && <RedDot />}
    </Container>
  );
}

const BUTTON_THEME_STYLE = {
  [HeaderTheme.blue]: css`
    color: ${globalVariables.white};

    &:hover {
      color: ${globalVariables.white};
      background-color: rgba(255, 255, 255, 0.2);
    }
  `,
  [HeaderTheme.white]: css`
    color: ${globalVariables.gray_600};

    &:hover {
      color: ${globalVariables.gray_600};
      background-color: ${globalVariables.gray_60};
    }
  `,
  [HeaderTheme.transparent]: css`
    color: ${globalVariables.white};

    &:hover {
      color: ${globalVariables.white};
      background-color: rgba(255, 255, 255, 0.2);
    }
  `,
};

const Container = styled.div`
  position: relative;
`;

const Button = styled.button<{
  colorTheme: HeaderThemeType;
}>`
  ${resetButton};

  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 8px 12px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;
  white-space: nowrap;
  transition: background-color 0.2s ease;

  ${({ colorTheme }) => BUTTON_THEME_STYLE[colorTheme]}
`;
