// 항공에서 필수적으로 실행 되는 Header, Footer에 대한 스크립트

import i18n from '../../common/i18n';
import HeaderFlight from '../../pages/HeaderFlight';
import Footer from 'mrt-layout/src/entries/MrtFooter';
import { initComponent, renderReactComponent } from './share';

function createTitle() {
  let title = document.head.getElementsByTagName('title')[0];

  if (!title) {
    title = document.createElement('title');
    title.text = i18n.t('header:title');
    document.head.appendChild(title);
  } else {
    title.text = i18n.t('header:title');
  }
}

function createFavicon() {
  const favicon = document.createElement('link');
  favicon.rel = 'shortcut icon';
  favicon.href = 'https://dffoxz5he03rp.cloudfront.net/logos/favicon.ico';

  document.head.appendChild(favicon);
}

function renderHeader(user = {}, target = '', mrt = {}) {
  let header = null;
  if (target) {
    header = document.getElementById(target);
    header.innerHTML = '';
  } else {
    const div = initComponent('mrt-new-header');

    const body = document.getElementsByTagName('body')[0];
    header = body.insertBefore(div, body.childNodes[0]);
  }

  renderReactComponent(<HeaderFlight user={user} {...mrt} />, header);
}

function renderFooter(user = {}) {
  const div = initComponent('mrt-new-footer');
  const body = document.getElementsByTagName('body')[0];
  const footer = body.appendChild(div);

  renderReactComponent(<Footer user={user} />, footer);
}

export default function renderComponent(user, headerTarget, _transparent, mrt = {}) {
  if (process.env.FLIGHT_MODE !== 'YES') {
    return;
  }

  renderHeader(user, headerTarget, mrt);
  renderFooter(user);

  createTitle();
  createFavicon();
}
