import { SystemProvider } from './product';

export enum PaymentMethod {
  // 1) 가주문서 API에서 내려주는 결제수단 유형
  TOSS = 'TOSS',
  PAYCO = 'PAYCO',
  KAKAOPAY = 'KAKAOPAY',
  NAVERPAY = 'NAVERPAY',
  CREDIT_CARD = 'CREDIT_CARD',
  REALTIME_TRANSFER = 'REALTIME_TRANSFER',
  VIRTUAL_ACCOUNT = 'VIRTUAL_ACCOUNT',
  ETC = 'ETC',
  MRT_DISCOUNT = 'MRT_DISCOUNT',
  PAY_LATER = 'PAY_LATER', // 나중 결제
  ARS = 'ARS',
  FORCE_ACCOUNT_DEPOSIT = 'FORCE_ACCOUNT_DEPOSIT', // 기업용 계좌이체
  OVERSEA_CREDIT_CARD = 'OVERSEA_CREDIT_CARD', // 해외카드

  // 2) CustomPaymentMethod - FE에서 임의로 확장하는 유형이다.
  KBPAY = 'KBPAY', // KB Pay(CREDIT_CARD => KOOKMIN 을 1depth로 올림)
}

export enum PG {
  INICIS = 'INICIS',
  TOSS_PAYMENT = 'TOSSPAYMENTS',
  KCP = 'KCP',
}

export enum PaymentMethodMap {
  TOSS = '토스페이',
  PAYCO = '페이코',
  KAKAOPAY = '카카오페이',
  NAVERPAY = '네이버페이',
  CREDIT_CARD = '신용/체크카드',
  REALTIME_TRANSFER = '실시간 계좌이체',
  VIRTUAL_ACCOUNT = '무통장 입금',
  ETC = '기타',
  MRT_DISCOUNT = '쿠폰/포인트 결제',
  PAY_LATER = '나중에 결제할게요',
  ARS = 'ARS결제',
  FORCE_ACCOUNT_DEPOSIT = '계좌 입금',
}

export enum PaymentStatus {
  TEMP = 'TEMP', // 결제임시
  RESERVE_REQUESTED = 'RESERVE_REQUESTED', // 결제예약요청
  RESERVE_SUCCEEDED = 'RESERVE_SUCCEEDED', // 결제예약성공
  RESERVE_FAILED = 'RESERVE_FAILED', // 결제예약실패
  AUTHENTICATE_REQUESTED = 'AUTHENTICATE_REQUESTED', // 결제인증요청
  AUTHENTICATE_SUCCEEDED = 'AUTHENTICATE_SUCCEEDED', // 결제인증성공
  AUTHENTICATE_FAILED = 'AUTHENTICATE_FAILED', // 결제인증실패
  AUTHENTICATE_TIMEOUT = 'AUTHENTICATE_TIMEOUT', // 결제인증시간초과
  AUTHORIZE_REQUESTED = 'AUTHORIZE_REQUESTED', // 결제승인요청
  AUTHORIZE_SUCCEEDED = 'AUTHORIZE_SUCCEEDED', // 결제승인성공
  AUTHORIZE_FAILED = 'AUTHORIZE_FAILED', // 결제승인실패
  DEPOSIT_REQUESTED = 'DEPOSIT_REQUESTED', // 무통장입금대기
  DEPOSIT_CONFIRMED = 'DEPOSIT_CONFIRMED', // 무통장입금확인
  DEPOSIT_NOT_CONFIRMED = 'DEPOSIT_NOT_CONFIRMED', // 무통장입금미확인
  DEPOSIT_TIMEOUT = 'DEPOSIT_TIMEOUT', // 무통장입금대기시간초과
  PAID = 'PAID', // 결제완료
  REFUND_REQUESTED = 'REFUND_REQUESTED', // 환불요청
  REFUND_SUCCEEDED = 'REFUND_SUCCEEDED', // 환불요청성공
  REFUND_FAILED = 'REFUND_FAILED', // 환불요청실패
  REFUNDED = 'REFUNDED', // 환불완료
}

export enum CurrencyCode {
  KRW = 'KRW',
}

export enum IamportIdCode {
  TEST = 'imp46216452',
  PRODUCTION = 'imp58658561',
}

export enum PaymentOrderResult {
  ONGOING = 'ONGOING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  PAYMENT_FAIL = 'PAYMENT_FAIL',
  PARTNER_WAIT = 'PARTNER_WAIT',
  PAY_LATER = 'PAY_LATER', // 나중 결제
  ARS = 'ARS', // ARS 결제
}

export const PaymentValidationLength = {
  NAME: {
    MIN: 2,
    MAX: 50,
  },
  EMAIL: {
    MIN: undefined,
    MAX: 100,
  },
  PHONE: {
    MIN: 9,
    MAX: 20,
  },
  TEXT: {
    MIN: 2,
    MAX: 200,
  },
  EXTRA_RESERVATION_INFOS: {
    MIN: 2,
    MAX: 3000,
  },
  FOREIGN_PASSPORT_NUMBER: {
    MIN: undefined,
    MAX: 20,
  },
};

export const MinimumPaymentAmount = 1000;
/*
주문은 0원 or MinimumPaymentAmount 이상의 금액으로만 결제가 가능합니다.

0 < 결제금액 < 1000 => MinimumPaymentAmount에 걸림 => fitMinimumPaymentAmount
결제금액 < 0 => 판매가보다 할인이 더 적용되어 negative price 임 => fitZeroAmount
*/
export enum PointAdjustmentReason {
  fitMinimumPaymentAmount = 'fitMinimumPaymentAmount',
  fitZeroAmount = 'fitZeroAmount',
}

export const ForeignCardType = {
  MASTER: 'MASTER',
  VISA: 'VISA',
  JCB: 'JCB',
};

export const CreditCardType = {
  GWANGJUBANK: 'GWANGJUBANK',
  KOOKMIN: 'KOOKMIN',
  NONGHYEOP: 'NONGHYEOP',
  LOTTE: 'LOTTE',
  KDBBANK: 'KDBBANK',
  SAMSUNG: 'SAMSUNG',
  SAEMAUL: 'SAEMAUL',
  SUHYEOP: 'SUHYEOP',
  SHINHAN: 'SHINHAN',
  SHINHYEOP: 'SHINHYEOP',
  CITI: 'CITI',
  WOORI: 'WOORI',
  POST: 'POST',
  SAVINGBANK: 'SAVINGBANK',
  JEONBUKBANK: 'JEONBUKBANK',
  JEJUBANK: 'JEJUBANK',
  KAKAOBANK: 'KAKAOBANK',
  HANA: 'HANA',
  HYUNDAI: 'HYUNDAI',
  BC: 'BC',
  KBANK: 'KBANK',
  DINERS: 'DINERS',
  DISCOVER: 'DISCOVER',
  UNIONPAY: 'UNIONPAY',
  ...ForeignCardType,
};

export const CreditCardTypeKorName = {
  [CreditCardType.GWANGJUBANK]: '광주은행',
  [CreditCardType.KOOKMIN]: '국민카드 (KB Pay)',
  [CreditCardType.NONGHYEOP]: 'NH농협카드',
  [CreditCardType.LOTTE]: '롯데카드',
  [CreditCardType.KDBBANK]: 'KDB산업은행',
  [CreditCardType.SAMSUNG]: '삼성카드',
  [CreditCardType.SAEMAUL]: '새마을금고',
  [CreditCardType.SUHYEOP]: 'SH수협은행',
  [CreditCardType.SHINHAN]: '신한카드 (SOL페이)',
  [CreditCardType.SHINHYEOP]: '신협',
  [CreditCardType.CITI]: '씨티카드',
  [CreditCardType.WOORI]: '우리카드',
  [CreditCardType.POST]: '우체국예금보험',
  [CreditCardType.SAVINGBANK]: '저축은행중앙회',
  [CreditCardType.JEONBUKBANK]: '전북은행',
  [CreditCardType.JEJUBANK]: '제주은행',
  [CreditCardType.KAKAOBANK]: '카카오뱅크',
  [CreditCardType.HANA]: '하나카드',
  [CreditCardType.HYUNDAI]: '현대카드',
  [CreditCardType.BC]: '비씨카드',
  [CreditCardType.KBANK]: '케이뱅크',
  [CreditCardType.DINERS]: '다이너스 클럽',
  [CreditCardType.DISCOVER]: '디스커버',
  [CreditCardType.MASTER]: '마스터카드',
  [CreditCardType.VISA]: 'VISA',
  [CreditCardType.UNIONPAY]: '유니온페이',
  [CreditCardType.JCB]: 'JCB',
};

export const PaymentGatewayType = {
  NICEPAY: 'NICEPAY',
  INICIS: 'INICIS',
  NAVERPAY: 'NAVERPAY',
  TOSS: 'TOSS',
  PAYCO: 'PAYCO',
  PAYPAL: 'PAYPAL',
  IAMPORT: 'IAMPORT',
  TOSSPAYMENTS: 'TOSSPAYMENTS',
  KAKAOPAY: 'KAKAOPAY',
};

export const OrderErrorMessage = {
  COUPON: `최소 결제 금액이 ${MinimumPaymentAmount}원이므로 현재 쿠폰은 사용할 수 없어요. 더 적은 금액의 쿠폰으로 변경해주세요.`,
  PAYMENT_AMOUNT: `0원 초과 ${MinimumPaymentAmount}원 미만의 상품은 결제가 불가능합니다`,
  POPUP_BLOCKED: '팝업 차단을 해제해주세요.',
  UNKNOWN: '예측하지 못한 문제가 생겼습니다. \n새로 고침 후 다시 시도해주세요. ',
};

export const Partnership = {
  STORE: 'STORE', // 입점 파트너(기존)
  AGENCY: 'AGENCY', // 여행사 파트너(b2b추가)
  CORPORATION: 'CORPORATION', // 법인제휴 파트너(b2b추가)
  MARKETING: 'MARKETING', // 마케팅 파트너(b2b추가)
};

export const OrderFormTypeMap = {
  /** 기본 주문서 */
  DEFAULT: 'DEFAULT',
  /** 견적서(맞춤여행) - TNA,EXP */
  PROPOSAL: 'PROPOSAL',
  /** 견적서(추가결제) - PKG */
  SURCHARGE: 'SURCHARGE',
} as const;

export const OrderOptionTitleMap = {
  // 2.0 T&A 입점 상품에서 사용
  personal_buyer_info: {
    [SystemProvider.BNB]: '여행자 정보',
    [SystemProvider.STY]: '여행자 정보',
    [SystemProvider.ACM]: '여행자 정보',
    [SystemProvider.TNA]: '여행자 정보',
    [SystemProvider.EXP]: '여행자 정보',
    [SystemProvider.CAR]: '여행자 정보',
  },
  // 2.0 T&A 입점 상품에서 사용
  personal_delivery_info: {
    [SystemProvider.BNB]: '배송 정보',
    [SystemProvider.STY]: '배송 정보',
    [SystemProvider.ACM]: '배송 정보',
    [SystemProvider.TNA]: '배송 정보',
    [SystemProvider.EXP]: '배송 정보',
    [SystemProvider.CAR]: '배송 정보',
  },
  // 2.0 T&A 입점 상품에서 사용
  personal_passport_info: {
    [SystemProvider.BNB]: '여권 정보',
    [SystemProvider.STY]: '여권 정보',
    [SystemProvider.ACM]: '여권 정보',
    [SystemProvider.TNA]: '여권 정보',
    [SystemProvider.EXP]: '여권 정보',
    [SystemProvider.CAR]: '여권 정보',
  },
  // 3.0 렌터카 상품에서 사용
  personal_license_info: {
    [SystemProvider.BNB]: '운전자 정보',
    [SystemProvider.STY]: '운전자 정보',
    [SystemProvider.ACM]: '운전자 정보',
    [SystemProvider.TNA]: '운전자 정보',
    [SystemProvider.EXP]: '운전자 정보',
    [SystemProvider.CAR]: '운전자 정보',
  },
  // 3.0 입점 숙소 상품에서 사용
  personal_korean_name: {
    [SystemProvider.BNB]: '한글 이름',
    [SystemProvider.STY]: '한글 이름',
    [SystemProvider.ACM]: '한글 이름',
    [SystemProvider.TNA]: '한글 이름',
    [SystemProvider.EXP]: '한글 이름',
    [SystemProvider.CAR]: '한글 이름',
  },
  // 2.0 T&A 입점, 3.0 통합숙소, 3.0 입점숙소 상품에서 사용
  personal_english_name: {
    [SystemProvider.BNB]: '체크인 고객 정보',
    [SystemProvider.STY]: '체크인 고객 정보',
    [SystemProvider.ACM]: '체크인 고객 정보',
    [SystemProvider.TNA]: '이용자 정보',
    [SystemProvider.EXP]: '이용자 정보',
    [SystemProvider.CAR]: '이용자 정보',
  },
  // 2.0 T&A 입점, 3.0 입점숙소 상품에서 사용
  personal_all_name: {
    [SystemProvider.BNB]: '대표 여행자 이름',
    [SystemProvider.STY]: '대표 여행자 이름',
    [SystemProvider.ACM]: '대표 여행자 이름',
    [SystemProvider.TNA]: '대표 여행자 이름',
    [SystemProvider.EXP]: '대표 여행자 이름',
    [SystemProvider.CAR]: '대표 여행자 이름',
  },
  // 2.0 T&A 입점 상품에서 사용
  personal_all_name_multi: {
    [SystemProvider.BNB]: '여행자 이름',
    [SystemProvider.STY]: '여행자 이름',
    [SystemProvider.ACM]: '여행자 이름',
    [SystemProvider.TNA]: '여행자 이름',
    [SystemProvider.EXP]: '여행자 이름',
    [SystemProvider.CAR]: '여행자 이름',
  },
  // 3.0 국내숙소에서 사용
  personal_representative: {
    [SystemProvider.BNB]: '대표 여행자 정보',
    [SystemProvider.STY]: '대표 여행자 정보',
    [SystemProvider.ACM]: '대표 여행자 정보',
    [SystemProvider.TNA]: '대표 여행자 정보',
    [SystemProvider.EXP]: '대표 여행자 정보',
    [SystemProvider.CAR]: '대표 여행자 정보',
  },
  // 3.0 통합숙소에서 사용
  personal_english_name_guest: {
    [SystemProvider.BNB]: '예약자 정보',
    [SystemProvider.STY]: '예약자 정보',
    [SystemProvider.ACM]: '예약자 정보',
    [SystemProvider.TNA]: '예약자 정보',
    [SystemProvider.EXP]: '예약자 정보',
    [SystemProvider.CAR]: '예약자 정보',
  },
};
