const language = {
  emailSignin: '이메일로 로그인',
  title: 'Welcome!',
  subtitle: '여행의 모든 것, 마이리얼트립',
  kakaoOAuth: '카카오로 계속하기',
  kakaoOAuthForPartner: '카카오로 로그인',
  appleOAuthForPartner: 'Apple로 로그인',
  facebookOAuth: '페이스북',
  naverOAuth: '네이버',
  emailLogin: '이메일',
  footerDescription: '아직 회원이 아니신가요?',
  footerLinktext: '회원가입',
  keep: '로그인 상태 유지',
  findPassword: '비밀번호 찾기',
  placeholder: {
    password: '비밀번호를 입력해주세요.',
  },
};

export default language;
