const search = {
  title: `'{{title}}' 검색 결과`,
  recommend: '추천',
  destination: '여행지',
  cityAccommodation: '숙소를 찾으시나요?',
  searchAccommodation: '숙소 검색',
  offers: '여행 상품',
  offersCount: '총 {{count}}개',
  suggestion: '제안',
  typingError: '이것을 찾으셨나요?',
  blank: {
    desc: '검색 결과가 없습니다.\n나라/도시명, 상품명을 검색해 보세요.',
    button: '다시 검색하기',
  },
  empty: {
    desc: '선택한 필터로 예약할 수 있는 상품이 없습니다.\n필터를 조절하거나 초기화해보세요.',
    button: '필터 초기화',
  },
  recentKeyword: '최근 검색어',
  removeAll: '전체삭제',
  populars: '인기 여행지',
  removeRecent: {
    title: '최근 검색어 삭제',
    body: '최근 검색어를 모두 지우시겠습니까?',
    button: '모두 삭제',
  },
  default: {
    title: '어디로 떠날지 고민 중이신가요?',
    description: '여행지 혹은 상품명을 검색하시거나 인기 여행지를 살펴보세요!',
  },
  filters: {
    areas: {
      title: '지역',
    },
    calendar: {
      title: '날짜',
    },
    price: {
      title: '가격',
      label: '{{price}}원',
    },
    rating: {
      title: '평점',
      options: [
        {
          key: 'all',
          label: '전체',
        },
        {
          key: '4~5',
          label: '4점 이상',
        },
        {
          key: '5~*',
          label: '5점만',
        },
      ],
    },
    location: {
      title: '여행지',
      city: '도시',
      landmark: '명소',
    },
    tour: {
      title: '투어 형태',
      options: [
        {
          key: 'all',
          label: '전체',
        },
        {
          key: 'group_tour',
          label: '그룹',
        },
        {
          key: 'private_tour',
          label: '프라이빗',
        },
      ],
    },
    immediately: {
      title: '즉시 확정',
    },
    language: {
      title: '한국어',
    },
    duration: {
      title: '소요시간',
      options: [
        {
          key: 'all',
          label: '전체',
        },
        {
          key: '0~120',
          label: '2시간 이내',
          text: '~2시간',
        },
        {
          key: '120~240',
          label: '2시간~4시간',
        },
        {
          key: '240~360',
          label: '4시간~6시간',
        },
        {
          key: '360~*',
          label: '6시간 이상',
          text: '6시간~',
        },
      ],
    },
    meet: {
      title: '만나는 시간',
      options: [
        {
          key: 'all',
          label: '전체',
        },
        {
          key: 'AM',
          label: '오전 (낮 12시이전)',
          text: '오전',
        },
        {
          key: 'PM',
          label: '오후 (낮 12시 ~ 오후 6시)',
          text: '오후',
        },
        {
          key: 'NT',
          label: '저녁 (오후 6시이후)',
          text: '저녁',
        },
      ],
    },
    sort: {
      title: '정렬',
      options: [
        {
          key: 'all',
          label: '추천순',
        },
        {
          key: 'selling_count:desc',
          label: '판매량순',
        },
        {
          key: 'price:asc',
          label: '낮은 가격순',
        },
        {
          key: 'price:desc',
          label: '높은 가격순',
        },
        {
          key: 'review_score:desc',
          label: '평점 높은 순',
        },
        {
          key: 'created_at:desc',
          label: '신상품순',
        },
      ],
    },
  },
  reset: '초기화',
  apply: '적용하기',
  filterReset: '필터 초기화',
};

export default search;
