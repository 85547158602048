import styled from '@emotion/styled';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

export default function RedDot() {
  return <Dot />;
}

const Dot = styled.span`
  position: absolute;
  top: 6px;
  right: 6px;
  width: 4px;
  height: 4px;
  background-color: ${globalVariables.red_400};
  border-radius: 4px;
`;
