import styled from '@emotion/styled';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';
import useClickOutside from '@myrealtrip/design-system/dist/hooks/useClickOutside';
import usePreventBodyScroll from '@myrealtrip/design-system/dist/hooks/usePreventBodyScroll';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import LoggedInItems from './LoggedInItems';
import LoggedOutItems from './LoggedOutItems';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function SideBar({ isOpen, onClose }: Props) {
  const { user } = useLayoutHeaderContext();
  const { ref } = useClickOutside(isOpen, [], onClose);
  usePreventBodyScroll(isOpen);

  const isLoggedIn = user && user.id;

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Container ref={ref}>{isLoggedIn ? <LoggedInItems /> : <LoggedOutItems />}</Container>
      <Dimmed />
    </>
  );
}

const Container = styled.aside`
  position: fixed;
  top: 0;
  width: 240px;
  height: 100%;
  overflow-y: auto;
  background-color: ${globalVariables.white};
  z-index: ${globalVariables.z_dialog};
`;

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${globalVariables.black};
  opacity: 0.3;
  z-index: ${globalVariables.z_dialog - 1};
`;
