export const GTAG_CATEGORY = {
  common: '공통',
  home: '홈',
  tour: '투어&티켓홈',
  checklist: '여행준비',
  wishlist: '저장됨',
  message: '메시지',
  reservation: '예약내역',
  search: '검색',
  accommodation: '숙소 메인',
  hotdeal: '특가여행 메인',
  country: '나라',
  city: '도시',
  tourlist: '투어티켓 리스트',
  hotellist: '호텔 리스트',
  lodginglist: '민박 리스트',
  hotdeallist: '특가여행 리스트',
  offer: '상품 상세페이지',
  purchase: '구매하기 페이지',
  complete: '구매완료 페이지',
  hotel: '호텔 상세페이지',
  hotelPromotion: '호텔 프로모션',
  staylist: '국내 숙소 리스트',
  staydetail: '국내 숙소 상세페이지',
} as const;

const isProduction = process.env.TARGET_ENV === 'production';

export const GTAG_KEY = {
  ga: isProduction ? 'UA-31181930-1' : 'UA-31181930-10',
  gaWebView: isProduction ? 'UA-31181930-14' : 'UA-31181930-15',
  gtm: isProduction ? 'GTM-NCVRS4' : 'GTM-MQQ6ST4',
} as const;

export const GOOGLE_TAG_CONFIGS = {
  ads: {
    key: 'AW-1004447359',
    sendTo: 'AW-1004447359/7ApgCO7535ABEP_M-t4D',
  },
  uac: {
    key: 'AW-804087520',
    sendTo: 'AW-804087520/EK6wCIGt64ADEODNtf8C',
  },
} as const;
