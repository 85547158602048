import { browserEnum } from '../enum';

export const isIE = getBrowserName() === browserEnum.ie;

export default function getBrowserName() {
  const { userAgent, appVersion } = window.navigator;

  if (userAgent.indexOf('Edge') > -1 && appVersion.indexOf('Edge') > -1) {
    return browserEnum.edge;
  }
  if (userAgent.indexOf('Opera') !== -1 || userAgent.indexOf('OPR') !== -1) {
    return browserEnum.opera;
  }
  if (userAgent.indexOf('Chrome') !== -1) {
    return browserEnum.chrome;
  }
  if (userAgent.indexOf('Safari') !== -1) {
    return browserEnum.safari;
  }
  if (userAgent.indexOf('Firefox') !== -1) {
    return browserEnum.firefox;
  }
  if (
    userAgent.indexOf('MSIE') !== -1 ||
    appVersion.indexOf('Trident/') !== -1 ||
    !!document.documentMode === true
  ) {
    return browserEnum.ie;
  }

  return browserEnum.unknown;
}
