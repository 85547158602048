/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

import { ResponseType } from 'mrt-apis';
import { MrtAxiosException } from 'mrt-error';
import { getSigninDeepLink, getSigninWebLink } from 'mrt-utils';

// mrtAxios 인스턴스 초기화 여부 판별 플래그
let initialized = false;

// status >= 400 이지만 Error가 아닌, Success처리를 위한 예외 에러코드
const errorExceptionArr = ['authentication.error.signupFailed'];

export interface MrtAxiosOptions {
  isWebView: boolean;
}
const COMMON_CLIENT_ERROR_CODE = 'common.error.clientError';
const COMMON_SERVER_ERROR_CODE = 'common.error.serverError';

const mrtAxios = axios.create({
  baseURL: process.env.API_V3_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

function getOnBeforeRequest(_option?: Record<string, any>) {
  return function (config: AxiosRequestConfig) {
    return config;
  };
}

function getOnRequestRejected(_option?: Record<string, any>) {
  return function (error: AxiosError) {
    const { message, stack = '' } = error;

    const mrtAxiosException = new MrtAxiosException({
      isMrtError: false,
      status: 500,
      code: COMMON_CLIENT_ERROR_CODE,
      message,
      error,
      stack,
    });

    return Promise.reject(mrtAxiosException);
  };
}

function getOnResponseFulfilled(option?: Record<string, any>) {
  return function (response: AxiosResponse<ResponseType>) {
    const { status, message, code } = response?.data?.result || {};
    const isExceptionCode = errorExceptionArr.includes(code);
    const isError = !isExceptionCode && status >= 400;
    const isWebView = Boolean(option?.isWebView);

    // MRT 응답 코드 >= 400 이면 에러 처리
    if (isError) {
      // 권한 문제라면 로그인 페이지로 리다이렉트
      if (status === 401) {
        if (isWebView) {
          const deepLink = getSigninDeepLink();
          window.location.href = deepLink;
        } else {
          const webLink = getSigninWebLink();

          window.location.href = webLink;
        }
      }

      const mrtAxiosException = new MrtAxiosException({
        isMrtError: true,
        status,
        code,
        message,
        error: new Error(message),
      });

      return Promise.reject(mrtAxiosException);
    }

    return response;
  };
}

function getOnResponseRejected(_option?: Record<string, any>) {
  return function (error: AxiosError<ResponseType>) {
    const {
      status = 500,
      code = COMMON_SERVER_ERROR_CODE,
      message = '',
    } = error?.response?.data?.result || {};

    const mrtAxiosException = new MrtAxiosException({
      isMrtError: false,
      status,
      code,
      message,
      stack: error?.stack || '',
      error,
    });

    return Promise.reject(mrtAxiosException);
  };
}

function getAxios(option?: Record<string, any>) {
  // 최초 호출일 경우에만 초기화 로직 수행
  if (!initialized) {
    const onBeforeRequest = getOnBeforeRequest(option);
    const onRequestRejected = getOnRequestRejected(option);

    const onResponseFulfilled = getOnResponseFulfilled(option);
    const onResponseRejected = getOnResponseRejected(option);

    mrtAxios.interceptors.request.use(onBeforeRequest, onRequestRejected);
    mrtAxios.interceptors.response.use(onResponseFulfilled, onResponseRejected);

    initialized = true;
  }

  return mrtAxios;
}

export default (option?: Record<string, any>) => {
  return getAxios(option);
};
