import { useState } from 'react';

import styled from '@emotion/styled';

import LocalNavItemsPopup, { PopupVerticalNavItem } from './LocalNavItemsPopup';
import LocalNavMoreButton from './LocalNavMoreButton';

interface Props {
  verticalItems: PopupVerticalNavItem[];
  showIcon?: boolean;
}

export default function RestLocalNavItems({ verticalItems, showIcon = false }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  return (
    <Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <LocalNavMoreButton />
      <LocalNavItemsPopup isOpen={isOpen} verticalItems={verticalItems} showIcon={showIcon} />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;
