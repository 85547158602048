import { useDetermineMobileContext } from 'mrt-components';
import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import media from 'mrt-utils/mediaQuery';

import styled from '@emotion/styled';
import Icon from '@myrealtrip/design-system/dist/components/Icon';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';
import { helper } from '@myrealtrip/frontend-weblog';

const { sendClickLog } = helper;

const APP_STORE_ITEMS = [
  {
    id: 'appstore',
    label: 'App Store',
    link: 'https://itunes.apple.com/app/id1141745032',
    description: '앱스토어로 이동하기',
    icon: {
      mobile: 'ic_appstore_20x20_gray_500',
      desktop: 'ic_appstore_20x20_gray_400',
    },
  },
  {
    id: 'googleplay',
    label: 'Google Play',
    link: 'https://play.google.com/store/apps/details?id=com.mrt.ducati',
    description: '구글플레이로 이동하기',
    icon: {
      mobile: 'ic_googleplay_20x20_gray_500',
      desktop: 'ic_googleplay_20x20_gray_400',
    },
  },
];

export default function AppStoreList() {
  const isMobile = useDetermineMobileContext();

  const handleClick =
    ({ description, link }) =>
    () => {
      sendClickLog({
        eventName: 'footer',
        screenName: WeblogScreenName.COMMON,
        pageCategory: WeblogPageCategory.COMMON,
        menuTitle: description,
        targetUrl: link,
      });
    };

  return (
    <Container>
      {APP_STORE_ITEMS.map(({ id, link, description, icon, label }) => {
        const iconName = isMobile ? icon.mobile : icon.desktop;

        return (
          <LinkItem key={id}>
            <Link
              href={link}
              onClick={handleClick({ description, link })}
              target="_blank"
              rel="noopener noreferrer"
              title={description}
            >
              <LinkIcon name={iconName} />
              {isMobile && <LinkLabel>{label}</LinkLabel>}
            </Link>
          </LinkItem>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;

  ${media.mobile} {
    width: 100%;
    justify-content: space-between;
    margin-top: 14px;
  }
`;

const LinkItem = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  margin: 0 10px;

  ${media.mobile} {
    width: 49%;
    height: 40px;
    margin: 0;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  height: 100%;

  ${media.mobile} {
    width: 100%;
    justify-content: center;
    border: 1px solid ${globalVariables.gray_300};
  }
`;

const LinkIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`;

const LinkLabel = styled.span`
  margin-left: 8px;
  font-size: 15px;
  font-weight: 600;
  color: ${globalVariables.gray_600};
`;
