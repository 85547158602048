/* eslint-disable camelcase */
import { RESPONSIVE_IMAGE } from './types';
import reviewPromotionCoupon_1x from './review_promotion_coupon.png';
import reviewPromotionCoupon_2x from './review_promotion_coupon@2x.png';
import reviewPromotionCoupon_3x from './review_promotion_coupon@3x.png';

export const reviewPromotionCoupon: RESPONSIVE_IMAGE = {
  src: reviewPromotionCoupon_1x,
  srcSet: `${reviewPromotionCoupon_1x}, ${reviewPromotionCoupon_2x} 2x, ${reviewPromotionCoupon_3x} 3x`,
};
