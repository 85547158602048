import { createContext, useLayoutEffect, useState, ReactNode } from 'react';

import { BreakPoint } from 'mrt-constants';
import { checkMobileUserAgent } from 'mrt-utils';

interface PropsType {
  children: ReactNode;
}

const defaultValue = window.innerWidth < BreakPoint.MOBILE || checkMobileUserAgent();

export const DetermineMobileContext = createContext(defaultValue);

function DetermineMobileProvider({ children }: PropsType) {
  const [isMobile, setMobile] = useState(defaultValue);

  useLayoutEffect(() => {
    const handleResize = () =>
      setMobile(window.innerWidth < BreakPoint.MOBILE || checkMobileUserAgent());

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  return (
    <DetermineMobileContext.Provider value={isMobile}>{children}</DetermineMobileContext.Provider>
  );
}

export default DetermineMobileProvider;
