import { MrtAxiosV3ErrorOptions, mrtAxiosV3 } from 'mrt-libs';
import { AfterFuncPayload, Rentcar } from 'mrt-types';

import { ResponseType } from '../types';

/* 렌터카 가주문서 생성 */
export interface RentcarPreOrderResponseType extends ResponseType {
  readonly data: Rentcar.PreOrderResponseData;
}

export const createRentcarPreOrderForm = (options: MrtAxiosV3ErrorOptions) => () => {
  const { payload } = options.api || {};

  return mrtAxiosV3(options).post<RentcarPreOrderResponseType>(
    `/order/v2/traveler/orders/order-form`,
    payload,
  );
};

export const afterCreateRentcarPreOrderForm = (
  afterFuncPayload: AfterFuncPayload<RentcarPreOrderResponseType>,
) => {
  const { data, isWebView } = afterFuncPayload;
  const formId = data.data?.orderFormId ?? '';

  if (isWebView) {
    const url = encodeURIComponent(`${process.env.HOME_URL}/instants/order/${formId}`);
    const title = encodeURIComponent('예약하기');

    window.location.href = `mrt://checkout?url=${url}&title=${title}`;

    return;
  }

  window.location.href = `${process.env.HOME_URL}/order/${formId}`;
};
