const button = {
  appDownload: '앱 다운로드',
  apply: '적용하기',
  init: '초기화',
  selectComplete: '선택 완료',
  applyFilter: '필터 적용하기',
  initFilter: '필터 초기화하기',
};

export default button;
