/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback, useContext } from 'react';

import * as LogContext from 'mrt-components/shared/LogProvider';

import { TrackEventType, LogDataType, helper } from '@myrealtrip/frontend-weblog';

const { trackLog } = helper;

export default function useLogStore() {
  const logStore = useContext<LogDataType>(LogContext.LogStateContext);

  const requestLogDataByKeys = useCallback(
    (
      eventType: TrackEventType,
      eventName: string,
      dataKeys: string[],
      logKey?: string | number,
    ) => {
      const targetLogData = {};

      dataKeys.forEach((key) => {
        targetLogData[key] = logStore.current[key];
      });
      trackLog(eventType, { ...targetLogData, eventName }, logKey);
    },
    [logStore],
  );

  const requestLogDataAll = useCallback(
    (eventType: TrackEventType, eventName: string, logKey?: string | number) => {
      trackLog(eventType, { ...logStore.current, eventName }, logKey);
    },
    [logStore],
  );

  const setLogStore = useCallback(
    (draftValue: LogDataType) => {
      logStore.current = draftValue;
    },
    [logStore],
  );

  const setLogDataByKey = useCallback(
    (key: string, value: any) => {
      logStore.current[key] = value;
    },
    [logStore],
  );

  const setLogDataByObject = useCallback(
    (targetObject: Record<string, any>) => {
      Object.entries(targetObject).forEach(([key, value]) => {
        logStore.current[key] = value;
      });
    },
    [logStore],
  );

  const getLogStore = useCallback(() => {
    return logStore.current;
  }, [logStore]);

  const getLogDataByKey = useCallback(
    (key: string) => {
      return logStore.current[key];
    },
    [logStore],
  );

  return {
    requestLogDataByKeys,
    requestLogDataAll,
    setLogStore,
    getLogStore,
    setLogDataByKey,
    setLogDataByObject,
    getLogDataByKey,
  };
}
