import moment, { Moment } from 'moment';

import { MomentFormat } from 'mrt-constants';

export interface TimeFormatterOptions {
  onlyHour: boolean;
  withColon: boolean;
}

const defaultOption: TimeFormatterOptions = {
  onlyHour: false,
  withColon: false,
};

function timeFormatterReadable(
  time: Moment | string | null,
  options?: Partial<TimeFormatterOptions>,
) {
  if (!time) {
    return '';
  }

  const { onlyHour, withColon } = {
    ...defaultOption,
    ...options,
  };

  let timeString: string;

  if (typeof time === 'string') {
    timeString = time;
  } else {
    timeString = time.format(MomentFormat.TIME_STANDARD_24);
  }

  const momentTime = moment(timeString, MomentFormat.LOCAL_TIME);

  if (!momentTime.isValid()) {
    return '';
  }

  const meridiem = momentTime.format('A');
  const hour = momentTime.format('h');
  const minute = momentTime.format('mm');

  if (onlyHour) {
    return `${meridiem} ${hour}시`;
  }

  if (withColon) {
    return `${meridiem} ${hour}:${minute}`;
  }

  return `${meridiem} ${hour}시 ${minute}분`;
}

export default timeFormatterReadable;
