const search = {
  title: `'{{title}}' Search Results`,
  recommend: 'Recommendation',
  destination: 'destination',
  cityAccommodation: 'Are you searching for a place to stay?',
  searchAccommodation: 'Search',
  offers: 'Travel products',
  offersCount: '{{count}} results',
  suggestion: 'Suggestion',
  typingError: 'Are you searching for this?',
  blank: {
    desc: 'No results.\nSearch by country/city name or product name.',
    button: 'Re Search',
  },
  empty: {
    desc: 'There are no products that can be reservation with the selected filter.\nTry adjusting or resetting the filter.',
    button: 'Reset Filters',
  },
  recentKeyword: 'Recent Keywords',
  removeAll: 'Clear All',
  populars: 'Popular Destination',
  removeRecent: {
    title: 'Clear Recent Keywords',
    body: 'Do you want to clear all recent keywords?',
    button: 'Clear All',
  },
  default: {
    title: `Can't decide where to go?`,
    description: 'Search for destination/product or Look around for popular destinations!',
  },
  filters: {
    calendar: {
      title: 'Schedule',
    },
    price: {
      title: 'Price',
      label: '{{price}}won',
    },
    rating: {
      title: 'Rating',
      options: [
        {
          key: 'all',
          label: 'All',
        },
        {
          key: '4~5',
          label: 'More than 4',
        },
        {
          key: '5~*',
          label: 'Only 5',
        },
      ],
    },
    location: {
      title: 'Destination',
      city: 'City',
      landmark: 'Landmark',
    },
    tour: {
      title: 'Tour type',
      options: [
        {
          key: 'all',
          label: 'All',
        },
        {
          key: 'group_tour',
          label: 'Group',
        },
        {
          key: 'private_tour',
          label: 'Private',
        },
      ],
    },
    immediately: {
      title: 'Confirm immediately',
    },
    language: {
      title: 'Korean',
    },
    duration: {
      title: 'Duration time',
      options: [
        {
          key: 'all',
          label: 'All',
        },
        {
          key: '0~120',
          label: 'Within 2 hours',
          text: '~ 2hours',
        },
        {
          key: '120~240',
          label: '2 to 4 hours',
        },
        {
          key: '240~360',
          label: '4 to 6 hours',
        },
        {
          key: '360~*',
          label: 'More than 6 hours',
          text: '6hours ~',
        },
      ],
    },
    meet: {
      title: 'Meeting Time',
      options: [
        {
          key: 'all',
          label: 'All',
        },
        {
          key: 'AM',
          label: 'Morning(before PM 12)',
          text: 'Morning',
        },
        {
          key: 'PM',
          label: 'Afternoon(PM 12 ~ PM 6)',
          text: 'Afternoon',
        },
        {
          key: 'NT',
          label: 'Evening(after 6 PM)',
          text: 'Evening',
        },
      ],
    },
    sort: {
      title: 'Sort',
      options: [
        {
          key: 'all',
          label: 'Recommend',
        },
        {
          key: 'selling_count:desc',
          label: 'Selling',
        },
        {
          key: 'price:asc',
          label: 'Lowest Price',
        },
        {
          key: 'price:desc',
          label: 'Highest Price',
        },
        {
          key: 'review_score:desc',
          label: 'Rating',
        },
        {
          key: 'created_at:desc',
          label: 'Newest',
        },
      ],
    },
  },
  reset: 'Reset',
  apply: 'Apply',
  filterReset: 'Reset filters',
};

export default search;
