export const HeaderTheme = {
  transparent: 'transparent',
  white: 'white',
  blue: 'blue',
} as const;

export const HeaderThemeMessage = {
  MRT_HEADER_CHANGES: 'MRT_HEADER_CHANGES',
} as const;

export const HeaderSize = {
  default: 'default',
  full: 'full',
} as const;

export type HeaderThemeType = keyof typeof HeaderTheme;
export type HeaderThemeMessageType = keyof typeof HeaderThemeMessage;
export type HeaderSizeType = keyof typeof HeaderSize;

export interface HeaderThemeMessageValue {
  theme: HeaderThemeType;
  size: HeaderSizeType;
  disabled?: boolean;
  excludeLnb?: boolean;
}

export type HeaderPostMessage = {
  type: HeaderThemeMessageType;
  value?: HeaderThemeMessageValue;
};

export interface HeaderChangeRequest {
  path: string;
  platform: 'mobile' | 'desktop' | 'all';
  theme?: HeaderThemeType;
  size?: HeaderSizeType;
  disabled?: boolean;
  excludeLnb?: boolean;
}
