// catch 절의 errorResponse는 any 또는 unknown 형식만 허용함
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AxiosResponse } from 'axios';

import { createToast, ToastPropsType } from '@myrealtrip/design-system';
import { CODE_LIST_FOR_USING_SERVER_ERROR_MESSAGE } from 'mrt-constants';
import i18n from 'mrt-i18n';
import { checkMobileUserAgent } from 'mrt-utils';

import { MrtErrorType, MrtAxiosException } from '../types';

interface ToastOptionsType extends ToastPropsType {
  show?: boolean;
}

/**
 * @export
 * @template T
 * @param {() => Promise<AxiosResponse<T>>} api
 * @param {ToastOptionsType} [toastOptions]
 * @returns {response, data, error}
 * @example
 */

const getErrorMessage = (errorResponse: MrtAxiosException): string => {
  const commonErrorCode = 'common.error.serverError';

  if (CODE_LIST_FOR_USING_SERVER_ERROR_MESSAGE.includes(errorResponse.code)) {
    return errorResponse.message || i18n(errorResponse.code) || i18n([commonErrorCode]);
  }

  if (errorResponse.isMrtError) {
    return i18n(errorResponse.code) || i18n([commonErrorCode]);
  }

  return i18n([commonErrorCode]);
};

export default async function mrtApiCall<T>(
  api: () => Promise<AxiosResponse<T>>,
  toastOptions?: ToastOptionsType,
) {
  const defaultToastOptions = {
    show: true,
    color: 'warning',
    position: checkMobileUserAgent() ? 'bottom' : 'top',
  };

  const targetToastOptions = Object.assign(defaultToastOptions, toastOptions || {});

  try {
    const response = await api();

    return { response, data: response.data, error: null };
  } catch (errorResponse: any) {
    const message = getErrorMessage(errorResponse);

    if (targetToastOptions.show) {
      createToast(message, targetToastOptions);
    }

    return {
      response: null,
      data: null,
      error: errorResponse as MrtErrorType<T>,
    };
  }
}
