const booking_assistant = {
  check_price: '금액 조회하기',
  update_price: '금액 업데이트하기',
  reserve_btn: '구매하기',
  ticket_cancel: '취소',
  option_btn: '선택완료',
  calendar_heading: {
    common:
      '여행일을 선택해 주세요. (단, e-티켓은 이용일, 실물 수령상품은 수령일 기재)',
    lodging: '체크인과 체크아웃 날짜를 선택해주세요.',
  },
  loader: {
    title: '여행금액은 매일 자정 환율을 기준으로 업데이트됩니다.',
    loading: '금액 조회중...',
  },
  select_option: '세부 옵션을 선택하세요.',
  detail_price: '세부 여행 금액',
  total_price: '총 여행금액',
  help_link: '금액 조회하기가 무엇인가요?',
  option_default: '옵션 선택',
  option_default_mobile: '날짜를 선택하면 가능한 옵션이 표시됩니다.',
  travelers_default: '인원 선택',
  beginAt_default: '날짜 선택',
  cancellation_policy: '취소∙환불 규정',
  error_message: '날짜 또는 옵션을 확인해주세요.',
  option_description: '▸ 옵션 설명: ',
  price_explain: {
    title: '금액 조회하기가 무엇인가요?',
    content:
      ' 여행 상품의 가격은 같은 상품이라 할지라도 여행 날짜, 그리고 여행자의 나이와 인원에 따라 달라질 수 있습니다. 마이리얼트립에서는 여행자님의 편의를 위해 결제를 진행하기 전에 상품의 가격을 조회해볼 수 있는 기능을 제공합니다. 더 궁금한 점이 있으시다면, 고객지원실로 연락해주세요.',
  },
};

export default booking_assistant;
