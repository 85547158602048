import React from 'react';
import DotDotDot from 'react-dotdotdot';

import PropTypes from 'prop-types';

import styles from './CardCategory.module.scss';

function CardCategory(props) {
  const { categories } = props;
  const filteredCategories = categories.filter((category) => Boolean(category));
  const categoriesStr = filteredCategories.join(' ・ ');

  return (
    <div className={styles.container}>
      <DotDotDot className={styles.label} clamp={1}>
        {categoriesStr}
      </DotDotDot>
    </div>
  );
}

CardCategory.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
};

CardCategory.defaultProps = {
  categories: [],
};

function areEqual(prev, next) {
  return prev.categories === next.categories;
}

export default React.memo(CardCategory, areEqual);
