export enum OndaPlaceType {
  GEO = 'GEO',
  POINT_OF_INTERESTS = 'POINT_OF_INTERESTS',
  GID = 'GID',
  NAME = 'NAME',
  LAYER = 'LAYER',
  GPLACE = 'GPLACE',
}

export enum OndaPlaceSearchType {
  ALL = 'ALL',
  HOTEL = 'HOTEL',
  PENSION = 'PENSION',
  PLACE = 'PLACE',
}
