const language = {
  title: {
    profile: '계좌관리',
    manage: '환불계좌 관리',
    register: '환불계좌 등록',
  },
  account: '환불계좌',
  noAccount: '등록된 계좌가 없습니다.',
  enterAccount: '환불 받으실 계좌 정보를 입력해주세요.',
  registerAccount: '계좌 등록',
  changeAccount: '환불계좌 변경',
  registration: '등록',
  change: '변경',
};

export default language;
