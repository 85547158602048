import axios from 'axios';
import { useState, useEffect } from 'react';

import Header from 'mrt-layout/src/entries/MrtHeader';

import { Constant } from '../common';
import withResize from '../components/resize/withResize';
import { userType } from '../types';

const API_URL = `${process.env.API_V2_URL}/flight/contents/vertical_items`;

function HeaderFlight(props) {
  const { user } = props;

  const [isLoading, setLoading] = useState(true);
  const [lnbVerticalItems, setLnbVerticalItems] = useState([]);

  useEffect(() => {
    async function fetchApi() {
      try {
        const response = await axios.get(API_URL, { timeout: 10000 });
        const { data } = response || {};
        const { vertical_items: newVerticalItems } = data.data || {};

        setLnbVerticalItems(newVerticalItems);
      } catch (error) {
        setLnbVerticalItems([...Constant.defaultLnbMenus]);
      }

      setLoading(false);
    }

    fetchApi();
  }, []);

  return isLoading ? null : <Header user={user} lnbVerticalItems={lnbVerticalItems} isFlightMode />;
}

HeaderFlight.propTypes = {
  user: userType,
};

HeaderFlight.defaultProps = {
  user: {},
};

export default withResize(HeaderFlight);
