/* eslint-disable @typescript-eslint/naming-convention */
import styled from '@emotion/styled';
import { helper } from '@myrealtrip/frontend-weblog';
import { WeblogPageCategory, WeblogScreenName, MYPAGE_URL } from 'mrt-constants';

import i18n from '@layout/app/utils/i18n';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';
import { resetButton } from '@layout/styles/reset';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

const { sendClickLog } = helper;

type Props = {
  showDetail?: boolean;
  onClick?: () => void;
};

export default function ProfileCardButton({ showDetail, onClick }: Props) {
  const { theme, user } = useLayoutHeaderContext();
  const isTransparent = theme !== 'white';
  const { profile_image, username } = user ?? {};

  const profileThumbSrc = profile_image?.urls.thumb ?? '';
  const profileManageUrl = MYPAGE_URL;

  const handleProfileManageClick = () => {
    sendClickLog({
      eventName: 'topmenu',
      screenName: WeblogScreenName.COMMON,
      pageCategory: WeblogPageCategory.COMMON,
      itemKind: 'menu',
      itemName: '프로필 관리',
      targetUrl: profileManageUrl,
    });
  };

  if (!user) {
    return null;
  }

  return (
    <Container>
      <CardButton type="button" isTransparent={isTransparent} onClick={onClick}>
        <PofileThumb src={profileThumbSrc} alt="" />
      </CardButton>
      {showDetail && (
        <DetailContainer>
          <UserName>{username}</UserName>
          <ProfileManageLink href={profileManageUrl} onClick={handleProfileManageClick}>
            {i18n.t('header:profileManage')}
          </ProfileManageLink>
        </DetailContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CardButton = styled.button<{ isTransparent: boolean }>`
  ${resetButton};

  display: inline-block;
  width: 36px;
  height: 36px;
  border: 2px solid
    ${({ isTransparent }) =>
      isTransparent ? 'rgba(255, 255, 255, 0.3)' : globalVariables.gray_100};
  border-color: ${globalVariables.white};
  border-radius: 50%;
  transition: border-color 0.2s ease;
  overflow: hidden;

  &:hover {
    border-color: ${({ isTransparent }) =>
      isTransparent ? 'rgba(255, 255, 255, 0.6)' : globalVariables.gray_100};
  }
`;

const PofileThumb = styled.img`
  width: 100%;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 16px;
`;

const UserName = styled.span`
  font-size: 17px;
  font-weight: 600;
  color: ${globalVariables.gray_700};
  line-height: 25px;
`;

const ProfileManageLink = styled.a`
  font-size: 14px;
  font-weight: 500;
  color: ${globalVariables.blue_500};
  line-height: 21px;
  transition: color 0.2s ease;

  &:hover {
    color: ${globalVariables.blue_800};
  }
`;
