import { useState } from 'react';

import { Dialog } from '@myrealtrip/design-system';
import { useDetermineMobileContext } from 'mrt-components';
import media from 'mrt-utils/mediaQuery';

import styled from '@emotion/styled';
import { resetButton } from '@layout/styles/reset';

export default function ISMS() {
  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useDetermineMobileContext();

  const DialogComponent = isMobile ? Dialog.Mobile : Dialog.Desktop;

  const handleToggleDialog = (isDialogOpen: boolean) => setIsOpen(isDialogOpen);

  return (
    <Container>
      <ISMSButton type="button" onClick={() => handleToggleDialog(true)}>
        <ISMSIcon src={`${process.env.S3_ASSET_URL}/etc/img-logo-isms@2x.png`} alt="ISMS-P" />
      </ISMSButton>
      <DialogComponent isOpen={isOpen} toggle={handleToggleDialog} titleText={' '}>
        {isOpen && (
          <ISMSImage
            src="https://dffoxz5he03rp.cloudfront.net/image-isms.png"
            alt="ISMS-P 인증서"
          />
        )}
      </DialogComponent>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  width: auto;
  margin-left: auto;

  ${media.mobile} {
    position: absolute;
    right: 16px;
    bottom: 86px;
  }
`;

const ISMSButton = styled.button`
  ${resetButton};
`;

const ISMSIcon = styled.img`
  width: 56px;
`;

const ISMSImage = styled.img`
  width: 100%;
`;
