import styled from '@emotion/styled';
import media from 'mrt-utils/mediaQuery';

import { getTravelerLink } from '@layout/app/utils/getTravelerLink';
import i18n from '@layout/app/utils/i18n';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import SiteMapItems from './SiteMapItems';

const SITE_MAP_ITEMS = [
  {
    title: i18n.t('footer:about'),
    items: [
      {
        label: i18n.t('footer:about-company'),
        link: 'https://careers.myrealtrip.com/north_star',
      },
      {
        label: i18n.t('footer:about-career'),
        link: 'https://careers.myrealtrip.com',
      },
      {
        label: i18n.t('footer:about-announcement'),
        link: getTravelerLink('/announcement'),
      },
    ],
  },
  {
    title: i18n.t('footer:partner'),
    items: [
      {
        label: i18n.t('footer:partner-registGuide'),
        link: `${process.env.PARTNER_URL}/welcome`,
      },
      {
        label: i18n.t('footer:partner-b2b'),
        link: `${process.env.PARTNER_URL}/welcome`,
      },
    ],
  },
  {
    title: i18n.t('footer:help'),
    items: [
      {
        label: i18n.t('footer:help-faq'),
        link: getTravelerLink('/help/faq'),
      },
      {
        label: i18n.t('footer:help-price'),
        link: getTravelerLink('/best_price'),
      },
    ],
  },
];

export default function SiteMap() {
  return (
    <Container>
      {SITE_MAP_ITEMS.map(({ title, items }) => (
        <SiteMapItems key={title} title={title} menuItems={items} />
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 50%;

  ${media.mobile} {
    width: 100%;
    margin-top: 32px;
    flex-direction: column;
    border-top: 1px solid ${globalVariables.gray_100};
  }
`;
