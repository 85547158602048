import styled from '@emotion/styled';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';

import LoggedInItems from './LoggedInItems';
import LoggedOutItems from './LoggedOutItems';

export default function GlobalNavItems() {
  const { user } = useLayoutHeaderContext();

  const isLoggedIn = user && user.id;

  return <Container>{isLoggedIn ? <LoggedInItems /> : <LoggedOutItems />}</Container>;
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  height: 100%;
  align-items: center;
`;
