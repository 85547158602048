import { useContext, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { getLocalStorageItem, setLocalStorageItem } from '@myrealtrip/browser';
import { ResponsiveImage, usePreventBodyScroll } from '@myrealtrip/design-system';
import { helper } from '@myrealtrip/frontend-weblog';
import { WebViewContext, useDetermineMobileContext } from 'mrt-components';
import { LocalStorageKey } from 'mrt-constants';
import { useLayerViewLog, useDeepLink } from 'mrt-hooks';
import { DeepLinkData, PageLogData } from 'mrt-types';
import CookieManager, { CookieKey } from 'mrt-utils/cookieManager';

const { sendClickLog } = helper;

interface BlackList {
  domain: string;
  queries: Array<{ name: string; value: string[] }>;
}

/**
 * 외부 서비스를 통해 진입한 경우, 외부 서비스 정책 상 앱 설치 유도 팝업을 미노출 해야하는 경우 대응한다.
 * @domain 필터링할 외부 서비스의 도메인
 * @queries 현재 URL Query에 외부 서비스 식별자가 있는 경우 필터링에 사용한다.
 */
const BLACK_LIST: BlackList[] = [
  {
    domain: 'shopping.naver.com',
    queries: [{ name: 'utm_source', value: ['NaverShopping'] }],
  },
];

function isBlackList({ referrer, currentHref }: { referrer: string; currentHref: string }) {
  const blackListDomains = BLACK_LIST.map(({ domain }) => domain);

  if (blackListDomains.some((domain) => referrer.includes(domain))) {
    return true;
  }

  try {
    const currentURL = new URL(currentHref);
    const blackListQueries = BLACK_LIST.map(({ queries }) => queries);

    return blackListQueries.some((query) => {
      return query.some(({ name, value }) =>
        value?.some((queryValue) => queryValue === currentURL.searchParams.get(name ?? '')),
      );
    });
  } catch (error) {
    return false;
  }
}

function isDefaultDisplay({
  localStorageValue,
  referrer,
  currentHref,
}: {
  localStorageValue: unknown;
  referrer: string;
  currentHref: string;
}) {
  if (isBlackList({ referrer, currentHref })) {
    return false;
  }

  return localStorageValue !== 'true';
}

interface Props {
  deepLinkData: DeepLinkData;
  productThumbnail: string;
  productTitle: string;
  pageLogData: PageLogData;
  itemId: number;
}

const downloadButtonText = '앱에서 편하게 보기';
const cancelButtonText = '다음에 볼게요';

function AppInstallPopup({
  productTitle,
  productThumbnail,
  pageLogData,
  deepLinkData,
  itemId,
}: Props) {
  const { isWebView } = useContext(WebViewContext);
  const isMobile = useDetermineMobileContext();
  const [isDisplay, setIsDisplay] = useState(
    isDefaultDisplay({
      localStorageValue: getLocalStorageItem(LocalStorageKey.APP_INSTALL_BANNER),
      referrer: document.referrer,
      currentHref: window.location.href,
    }),
  );
  const isMarketing = CookieManager.get(CookieKey.PARTNERSHIP_CODE) ?? null;
  const canDisplay = isMobile && isDisplay && !isWebView && !isMarketing;
  usePreventBodyScroll(canDisplay);
  const { templateId, deepLink, webLink, afSub1 } = deepLinkData;
  const { openDeepLink } = useDeepLink({
    templateId,
    link: {
      deepLink: deepLink,
      webLink: webLink,
    },
    defaultMediaSourceValue: 'mrt_app_install_banner',
    afSub1,
  });

  const baseLogData = useMemo(
    () => ({
      ...pageLogData,
      itemId,
      popupTitle: '지금 앱 다운 받고 여행을 편하게 관리하세요',
    }),
    [itemId, pageLogData],
  );

  useLayerViewLog(
    {
      ...baseLogData,
      eventName: 'front_popup',
      itemKind: 'popup',
      itemName: 'app_install',
    },
    canDisplay,
  );

  const handleClickLog = ({ itemName }: { itemName: string }) => {
    sendClickLog({
      ...baseLogData,
      eventName: 'front_button',
      itemKind: 'button',
      itemName,
      popupName: 'app_install',
    });
  };

  const closePopup = () => {
    setLocalStorageItem(LocalStorageKey.APP_INSTALL_BANNER, 'true', 168);
    setIsDisplay(false);
  };

  const handleClickDownload = () => {
    handleClickLog({ itemName: downloadButtonText });

    openDeepLink('redirect');
  };

  const handleClosePopup = () => {
    handleClickLog({ itemName: cancelButtonText });

    closePopup();
  };

  if (!canDisplay) {
    return null;
  }

  return (
    <Container>
      <Wrapper>
        <Contents>
          <Image src={productThumbnail} />
          <Title>{productTitle}</Title>
          <Text>
            지금 앱 다운 받고
            <br />
            여행을 편하게 관리하세요
          </Text>
        </Contents>

        <Buttons>
          <DownloadButton onClick={handleClickDownload}>{downloadButtonText}</DownloadButton>
          <CancelButton onClick={handleClosePopup}>{cancelButtonText}</CancelButton>
        </Buttons>
      </Wrapper>

      <Dimmed onClick={handleClosePopup} />
    </Container>
  );
}

export default AppInstallPopup;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-popup);
`;

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--black);
  opacity: 0.3;
`;

const Wrapper = styled.div`
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 12px 16px;
  max-width: 310px;
  z-index: 1;
  background-color: var(--white);
`;

const Contents = styled.div`
  margin-top: 16px;
  padding: 38px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Image = styled(ResponsiveImage)`
  width: 88px;
  height: 88px;
  border-radius: 16px;
  object-fit: cover;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: -0.01em;
  color: var(--gray-600);

  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.24;
  letter-spacing: -0.02em;
  color: var(--gray-1000);
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  background-color: var(--white);
  letter-spacing: -0.02em;
  line-height: 1.24;
`;

const DownloadButton = styled(Button)`
  border-radius: 8px;
  background-color: var(--blue-500);
  color: var(--white);
  font-weight: 600;
  font-size: 16px;
  padding: 14px 0;
`;

const CancelButton = styled(Button)`
  color: var(--gray-600);
  font-weight: 500;
  font-size: 12px;
  padding: 8px 0;
`;
