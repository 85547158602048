/* eslint-disable @typescript-eslint/naming-convention */
import { fetchHasFreshNotification } from '@layout/app/api/notification';
import { useQuery } from '@tanstack/react-query';

export default function useFetchHasFreshNotification(isFlightMode: boolean) {
  const queryResult = useQuery(['fetchHasFreshNotification'], () => fetchHasFreshNotification(), {
    enabled: !isFlightMode,
    refetchOnWindowFocus: false,
  });

  return {
    ...queryResult,
    data: queryResult.data?.data,
  };
}
