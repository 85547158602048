import React from 'react';
import PropTypes from 'prop-types';

import { contentsType } from '../../types';
import Grid from '../shared/Grid';
import Carousel from '../shared/Carousel';
import ContentsCarouselItem from './ContentsCarouselItem';
import styles from './ContentsCarousel.module.scss';

const options = {
  setWrapperSize: true,
  spaceBetween: 0,
  slidesPerView: 3,
  slidesPerGroup: 3,
  slidesOffsetBefore: 0,
  slidesOffsetAfter: 0,
  breakpoints: {
    767: {
      slidesPerView: 'auto',
      slidesPerGroup: 1,
      freeMode: true,
      slidesOffsetBefore: 16,
      slidesOffsetAfter: 16,
      grabCursor: true,
    },
  },
};

function ContentsCarousel(props) {
  const {
    contents,
    location,
    onClick,
    openNewTab,
    isWebView,
    qs,
    verticalIndex,
  } = props;

  return (
    <Grid customClass={styles.container} isFullWidth>
      <Carousel options={options} keyName="contents">
        {contents.map((content, index) => (
          <ContentsCarouselItem
            key={content.id}
            cityName={location}
            onClick={onClick}
            openNewTab={openNewTab}
            isWebView={isWebView}
            sectionHorizontalIndex={index}
            verticalIndex={verticalIndex}
            qs={qs}
            {...content}
          />
        ))}
      </Carousel>
    </Grid>
  );
}

ContentsCarousel.propTypes = {
  contents: PropTypes.arrayOf(contentsType).isRequired,
  onClick: PropTypes.func,
  openNewTab: PropTypes.bool,
  isWebView: PropTypes.bool,
  qs: PropTypes.string,
  verticalIndex: PropTypes.number,
};

ContentsCarousel.defaultProps = {
  onClick: () => null,
  openNewTab: false,
  isWebView: false,
  qs: null,
  verticalIndex: 0,
};

export default ContentsCarousel;
