import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

import { GlobalSearch } from 'mrt-types';

import styled from '@emotion/styled';
import Dialog from '@myrealtrip/design-system/dist/components/Containers/Dialog';
import { globalVariables } from '@myrealtrip/design-system/dist/styles/variables';

import SearchInput from '../SearchInput';
import SearchSuggestionContent from '../SearchSuggestionContent';
import { searchTerm } from '../recoil';

const { LAYOUT, COLOR } = GlobalSearch;

interface Props {
  isOpen: boolean;
  variations: GlobalSearch.Variation;
  placeholder: string;
  onDialogToggle: (isOpen: boolean) => void;
  onPressKeyboard: (key: string) => void;
}

function SearchSuggestionDialog({
  isOpen,
  variations,
  placeholder,
  onDialogToggle,
  onPressKeyboard,
}: Props) {
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const setSearchKeyword = useSetRecoilState(searchTerm);
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const defaultVariations: GlobalSearch.Variation = {
    layout: LAYOUT.header,
    color: COLOR.white,
  };

  const handleDialogClose = () => {
    setSearchKeyword('');
    onDialogToggle(false);
  };

  const handleClearInput = () => {
    hiddenInputRef.current?.focus();
    searchInputRef.current?.focus();
  };

  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <DialogContainer
      isOpen={isOpen}
      titleText=" "
      toggle={() => {}}
      onCloseClick={handleDialogClose}
    >
      <SearchInputContainer>
        <SearchInput
          ref={searchInputRef}
          variations={defaultVariations}
          placeholder={placeholder}
          popupSide
          onDialogToggle={onDialogToggle}
          onPressKeyboard={onPressKeyboard}
          onClearInput={handleClearInput}
        />
        <HiddenInput tabIndex={-1} ref={hiddenInputRef} />
      </SearchInputContainer>
      <SearchSuggestionContentContainer>
        <SearchSuggestionContent variations={variations} />
      </SearchSuggestionContentContainer>
    </DialogContainer>
  );
}

export default SearchSuggestionDialog;

const DialogContainer = styled(Dialog.Mobile)`
  .mrt-dialog-content {
    padding: 0;
  }
  .mrt-dialog-title {
    div:first-of-type {
      border-bottom: 0;
    }
  }

  z-index: ${globalVariables.z_dialog}; // var(--z-dialog)
`;

const SearchInputContainer = styled.div`
  position: relative;
  margin-top: 8px;
  padding: 0 16px;
`;

const SearchSuggestionContentContainer = styled.div`
  margin-top: 8px;
`;

const HiddenInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
`;
