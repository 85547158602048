import qs from 'qs';
import { GTAG_KEY } from 'mrt-eventlog/gtag/constants';

function hasGtag() {
  return window.gtag && typeof window.gtag === 'function';
}

function getListName() {
  const query = window.location.search.slice(1);
  const { listName } = qs.parse(query);

  return listName;
}

export function sendCustomEvent(category, action, label = '') {
  if (!hasGtag() || !category || !action) {
    return;
  }

  window.gtag('event', action, {
    send_to: window.isWebview ? GTAG_KEY.gaWebView : GTAG_KEY.ga,
    event_category: category,
    event_label: label,
  });
}

export function sendEcommerceEvent(category, offers, listName = getListName()) {
  if (!hasGtag()) {
    return;
  }

  window.gtag('event', category, {
    send_to: GTAG_KEY.ga,
    items: offers.map((offer) => ({
      id: `${offer.id}`,
      name: offer.title,
      list_name: listName,
      brand: offer.guide.name,
      category: offer.type,
      price: offer.price.main,
      variant: offer.category,
    })),
  });
}
