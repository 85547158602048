import styled from '@emotion/styled';
import { getSigninWebLink } from 'mrt-utils';

import GlobalNavButtonItem from '@layout/app/components/GlobalNavItems/GlobalNavButtonItem';
import GlobalNavRoundButton from '@layout/app/components/GlobalNavItems/GlobalNavRoundButton';
import i18n from '@layout/app/utils/i18n';

export default function LoggedOutItems() {
  return (
    <Container>
      <GlobalNavButtonItem
        type="link"
        label={i18n.t('header:signInPartner')}
        pathname={`${process.env.PARTNER_URL}/signIn`}
      />
      <GlobalNavRoundButton label={i18n.t('header:signInUp')} pathname={() => getSigninWebLink()} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  > :not(:last-child) {
    margin-right: 8px;
  }
`;
