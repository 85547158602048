import { PropsWithChildren, Suspense } from 'react';
import { RecoilRoot } from 'recoil';

import { FallbackLoading, DetermineMobileProvider, QueryClientProvider } from 'mrt-components';

import GlobalVariables from '@layout/styles/GlobalVariables';

function LayoutProvider({ children }: PropsWithChildren<unknown>) {
  return (
    <DetermineMobileProvider>
      <GlobalVariables />
      <RecoilRoot>
        <QueryClientProvider>
          <Suspense fallback={<FallbackLoading />}>{children}</Suspense>
        </QueryClientProvider>
      </RecoilRoot>
    </DetermineMobileProvider>
  );
}

export default LayoutProvider;
