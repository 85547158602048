import { useState, useEffect } from 'react';

import { useDetermineMobileContext, GlobalSearch } from 'mrt-components';
import { WeblogPageCategory, WeblogScreenName } from 'mrt-constants';
import { LAYOUT, COLOR } from 'mrt-types/globalSearch/variation';
import media from 'mrt-utils/mediaQuery';

import styled from '@emotion/styled';
import GlobalNavItems from '@layout/app/components/GlobalNavItems';
import GnbLogo from '@layout/app/components/GnbLogo';
import SearchButton from '@layout/app/components/SearchButton';
import SideBar from '@layout/app/components/SideBar';
import SideBarButton from '@layout/app/components/SideBarButton';
import { useLayoutHeaderContext } from '@layout/modules/LayoutHeaderContext';
import { helper } from '@myrealtrip/frontend-weblog';

const { sendClickLog } = helper;

export default function GNB() {
  const { theme } = useLayoutHeaderContext();
  const isMobile = useDetermineMobileContext();

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);

  const isExperiencesPage = window.location.pathname.startsWith('/experiences');

  const handleSearchDialogToggle = (isOpen: boolean) => setIsSearchDialogOpen(isOpen);

  const handleOpenSearchPopup = () => {
    sendClickLog({
      itemKind: 'button',
      screenName: WeblogScreenName.COMMON,
      pageCategory: WeblogPageCategory.COMMON,
      eventName: 'search_init_button',
      itemName: 'search_init_button',
    });
    handleSearchDialogToggle(true);
  };
  const handleOpenSideBar = () => setIsSideBarOpen(true);
  const handleCloseSideBar = () => setIsSideBarOpen(false);

  useEffect(() => {
    const isMountedSideBar = isMobile && isSideBarOpen;
    const headerEl = document.getElementById('mrt-main-header');

    if (!headerEl) {
      return;
    }

    // 사이드바가 열렸을 때, 포탈로 마운트된 컴포넌트 보다 상위에 노출되도록 z-index를 조정한다.
    if (isMountedSideBar) {
      headerEl.style.zIndex = '40001'; // --z-alert(40000) + 1
      return;
    }

    headerEl.style.removeProperty('z-index');
  }, [isMobile, isSideBarOpen]);

  if (isMobile) {
    return (
      <Container>
        <SideBarButton onClick={handleOpenSideBar} />
        <GnbLogo />
        <SearchButton onClick={handleOpenSearchPopup} />
        <GlobalSearch
          isShowSearchInput={false}
          isSuggestionDialogOpen={isSearchDialogOpen}
          onSuggestionDialogToggle={handleSearchDialogToggle}
        />
        {isSideBarOpen && <SideBar isOpen={isSideBarOpen} onClose={handleCloseSideBar} />}
      </Container>
    );
  }
  return (
    <Container>
      <GnbLogo />
      {/* TnA 홈에는 콘텐츠에 글로벌 검색 UI가 존재하므로 노출하지 않는다 */}
      {!isExperiencesPage && (
        <GlobalSearchContainer>
          <GlobalSearch
            variations={{
              layout: LAYOUT.header,
              color: theme === 'white' ? COLOR.white : COLOR.transparent,
            }}
          />
        </GlobalSearchContainer>
      )}
      <GlobalNavItems />
    </Container>
  );
}

const Container = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  max-width: 1060px;
  margin: 0 auto;

  ${media.mobile} {
    justify-content: space-between;
    height: 48px;
  }
`;

const GlobalSearchContainer = styled.div`
  position: relative;
  width: 340px;
  height: 48px;
  margin-left: 24px;
`;
