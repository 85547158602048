import axios from 'axios';
import ReactOnRails from 'react-on-rails';

export const apiClient = axios.create({
  headers: {
    'X-CSRF-TOKEN': ReactOnRails.authenticityToken(),
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
  timeout: 15000,
});
