const language = {
  title: {
    profile: 'Bank Account',
    manage: 'Bank Account',
    register: 'Bank Account',
  },
  account: 'Refund Account',
  noAccount: 'No registered account.',
  enterAccount: 'Please enter the account information for the refund.',
  registerAccount: 'Register bank account',
  changeAccount: 'Change bank account',
  registration: 'Registration',
  change: 'Change',
};

export default language;
