export default {
  agreement: 'Terms of Use',
  traveler: 'Traveler Terms',
  partner: 'Partner Terms',
  location: 'Location Information Terms of Use',
  video: 'Video information processing equipment operation management policy',
  unionstay: 'Overseas Stay Terms',
  provide: {
    recipient: 'Recipient',
    purpose: 'Purpose',
    info: 'Information',
  },
  affiliate: {
    lodging: 'Lodgings',
    land: 'Lands',
  },
};
