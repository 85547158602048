const location = {
  popularCity: 'Popular cities',
  hotelPromotion: 'See details',
  contents: 'My Real Magazine',
  more: 'Show more',
  city: {
    recommend: {
      title: 'Recommended product',
      key: 'recommend_offers',
      event: { category: '도시', action: '상품_추천' },
    },
    promote: {
      title: 'Only on MyRealTrip',
      key: 'promote_offers',
      event: { category: '도시', action: '상품_마리트Only' },
    },
    landmarks: {
      title: 'Tourist Sites',
      key: 'landmarks',
      event: { category: '도시', action: '관광명소' },
    },
    experience_categories: {
      title: 'Bestseller Tour&Ticket',
      key: 'best_offers',
      more: true,
      group: 'experience',
      event: { category: '도시', action: '상품_베스트셀러' },
    },
    hotels: {
      title: 'Popular Hotels',
      subtitle: 'How to get a reward upto 8%',
      key: 'hotels',
      more: true,
      event: { category: '도시', action: '상품_인기호텔' },
    },
    stays: {
      title: 'Popular Cottages',
      key: 'stays',
      more: true,
      event: { category: '도시', action: '상품_인기국내숙소' },
    },
    lodging: {
      title: 'Trending Korean lodging',
      key: 'lodging_offers',
      more: true,
      group: 'lodging',
      event: { category: '도시', action: '상품_인기민박' },
    },
    package: {
      title: 'Trending Package products',
      key: 'package_offers',
      more: true,
      group: 'hot_deal',
      event: { category: '도시', action: '상품_인기 패키지' },
    },
    themes: {
      key: 'theme_offers',
      more: true,
      event: { category: '도시', action: '상품_테마' },
    },
  },
  country: {
    popular: {
      title: 'Trending tour & ticket by destination',
      key: 'city_offers',
      more: true,
      group: 'experience',
      event: { category: '나라', action: '상품_인기투어티켓' },
    },
    lodging: {
      title: 'Trending Korean lodgings',
      key: 'lodging_offers',
      more: true,
      group: 'lodging',
      event: { category: '나라', action: '상품_인기민박' },
    },
    themes: {
      key: 'theme_offers',
      more: true,
      event: { category: '나라', action: '상품_테마' },
    },
  },
};

export default location;
