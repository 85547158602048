import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

export type LayoutFooterContextType = {
  user: {
    id: number;
    username: string;
    email: string;
    phoneNumber: string;
  } | null;
  isDisabled: boolean;
  setDisabled: (nextDisabled: boolean) => void;
};

const defaultState = {
  user: null,
  isDisabled: false,
  setDisabled: () => null,
};

export const LayoutFooterContext = createContext<LayoutFooterContextType>(defaultState);

export function LayoutFooterContextProvider({
  initialState,
  children,
}: PropsWithChildren<{
  initialState: LayoutFooterContextType;
}>) {
  const combineState = { ...defaultState, ...initialState };
  const [currentDisabled, setCurrentDisabled] = useState<boolean>(combineState.isDisabled ?? false);
  const handleDisabled = useCallback(
    (nextDisabled: boolean) => setCurrentDisabled(nextDisabled),
    [],
  );

  return (
    <LayoutFooterContext.Provider
      value={{
        ...combineState,
        isDisabled: currentDisabled,
        setDisabled: handleDisabled,
      }}
    >
      {children}
    </LayoutFooterContext.Provider>
  );
}

export function useLayoutFooterContext() {
  return useContext(LayoutFooterContext);
}
